import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";

export const getData = (navigate: NavigateFunction) => [
  {
    title: t("Snap.Tariffs.Basic.Title"),
    tariffIncludes: [
      t("Snap.Tariffs.Basic.Includes.AIThreatDetection"),
      t("Snap.Tariffs.Basic.Includes.OnChainRiskScoring"),
      t("Snap.Tariffs.Basic.Includes.DataAggregator"),
      t("Snap.Tariffs.Basic.Includes.ImmediateUpdateTime"),
      t("Snap.Tariffs.Basic.Includes.CrowdSourcedDataAccess"),
      t("Snap.Tariffs.Basic.Includes.APIPlan"),
      t("Snap.Tariffs.Basic.Includes.TransactionInspector"),
    ],
    button: {
      name: t("Actions.StartForFree"),
      handler: () => navigate(APP_ROUTES.SNAP.CONNECT),
    },
    isGradientBG: true,
  },
  {
    title: t("Snap.Tariffs.Advanced.Title"),
    description: t("Snap.Tariffs.Advanced.Description"),
    label: t("Snap.Tariffs.Advanced.Label"),
  },
];
