import { t } from "i18next";

import { useGetRefRewardStatsQuery } from "services/api/hapi/ref";
import { Tooltip } from "shared/components/Tooltip";
import { M_DASH } from "shared/constants";

import styles from "./styles";

export const ReferralStats = () => {
  const { data } = useGetRefRewardStatsQuery();

  if (!data || data.errorCode === 1) return null;

  const stats = [
    {
      title: t("Dashboard.Referrals.Stats.AccumulatedRewards"),
      tooltip: t("Dashboard.Tooltip.AccumulatedRewards"),
      value: data.accumulated_rewards || M_DASH,
    },
    {
      title: t("Dashboard.Referrals.Stats.Treasury"),
      tooltip: t("Dashboard.Tooltip.Treasury"),
      value: data.treasury || M_DASH,
    },
  ];

  return (
    <styles.Container>
      {stats.map(({ title, value, tooltip }) => (
        <styles.Element key={title}>
          <div>{value}</div>
          <div>
            {title}
            <Tooltip tooltip={tooltip} />
          </div>
        </styles.Element>
      ))}
    </styles.Container>
  );
};
