import styled, { css } from "styled-components";

const ValueContainer = styled.div`
  gap: 3rem;
  display: flex;
  justify-content: center;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        flex-direction: column;
      `,
    )};
`;

const Value = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    display: flex;
    font-style: normal;
    justify-content: center;
  }

  & > p:first-child {
    gap: 0.5rem;
    display: flex;
    font-size: 4rem;
    font-weight: 900;
    line-height: 160%;
    user-select: none;
    color: transparent;
    -webkit-text-stroke-width: 0.15625rem;
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.yellow};
    ${({ theme }) => theme.mediaWidth("upToMedium", `-webkit-text-stroke-width: 0.125rem;`)};

    span {
      font-family: ${({ theme }) => theme.fonts.archivo};

      &:first-child,
      &:last-child {
        font-weight: 100;
        -webkit-text-stroke-width: 0.0625rem;
        color: ${({ theme }) => theme.colors.yellow};
        font-family: ${({ theme }) => theme.fonts.inter};
        ${({ theme }) => theme.mediaWidth("upToMedium", `-webkit-text-stroke-width: 0.075rem;`)};
      }
    }
  }

  & > p:last-child {
    opacity: 0.8;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.188rem;
    color: ${({ theme }) => theme.colors.grayLight};
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        & > p:first-child {
          font-size: 3rem;
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        & > p:first-child {
          font-size: 2.5rem;
        }
      `,
    )}
`;

const styles = {
  ValueContainer,
  Value,
};

export default styles;
