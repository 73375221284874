import { ILeader } from "shared/interfaces/staking";
import { colors } from "shared/theme/colors";

import { INDEX_ELEVEN, emptyLeader } from "./constants";

export const leaderboardListSwitcher = ["You", "Team"] as const;
export type LeaderboardSwitcherType = (typeof leaderboardListSwitcher)[number];

export const dataByRank = (rank: number) => {
  switch (rank) {
    case 1:
      return { color: colors.hapiYellow1, isWinner: true };
    case 2:
      return { color: colors.hapiGrey20, isWinner: true };
    case 3:
      return { color: colors.cinnamon, isWinner: true };

    default:
      return { color: colors.white, isWinner: false };
  }
};

export const populateArrayWithEmptyObj = (arr: ILeader[]) => {
  const lastIndex = arr.length - 1;
  if (arr.length === INDEX_ELEVEN && arr[lastIndex].rank !== INDEX_ELEVEN) arr.splice(lastIndex, 0, emptyLeader);
  return arr;
};
