import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import navigationItemStyles from "shared/components/Header/components/Navigation/components/NavItem/styles";
import { ENavigationListKeys } from "shared/components/Header/components/Navigation/enum";
import { Submenu } from "shared/components/Header/components/Submenu";
import { ISubmenuOptionsProps } from "shared/components/Header/components/Submenu/interface";
import submenuStyles from "shared/components/Header/components/Submenu/styles";
import { ecosystem } from "shared/data/ecosystem";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import theme from "shared/theme";

export function EcosystemSubmenu({ toggleSubmenu }: ISubmenuOptionsProps) {
  const navigate = useNavigate();
  const { isSmall, isMedium } = useWindowDimensions();
  const isAdaptive = isSmall || isMedium;

  function openLink(url: string, isInternal = false) {
    isInternal ? navigate(url) : window.open(url, "_blank", "noreferrer");
    toggleSubmenu && toggleSubmenu(ENavigationListKeys.About, false);
  }

  return (
    <Submenu withBackground={!isAdaptive}>
      {ecosystem.map((project) => (
        <submenuStyles.ListItem
          onClick={() => openLink(project.url || "#", project.isInternal)}
          $isDisabled={!project.url}
          key={project.key}
        >
          {project.icon} {t(`Ecosystem.${project.key}`)}
          {!project.url && (
            <navigationItemStyles.Label $colorText={theme.colors.hapiDark} $colorBg={theme.colors.orange}>
              {t("Navigation.Soon")}
            </navigationItemStyles.Label>
          )}
          <submenuStyles.Arrow />
        </submenuStyles.ListItem>
      ))}
    </Submenu>
  );
}
