import { t } from "i18next";

import { socials } from "shared/data/socials";

import styles from "./styles";

export function Footer({ marginTopInRem }: { marginTopInRem?: number }) {
  return (
    <styles.FooterContainer $marginTopInRem={marginTopInRem}>
      {/* TODO: redo when correct data will be available */}
      {/* <styles.FirstRow>
        <div>
          <styles.List $isSingleColumn>
            <styles.ListItem>HAPI is a Cybersecurity</styles.ListItem>
            <styles.ListItem>Layer That</styles.ListItem>
            <styles.ListItem>Notifying Entities</styles.ListItem>
          </styles.List>
        </div>
      </styles.FirstRow> */}
      <styles.SecondRow>
        <styles.Copyright>{t("Footer.Copyright", { year: new Date().getFullYear() })}</styles.Copyright>
        <styles.Socials>
          {socials.map((social) => (
            <styles.Social key={social.url} onClick={() => window.open(social.url, "_blank", "noreferrer")}>
              {social.icon}
            </styles.Social>
          ))}
        </styles.Socials>
      </styles.SecondRow>
    </styles.FooterContainer>
  );
}
