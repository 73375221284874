import { styled } from "styled-components";

const StakingLabel = styled.div<{ $alignRight?: boolean }>`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: ${({ $alignRight }) => ($alignRight ? "flex-end" : "unset")};

  span {
    font-weight: 500;
    line-height: 120%;
    font-size: 0.75rem;

    &:first-child {
      color: ${({ theme }) => theme.colors.hapiGrey40};
    }

    &:last-child {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export default {
  StakingLabel,
};
