import { t } from "i18next";
import { z as zod } from "zod";

import { BigNumber, formatUnits } from "shared/utils/displayAmount";

import { parseTokenAmount } from "./utils";

export const stakingSchema = ({
  isStake,
  symbol,
  minStake,
  balance,
  amountToUnstake,
  decimals,
}: {
  isStake: boolean;
  symbol?: string;
  minStake?: bigint;
  balance?: bigint;
  amountToUnstake?: bigint;
  decimals?: number;
}) => {
  return zod.object({
    stake: zod.string().superRefine((stake, ctx) => {
      if (!minStake || (isStake && !balance) || !decimals) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: t("Dashboard.Messages.IncorrectData"),
        });

        return zod.INVALID;
      }

      if (!stake) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: t("Dashboard.Messages.EmptyField"),
        });
        return zod.OK;
      }

      const minStakeBig = BigNumber(minStake.toString());
      const formatMinStakeBig = formatUnits(minStake.toString(), decimals);
      const balanceBig = BigNumber(balance?.toString() || 0);
      const amountToUnstakeBig = BigNumber(amountToUnstake?.toString());
      const stakeBig = parseTokenAmount(stake, decimals);

      const enoughMinStake = stakeBig.gte(minStakeBig);
      const enoughBalance = stakeBig.lte(balanceBig);
      const enoughAmountToUnstake = amountToUnstake && stakeBig.lte(amountToUnstakeBig);

      if (isStake) {
        if (!enoughMinStake) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: t("Dashboard.Messages.MinStake", { value: formatMinStakeBig, symbol }),
          });
          return zod.INVALID;
        }
        if (!enoughBalance) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: t("Dashboard.Messages.InsufficientFundsStake", { symbol }),
          });
          return zod.INVALID;
        }
        return zod.OK;
      }

      if (!isStake) {
        if (!enoughAmountToUnstake) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: t("Dashboard.Messages.InsufficientFundsUnstake", { symbol }),
          });
          return zod.INVALID;
        }
        if (!stakeBig.gt(0)) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: t("Dashboard.Messages.MinUnstake"),
          });
          return zod.INVALID;
        }
        return zod.OK;
      }

      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        message: t("Dashboard.Messages.IncorrectData"),
      });

      return zod.INVALID;
    }),
    agreement: zod.literal(true),
  });
};
