import { IStakingValue } from "./interface";
import styles from "./styles";

export const StakingLabel = ({ title, value, alignRight }: IStakingValue) => {
  return (
    <styles.StakingLabel $alignRight={alignRight}>
      <span>{title}</span>
      <span>{value}</span>
    </styles.StakingLabel>
  );
};
