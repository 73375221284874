import { ReactComponent as Gov } from "assets/icons/gov.svg";
import { contactUsUrl } from "shared/constants";

export const plans = [
  {
    title: "Plans.HAPITerminalLite.Title",
    description: "Plans.HAPITerminalLite.Description",
    services: [
      "HAPITerminalLite.Services.APIBusinessPlan",
      "HAPITerminalLite.Services.OnchainRiskScoringProtocol",
      "HAPITerminalLite.Services.AggregatedAuditsDatabase",
      "HAPITerminalLite.Services.BranchingTransactionVisualizer",
      "HAPITerminalLite.Services.RiskAssessingSuite",
      "HAPITerminalLite.Services.LiquidityPoolMonitoring",
      "HAPITerminalLite.Services.AutomatedRiskAlerts",
      "HAPITerminalLite.Services.OSINTDatabase",
    ],
    price: "Plans.HAPITerminalLite.Price",
    button: {
      title: "Actions.BuyNow",
      clickHandler: () => window.open(contactUsUrl, "_blank"),
    },
  },
  {
    title: "Plans.HAPITerminal.Title",
    description: "Plans.HAPITerminal.Description",
    services: [
      "HAPITerminalLite.Services.APIBusinessPlan",
      "HAPITerminalLite.Services.OnchainRiskScoringProtocol",
      "HAPITerminalLite.Services.AggregatedAuditsDatabase",
      "HAPITerminalLite.Services.BranchingTransactionVisualizer",
      "HAPITerminalLite.Services.RiskAssessingSuite",
      "HAPITerminalLite.Services.LiquidityPoolMonitoring",
      "HAPITerminal.Services.CrowdsourcedDataAggregation",
      "HAPITerminal.Services.AutomatedTokenSecurityAuditor",
      "HAPITerminalLite.Services.AutomatedRiskAlerts",
      "HAPITerminalLite.Services.OSINTDatabase",
    ],
    price: "Plans.HAPITerminal.Price",
    button: {
      title: "Actions.BuyNow",
      clickHandler: () => window.open(contactUsUrl, "_blank"),
    },
  },
  {
    icon: <Gov />,
    title: "Plans.SpecialConditions.Title",
    description: "Plans.SpecialConditions.Description",
    button: {
      title: "Actions.ContactUs",
      isOutlined: true,
      clickHandler: () => window.open(contactUsUrl, "_blank"),
    },
  },
];
