import { motion, useScroll, useTransform } from "framer-motion";
import { t } from "i18next";
import { useRef } from "react";

import attestations from "assets/images/hapi-id/attestations.webp";
import { useRefsContext } from "shared/providers/refs";

import styles from "./styles";

export default function Attestations() {
  const { hapiIdAttestationsRef } = useRefsContext();

  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 0"],
  });

  const scaleProgress = useTransform(scrollYProgress, [0, 0.5, 1], [1, 1, 1]);
  const rotateXProgress = useTransform(scrollYProgress, [0, 0.5, 1], [30, 0, 0]);

  return (
    <styles.AttestationContainer ref={hapiIdAttestationsRef}>
      <styles.TitleDescription>
        <styles.Title>{t("HapiId.Attestations.Title")}</styles.Title>
        <styles.Description>{t("HapiId.Attestations.Description")}</styles.Description>
      </styles.TitleDescription>
      <motion.div
        ref={ref}
        style={{
          transformPerspective: "1200px",
          scale: scaleProgress,
          rotateX: rotateXProgress,
        }}
      >
        <img src={attestations} alt={t("HapiId.Attestations.Title")} />
      </motion.div>
    </styles.AttestationContainer>
  );
}
