import mixpanel from "mixpanel-browser";

import { currentEnvironment, mixpanelToken } from "services/config";

export type TAdditionalArguments = { [key: string]: string };

const MixpanelEvents = {
  ENTER_REF_CODE: "enter_ref_code",
  SEND_REF_CODE: "send_ref_code",
  UPDATE_REF_CODE: "update_ref_code",
  CANCEL_REF_CODE: "cancel_ref_code",
  NAVIGATE: "navigate",
  REDIRECT: "redirect",
  REF_TWITTER: "ref_twitter",
  REF_TELEGRAM: "ref_telegram",
  REF_WALLET: "ref_wallet",
  REF_SIGN_MESSAGE: "ref_signMessage",
  CONNECT_WALLET: "connect_wallet",
  ADD_WALLET: "add_wallet",
  SIGN_MESSAGE: "sign_message",
  OPEN_MODAL: "open_modal",
  CLOSE_MODAL: "close_modal",
  LINK: "link",
  CLAIM_STAKE_REWARD: "claim_stake_reward",
  CLAIM_REFERRAL_REWARD: "claim_referral_reward",
  STAKE: "staking_stake",
  MAX_STAKE: "staking_max_stake",
  UNSTAKE: "staking_unstake",
  MAX_UNSTAKE: "staking_max_unstake",
  STAKING_STAGE: "staking_stage",
  WARNING_NOT_MAIN_WALLET: "staking_warning_not_main_wallet",
  SET_STAKING_VIEW: "staking_view",
  SET_LOCKUP_PERIOD: "staking_lockup_period",
  STAKE_MORE: "staking_stake_more_button_click",
};

class MixpanelService {
  private IS_PRODUCTION_ENVIRONMENT = currentEnvironment === "production";

  constructor() {
    this.init();
  }

  private init() {
    mixpanel.init(mixpanelToken, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
  }

  public userIdentify(jwtToken: string) {
    if (this.IS_PRODUCTION_ENVIRONMENT) {
      mixpanel.identify(jwtToken);
    }
  }

  public reset() {
    if (this.IS_PRODUCTION_ENVIRONMENT) {
      mixpanel.reset();
    }
  }

  public trackEvent(
    eventName: keyof typeof MixpanelEvents,
    arg?: Record<string, string | object | boolean | undefined>,
  ) {
    if (this.IS_PRODUCTION_ENVIRONMENT) {
      const event = MixpanelEvents[eventName];
      mixpanel.track(event, { ...arg });
    }
  }
}

export const logger = new MixpanelService();
