import { createAsyncThunk } from "@reduxjs/toolkit";
import { readContracts } from "@wagmi/core";
import { Address, erc20Abi, formatUnits } from "viem";

import { walletConfig } from "services/wallet";
import type { ThunkApiConfig } from "store";
import { Token } from "store/slices/token";

export const loadTokenData = createAsyncThunk<Token | null, { tokenAddress: Address }, ThunkApiConfig>(
  "loadTokenData",
  async ({ tokenAddress }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const tokens = getState().tokens;
      if (tokens[tokenAddress]) return fulfillWithValue(tokens[tokenAddress]);

      const tokenContractConfig = {
        address: tokenAddress,
        abi: erc20Abi,
      };

      const [decimals, name, symbol, totalSupply] = await readContracts(walletConfig, {
        allowFailure: false,
        contracts: [
          {
            ...tokenContractConfig,
            functionName: "decimals",
          },
          {
            ...tokenContractConfig,
            functionName: "name",
          },
          {
            ...tokenContractConfig,
            functionName: "symbol",
          },
          {
            ...tokenContractConfig,
            functionName: "totalSupply",
          },
        ],
      });

      return fulfillWithValue({
        address: tokenAddress,
        decimals,
        name,
        symbol,
        totalSupply: {
          formatted: formatUnits(totalSupply, decimals),
          value: totalSupply,
        },
      });
    } catch (e) {
      console.error(`Error: while loading ${tokenAddress} token \n ${e}`);
      return rejectWithValue(null);
    }
  },
);
