import { useTranslation } from "react-i18next";

import { ReactComponent as Arrow } from "assets/icons/arrow-small.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as Near } from "assets/icons/dashboard/near.svg";
import { ReactComponent as WalletConnect } from "assets/icons/dashboard/walletconnect.svg";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { IAddWalletModalProps } from "shared/interfaces/modal";
import theme from "shared/theme";

import styles from "./styles";

export function AddWalletModal({ closeModal, nearHandler, evmHandler }: IAddWalletModalProps) {
  const { t } = useTranslation();

  return (
    <ModalWrapper closeModal={closeModal} bgColor={theme.colors.registerCardBg} widthInRem="23.5">
      <styles.Container>
        <styles.Header>
          <styles.Title>{t("Dashboard.ConnectWallet.Title")}</styles.Title>
          <styles.Description>{t("Dashboard.ConnectWallet.Description")}</styles.Description>
          <styles.Close>
            <CloseIcon onClick={closeModal} />
          </styles.Close>
        </styles.Header>
        <styles.ButtonContainer>
          <styles.Button onClick={evmHandler}>
            <styles.Icon>
              <WalletConnect />
            </styles.Icon>
            <span>{t("Dashboard.Actions.WalletConnect")}</span>
            <Arrow />
          </styles.Button>
          <styles.Button onClick={nearHandler}>
            <styles.Icon>
              <Near />
            </styles.Icon>
            <span>{t("Dashboard.Actions.NearWallets")}</span>
            <Arrow />
          </styles.Button>
        </styles.ButtonContainer>
      </styles.Container>
    </ModalWrapper>
  );
}
