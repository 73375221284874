import { useState, useEffect } from "react";

const useDeviceDetection = () => {
  const [isTouchedDisplay, setIsTouchedDisplay] = useState<boolean>(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevices = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
    const isTabletDevices = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

    isMobileDevices || isTabletDevices ? setIsTouchedDisplay(true) : setIsTouchedDisplay(false);
  }, []);

  return isTouchedDisplay;
};

export default useDeviceDetection;
