import { ReactComponent as ArrowLink } from "assets/icons/arrow-link.svg";
import { ReactComponent as AggregatedAudits } from "assets/icons/tools/aggregated-audits.svg";
import { ReactComponent as DataAggregation } from "assets/icons/tools/data-aggregation.svg";
import { ReactComponent as LiquidityPool } from "assets/icons/tools/liquidity-pool.svg";
import { ReactComponent as Osint } from "assets/icons/tools/osint.svg";
import { ReactComponent as RiskAlerts } from "assets/icons/tools/risk-alerts.svg";
import { ReactComponent as RiskAssessing } from "assets/icons/tools/risk-assessing.svg";
import { ReactComponent as RiskScoring } from "assets/icons/tools/risk-scoring.svg";
import { ReactComponent as TokenSecurity } from "assets/icons/tools/token-security.svg";
import { ReactComponent as TransactionVisualizer } from "assets/icons/tools/transaction-visualizer.svg";
import { ReactComponent as Bocachica } from "assets/images/clients/bc.svg";
import { ReactComponent as Bitcoincom } from "assets/images/clients/bitcoincom.svg";
import { ReactComponent as Coingecko } from "assets/images/clients/coingecko.svg";
import { ReactComponent as Coinstore } from "assets/images/clients/coinstore.svg";
import { ReactComponent as Dextools } from "assets/images/clients/dextools.svg";
import { ReactComponent as Jumbo } from "assets/images/clients/jumbo.svg";
import { ReactComponent as Whitebit } from "assets/images/clients/whitebit.svg";
import { explorerHapi } from "shared/constants";

export interface IAdditionalButton {
  name: string;
  link: string;
  iconRight: JSX.Element;
}

export const toolsData = [
  {
    icon: <RiskScoring />,
    key: "Tools.OnchainRiskScoringProtocol",
    clientLogos: [<Bitcoincom />, <Jumbo />, <Bocachica />],
    additionalButton: {
      name: "Tools.OnchainRiskScoringProtocol.Explorer",
      link: explorerHapi,
      iconRight: <ArrowLink />,
    },
  },
  {
    icon: <AggregatedAudits />,
    key: "Tools.AggregatedAuditsDatabase",
    clientLogos: [<Whitebit />, <Coingecko />, <Dextools />],
  },
  {
    icon: <TransactionVisualizer />,
    key: "Tools.BranchingTransactionVisualizer",
    clientLogos: [<Bitcoincom />, <Coinstore />],
  },
  {
    icon: <RiskAssessing />,
    key: "Tools.RiskAssessingSuite",
    clientLogos: [<Whitebit />, <Bitcoincom />, <Dextools />],
  },
  {
    icon: <LiquidityPool />,
    key: "Tools.LiquidityPoolMonitoring",
    clientLogos: [<Bitcoincom />, <Jumbo />, <Bocachica />, <Coingecko />, <Dextools />],
  },
  {
    icon: <DataAggregation />,
    key: "Tools.CrowdsourcedDataAggregation",
    clientLogos: [<Bitcoincom />, <Whitebit />, <Jumbo />, <Bocachica />, <Coingecko />, <Dextools />],
  },
  {
    icon: <TokenSecurity />,
    key: "Tools.AutomatedTokenSecurityAuditor",
    clientLogos: [<Whitebit />, <Coingecko />, <Dextools />],
  },
  {
    icon: <RiskAlerts />,
    key: "Tools.AutomatedRiskAlerts",
    clientLogos: [<Whitebit />, <Bitcoincom />, <Jumbo />, <Bocachica />, <Coingecko />, <Dextools />],
  },
  {
    icon: <Osint />,
    key: "Tools.OSINTDatabase",
    clientLogos: [<Whitebit />, <Coingecko />, <Dextools />],
  },
];
