import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding: 0.5rem 2rem;
  button {
    min-width: 10.125px;
    align-self: center;
  }
`;

const Header = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const Close = styled.div`
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  right: -20px;
  top: -20px;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.hapiGrey20};
    }
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 140%;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.hapiGrey5};
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 160%;
`;

export default {
  Container,
  Header,
  Title,
  Description,
  Close,
};
