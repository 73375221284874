import styled, { css } from "styled-components";

import { BaseContainer } from "shared/theme/styles";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
`;

const Body = styled(BaseContainer)`
  flex: 1;
  display: flex;
  max-width: 78rem;
  flex-direction: column;
  margin-top: 6.5rem;

  & > button {
    width: fit-content;
    align-self: center;
    margin-top: 2.5rem;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        margin-top: 3.3rem;
        padding: 0 3rem;
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 0 1rem;
        margin-top: 2.5rem;
        & > button {
          margin-top: 1.75rem;
        }
      `,
    )};
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  align-self: center;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        display: grid;
        grid-template-columns: 17.375rem 17.375rem;
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        display: flex;
        flex-direction: column;
        width: 100%;
      `,
    )};
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.grayLight};
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 0.75rem;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
      `,
    )};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};
  font-size: 3.375rem;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 3.75rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        margin-bottom: 1.75rem;
        font-size: 2rem;
      `,
    )};
`;

const Card = styled.div<{ $disabled?: boolean }>`
  display: flex;
  width: 100%;
  height: 17rem;
  padding: 1.5rem;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.colors.registerCardBg};

  iframe > body {
    background-color: white !important;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        height: 15.75rem;
      `,
    )};
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      user-select: none;
      opacity: 0.4;
    `}
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.38rem;
  min-height: 2rem;
  p {
    flex: 1;
    margin-block-start: 0;
    margin-block-end: 0;
    align-self: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    position: relative;
    span {
      position: absolute;
      bottom: -24px;
      left: 0;

      color: ${({ theme }) => theme.colors.hapiGrey20};
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 125%;
    }
  }
  svg,
  div {
    flex-shrink: 0;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        p {
          font-size: 1.125rem;
        }
      `,
    )};
`;

const Step = styled.div`
  color: ${({ theme }) => theme.colors.hapiYellow1};
  font-size: 1.00013rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  position: relative;
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const ConnectedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 12.5rem;
  overflow: hidden;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    flex-shrink: 0;
  }
`;

export default {
  Container,
  Body,
  Description,
  Title,
  CardWrapper,
  Card,
  CardHeader,
  Step,
  ConnectedContainer,
};
