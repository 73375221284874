import { useMotionValue, PanInfo, animate } from "framer-motion";
import { useState, useEffect, RefObject } from "react";

import { transition } from "shared/theme/styles";

export const useCarousel = (pages: number, ref: RefObject<HTMLDivElement>) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const x = useMotionValue(0);

  const calculateNewX = () => {
    return -currentSlide * (ref.current?.clientWidth || 0);
  };
  const handleEndDrag = (_: Event, dragProps: PanInfo) => {
    const clientWidth = ref.current?.clientWidth || 0;
    const { offset } = dragProps;
    if (offset.x > clientWidth / 4) {
      handlePrev();
    } else if (offset.x < -clientWidth / 4) {
      handleNext();
    } else {
      animate(x, calculateNewX(), transition);
    }
  };
  const handleNext = () => {
    setCurrentSlide(currentSlide + 1 === pages ? 0 : currentSlide + 1);
  };

  const handlePrev = () => {
    setCurrentSlide(currentSlide - 1 < 0 ? pages - 1 : currentSlide - 1);
  };

  useEffect(() => {
    const controls = animate(x, calculateNewX(), transition);
    return controls.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  return {
    x,
    handleEndDrag,
    handlePrev,
    handleNext,
    currentSlide,
    setCurrentSlide,
  };
};
