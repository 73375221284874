import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useTranslation } from "react-i18next";
import { useAccount, useDisconnect } from "wagmi";

import { ReactComponent as Wallet } from "assets/icons/wallet.svg";
import { Header } from "pages/Dashboard/components/Header";
import dashboardStyles from "pages/Dashboard/styles";
import { APP_ROUTES } from "routes/appRoutes";
import { logger } from "services/mixpanel";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { Footer } from "shared/components/Footer";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAuthWeb3 } from "shared/hooks/useAuthWeb3";
import { useLoggerNavigate } from "shared/hooks/useLoggerNavigate";
import { useWalletSelector } from "shared/providers/wallet/walletSelector";
import { cropString } from "shared/utils/stringOperations";
import { EModals, closeModal, showModal } from "store/slices/modals";

import styles from "./styles";

enum BLOCKCHAIN {
  EVM = "evm",
  NEAR = "near",
}

export function Login() {
  const { t } = useTranslation();
  const navigate = useLoggerNavigate();
  const dispatch = useAppDispatch();

  const { open } = useWeb3Modal();
  const walletSelector = useWalletSelector();

  const { loginEVMWallet, loginNearWallet } = useAuthWeb3();
  const evmAccount = useAccount();
  const { disconnectAsync } = useDisconnect();

  const blockchainData: Record<
    BLOCKCHAIN,
    { address: string | null; openModal: () => void; login: (address?: string) => void }
  > = {
    near: {
      address: walletSelector.accountId,
      openModal: async () => {
        const { modal, selector } = walletSelector;
        if (selector.isSignedIn()) {
          const wallet = await selector.wallet();
          await wallet.signOut();
        }
        modal.show();
        dispatch(closeModal());
        logger.trackEvent("CONNECT_WALLET", { type: BLOCKCHAIN.NEAR });
      },
      login: async () => {
        const response = await loginNearWallet(walletSelector.accountId);
        if (response && response.onboarded) {
          navigate(APP_ROUTES.DASHBOARD.HOME, { replace: true });
        }
      },
    },
    evm: {
      address: evmAccount.address || null,
      openModal: async () => {
        await disconnectAsync(undefined, {
          onSuccess() {
            open();
            dispatch(closeModal());
            logger.trackEvent("CONNECT_WALLET", { type: BLOCKCHAIN.EVM });
          },
        });
      },
      login: async () => {
        const response = await loginEVMWallet(evmAccount.address);
        if (response) {
          if (response.onboarded) {
            navigate(APP_ROUTES.DASHBOARD.HOME, { replace: true });
          } else {
            navigate(APP_ROUTES.REGISTRATION);
          }
        }
      },
    },
  };

  const handlerConnectWallet = () => {
    dispatch(
      showModal({
        modal: EModals.CONNECT_WALLET_MODAL,
        props: {
          evmHandler: blockchainData.evm.openModal,
          nearHandler: blockchainData.near.openModal,
        },
      }),
    );
  };

  const dataByType = evmAccount.isConnected
    ? blockchainData[BLOCKCHAIN.EVM]
    : walletSelector.accountId
      ? blockchainData[BLOCKCHAIN.NEAR]
      : null;

  return (
    <dashboardStyles.Container>
      <Header />
      <dashboardStyles.Body>
        <styles.Container>
          <styles.Title>{t("Dashboard.Login.Title")}</styles.Title>
          <styles.Description>{t("Dashboard.Login.Description")}</styles.Description>
          {dataByType && dataByType.address && (
            <styles.Address>
              {t("Dashboard.Login.ConnectedTo", { address: cropString(dataByType.address) })}
            </styles.Address>
          )}
          {dataByType && dataByType.address ? (
            <ButtonPrimary name={t("Dashboard.Actions.SignMessage")} handler={dataByType.login} />
          ) : (
            <ButtonPrimary
              name={t("Dashboard.Actions.ConnectWallet")}
              handler={() => {
                logger.trackEvent("CONNECT_WALLET");
                handlerConnectWallet();
              }}
              iconRight={<Wallet />}
            />
          )}
        </styles.Container>
      </dashboardStyles.Body>
      <Footer />
    </dashboardStyles.Container>
  );
}
