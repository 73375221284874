import { useWeb3Modal } from "@web3modal/wagmi/react";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useAccount, useDisconnect } from "wagmi";

import { chains } from "services/config";
import { logger } from "services/mixpanel";
import { Warning, WarningWithSolution } from "shared/components/Warning";
import { EMPTY_STRING } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { EStakingWarning } from "shared/interfaces/staking";
import { colors } from "shared/theme/colors";
import { cropString } from "shared/utils/stringOperations";
import { switchNetwork } from "shared/utils/switchNetwork";
import { selectUserStaking } from "store/slices/staking";

export const StakingWarning = ({ type }: { type: EStakingWarning }) => {
  const { address, chain } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const { open } = useWeb3Modal();

  const { data: userData } = useAppSelector(selectUserStaking);

  switch (type) {
    case EStakingWarning.ConnectWallet: {
      const mainWallet = userData?.addresses[0]?.address;
      if (!mainWallet) return null;
      return (
        <WarningWithSolution
          title={t("Dashboard.Messages.NoConnectionToWallet")}
          description={
            <Trans
              i18nKey="Dashboard.Messages.PleaseConnectMainWallet"
              components={{ bold: <strong /> }}
              values={{ address: cropString(mainWallet) }}
            />
          }
          solution={t("Dashboard.Actions.ConnectWallet")}
          solutionHandler={() => open()}
        />
      );
    }
    case EStakingWarning.SwitchNetwork: {
      const currentChain = chain;
      const supportedChain = chains[0];
      return (
        <WarningWithSolution
          title={t("Dashboard.Messages.SelectedChainIsNotSupported", { chain: currentChain?.name || EMPTY_STRING })}
          description={t("Dashboard.Messages.PleaseSwitchToSupportedChain", { chain: supportedChain.name })}
          solution={t("Dashboard.Actions.SwitchNetwork")}
          solutionHandler={switchNetwork}
        />
      );
    }
    case EStakingWarning.MainWallet: {
      const mainWallet = userData?.addresses[0]?.address;
      if (!mainWallet || !address) return null;
      const warningHandler = async () => {
        logger.trackEvent("WARNING_NOT_MAIN_WALLET", { address });
        await disconnectAsync(undefined, {
          onSuccess() {
            open({ view: "Connect" });
          },
        });
      };
      return (
        <WarningWithSolution
          title={t("Dashboard.Messages.SelectedWalletIsNotMain", { address: cropString(address) })}
          description={t("Dashboard.Messages.PleaseSwitchToMainWallet", { address: cropString(mainWallet) })}
          solution={t("Dashboard.Actions.SwitchAccount")}
          solutionHandler={warningHandler}
        />
      );
    }
    case EStakingWarning.Agreement: {
      return (
        <Warning
          title={t("Dashboard.Messages.Agreement")}
          color={colors.warningRed}
          iconBg={colors.lighterWarningRed}
        />
      );
    }

    default:
      return null;
  }
};
