import styled from "styled-components";

import transitions from "shared/theme/transitions";

const Container = styled.div<{ $bgRGB: string }>`
  display: flex;
  padding: 1rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: ${({ $bgRGB }) => `rgba(${$bgRGB}, 0.2)`};
`;

const IconContainer = styled.div<{ $bgRGB: string }>`
  display: flex;
  padding: 8px;
  border-radius: 50%;
  background: ${({ $bgRGB }) => `rgba(${$bgRGB}, 0.4)`};
  align-self: flex-start;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 120%;
  p {
    margin: 0;
  }

  & > p {
    width: fit-content;
    cursor: pointer;
    transition: ${transitions.hoverTransition};
    &:hover {
      opacity: 0.5;
    }
  }
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  p {
    &:last-child {
      opacity: 0.7;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 140%;
    }
  }
`;

const WarningContainer = styled.div<{ $bg: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background: ${({ $bg }) => $bg};
  p {
    margin: 0;
  }
`;

const WarningIconContainer = styled.div<{ $iconBg: string }>`
  display: flex;
  padding: 0.5rem;
  border-radius: 50%;
  background: ${({ $iconBg }) => $iconBg};
  align-self: flex-start;
`;

const WarningHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const WarningHeaderText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: normal;
`;

const WarningText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
`;

export default {
  Container,
  IconContainer,
  Text,
  HeaderText,
  WarningContainer,
  WarningHeader,
  WarningHeaderText,
  WarningText,
  WarningIconContainer,
};
