import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Trans, useTranslation } from "react-i18next";
import { useAccount, useDisconnect } from "wagmi";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { Skeleton } from "shared/components/Skeleton";
import { Warning } from "shared/components/Warning";
import { EMPTY_STRING } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { useBalance } from "shared/hooks/useBalance";
import { IConfirmMainWalletModalProps } from "shared/interfaces/modal";
import theme from "shared/theme";
import { displayAmount } from "shared/utils/displayAmount";
import { cropString } from "shared/utils/stringOperations";
import { selectStakeTokenAddress } from "store/slices/staking";
import { selectToken } from "store/slices/token";

import styles from "./styles";

export function ConfirmMainWalletModal({ closeModal, confirm }: IConfirmMainWalletModalProps) {
  const { address } = useAccount();

  const tokenAddress = useAppSelector(selectStakeTokenAddress);
  const token = useAppSelector((state) => selectToken(state, tokenAddress));
  const { balance, isLoading } = useBalance(token?.address);
  const { disconnectAsync } = useDisconnect();
  const { open } = useWeb3Modal();
  const { t } = useTranslation();

  const switchHandler = async () => {
    await disconnectAsync();
    open();
  };

  const confirmHandler = async () => {
    closeModal();
    confirm();
  };

  return (
    <ModalWrapper closeModal={closeModal} bgColor={theme.colors.registerCardBg} widthInRem="23.5">
      <styles.Container>
        <styles.Header>
          <styles.Title>{t("Registry.ConfirmMainWallet.YourMainWallet")}</styles.Title>
          <styles.Description>{address ? cropString(address) : EMPTY_STRING}</styles.Description>
          <styles.Close>
            <CloseIcon onClick={closeModal} />
          </styles.Close>
        </styles.Header>
        <styles.BalanceBlock>
          {balance && isLoading ? (
            <Skeleton w="100%" h="1.25rem" borderRadius="0.5rem" />
          ) : (
            <>
              <p>{t("Registry.ConfirmMainWallet.TokenBalance", { symbol: balance?.symbol })}</p>
              <p>
                {t("Dashboard.TokenAmount", { amount: displayAmount(balance?.formatted), symbol: balance?.symbol })}
              </p>
            </>
          )}
        </styles.BalanceBlock>
        <Warning
          title={t("Registry.ConfirmMainWallet.Note.Title")}
          description={
            <Trans i18nKey="Registry.ConfirmMainWallet.Note.Description" components={{ bold: <strong /> }} />
          }
        />
        <styles.ButtonContainer>
          <ButtonPrimary name={t("Actions.Switch")} handler={switchHandler} isOutlined />
          <ButtonPrimary name={t("Actions.Confirm")} handler={confirmHandler} />
        </styles.ButtonContainer>
      </styles.Container>
    </ModalWrapper>
  );
}
