import styled, { keyframes } from "styled-components";

const InputWrapper = styled.div`
  display: flex;
  align-items: start;
  position: relative;
`;

const bounce = keyframes`
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
`;

const Box = styled.label`
  position: relative;

  input,
  svg {
    width: 1rem;
    height: 1rem;
    display: block;
  }

  input {
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    -moz-appearance: none;
    border-radius: 0.25rem;
    background: transparent;
    -webkit-appearance: none;
    border: 1px solid ${({ theme }) => theme.colors.hapiGrey40};
    transition: ${({ theme }) => theme.transitions.hoverTransition};

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.hapiGrey20};
    }

    &:checked {
      border: 1px solid ${({ theme }) => theme.colors.hapiYellow2};
      box-shadow: inset 0 0 0 0.688rem ${({ theme }) => theme.colors.hapiYellow2};

      & + svg {
        animation: ${bounce} 0.4s linear forwards 0.2s;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  svg {
    top: 0;
    left: 0;
    fill: none;
    stroke-width: 2px;
    position: absolute;
    pointer-events: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform: scale(0) translateZ(0);
    stroke: ${({ theme }) => theme.colors.black};
  }
`;

const Label = styled.div`
  font-weight: 400;
  line-height: 140%;
  font-size: 0.75rem;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.hapiGrey40};

  a {
    color: inherit;
    transition: ${({ theme }) => theme.transitions.hoverTransition};

    &:hover {
      opacity: 0.8;
    }
  }
`;

export default {
  InputWrapper,
  Box,
  Label,
};
