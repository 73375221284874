import { t } from "i18next";
import { useEffect, useRef, useState } from "react";

import { BorderForBlog } from "assets/images/icons/BorderForBlog";
import { MediumApi } from "services/api/medium";
import { Slider, Dots, Arrow } from "shared/components/CarouselItems";
import { linkDmHapi } from "shared/constants";
import { useCarousel } from "shared/hooks/useCarousels";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { IPost, STATUS } from "shared/interfaces";

import styles from "./styles";
import { getPostsPerSlide, setupPosts } from "./utils";
import { Post } from "../Post";

export function Blog() {
  const containerRef = useRef<HTMLDivElement>(null);
  const dimensions = useWindowDimensions();

  const [posts, setPosts] = useState<IPost[] | null>(null);

  const isSmallScreen = dimensions.isSmall || dimensions.isMedium;

  useEffect(() => {
    const uploadBlog = async () => {
      const mediumApi = new MediumApi();
      const feedInfo = await mediumApi.getFeedInfoByUser();
      if (!feedInfo || feedInfo.status !== STATUS.OK) return null;
      setPosts(setupPosts(feedInfo.items.reverse()));
    };
    uploadBlog();
  }, []);

  const totalSliders = Math.ceil((posts?.length || 0) / getPostsPerSlide(dimensions));

  const { x, handleEndDrag, handlePrev, handleNext, currentSlide, setCurrentSlide } = useCarousel(
    totalSliders,
    containerRef,
  );

  if (!posts) return null; // todo add loader

  return (
    <styles.Section>
      <styles.Container>
        <BorderForBlog />
        <styles.Title>{t("Blog")}</styles.Title>
        <styles.Posts ref={containerRef}>
          {posts.map((post, i) => (
            <Slider onDragEnd={handleEndDrag} totalSliders={totalSliders} x={x} i={i} key={i}>
              <Post key={post.title} {...post} />
            </Slider>
          ))}
        </styles.Posts>
        {isSmallScreen && <Dots length={totalSliders} setActiveIndex={setCurrentSlide} activeIndex={currentSlide} />}
        <styles.Footer>
          <styles.Link href={linkDmHapi} target="_blank" rel="noreferrer">
            {t("Actions.GoToBlog")}
            <styles.ArrowLink />
          </styles.Link>
          {!isSmallScreen && <Dots length={totalSliders} setActiveIndex={setCurrentSlide} activeIndex={currentSlide} />}
          <styles.ArrowContainer>
            <Arrow left onClick={handlePrev} />
            <Arrow onClick={handleNext} />
          </styles.ArrowContainer>
        </styles.Footer>
      </styles.Container>
    </styles.Section>
  );
}
