import { t } from "i18next";
import { useState } from "react";

import { ReactComponent as CheckYellow } from "assets/icons/check-circle-yellow.svg";
import { ReactComponent as CheckEmpty } from "assets/icons/check-circle.svg";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";

import { tariffs } from "./data";
import styles from "./styles";
import plansStyles from "../../styles";

export function HapiApiPlan() {
  const [chosenPlan, setChosenPlan] = useState(tariffs[0]);

  return (
    <plansStyles.Plan>
      <plansStyles.PlanTitle>{t("Plans.HAPIProtocolAPI.Title")}</plansStyles.PlanTitle>
      <plansStyles.PlanDescription>{t("Plans.HAPIProtocolAPI.Description")}</plansStyles.PlanDescription>
      <styles.Tariffs>
        {tariffs.map((tariff) => (
          <styles.Tariff
            key={tariff.key}
            $isCurrent={tariff.key === chosenPlan.key}
            onClick={() => setChosenPlan(tariff)}
          >
            <styles.TitleIcon>
              <styles.Title>{t(`Plans.HAPIProtocolAPI.Tariffs.${tariff.title}.Title`)}</styles.Title>
              {tariff.key === chosenPlan.key ? <CheckYellow /> : <CheckEmpty />}
            </styles.TitleIcon>
            <styles.Details>
              {tariff.details.map((detail) => (
                <styles.Detail key={detail}>
                  <div>{t(`Plans.HAPIProtocolAPI.Tariffs.Details.${detail}`)}</div>
                  <div>{t(`Plans.HAPIProtocolAPI.Tariffs.${tariff.title}.Details.${detail}`)}</div>
                </styles.Detail>
              ))}
            </styles.Details>
          </styles.Tariff>
        ))}
      </styles.Tariffs>
      <plansStyles.PriceButtonContainer>
        <plansStyles.Price>{t(`Plans.HAPIProtocolAPI.Tariffs.${chosenPlan.price}`)}</plansStyles.Price>
        <ButtonPrimary fullWidth name={t(chosenPlan.buttonName)} handler={chosenPlan.clickHandler} />
      </plansStyles.PriceButtonContainer>
    </plansStyles.Plan>
  );
}
