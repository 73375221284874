import { ValueAnimationTransition } from "framer-motion";
import styled, { css } from "styled-components";

export const transition: ValueAnimationTransition = {
  type: "spring",
  bounce: 0,
};

export const BaseContainer = styled.section`
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 0 1rem;
      `,
    )};
`;
