import { colors } from "shared/theme/colors";

import styles from "./styles";

interface ISkeleton {
  w: string;
  h: string;
  bg?: keyof typeof colors;
  animation?: boolean;
  borderRadius?: string;
}

export const Skeleton = ({ w, h, bg = "whiteOp15", animation = true, borderRadius }: ISkeleton) => {
  return <styles.Skeleton $bg={bg} $w={w} $h={h} $animation={animation} $borderRadius={borderRadius} />;
};
