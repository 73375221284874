import { t } from "i18next";
import { Trans } from "react-i18next";

import { ReactComponent as MembersIcon } from "assets/icons/dashboard/members.svg";
import { ReactComponent as ReferralsIcon } from "assets/icons/dashboard/referrals.svg";
import theme from "shared/theme";

export const getStats = (
  apyDirect: string,
  apyIndirect: string,
  stakedDirect: number,
  stakedIndirect: number,
  links: number,
  members: number,
  symbol: string,
) => {
  return [
    {
      value: t("Dashboard.Referrals.Stats.Direct"),
      title: (
        <Trans
          i18nKey="Dashboard.Referrals.Stats.RewardAPY"
          values={{ value: apyDirect }}
          components={{ bold: <strong /> }}
        />
      ),
      additionalTitle: (
        <Trans
          i18nKey="Dashboard.Referrals.Stats.Staked"
          values={{ value: stakedDirect }}
          components={{ bold: <strong /> }}
        />
      ),
      color: theme.colors.orange,
      background: theme.colors.wetSand,
    },
    {
      value: t("Dashboard.Referrals.Stats.Indirect"),
      additionalValue: symbol,
      title: (
        <Trans
          i18nKey="Dashboard.Referrals.Stats.RewardAPY"
          values={{ value: apyIndirect }}
          components={{ bold: <strong /> }}
        />
      ),
      additionalTitle: (
        <Trans
          i18nKey="Dashboard.Referrals.Stats.Staked"
          values={{ value: stakedIndirect }}
          components={{ bold: <strong /> }}
        />
      ),
      color: theme.colors.blueViolet,
      background: theme.colors.nightSky,
    },
    {
      value: members,
      title: t("Dashboard.Referrals.Stats.TotalMembers"),
      icon: <MembersIcon />,
      color: theme.colors.violet,
      background: theme.colors.aubergine,
    },
    {
      value: links,
      title: t("Dashboard.Referrals.Stats.RefLinks"),
      icon: <ReferralsIcon />,
      color: theme.colors.lightGreen,
      background: theme.colors.cucumber,
    },
  ];
};
