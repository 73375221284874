import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { Footer } from "shared/components/Footer";
import { EMPTY_STRING } from "shared/constants";
import theme from "shared/theme";

import { Header } from "./components/Header";
import styles from "./styles";

export const SnapOutlet: React.FC = () => {
  useEffect(() => {
    document.body.style.backgroundColor = theme.colors.snapBlack;

    return () => {
      document.body.style.backgroundColor = EMPTY_STRING;
    };
  }, []);

  return (
    <styles.SnapContainer>
      <Header />
      <Outlet />
      <Footer />
    </styles.SnapContainer>
  );
};
