import { SignedMessage } from "@near-wallet-selector/core";
import { keyStores } from "near-api-js";
import { useCallback } from "react";
import { UserRejectedRequestError } from "viem";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";

import { APP_ROUTES } from "routes/appRoutes";
import { refApi } from "services/api/hapi/ref";
import { currentEnvironment, nearContractId } from "services/config";
import { logger } from "services/mixpanel";
import ToastService from "shared/components/Toast";
import { API_ERROR } from "shared/constants";
import { LoginResponse } from "shared/interfaces/api";
import { useWalletSelector } from "shared/providers/wallet/walletSelector";
import { logout as stakingLogout } from "store/slices/staking";

import { useAppDispatch } from "./redux/useAppDispatch";
import { useLoggerNavigate } from "./useLoggerNavigate";

const bufferToString = (data: Uint8Array) => Buffer.from(data).toString("base64");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const instanceOfSignMessage = (object: any): object is SignedMessage => {
  return object;
};

const randomBytes = (length: number) => {
  const buffer = new Uint8Array(length);
  crypto.getRandomValues(buffer);
  return Buffer.from(buffer);
};

//todo: track error text
export const useAuthWeb3 = () => {
  const { isConnected } = useAccount();
  const dispatch = useAppDispatch();
  const { signMessageAsync } = useSignMessage();
  const { disconnectAsync } = useDisconnect();
  const navigate = useLoggerNavigate();
  const { selector, accountId } = useWalletSelector();

  const getNonceMessage = useCallback(async () => {
    const nonce = await dispatch(refApi.endpoints.getNonceMessage.initiate()).unwrap();
    if (!nonce.messageForSign) {
      throw new Error(`code: ${nonce.errorCode}, message: ${nonce.message}`);
    }
    return nonce.messageForSign;
  }, [dispatch]);

  const signUp = useCallback(
    async (address: string | undefined): Promise<boolean> => {
      try {
        if (!address) throw new Error("wallet not connected");
        const nonce = await getNonceMessage();

        const signed = await signMessageAsync({ message: nonce });
        const response = await dispatch(
          refApi.endpoints.sendWalletData.initiate({ address, signature: signed, message: nonce }),
        );
        if ("data" in response) return true;
        return false;
      } catch (error) {
        if (error instanceof UserRejectedRequestError) {
          ToastService.error({ text: "USER REJECT" });
          console.error("Error: while registering wallet. The user rejected the request \n\n", error);
          return false;
        }
        console.error("Error: while registering wallet \n\n", error);
        return false;
      }
    },
    [dispatch, getNonceMessage, signMessageAsync],
  );

  const loginEVMWallet = useCallback(
    async (address: string | undefined): Promise<LoginResponse | null> => {
      try {
        if (!address) throw new Error("wallet not connected");
        const nonce = await getNonceMessage();

        const signed = await signMessageAsync({ message: nonce });

        const response = await dispatch(
          refApi.endpoints.sendWalletLogin.initiate({
            address,
            signature: signed,
            message: nonce,
          }),
        ).unwrap();
        const jwtToken = response.token;
        if (!jwtToken) throw new Error(`code: ${response.errorCode}, message: ${response.message}`);
        return response;
      } catch (error) {
        if (error instanceof UserRejectedRequestError) {
          ToastService.error({ text: "USER REJECT" });
          console.error("Error: while login EVM wallet. The user rejected the request \n\n", error);
          return null;
        }
        console.error("Error: while login EVM wallet \n\n", error);
        return null;
      }
    },
    [getNonceMessage, signMessageAsync, dispatch],
  );

  const logout = useCallback(async () => {
    if (isConnected) await disconnectAsync();
    if (selector.isSignedIn()) (await selector.wallet()).signOut();
    logger.reset();
    dispatch(stakingLogout());
    navigate(APP_ROUTES.DASHBOARD.LOGIN);
  }, [disconnectAsync, dispatch, isConnected, navigate, selector]);

  const getNearSignatureAndPublicKey = useCallback(
    async (nonce: string, accountId: string): Promise<{ signature: string; publickey: string }> => {
      const keyStore = new keyStores.BrowserLocalStorageKeyStore();
      const keyPair = await keyStore.getKey(currentEnvironment === "production" ? "mainnet" : "testnet", accountId);

      if (keyPair) {
        const nonceHash = new TextEncoder().encode(nonce);
        const { signature } = keyPair.sign(nonceHash);
        const publicKey = keyPair.getPublicKey();
        return { signature: bufferToString(signature), publickey: publicKey.toString() };
      } else {
        const wallet = await selector.wallet();
        const message = await wallet.signMessage({
          message: nonce,
          recipient: nearContractId,
          nonce: randomBytes(32),
        });

        if (instanceOfSignMessage(message)) {
          const { signature, publicKey } = message;
          return { signature: signature, publickey: publicKey.toString() };
        }
        throw new Error(`message is a void`);
      }
    },
    [selector],
  );
  const connectNear = useCallback(async (): Promise<boolean> => {
    try {
      if (!accountId) throw new Error("wallet not connected");
      const nonce = await getNonceMessage();

      const { signature, publickey } = await getNearSignatureAndPublicKey(nonce, accountId);

      const response = await dispatch(
        refApi.endpoints.sendNearWalletData.initiate({
          userid: accountId,
          signature: signature,
          publickey,
        }),
      );
      if ("data" in response) return true;
      return false;
    } catch (error) {
      console.error("Error while sign message on NEAR \n\n", error);
      return false;
    }
  }, [accountId, getNonceMessage, getNearSignatureAndPublicKey, dispatch]);

  const loginNearWallet = useCallback(
    async (address: string | null): Promise<LoginResponse | null> => {
      try {
        if (!address) throw new Error("wallet not connected");
        const nonce = await getNonceMessage();

        const { signature, publickey } = await getNearSignatureAndPublicKey(nonce, address);

        const response = await dispatch(
          refApi.endpoints.sendNearLogin.initiate({
            userid: address,
            signature: signature,
            publickey,
          }),
        ).unwrap();
        const jwtToken = response.token;
        if (!jwtToken) throw new Error(response.message);
        return response;
      } catch (error) {
        if (error instanceof Error && error.message === API_ERROR.USER_NOT_REGISTERED) {
          ToastService.error({ text: error.message });
        }
        console.error("Error: while login NEAR wallet \n\n", error);
        return null;
      }
    },
    [getNonceMessage, getNearSignatureAndPublicKey, dispatch],
  );
  return { signUp, loginEVMWallet, loginNearWallet, logout, connectNear };
};
