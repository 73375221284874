import { mainnet } from "viem/chains";

import { stakingAbi } from "shared/contract/abi/staking";
import { whitelistAbi } from "shared/contract/abi/whitelist";

import { IConfig } from "../config";

export default {
  apiURL: "https://hapi.one",
  contractWhitelist: "0xce937BaF86E4BDef49D5401AE8b23Fa328D30661",
  contractToken: "0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54",
  contractStaking: "0xA0417c50c132A97ff79b4B3715fCbC769B9FDa59",
  walletConnectProjectId: "3ecc1e03f86fefc161bb26d227f849eb",
  chains: [mainnet],
  whitelistAbi,
  stakingAbi,
  payment: {
    tokenUrl: "https://hapi.one/payment",
    iframeUrl: "https://app.topperpay.com",
  },
  nearContractId: "proxy.hapiprotocol.near",
  mixpanelToken: "79e6b5b2e4dcc229e769dd1e2dc810c5",
  botName: "i_am_hapi_bot",
} as IConfig;
