import { css, styled } from "styled-components";

import Referrals from "assets/images/dashboard/referrals.svg";

const ReferralTypesContainer = styled.div`
  gap: 1.5rem;
  display: flex;
  padding: 1rem;
  border-radius: 1rem;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.surfaceWhale};
`;

const ReferralColumns = styled.div`
  gap: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        grid-template-columns: 1fr;
      `,
    )};
`;

const Referral = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const TitleDescription = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`;

const TitleProgress = styled.div`
  gap: 2rem;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 600;
  line-height: 116%;
  font-size: 1.25rem;
`;

const Description = styled.div`
  font-weight: 400;
  line-height: 140%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.hapiGrey40};
`;

const ProgressContainer = styled.div`
  gap: 0.63rem;
  display: flex;
  flex-direction: column;
`;

const ProgressLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  text-align: right;
  line-height: 140%;
`;

const Progress = styled.progress<{ $background: string }>`
  border: 0;
  width: 100%;
  height: 1.75rem;
  border-radius: 0.5rem;
  background-color: rgba(${({ $background }) => $background}, 0.2);

  &::-webkit-progress-value {
    border: 0;
    height: 0.25rem;
    border-radius: 0.5rem;
    background-color: rgba(${({ $background }) => $background}, 1);
  }

  &::-moz-progress-bar {
    border: 0;
    height: 1.75rem;
    border-radius: 0.5rem;
    background-color: rgba(${({ $background }) => $background}, 0.2);
  }

  &::-webkit-progress-bar {
    border: 0;
    height: 1.75rem;
    border-radius: 0.5rem;
    background-color: rgba(${({ $background }) => $background}, 0.2);
  }
`;

const AdditionalInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AdditionalInfoKeyValue = styled.div`
  display: flex;
  gap: 0.3125rem;
  align-items: center;
`;

const AdditionalInfoKey = styled.span`
  font-weight: 500;
  line-height: 116%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.grayRGB06};
`;

const AdditionalInfoValue = styled(AdditionalInfoKey)`
  color: ${({ theme }) => theme.colors.white};
`;

const TableBody = styled.div`
  div:last-child {
    border-bottom: none;
  }
`;

const Tr = styled.div`
  display: flex;
  height: 2.5rem;
  font-weight: 400;
  line-height: 120%;
  font-size: 0.75rem;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.hapiGrey40};
  border-bottom: 1px solid ${({ theme }) => theme.colors.hapiGrey80};

  span {
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }
`;

const TrBody = styled(Tr)<{ $colorChecked?: string; checked?: boolean }>`
  color: ${({ theme, checked, $colorChecked }) => (checked ? `rgb(${$colorChecked})` : theme.colors.hapiGrey5)};
`;

const HowItWorks = styled.div`
  gap: 2.88rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        grid-template-columns: 1fr;
      `,
    )};
`;

const ReferralsImage = styled.div`
  max-width: 23.5625rem;
  width: 23.5625rem;
  height: 22.8125rem;
  background: url(${Referrals}) no-repeat;
  background-size: 100%;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        margin: 0 auto;
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        width: 100%;
      `,
    )};
`;

const HowItWorksTitle = styled.div`
  font-weight: 600;
  line-height: 116%;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
`;

const HowItWorksDescription = styled.div`
  gap: 1rem;
  display: flex;
  font-weight: 400;
  line-height: 140%;
  font-size: 0.875rem;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.hapiGrey40};
`;

const TitleDescriptionContainer = styled.div`
  gap: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export default {
  ReferralTypesContainer,
  ReferralColumns,
  Referral,
  TitleDescription,
  TitleProgress,
  Title,
  Description,
  ProgressContainer,
  ProgressLabel,
  Progress,
  AdditionalInfo,
  AdditionalInfoKeyValue,
  AdditionalInfoKey,
  AdditionalInfoValue,
  TableBody,
  Tr,
  TrBody,
  HowItWorks,
  ReferralsImage,
  HowItWorksTitle,
  HowItWorksDescription,
  TitleDescriptionContainer,
};
