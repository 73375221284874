import { css, styled } from "styled-components";

const Container = styled.div`
  width: 100%;
  gap: 1.5rem;
  display: flex;
  max-width: 28.2rem;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        max-width: 100%;
      `,
    )};
`;

const StakingWrapper = styled.div`
  gap: 1.75rem;
  display: flex;
  flex-direction: column;
`;

const TogglerAndStakeInfo = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const Toggler = styled.div`
  gap: 0.75rem;
  display: flex;
`;

const CardsWrapper = styled.div`
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 0.75rem;
        grid-template-columns: 1fr;
      `,
    )};
`;

const Card = styled.div`
  display: flex;
  gap: 1.625rem;
  padding: 0.75rem;
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.whiteOp05};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & > div {
    &:last-child {
      border-radius: 0.75rem;
    }
  }
`;

const RadioButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  & > div {
    ${({ theme }) =>
      theme.mediaWidth(
        "upToSmall",
        css`
          gap: 0.75rem;
          grid-template-columns: 1fr 1fr;
        `,
      )};
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;

      gap: 0.5rem;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Datalist = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & > div {
    display: flex;
    justify-content: space-between;
  }
`;

const Button = styled.div`
  margin-top: 2rem;
  div {
    border-radius: 0.5rem;
  }
`;

export default {
  Container,
  StakingWrapper,
  TogglerAndStakeInfo,
  Toggler,
  CardsWrapper,
  Card,
  InputWrapper,
  RadioButtons,
  Datalist,
  Button,
};
