import { t } from "i18next";

import { ReactComponent as HapiIdCard } from "assets/images/hapi-id-card-scaled.svg";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { selectUserTicketId } from "store/slices/whitelist";

import styles from "./styles";

export const HapiIdComingSoon = () => {
  const userHapiId = useAppSelector(selectUserTicketId);

  return (
    <styles.HapiIdComingSoonContainer>
      <div>
        <styles.Title>{t("Dashboard.Profile.HapiId.Title")}</styles.Title>
        {!!userHapiId && <styles.Subtitle>{t("Dashboard.Profile.HapiId.Description")}</styles.Subtitle>}
        {/* TODO: add handler, when it will be available */}
        <ButtonPrimary name={t("Actions.Mint")} handler={() => console.log("Mint")} disabled />
      </div>
      <HapiIdCard />
    </styles.HapiIdComingSoonContainer>
  );
};
