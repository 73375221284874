import styled, { css, keyframes } from "styled-components";

import { colors } from "shared/theme/colors";

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

const Skeleton = styled.div<{
  $bg: keyof typeof colors;
  $w: string;
  $h: string;
  $animation: boolean;
  $borderRadius?: string;
}>`
  width: ${({ $w }) => $w};
  height: ${({ $h }) => $h};
  background-color: ${({ $bg, theme }) => theme.colors[$bg]};
  border-radius: ${({ $borderRadius }) => $borderRadius || "1.5rem"};

  ${({ $animation }) =>
    $animation &&
    css`
      animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    `}
`;

export default {
  Skeleton,
};
