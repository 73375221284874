import { t } from "i18next";

import styles from "./styles";

export const TitleWithLogo = () => {
  return (
    <styles.Container>
      <p>{t("TokenUtility.Title")}</p>
      <styles.Coins />
    </styles.Container>
  );
};
