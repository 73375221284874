import referralLinksStyles from "pages/Dashboard/components/Referrals/components/ReferralLinks/styles";
import referralTypesStyles from "pages/Dashboard/components/Referrals/components/ReferralTypes/styles";
import statsStyles from "pages/Dashboard/components/Referrals/components/Stats/styles";
import { Skeleton } from "shared/components/Skeleton";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import theme from "shared/theme";

export const ReferralsStatsSkeleton = () => {
  return (
    <statsStyles.Stats>
      <statsStyles.StatsLeft $isSkeleton>
        <Skeleton w="3.4375rem" h="0.75rem" />
        <statsStyles.AmountToClaimAction>
          <statsStyles.AmountToClaim>
            <Skeleton w="3.9375rem" h="1.25rem" />
            <Skeleton w="5.625rem" h="0.625rem" />
          </statsStyles.AmountToClaim>
          <Skeleton w="4.75rem" h="2.25rem" borderRadius="0.75rem" />
        </statsStyles.AmountToClaimAction>
      </statsStyles.StatsLeft>
      <statsStyles.StatsRight>
        {Array.from(Array(4).keys()).map((el) => (
          <statsStyles.Stat key={el} $color={theme.colors.blackLight} $background={theme.colors.blackLight}>
            <Skeleton w="3.4375rem" h="0.75rem" />
            <Skeleton w="3.9375rem" h="1.25rem" />
          </statsStyles.Stat>
        ))}
      </statsStyles.StatsRight>
    </statsStyles.Stats>
  );
};

export const ReferralTypesSkeleton = () => {
  const { isSmall } = useWindowDimensions();

  return (
    <referralTypesStyles.ReferralTypesContainer>
      {!isSmall && (
        <referralTypesStyles.TitleDescriptionContainer>
          <referralTypesStyles.TitleDescription>
            <Skeleton w="3.4375rem" h="1rem" />
            <Skeleton w="100%" h="0.75rem" />
            <Skeleton w="100%" h="0.75rem" />
            <Skeleton w="12.3125rem" h="0.75rem" />
          </referralTypesStyles.TitleDescription>
          <referralTypesStyles.TitleDescription>
            <Skeleton w="3.4375rem" h="1rem" />
            <Skeleton w="100%" h="0.75rem" />
            <Skeleton w="100%" h="0.75rem" />
            <Skeleton w="12.3125rem" h="0.75rem" />
          </referralTypesStyles.TitleDescription>
        </referralTypesStyles.TitleDescriptionContainer>
      )}
      <referralTypesStyles.ReferralColumns>
        {Array.from(Array(2).keys()).map((el) => (
          <referralTypesStyles.Referral key={el}>
            {isSmall && (
              <referralTypesStyles.TitleDescription>
                <Skeleton w="3.4375rem" h="1rem" />
                <Skeleton w="100%" h="0.75rem" />
                <Skeleton w="100%" h="0.75rem" />
                <Skeleton w="12.3125rem" h="0.75rem" />
              </referralTypesStyles.TitleDescription>
            )}
            <referralTypesStyles.ProgressContainer>
              <referralTypesStyles.ProgressLabel>
                <Skeleton w="3.4375rem" h="1rem" />
              </referralTypesStyles.ProgressLabel>
              <Skeleton w="100%" h="1.875rem" />
              <referralTypesStyles.AdditionalInfo>
                <Skeleton w="5.625rem" h="0.75rem" />
                <Skeleton w="7rem" h="0.75rem" />
              </referralTypesStyles.AdditionalInfo>
            </referralTypesStyles.ProgressContainer>
            <div>
              <referralTypesStyles.Tr>
                <Skeleton w="3.9375rem" h="0.75rem" />
                <Skeleton w="3.4375rem" h="0.75rem" />
              </referralTypesStyles.Tr>
              <referralTypesStyles.TableBody>
                {Array.from(Array(7).keys()).map((el) => {
                  return (
                    <referralTypesStyles.TrBody key={el}>
                      <Skeleton w="5.5rem" h="0.75rem" />
                      <Skeleton w="7rem" h="0.75rem" />
                    </referralTypesStyles.TrBody>
                  );
                })}
              </referralTypesStyles.TableBody>
            </div>
          </referralTypesStyles.Referral>
        ))}
      </referralTypesStyles.ReferralColumns>
      <referralTypesStyles.HowItWorks>
        <div>
          <referralTypesStyles.HowItWorksTitle>
            <Skeleton w="3.4375rem" h="1rem" />
          </referralTypesStyles.HowItWorksTitle>
          <referralTypesStyles.HowItWorksDescription>
            <Skeleton w="100%" h="0.75rem" />
            <Skeleton w="100%" h="0.75rem" />
            <Skeleton w="100%" h="0.75rem" />
            <Skeleton w="90%" h="0.75rem" />
            <Skeleton w="80%" h="0.75rem" />
            <Skeleton w="85%" h="0.75rem" />
          </referralTypesStyles.HowItWorksDescription>
        </div>
        <Skeleton w="100%" h="16.75rem" />
      </referralTypesStyles.HowItWorks>
    </referralTypesStyles.ReferralTypesContainer>
  );
};

export const ReferralLinksSkeleton = () => {
  return (
    <referralLinksStyles.ReferralLinksContainer>
      <Skeleton w="8.4375rem" h="1rem" />
      <referralLinksStyles.Row>
        <referralLinksStyles.List>
          {Array.from(Array(10).keys()).map((el) => (
            <referralLinksStyles.ListItem key={el}>
              <Skeleton w="6.875rem" h="0.75rem" />
              <Skeleton w="3.4375rem" h="0.75rem" />
            </referralLinksStyles.ListItem>
          ))}
        </referralLinksStyles.List>
      </referralLinksStyles.Row>
    </referralLinksStyles.ReferralLinksContainer>
  );
};
