import Big from "big.js";
import { t } from "i18next";
import { useDispatch } from "react-redux";

import { ReactComponent as ReferralsIcon } from "assets/icons/dashboard/referrals.svg";
import { useGetHapiPriceQuery, useGetReferralsQuery } from "services/api/hapi/ref";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { EMPTY_STRING, ZERO_STRING } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { getUserFriendlyAmount } from "shared/utils/getUserFriendlyAmount";
import { EModals, showModal } from "store/slices/modals";
import { selectStakingData } from "store/slices/staking";
import { selectToken } from "store/slices/token";

import styles from "./styles";
import { getStats } from "./utils";
import CountdownTimer from "../CountdownTimer";
import { ReferralsStatsSkeleton } from "../Skeletons";

export const Stats: React.FC = () => {
  const dispatch = useDispatch();
  const { stakingFieldsData } = useAppSelector(selectStakingData);
  const token = useAppSelector((state) => selectToken(state, stakingFieldsData?.token));
  const { data, isLoading } = useGetReferralsQuery();
  const { data: hapiPriceData } = useGetHapiPriceQuery();

  if (isLoading) return <ReferralsStatsSkeleton />;

  const hapiPrice = hapiPriceData?.price || ZERO_STRING;
  const symbol = token?.symbol || EMPTY_STRING;
  const referrals = data?.data;

  const links = referrals?.permanent_code ? 1 : referrals?.onetime_codes.length || 0;
  const members = referrals ? referrals?.level1.referral_count + referrals?.level2.referral_count : 0;

  const stakedDirect = referrals?.level1.total_stake || 0;
  const stakedIndirect = referrals?.level2.total_stake || 0;

  const apyDirect = data?.data?.level1.apy || EMPTY_STRING;
  const apyIndirect = data?.data?.level2.apy || EMPTY_STRING;

  const stats = getStats(apyDirect, apyIndirect, stakedDirect, stakedIndirect, links, members, symbol);

  const rewardDirect = data?.data?.level1.reward || ZERO_STRING;
  const rewardIndirect = data?.data?.level2.reward || ZERO_STRING;
  const reward = Big(rewardDirect).add(rewardIndirect);
  const rewardUSD = reward.mul(hapiPrice).toFixed(2);

  return (
    <styles.Stats>
      <styles.StatsLeft>
        <styles.StatsLeftTitleTimer>
          <styles.StatsLeftTitle>
            <ReferralsIcon />
            {t("Dashboard.Referrals.Stats.ReferralRewards.Title")}
          </styles.StatsLeftTitle>
          <styles.StatsLeftTimer>
            {t("Dashboard.Referrals.Stats.ReferralRewards.NextUpdate")}
            <CountdownTimer />
          </styles.StatsLeftTimer>
        </styles.StatsLeftTitleTimer>
        <styles.AmountToClaimAction>
          <styles.AmountToClaim>
            <div>
              {getUserFriendlyAmount(reward)} {symbol}
            </div>
            <div>${rewardUSD}</div>
          </styles.AmountToClaim>
          <ButtonPrimary
            name={t("Actions.Claim")}
            handler={() => dispatch(showModal({ modal: EModals.CLAIM_MODAL, props: { claimAmount: reward, symbol } }))}
            isOutlined
          />
        </styles.AmountToClaimAction>
      </styles.StatsLeft>
      <styles.StatsRight>
        {stats.map(({ value, icon, title, additionalTitle, color, background }) => (
          <styles.Stat $color={color} $background={background} key={background}>
            <styles.ValueIcon>
              <styles.Value>{value}</styles.Value>
              {icon}
            </styles.ValueIcon>
            <styles.Title>
              <div>{title}</div>
              {additionalTitle && <div>{additionalTitle}</div>}
            </styles.Title>
          </styles.Stat>
        ))}
      </styles.StatsRight>
    </styles.Stats>
  );
};
