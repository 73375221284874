import styled, { keyframes } from "styled-components";

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;

export const StyledLoading = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.black};
  border-color: ${({ theme }) => theme.colors.black} transparent ${({ theme }) => theme.colors.black} transparent;
  animation: ${spinner} 1s linear infinite;
`;
