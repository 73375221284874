import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { logger } from "services/mixpanel";
import * as M from "shared/components/modals";
import { IClaimModalProps } from "shared/components/modals/ClaimReferralRewards/types";
import {
  IModalProps,
  ISignMessageModalProps,
  IAddWalletModalProps,
  IConfirmMainWalletModalProps,
  IAccountDuplicationModalProps,
  IStakeModalProps,
} from "shared/interfaces/modal";
import { RootState } from "store";

export enum EModals {
  EMPTY = "EMPTY",
  LOGOUT_MODAL = "LOGOUT_MODAL",
  EDIT_NAME_MODAL = "EDIT_NAME_MODAL",
  CONNECT_WALLET_MODAL = "CONNECT_WALLET_MODAL",
  SIGN_MESSAGE_MODAL = "SIGN_MESSAGE_MODAL",
  STAKE_MODAL = "STAKE_MODAL",
  CLAIM_MODAL = "CLAIM_MODAL",
  CONFIRM_MAIN_WALLET = "CONFIRM_MAIN_WALLET",
  ACCOUNT_DUPLICATION = "ACCOUNT_DUPLICATION",
}

export type IModalsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
  [EModals.LOGOUT_MODAL]: IModalProps;
  [EModals.EDIT_NAME_MODAL]: IModalProps;
  [EModals.CONNECT_WALLET_MODAL]: IAddWalletModalProps;
  [EModals.SIGN_MESSAGE_MODAL]: ISignMessageModalProps;
  [EModals.STAKE_MODAL]: IStakeModalProps;
  [EModals.CLAIM_MODAL]: IClaimModalProps;
  [EModals.CONFIRM_MAIN_WALLET]: IConfirmMainWalletModalProps;
  [EModals.ACCOUNT_DUPLICATION]: IAccountDuplicationModalProps;
};

export type IModals = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
  [EModals.LOGOUT_MODAL]: React.FC<IModalProps>;
  [EModals.EDIT_NAME_MODAL]: React.FC<IModalProps>;
  [EModals.CONNECT_WALLET_MODAL]: React.FC<IAddWalletModalProps>;
  [EModals.SIGN_MESSAGE_MODAL]: React.FC<ISignMessageModalProps>;
  [EModals.STAKE_MODAL]: React.FC<IStakeModalProps>;
  [EModals.CLAIM_MODAL]: React.FC<IClaimModalProps>;
  [EModals.CONFIRM_MAIN_WALLET]: React.FC<IConfirmMainWalletModalProps>;
  [EModals.ACCOUNT_DUPLICATION]: React.FC<IAccountDuplicationModalProps>;
};

export const MODALS: IModals = {
  [EModals.EMPTY]: null,
  [EModals.LOGOUT_MODAL]: M.LogoutModal,
  [EModals.EDIT_NAME_MODAL]: M.EditNameModal,
  [EModals.CONNECT_WALLET_MODAL]: M.AddWalletModal,
  [EModals.SIGN_MESSAGE_MODAL]: M.SignMessageModal,
  [EModals.STAKE_MODAL]: M.StakeModal,
  [EModals.CLAIM_MODAL]: M.ClaimReferralRewardsModal,
  [EModals.CONFIRM_MAIN_WALLET]: M.ConfirmMainWalletModal,
  [EModals.ACCOUNT_DUPLICATION]: M.AccountDuplicationModal,
};

interface IModalState {
  modal: EModals;
  props: IModalsProps[EModals];
}

const initialState: IModalState = {
  modal: EModals.EMPTY,
  props: null,
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    showModal: (_, action: PayloadAction<IModalState>) => {
      logger.trackEvent("OPEN_MODAL", { modal: action.payload.modal });
      return action.payload;
    },
    closeModal: (state) => {
      logger.trackEvent("CLOSE_MODAL", { modal: state.modal });
      return initialState;
    },
  },
});

export const selectModalState = (state: RootState) => state.modals;

export const { showModal, closeModal } = modalsSlice.actions;
