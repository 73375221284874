import { getAccount, switchChain } from "@wagmi/core";

import { chains } from "services/config";
import { walletConfig } from "services/wallet";
import ToastService, { EToast } from "shared/components/Toast";
import { EMPTY_STRING } from "shared/constants";

export const switchNetwork = async () => {
  const { chain } = getAccount(walletConfig);
  const isSupportedChain = Object.values(chains).find((supportedChains) => supportedChains.id === chain?.id);
  if (isSupportedChain) return;

  const toastId = ToastService.loading({ text: `Switching network` });
  try {
    const [defaultChain] = chains;
    const switchNetworkResult = await switchChain(walletConfig, { chainId: defaultChain.id });

    ToastService.update({ id: toastId, text: `Network ${switchNetworkResult.name}`, type: EToast.SUCCESS });
  } catch (error) {
    console.error(`Error: while switch network \n ${error}`, `\n\nChainId: ${chain?.id}`);
    ToastService.update({
      id: toastId,
      text: `Your Network ${
        chain?.name || EMPTY_STRING
      } are Not Supported. Please Choose a Different Network in wallet.`,
      type: EToast.ERROR,
    });
  }
};
