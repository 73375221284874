import { Dispatch, SetStateAction } from "react";

import { ReactComponent as Close } from "assets/icons/close.svg";

import styles from "./styles";

interface IBurgerToggle {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

export default function BurgerToggle({ isOpened, setIsOpened }: IBurgerToggle) {
  return (
    <styles.Toggle $isOpen={isOpened} onClick={() => setIsOpened(!isOpened)}>
      {isOpened ? (
        <Close />
      ) : (
        <>
          <div>
            <span />
          </div>
          <div>
            <span />
          </div>
          <div>
            <span />
          </div>
        </>
      )}
    </styles.Toggle>
  );
}
