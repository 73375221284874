import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";

import { ReactComponent as Wallet } from "assets/icons/wallet.svg";
import { useGetDashboardQuery } from "services/api/hapi/ref";
import { logger } from "services/mixpanel";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { Skeleton } from "shared/components/Skeleton";
import { SEARCH_PARAMS } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { useWalletSelector } from "shared/providers/wallet/walletSelector";
import { displayAmount } from "shared/utils/displayAmount";
import { cropString } from "shared/utils/stringOperations";
import { EModals, closeModal, showModal } from "store/slices/modals";
import { selectUserStakingData } from "store/slices/staking";

import styles from "./styles";

enum BLOCKCHAIN {
  EVM = "evm",
  NEAR = "near",
}

export function UserWallets() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const walletSelector = useWalletSelector();
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const userStakingData = useAppSelector(selectUserStakingData);
  const addresses = useMemo(() => (userStakingData ? userStakingData.addresses : []), [userStakingData]);

  const { disconnectAsync } = useDisconnect();

  const { isFetching: isValidating } = useGetDashboardQuery();

  const [searchParams, setSearchParams] = useSearchParams();

  const memoData = useMemo(() => {
    const signMessageModalKey = searchParams.get(SEARCH_PARAMS.SIGN_MESSAGE_MODAL_KEY) as BLOCKCHAIN | null;
    const nearWalletExists = addresses.some((wallet) => wallet.address === walletSelector.accountId);
    const evmWalletExists = addresses.some((wallet) => wallet.address === address);
    const isNearSignedIn =
      signMessageModalKey === BLOCKCHAIN.NEAR && walletSelector.selector.isSignedIn() && walletSelector.accountId;
    return {
      signMessageModalKey,
      nearWalletExists,
      evmWalletExists,
      isNearSignedIn,
    };
  }, [address, addresses, walletSelector.accountId, walletSelector.selector, searchParams]);

  useEffect(() => {
    if (memoData.nearWalletExists && memoData.signMessageModalKey) {
      searchParams.delete(SEARCH_PARAMS.SIGN_MESSAGE_MODAL_KEY);
      setSearchParams(searchParams);
    } else if (memoData.isNearSignedIn && !memoData.nearWalletExists && memoData.signMessageModalKey) {
      dispatch(showModal({ modal: EModals.SIGN_MESSAGE_MODAL, props: { type: BLOCKCHAIN.NEAR } }));
    } else if (address && !memoData.evmWalletExists && memoData.signMessageModalKey === BLOCKCHAIN.EVM) {
      dispatch(showModal({ modal: EModals.SIGN_MESSAGE_MODAL, props: { type: BLOCKCHAIN.EVM } }));
    }
  }, [
    searchParams,
    setSearchParams,
    address,
    dispatch,
    memoData.evmWalletExists,
    memoData.isNearSignedIn,
    memoData.nearWalletExists,
    memoData.signMessageModalKey,
  ]);

  const evmHandler = async () => {
    await disconnectAsync(undefined, {
      onSuccess() {
        open();
        dispatch(closeModal());
        logger.trackEvent("ADD_WALLET", { type: BLOCKCHAIN.EVM });
        searchParams.set(SEARCH_PARAMS.SIGN_MESSAGE_MODAL_KEY, BLOCKCHAIN.EVM);
        setSearchParams(searchParams);
      },
    });
  };

  const nearHandler = async () => {
    const { modal, selector } = walletSelector;
    if (selector.isSignedIn()) {
      const wallet = await selector.wallet();
      await wallet.signOut();
    }
    modal.show();
    dispatch(closeModal());
    logger.trackEvent("ADD_WALLET", { type: BLOCKCHAIN.NEAR });
    searchParams.set(SEARCH_PARAMS.SIGN_MESSAGE_MODAL_KEY, BLOCKCHAIN.NEAR);
    setSearchParams(searchParams);
  };

  const handlerAddWallet = () => {
    dispatch(
      showModal({
        modal: EModals.CONNECT_WALLET_MODAL,
        props: {
          evmHandler,
          nearHandler,
        },
      }),
    );
  };

  return (
    <styles.Container>
      <styles.Title>{t("Dashboard.Profile.ConnectedWallets")}</styles.Title>
      <styles.List>
        {addresses.map(({ address, balance_hapi }, index) => (
          <styles.Item key={address}>
            <p>
              {cropString(address)}
              {index === 0 && <styles.Chip>{t("Dashboard.Profile.MainWallet")}</styles.Chip>}
            </p>
            {isValidating ? (
              <Skeleton w="3rem" h="1.4rem" />
            ) : (
              <p>{t("Dashboard.TokenAmount", { amount: displayAmount(balance_hapi), symbol: "HAPI" })}</p>
            )}
          </styles.Item>
        ))}
      </styles.List>
      <ButtonPrimary
        name={t("Dashboard.Actions.AddWallet")}
        handler={handlerAddWallet}
        isOutlined
        iconRight={<Wallet />}
      />
    </styles.Container>
  );
}
