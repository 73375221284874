import { t } from "i18next";

import { ReferralLinks } from "./components/ReferralLinks";
import { ReferralStats } from "./components/ReferralStats";
import { ReferralTypes } from "./components/ReferralTypes";
import { Stats } from "./components/Stats";
import styles from "./styles";
import dashboardStyles from "../../styles";

export const Referrals: React.FC = () => {
  return (
    <styles.RightSideContainer>
      <dashboardStyles.Title>{t("Dashboard.Referrals.Title")}</dashboardStyles.Title>
      <styles.ReferralsContainer>
        <Stats />
        <ReferralStats />
        <ReferralTypes />
        <ReferralLinks />
      </styles.ReferralsContainer>
    </styles.RightSideContainer>
  );
};
