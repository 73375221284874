import styled, { css } from "styled-components";

const AdvantagesContainer = styled.div`
  padding: 0 4rem;
  background-color: #ff6960;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        padding: 0 1rem;
      `,
    )};
`;

const Advantages = styled.div`
  display: flex;
  height: 12000px;
  align-items: center;
  padding-bottom: 12rem;
  flex-direction: column;
`;

const Title = styled.div`
  position: sticky;
  top: 6.25rem;
  font-weight: 900;
  line-height: 109%;
  font-size: 6.25rem;
  text-align: center;
  margin-top: 6.25rem;
  color: ${({ theme }) => theme.colors.hapiDark};
  font-family: ${({ theme }) => theme.fonts.archivo};

  & + div {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 1rem;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 2rem;
      filter: blur(43.150001525878906px);
      background: ${({ theme }) => theme.colors.hapiDark};
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        top: 10rem;
        margin-top: 10rem;
        font-size: 3.75rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        top: 8.75rem;
        font-size: 2.75rem;
        margin-top: 8.75rem;
      `,
    )};
`;

export default {
  AdvantagesContainer,
  Advantages,
  Title,
};
