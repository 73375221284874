import { payment } from "services/config";

import { fetchData } from "./index";
import { IPaymentToken } from "./interfaces";

export class PaymentApi {
  async getPaymentTokenPayload() {
    const apiUrl = `${payment.tokenUrl}/token`;
    return fetchData<IPaymentToken>(apiUrl, {});
  }

  getPaymentUrl(token: string) {
    return `${payment.iframeUrl}/?bt=${token}`;
  }
}
