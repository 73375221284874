import { ICard } from "./interface";
import styles from "./styles";

export const Card = ({ title, subtitle, value, button }: ICard) => {
  return (
    <styles.CardWrapper>
      <styles.Row $singleColumn={!subtitle}>
        <styles.Title>{title}</styles.Title>
        {subtitle && <styles.Subtitle>{subtitle}</styles.Subtitle>}
      </styles.Row>
      <styles.Row $singleColumn={!button}>
        <styles.Value>{value}</styles.Value>
        {button && (
          <styles.ButtonWrapper>
            <styles.Button onClick={button.handler} disabled={button.disabled}>
              {button.title}
            </styles.Button>
          </styles.ButtonWrapper>
        )}
      </styles.Row>
    </styles.CardWrapper>
  );
};
