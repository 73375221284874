import { t } from "i18next";

export const getData = () => [
  {
    title: t("Snap.AdditionalInfo.ComprehensiveAndLatestData.Title"),
    description: t("Snap.AdditionalInfo.ComprehensiveAndLatestData.Description"),
  },
  {
    title: t("Snap.AdditionalInfo.AIThreatDetection.Title"),
    description: t("Snap.AdditionalInfo.AIThreatDetection.Description"),
  },
];
