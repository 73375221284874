import { Footer } from "shared/components/Footer";
import { Header } from "shared/components/Header";
import {
  TitleWithLogo,
  StakingProgram,
  TokenDistributionBySalesMintingAttestations,
  TokenDistributionByServices,
} from "shared/components/TokenUtility";

import styles from "./styles";

export const TokenUtility = () => {
  return (
    <styles.Container>
      <Header />
      <styles.Body>
        <TitleWithLogo />
        <TokenDistributionByServices />
        <TokenDistributionBySalesMintingAttestations />
        <StakingProgram />
        <styles.FirstGradient src={"/static/token-utility/first-gradient-bg.png"} />
        <styles.SecondGradient src={"/static/token-utility/second-gradient-bg.png"} />
      </styles.Body>
      <Footer />
    </styles.Container>
  );
};
