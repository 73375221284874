import { useInView } from "framer-motion";
import { t } from "i18next";
import { useRef } from "react";

import { FAQItem } from "shared/components/FAQ/FAQItem";

import styles from "./styles";
import snapStyles from "../../styles";

export const FAQ: React.FC = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <styles.FAQContainer ref={ref} isInView={isInView}>
      <snapStyles.H2>{t("Snap.FAQ.Title")}</snapStyles.H2>
      <styles.List>
        {Array.from({ length: 4 }, (_, i) => i + 1).map((item) => (
          <FAQItem
            key={t(`Snap.FAQ.List.${item}.Question`)}
            question={t(`Snap.FAQ.List.${item}.Question`)}
            answer={t(`Snap.FAQ.List.${item}.Answer`)}
          />
        ))}
      </styles.List>
    </styles.FAQContainer>
  );
};
