import { ReactComponent as Dashboard } from "assets/icons/ecosystem/dashboard.svg";
import { ReactComponent as HapiId } from "assets/icons/ecosystem/hapi-id.svg";
import { ReactComponent as Scamfari } from "assets/icons/ecosystem/scamfari.svg";
import { ReactComponent as Snap } from "assets/icons/ecosystem/snap.svg";
import { ReactComponent as TokenUtility } from "assets/icons/ecosystem/token-utility.svg";
import { APP_ROUTES } from "routes/appRoutes";

export const ECOSYSTEM_LINKS = {
  scamfari: "https://scamfari.com",
  hapiId: "/hapi-id",
  tokenUtility: APP_ROUTES.TOKEN_UTILITY,
  dashboard: APP_ROUTES.DASHBOARD.HOME,
  snap: APP_ROUTES.SNAP.HOME,
};

export enum EEcosystem {
  SCAMFARI = "Scamfari",
  HAPI_ID = "HapiId",
  TOKEN_UTILITY = "TokenUtility",
  Dashboard = "Dashboard",
  SNAP = "Snap",
}

export const ecosystem = [
  {
    key: EEcosystem.SCAMFARI,
    url: ECOSYSTEM_LINKS.scamfari,
    icon: <Scamfari />,
  },
  {
    isInternal: true,
    key: EEcosystem.HAPI_ID,
    url: ECOSYSTEM_LINKS.hapiId,
    icon: <HapiId />,
  },
  {
    isInternal: true,
    key: EEcosystem.TOKEN_UTILITY,
    url: ECOSYSTEM_LINKS.tokenUtility,
    icon: <TokenUtility />,
  },
  {
    isInternal: true,
    key: EEcosystem.Dashboard,
    url: ECOSYSTEM_LINKS.dashboard,
    icon: <Dashboard />,
  },
  {
    isInternal: true,
    key: EEcosystem.SNAP,
    url: ECOSYSTEM_LINKS.snap,
    icon: <Snap />,
  },
];
