import { css, styled } from "styled-components";

const StatisticContainer = styled.div`
  gap: 2rem;
  width: 100%;
  display: flex;
  max-width: 59.25rem;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        gap: 1.5rem;
      `,
    )};
`;

const Title = styled.div`
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  font-size: 1.25rem;
`;

const Stats = styled.div`
  gap: 1.5rem;
  display: flex;
  justify-content: center;

  > div {
    flex: 1;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        gap: 1rem;
        flex-direction: column;
      `,
    )};
`;

export default {
  StatisticContainer,
  Stats,
  Title,
};
