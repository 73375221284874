import { createGlobalStyle } from "styled-components";

import { Theme } from "./index";

const GlobalStyle = createGlobalStyle`
  :root{
    --w3m-z-index: 400;
  }

  * {
    box-sizing: border-box;
    font-family: ${({ theme }: { theme: Theme }) => theme.fonts.inter};
  }

  html {
    margin: 0;
    padding: 0;
    overflow-x: clip;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: clip;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.hapiDark};
  }
  
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .nws-modal-wrapper {
    z-index: var(--w3m-z-index);
  }

  input[type=number] {
    appearance: none;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export default GlobalStyle;
