import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  width: 100%;
  margin-bottom: 1rem;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  max-width: 28.2rem;
  width: 100%;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        max-width: 100%;
      `,
    )};
`;

const RightContainer = styled.div`
  max-width: 28.2rem;
  width: 100%;
`;

const UserName = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 120%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.hapiGrey20};
    &:hover {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

const ProfileData = styled.div`
  display: flex;
  & > div {
    &:nth-child(2) {
      align-items: center;
    }
    &:last-child {
      align-items: flex-end;
    }
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        flex-direction: column;
        gap: 1rem;
      `,
    )};
`;

const ProfileDataCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  p {
    margin: 0;
    display: flex;
    line-height: 140%;
    &:first-child {
      color: ${({ theme }) => theme.colors.hapiGrey20};
      font-size: 0.875rem;
      font-weight: 400;
      gap: 0.25rem;
    }
    &:last-child {
      color: ${({ theme }) => theme.colors.white};
      font-size: 1.25rem;
      font-weight: 600;
      gap: 0.5rem;
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        flex-direction: row;
        justify-content: space-between;
      `,
    )};
`;

export default {
  Container,
  LeftContainer,
  RightContainer,
  UserName,
  ProfileData,
  ProfileDataCol,
};
