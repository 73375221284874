import styled from "styled-components";

const List = styled.div`
  gap: 0.94rem;
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

const TitleTooltip = styled.div`
  gap: 0.25rem;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 500;
  line-height: 120%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.hapiGrey40};
`;

const Value = styled.div`
  gap: 0.5rem;
  display: flex;
  font-weight: 500;
  line-height: 120%;
  text-align: right;
  font-size: 0.875rem;
  align-items: center;
  justify-content: flex-end;
`;

const Subvalue = styled.div`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.hapiGrey40};
`;

const Tooltip = styled.div`
  line-height: 0;
`;

const Button = styled.button`
  border: 0;
  padding: 0;
  cursor: pointer;
  font-weight: 700;
  width: fit-content;
  font-size: 0.875rem;
  background-color: unset;
  color: ${({ theme }) => theme.colors.hapiYellow1};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export default {
  List,
  ListItem,
  TitleTooltip,
  Title,
  Value,
  Tooltip,
  Subvalue,
  Button,
};
