import styled from "styled-components";

const Table = styled.table`
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
`;

const TableHeader = styled.thead``;

const TableHead = styled.th``;

const TableBody = styled.tbody``;

const TableCell = styled.td``;

const TableRow = styled.tr`
  border-width: 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.hapiGrey80};
  border-bottom-width: 1px;
`;

export { Table, TableBody, TableCell, TableHead, TableHeader, TableRow };
