import { useInView } from "framer-motion";
import { t } from "i18next";
import { useRef } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ArrowLink } from "assets/icons/arrow-link.svg";
import { ReactComponent as Bocachica } from "assets/images/snap/projects/bocachica.svg";
import { ReactComponent as Jumbo } from "assets/images/snap/projects/jumbo.svg";
import { ReactComponent as Pitchtalk } from "assets/images/snap/projects/pitchtalk.svg";
import { ReactComponent as Scamfari } from "assets/images/snap/projects/scamfari.svg";
import { ReactComponent as Explorer } from "assets/images/snap/tools/explorer.svg";
import { ReactComponent as Protocol } from "assets/images/snap/tools/protocol.svg";
import { ReactComponent as Terminal } from "assets/images/snap/tools/terminal.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";

import styles from "./styles";
import snapStyles from "../../styles";

export const SecureCryptos: React.FC = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const isInView = useInView(ref, { once: true });

  return (
    <styles.SecureCryptosContainer ref={ref} isInView={isInView}>
      <styles.Info>
        <snapStyles.H2>{t("Snap.SecureCryptos.Title")}</snapStyles.H2>
        <snapStyles.Description>{t("Snap.SecureCryptos.Description")}</snapStyles.Description>
        <ButtonPrimary
          name={t("Actions.LearnAboutHAPI")}
          handler={() => navigate(APP_ROUTES.HOME)}
          iconRight={<ArrowLink />}
          isOutlined
        />
      </styles.Info>
      <styles.MarqueeContainer>
        <Marquee>
          <styles.MarqueeItem>
            <Jumbo />
            <Pitchtalk />
            <Scamfari />
            <Bocachica />
          </styles.MarqueeItem>
        </Marquee>
        <Marquee direction="right">
          <styles.MarqueeItem>
            <Explorer />
            <Terminal />
            <Protocol />
          </styles.MarqueeItem>
        </Marquee>
      </styles.MarqueeContainer>
    </styles.SecureCryptosContainer>
  );
};
