enum RequestTypes {
  GET = "GET",
}
const HEADERS = { "Content-type": "application/json; charset=UTF-8" };

export async function fetchData<T>(url: string, headers: { [key: string]: string }): Promise<T | null> {
  try {
    const response = await fetch(url, {
      headers: Object.assign(HEADERS, headers),
      method: RequestTypes.GET,
    });
    return response.json();
  } catch (error) {
    console.error(`Error fetching data from ${url}:\n`, error);
    return null;
  }
}
