import { Skeleton } from "shared/components/Skeleton";
import { ColumnsOptions } from "shared/interfaces";
import { randomFloat } from "shared/utils/calculation";

export const columnsSkeleton = <ColumnType,>(value: ColumnType[], options: ColumnsOptions): ColumnType[] =>
  value.map((column, index, arr) => {
    const element = options[index];
    return {
      ...column,
      cell: () => {
        const maxWidth = element.maxWidth;
        const minWidth = element.minWidth;
        const randomWidth = element?.isRandomWidth && maxWidth && minWidth ? randomFloat(minWidth, maxWidth) : "100%";
        const width = element.width ?? randomWidth;
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: index === arr.length - 1 ? "flex-end" : undefined,
            }}
          >
            <Skeleton w={`${width}rem`} h="1rem" borderRadius="0.25rem" />
          </div>
        );
      },
    };
  });
