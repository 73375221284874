export const copy = async (value: string) => {
  try {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(value).catch(() => {
        throw Error();
      });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.warn(`Error: while copy ${value} \n ${error}`);
    return false;
  }
};
