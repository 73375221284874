import { Dispatch, SetStateAction } from "react";

import { ReactComponent as ArrowLink } from "assets/icons/arrow-link.svg";
import { ReactComponent as ArrowSmall } from "assets/icons/arrow-small.svg";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import { Folder } from "shared/components/Header/components/Folder";
import { ENavigationListKeys } from "shared/components/Header/components/Navigation/enum";
import { INavigationItem } from "shared/components/Header/components/Navigation/interface";
import { ISubmenuOptionsProps } from "shared/components/Header/components/Submenu/interface";
import { CommunitySubmenu } from "shared/components/Header/components/Submenu/options/Community";
import { EcosystemSubmenu } from "shared/components/Header/components/Submenu/options/Ecosystem";
import { ToolsSubmenu } from "shared/components/Header/components/Submenu/options/Tools";
import theme from "shared/theme";

export const submenuCollectionStatusesInitial = {
  [ENavigationListKeys.Ecosystem]: {
    display: false,
  },
  [ENavigationListKeys.Community]: {
    display: false,
  },
  [ENavigationListKeys.HapiId]: {
    display: false,
  },
  [ENavigationListKeys.About]: { display: false },
  [ENavigationListKeys.Docs]: { display: false },
  [ENavigationListKeys.BuyCrypto]: { display: false },
  [ENavigationListKeys.Tools]: { display: false },
};

export const navList: INavigationItem[] = [
  {
    key: ENavigationListKeys.Ecosystem,
    submenu: {
      component: (
        toggleSubmenu?: ISubmenuOptionsProps["toggleSubmenu"],
        setShowBurger?: Dispatch<SetStateAction<boolean>>,
      ) => <EcosystemSubmenu toggleSubmenu={toggleSubmenu} setShowBurger={setShowBurger} />,
      fullWidth: false,
    },
  },
  {
    key: ENavigationListKeys.Community,
    submenu: {
      component: (
        toggleSubmenu?: ISubmenuOptionsProps["toggleSubmenu"],
        setShowBurger?: Dispatch<SetStateAction<boolean>>,
      ) => <CommunitySubmenu toggleSubmenu={toggleSubmenu} setShowBurger={setShowBurger} />,
      fullWidth: false,
    },
  },
  {
    key: ENavigationListKeys.HapiId,
  },
  {
    key: ENavigationListKeys.About,
  },
  {
    key: ENavigationListKeys.Docs,
    icon: { component: <ArrowLink /> },
  },
  {
    key: ENavigationListKeys.BuyCrypto,
    icon: { component: <ArrowLink /> },
  },
  {
    key: ENavigationListKeys.Tools,
    submenu: {
      component: (toggleSubmenu?: ISubmenuOptionsProps["toggleSubmenu"]) => (
        <ToolsSubmenu toggleSubmenu={toggleSubmenu} />
      ),
      fullWidth: true,
    },
    icon: { component: <Arrow />, componentAdaptive: <ArrowSmall /> },
    backgroundFigure: {
      component: (isHeaderAlternative: boolean) => <Folder isHeaderAlternative={isHeaderAlternative} />,
      colorsDefault: {
        text: theme.colors.hapiDark,
        bg: theme.colors.hapiDark,
      },
      colorsHover: {
        text: theme.colors.hapiDark,
        bg: theme.colors.hapiDark,
      },
    },
    showOnMobile: true,
  },
];
