import { t } from "i18next";
import { useEffect } from "react";
import { useAccount } from "wagmi";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { EStakeUnstakeStage, IStakeModalProps } from "shared/interfaces/modal";
import theme from "shared/theme";
import { setStakingStage } from "store/slices/staking";
import { selectStakeStage } from "store/slices/staking";

import styles from "./styles";
import { getStageData } from "./utils";

export function StakeModal({ closeModal, input, decimals, isStakeView, onSubmit }: IStakeModalProps) {
  const { address } = useAccount();
  const dispatch = useAppDispatch();
  const stage = useAppSelector(selectStakeStage);

  const closeAndSetInitialModal = () => {
    closeModal();
    dispatch(setStakingStage(EStakeUnstakeStage.ENABLE_SPENDING));
  };

  const { icon, title, description, value, isLoading, button, hideClose } = getStageData(
    input,
    decimals,
    closeAndSetInitialModal,
    onSubmit,
    isStakeView,
    dispatch,
    stage,
    address,
  );

  useEffect(() => {
    !isStakeView && dispatch(setStakingStage(EStakeUnstakeStage.STAKING_UNSTAKING));
  }, [isStakeView, dispatch]);

  return (
    <ModalWrapper closeModal={() => {}} bgColor={theme.colors.darkenBlue} widthInRem="23.5" clearPadding>
      <styles.Container>
        <styles.Close>{!hideClose && <CloseIcon onClick={closeModal} />}</styles.Close>
        <styles.IconContainer>{icon && icon}</styles.IconContainer>
        <styles.Body>
          <styles.TitleDescriptionValue>
            {title && <styles.Title>{title}</styles.Title>}
            {description && <styles.Description>{description}</styles.Description>}
            {value && <styles.Value>{value}</styles.Value>}
          </styles.TitleDescriptionValue>
          {isLoading && (
            <styles.ProcessingContainer>
              <styles.Processing />
              <div>{t("Dashboard.Staking.Modal.Processing")}</div>
            </styles.ProcessingContainer>
          )}
          {button && <ButtonPrimary name={button.name} handler={button.handler} iconRight={button.iconRight} />}
        </styles.Body>
      </styles.Container>
    </ModalWrapper>
  );
}
