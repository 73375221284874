import { useTranslation, Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAccount } from "wagmi";

import { ReactComponent as ArrowLink } from "assets/icons/arrow-link.svg";
import { ReactComponent as ExitIcon } from "assets/icons/dashboard/exit.svg";
import { ReactComponent as Info } from "assets/icons/info.svg";
import { ReactComponent as LogoYellow } from "assets/logo/hapi-logo.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { logger } from "services/mixpanel";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { useAuthWeb3 } from "shared/hooks/useAuthWeb3";
import { useLoggerNavigate } from "shared/hooks/useLoggerNavigate";
import { ERoutes } from "shared/interfaces";
import { useWalletSelector } from "shared/providers/wallet/walletSelector";
import { displayAmount } from "shared/utils/displayAmount";
import { UserLevelIconMap, getNumberOfScarceTokens, getUserLevel } from "shared/utils/userLevel";
import { showModal, EModals } from "store/slices/modals";
import { selectUserStaking } from "store/slices/staking";

import styles from "./styles";
import { HeaderBanner } from "../HeaderBanner";

export const Header = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useLoggerNavigate();
  const dispatch = useAppDispatch();
  const { jwtToken, data: userData } = useAppSelector(selectUserStaking);

  const evm = useAccount();
  const near = useWalletSelector();
  const { logout } = useAuthWeb3();

  const isDashboardPage = location.pathname.includes(APP_ROUTES.DASHBOARD.HOME);
  const isLoginPagePage = location.pathname.includes(APP_ROUTES.DASHBOARD.LOGIN);

  const showBanner = Boolean(jwtToken) && userData && !userData.is_whale && isDashboardPage;

  const handleLogout = async () => {
    if (isLoginPagePage) {
      await logout();
      return;
    }
    dispatch(showModal({ modal: EModals.LOGOUT_MODAL, props: {} }));
  };

  return (
    <>
      {showBanner && (
        <HeaderBanner
          text={t("Dashboard.Banner.NotEnoughBalance")}
          description={
            <Trans
              i18nKey="Dashboard.Banner.YouNeedBuy"
              values={{ amount: displayAmount(getNumberOfScarceTokens(userData.total_hapi)) }}
              components={{ bold: <strong /> }}
            />
          }
          icon={
            <styles.HeaderBannerIcon>
              <Info />
            </styles.HeaderBannerIcon>
          }
          button={{
            name: "Buy HAPI",
            handler: () => {
              const url = "https://app.uniswap.org/swap";
              logger.trackEvent("LINK", { url });
              window.open(url);
            },
            icon: <ArrowLink />,
          }}
        />
      )}
      <styles.Header>
        <LogoYellow onClick={() => navigate(ERoutes.HOME)} />
        {isDashboardPage && (evm.isConnected || near.accountId || Boolean(jwtToken)) && (
          <styles.RightWrapper>
            {userData && (
              <styles.Whale>
                {userData.is_whale ? t(`Dashboard.Profile.Whale`) : t(`Dashboard.Profile.Regular`)}
                <styles.IconContainer>
                  {UserLevelIconMap[getUserLevel(userData.total_hapi.toFixed())]}
                </styles.IconContainer>
              </styles.Whale>
            )}
            <ExitIcon onClick={handleLogout} />
          </styles.RightWrapper>
        )}
      </styles.Header>
    </>
  );
};
