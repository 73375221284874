import { t } from "i18next";

import dashboardReferalsStyles from "pages/Dashboard/components/Referrals/components/Stats/styles";
import { useGetRefHapiIdStatsQuery } from "services/api/hapi/ref";
import { ZERO_STRING } from "shared/constants";
import formatNumber from "shared/utils/formatNumber";

import { getData } from "./data";
import styles from "./styles";

export default function Statistic() {
  const { data } = useGetRefHapiIdStatsQuery();

  const issued = data?.attestation_issued || ZERO_STRING;
  const maxScore = data?.max_score || ZERO_STRING;
  const totalUsers = data?.total_users || ZERO_STRING;

  const stats = getData(maxScore, totalUsers, issued);

  return (
    <styles.StatisticContainer>
      <styles.Title>{t("HapiId.Statistic.Title")}</styles.Title>
      <styles.Stats>
        {stats.map(({ value, icon, title, color, background }) => (
          <dashboardReferalsStyles.Stat $color={color} $background={background} key={background}>
            <dashboardReferalsStyles.ValueIcon>
              <dashboardReferalsStyles.Value>{formatNumber(Number(value))}</dashboardReferalsStyles.Value>
              {icon}
            </dashboardReferalsStyles.ValueIcon>
            <dashboardReferalsStyles.Title>
              <div>{title}</div>
            </dashboardReferalsStyles.Title>
          </dashboardReferalsStyles.Stat>
        ))}
      </styles.Stats>
    </styles.StatisticContainer>
  );
}
