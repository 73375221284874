import { ReactComponent as DefaultIcon } from "assets/icons/info.svg";
import { colors } from "shared/theme/colors";

import { IWarning, IWarningWithSolution } from "./interface";
import styles from "./styles";

export const WarningWithSolution = ({
  title,
  description,
  color = colors.warningRedRGB,
  icon = <DefaultIcon />,
  solution,
  solutionHandler,
}: IWarningWithSolution) => {
  return (
    <styles.Container $bgRGB={color}>
      <styles.IconContainer $bgRGB={color}>{icon}</styles.IconContainer>
      <styles.Text>
        <styles.HeaderText>
          <p>{title}</p>
          <p>{description}</p>
        </styles.HeaderText>
        <p onClick={solutionHandler}>{solution}</p>
      </styles.Text>
    </styles.Container>
  );
};

export const Warning = ({
  title,
  description,
  color = colors.redError,
  iconBg = colors.lightRedError,
  icon = <DefaultIcon />,
}: IWarning) => {
  return (
    <styles.WarningContainer $bg={color}>
      <styles.WarningHeader>
        <styles.WarningIconContainer $iconBg={iconBg}>{icon}</styles.WarningIconContainer>
        <styles.WarningHeaderText>{title}</styles.WarningHeaderText>
      </styles.WarningHeader>
      {description && <styles.WarningText>{description}</styles.WarningText>}
    </styles.WarningContainer>
  );
};
