import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as LogoSmallWhite } from "assets/logo/hapi-logo-small-white.svg";
import { ReactComponent as LogoSmallYellow } from "assets/logo/hapi-logo-small.svg";
import { ReactComponent as LogoWhite } from "assets/logo/hapi-logo-white.svg";
import { ReactComponent as LogoYellow } from "assets/logo/hapi-logo.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { ILogoProps } from "shared/components/Header/components/Logo/interface";
import styles from "shared/components/Header/components/Logo/styles";
import useWindowDimensions from "shared/hooks/useWindowDimensions";

export function Logo({ isHeaderAlternative }: ILogoProps) {
  const { isExtraLarge } = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();
  const isHome = location.pathname === APP_ROUTES.HOME;

  const getLogo = () => {
    if (isExtraLarge) {
      return isHeaderAlternative ? <LogoWhite /> : <LogoYellow />;
    }
    return isHeaderAlternative ? <LogoSmallWhite /> : <LogoSmallYellow />;
  };

  const handler = () => {
    if (isHome) return;
    navigate(APP_ROUTES.HOME);
  };

  return (
    <styles.LogoContainer $isHome={isHome} onClick={handler}>
      {getLogo()}
    </styles.LogoContainer>
  );
}
