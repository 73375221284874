import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding: 0.5rem 2rem;
`;

const Header = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
`;

const Close = styled.div`
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  right: -20px;
  top: -20px;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.hapiGrey20};
    }
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 140%;
  max-width: 14.625rem;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.hapiGrey5};
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
  max-width: 17.8125rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  button {
    width: 100%;
    height: 2.5rem;
    padding: 0 1rem;
    &:first-child {
      white-space: nowrap;
    }
  }
`;

export default {
  Container,
  Header,
  Title,
  Description,
  Close,
  ButtonContainer,
};
