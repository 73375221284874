import { Variants } from "framer-motion";
import { css } from "styled-components";

export const slideDownAnimation: Variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      mass: 0.8,
    },
    display: "block",
  },
  close: {
    y: -100,
    opacity: 0,
    transition: {
      duration: 0.3,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

export const dropdownAnimation: Variants = {
  open: {
    height: "auto",
    transition: {
      height: {
        duration: 0.3,
      },
    },
  },
  close: {
    height: 0,
    overflow: "hidden",
    transition: {
      height: {
        duration: 0.3,
      },
    },
  },
};

export const slideDownAnimationGrid: Variants = {
  ...slideDownAnimation,
  open: { ...slideDownAnimation.open, display: "grid" },
};

export const backgroundLayoutVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const submenuAnimationCentered: Variants = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.3,
      mass: 0.8,
    },
  },
  close: { opacity: 0 },
};

export const getFadeIn = (isInView: boolean) => css`
  opacity: ${isInView ? 1 : 0};
  transition: all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s;
`;
