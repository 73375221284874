import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as ClientsGradient } from "assets/images/clients-gradient.svg";

const ToolContainer = styled.div<{ $isOpened: boolean }>`
  padding: 2.25rem 4rem;
  background-color: ${({ theme, $isOpened }) => ($isOpened ? theme.colors.white : "transparent")};
  cursor: ${({ $isOpened }) => ($isOpened ? `url("static/close.svg"), pointer` : "pointer")};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:hover {
    > div > div {
      &:first-child {
        margin-left: ${({ $isOpened }) => ($isOpened ? "0" : "2.5rem")};
      }
    }

    > div > div + svg {
      opacity: 1;
      right: 2.5rem;
      transform: ${({ $isOpened }) => ($isOpened ? "unset" : "translateY(-50%) rotate(-135deg)")};
    }
  }

  ${({ theme, $isOpened }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        padding: 1.5rem 3rem;

        &:hover {
          > div > div {
            &:first-child {
              margin-left: 0;
            }
          }

          > div > div + svg {
            right: 3rem;
            transform: ${$isOpened ? "rotate(0deg)" : "rotate(45deg)"};
          }
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
      padding: 1rem;
      
      &:hover {
        > div > div + svg {
            right: 1rem;
          }
        }
      }
  `,
    )};
`;

const Tool = styled.div<{ $isOpened: boolean }>`
  margin: 0 auto;
  max-width: 82rem;
  user-select: none;
  position: relative;
  align-items: flex-start;
  grid-template-columns: 60% 40%;
  display: ${({ $isOpened }) => ($isOpened ? "grid" : "block")};
  grid-template-areas: "titleDescription metrics" "clients buttonContainer";

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      `
        position: unset;
        grid-row-gap: 2rem;
        flex-direction: column;
        grid-template-columns: 100%;
        grid-template-areas: "titleDescription" "metrics" "clients" "buttonContainer";
      `,
    )};
`;

const Title = styled.div<{ $isOpened: boolean }>`
  gap: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  svg {
    height: auto;
    width: 2.75rem;

    circle {
      fill: ${({ theme, $isOpened }) => ($isOpened ? theme.colors.hapiDark : theme.colors.white)};
    }

    path {
      fill: ${({ theme, $isOpened }) => ($isOpened ? theme.colors.white : theme.colors.hapiDark)};
    }
  }

  span {
    flex: 1;
    font-weight: 400;
    line-height: 120%;
    font-size: 2.25rem;
    letter-spacing: -0.045rem;
    font-family: ${({ theme }) => theme.fonts.archivoBlack};
    color: ${({ theme, $isOpened }) => ($isOpened ? theme.colors.hapiDark : theme.colors.white)};
    ${({ theme }) => theme.mediaWidth("upToMedium", `font-size: 2rem;`)};
    ${({ theme }) => theme.mediaWidth("upToSmall", `font-size: 1.25rem;`)};
  }

  ${({ theme }) => theme.mediaWidth("upToMedium", `margin-bottom: 1rem;`)};
`;

const Description = styled.div<{ $isOpened: boolean }>`
  font-weight: 400;
  line-height: 160%;
  font-size: 1.125rem;
  color: ${({ theme, $isOpened }) => ($isOpened ? theme.colors.hapiGrey60 : theme.colors.hapiGrey5)};
  white-space: ${({ $isOpened }) => ($isOpened ? "unset" : "nowrap")};
  overflow: ${({ $isOpened }) => ($isOpened ? "unset" : "hidden")};
  text-overflow: ${({ $isOpened }) => ($isOpened ? "unset" : "ellipsis")};
  ${({ theme }) => theme.mediaWidth("upToMedium", `font-size: 0.75rem;`)};
`;

const ClientsContainer = styled(motion.div)`
  margin-top: 3rem;
  grid-area: clients;
  ${({ theme }) => theme.mediaWidth("upToMedium", `margin-top: 0;`)};
`;

const MainContainer = styled.div<{ $isOpened: boolean }>`
  grid-area: titleDescription;
  width: ${({ $isOpened }) => ($isOpened ? "100%" : "60%")};
  transition: ${({ theme }) => theme.transitions.hoverMediumTransition};
  ${({ theme }) => theme.mediaWidth("upToMedium", `width: 85%`)};
`;

const AdditionalContainer = styled(motion.div)`
  grid-area: metrics;
  display: grid;
  padding-top: 1rem;
  grid-row-gap: 2rem;
  margin-left: 5.5rem;
  grid-column-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      `
        width: 100%;
        padding-top: 0;
        margin-left: 0;
        grid-column-gap: 1.5rem;
        grid-template-columns: repeat(4, 1fr);
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        grid-row-gap: 1rem;
        grid-column-gap: 1.5rem;
        grid-template-columns: repeat(2, 1fr);
      `,
    )};
`;

const Metric = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const TitleSmall = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.hapiGrey40};
  ${({ theme }) => theme.mediaWidth("upToMedium", `font-size: 0.75rem;`)};
`;

const MetricValue = styled.div`
  font-weight: 900;
  margin-top: 1rem;
  line-height: 120%;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.hapiDark};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      `
          font-size: 1.5rem;
          margin-top: 0.5rem;
        `,
    )};
`;

const ClientsTitle = styled(TitleSmall)`
  line-height: 160%;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.hapiGrey80};
`;

const MarqueeContainer = styled.div`
  position: relative;
  .rfm-initial-child-container,
  .rfm-marquee,
  .rfm-marquee-container {
    gap: 2.52rem;
    align-items: center;
  }
`;

const GradientLeft = styled(ClientsGradient)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: auto;
  height: 4rem;
`;

const GradientRight = styled(ClientsGradient)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: auto;
  height: 4rem;
  transform: rotate(180deg) !important;
`;

const Toggle = styled(Plus)<{ $isOpened: boolean }>`
  top: 50%;
  right: 0;
  opacity: 0;
  width: 3rem;
  height: auto;
  padding: 0.625rem;
  position: absolute;
  border-radius: 50%;
  transform: rotate(${({ $isOpened }) => ($isOpened ? "45deg" : "0deg")}) translateY(-50%);
  border: 2px solid ${({ theme, $isOpened }) => ($isOpened ? theme.colors.hapiDark : theme.colors.whiteOpacity02Alt)};
  background-color: ${({ theme, $isOpened }) => ($isOpened ? theme.colors.hapiDark : "transparent")};
  transition: ${({ theme }) => theme.transitions.hoverMediumTransition};

  ${({ theme, $isOpened }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        opacity: 1;
        top: 1.5rem;
        right: 3rem;
        transform: ${$isOpened ? "rotate(0deg)" : "rotate(45deg)"};
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        top: 1rem;
        opacity: 1;
        right: 1rem;
        width: 2rem;
      `,
    )};
`;

const ButtonContainer = styled.div`
  height: 100%;
  gap: 0.62rem;
  display: flex;
  text-align: right;
  align-items: flex-end;
  justify-content: flex-end;
  grid-area: buttonContainer;
  ${({ theme }) => theme.mediaWidth("upToMedium", `margin-top: 0rem;`)};

  button {
    order: 2;
    color: ${({ theme }) => theme.colors.hapiYellow1};
    border-color: ${({ theme }) => theme.colors.hapiDark};
    background-color: ${({ theme }) => theme.colors.hapiDark};

    & + button {
      order: 1;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.hapiDark};
      border-color: ${({ theme }) => theme.colors.hapiGrey20Alt};
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        flex-direction: column;
      `,
    )};
`;

const styles = {
  ToolContainer,
  Tool,
  Title,
  Description,
  ClientsContainer,
  ClientsTitle,
  MainContainer,
  AdditionalContainer,
  Metric,
  TitleSmall,
  MetricValue,
  MarqueeContainer,
  GradientLeft,
  GradientRight,
  Toggle,
  ButtonContainer,
};

export default styles;
