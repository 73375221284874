import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Metamask } from "assets/icons/metamask.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { useSnap } from "shared/hooks/useSnap";

import styles from "./styles";

export const Main: React.FC = () => {
  const navigate = useNavigate();
  const { isAuth, connectMetamask } = useSnap();

  return (
    <styles.MainContainer>
      <styles.Content>
        <styles.H1>{t("Snap.Main.Title")}</styles.H1>
        <styles.H1Description>{t("Snap.Main.Subtitle")}</styles.H1Description>
        <styles.Description>{t("Snap.Main.Description")}</styles.Description>
        <ButtonPrimary
          name={isAuth ? t("Actions.ConnectSnap") : t("Actions.ConnectMetamask")}
          handler={() => (isAuth ? navigate(APP_ROUTES.SNAP.CONNECT) : connectMetamask())}
          iconLeft={isAuth ? undefined : <Metamask />}
        />
      </styles.Content>
    </styles.MainContainer>
  );
};
