import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 5.75rem;
`;

const Title = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.archivoBlack};
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1.5rem;
        letter-spacing: -0.03rem;
      `,
    )};
`;

const TableContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.surfaceTable};
  border-radius: 2rem;
  padding: 2.25rem 1.5rem 1.75rem;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        border-radius: 1.25rem;
        padding: 2rem 0;
        th,
        td {
          padding-inline-start: 1rem;
        }
      `,
    )};
`;

const TableDescription = styled.p`
  margin-block-start: 1.5rem;
  margin-block-end: 0;
  color: ${({ theme }) => theme.colors.hapiGrey5};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 0.75rem;
        padding: 0 1rem;
      `,
    )};
`;

export default {
  Container,
  Title,
  TableContainer,
  TableDescription,
};
