import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";
import { useRefsContext } from "shared/providers/refs";

export const useScrollTo = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { scrollToBlock } = useRefsContext();

  const isHome = matchPath(APP_ROUTES.HOME, pathname);

  const scrollTo = (ref: React.RefObject<HTMLDivElement>) =>
    isHome ? scrollToBlock(ref) : navigate(APP_ROUTES.HOME, { state: { ref } });

  return { scrollTo };
};
