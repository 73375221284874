import { useTranslation } from "react-i18next";

import { ReactComponent as EditIcon } from "assets/icons/dashboard/edit.svg";
import { ReactComponent as HapiToken } from "assets/icons/dashboard/hapi-token.svg";
import { useGetDashboardQuery } from "services/api/hapi/ref";
import { Skeleton } from "shared/components/Skeleton";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { displayAmount } from "shared/utils/displayAmount";
import { cropString } from "shared/utils/stringOperations";
import { UserLevelIconMap, getUserLevel } from "shared/utils/userLevel";
import { EModals, showModal } from "store/slices/modals";
import { selectUserStakingData } from "store/slices/staking";

import { HapiIdComingSoon } from "./components/HapiIdComingSoon";
import { ProfileSkeleton } from "./Skeleton";
import styles from "./styles";
import { Rewards } from "../Rewards";
import { UserWallets } from "../UserWallets";

//todo: tooltip for level
export const Profile = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userData = useAppSelector(selectUserStakingData);
  const { isLoading, isFetching: isValidating } = useGetDashboardQuery();

  const hapiStaked = userData && userData.addresses.length ? userData.addresses[0].stake_hapi : 0;
  const total_hapi = userData ? userData.total_hapi : 0;
  const level = getUserLevel(total_hapi.toString());

  const changeName = () => {
    dispatch(showModal({ modal: EModals.EDIT_NAME_MODAL, props: {} }));
  };

  if (isLoading) return <ProfileSkeleton />;

  return (
    <styles.Container>
      <styles.LeftContainer>
        <styles.UserName>
          {isValidating ? (
            <Skeleton w="6.94rem" h="2rem" />
          ) : (
            <>
              {cropString(userData?.username || t("Dashboard.Profile.YourName"))}
              <EditIcon onClick={changeName} />
            </>
          )}
        </styles.UserName>
        <styles.ProfileData>
          <styles.ProfileDataCol>
            <p>{t("Dashboard.Profile.Level")}</p>
            {isValidating ? (
              <Skeleton w="3.44rem" h="1.75rem" />
            ) : (
              <p>
                {userData?.is_whale ? t(`Dashboard.Profile.Whale`) : t(`Dashboard.Profile.Regular`)}
                {UserLevelIconMap[level]}
              </p>
            )}
          </styles.ProfileDataCol>
          <styles.ProfileDataCol>
            <p>{t("Dashboard.Profile.HapiStaked")}</p>
            {isValidating ? (
              <Skeleton w="5.63rem" h="1.75rem" />
            ) : (
              <p>
                <HapiToken />
                {displayAmount(hapiStaked)}
              </p>
            )}
          </styles.ProfileDataCol>
          <styles.ProfileDataCol>
            <p>{t("Dashboard.Profile.TotalHAPIBalance")}</p>
            {isValidating ? (
              <Skeleton w="5rem" h="1.75rem" />
            ) : (
              <p>
                <HapiToken />
                {displayAmount(total_hapi)}
              </p>
            )}
          </styles.ProfileDataCol>
        </styles.ProfileData>
        <HapiIdComingSoon />
        <Rewards />
      </styles.LeftContainer>
      <styles.RightContainer>
        <UserWallets />
      </styles.RightContainer>
    </styles.Container>
  );
};
