import { Address } from "viem";

import { EClaimStage } from "shared/components/modals/ClaimReferralRewards/types";

import { EStakeUnstakeStage } from "./modal";

export enum LockupTypeEnum {
  None,
  Lockup,
}

export interface IUserStakingData {
  id: number;
  username: string;
  is_whale: boolean;
  total_hapi: number;
  addresses: Array<{ address: string; balance_hapi?: number; balance_usd: number; stake_hapi?: number }>;
}

export enum ETiers {
  Regular,
  Whale,
}

export interface ITierAPY {
  regular: bigint;
  whale: bigint;
}

export interface IStakingFieldsData {
  lastUpdateTimestamp: number;
  token: Address;
  totalStaked: {
    value: bigint;
    formatted?: string;
  };
  lockupPeriod: {
    value: bigint;
    formatted: number;
  };
  claimLockDuration: {
    value: bigint;
    formatted: number;
  };
  minStake: {
    value: bigint;
    formatted?: string;
  };
  lockupBonus: number;
  hapiIDBonus: number;
  compoundNumber: number;
}

export interface IStakingAmount {
  value: bigint;
  formatted?: string;
}

export interface IStaker {
  tier: ETiers;
  amount: {
    value: bigint;
    formatted?: string;
  };
  stakedTimestamp: number;
  unlockTimestamp: number;
  unlockClaimTimestamp: number;
  amountToClaim: {
    value: bigint;
    formatted?: string;
  };
  type?: LockupTypeEnum;
}

export interface IStakingState {
  loading: boolean;
  reward?: IStakingAmount;
  stakingFieldsData?: IStakingFieldsData;
  staked?: IStakingAmount;
  staker?: IStaker;
  tierAPY?: ITierAPY;
  stakingStage?: EStakeUnstakeStage;
  claimingStage?: EClaimStage;
  user: {
    jwtToken: string | null;
    data: IUserStakingData | null;
  };
  tokenAddress?: Address;
}

export interface IReferral {
  rank: number;
  username: string;
  stake: number;
  is_whale: boolean;
}

export interface IReferralLevel {
  apy: string;
  referral_count: number;
  total_stake: number;
  reward: string;
  referrals: Array<IReferral>;
}

export interface IReferrals {
  level1: IReferralLevel;
  level2: IReferralLevel;
  my_stake: number;
  my_username: string;
  permanent_code: string | null;
  onetime_codes: Array<string>;
}

export enum EStakingWarning {
  Empty,
  ConnectWallet,
  SwitchNetwork,
  Agreement,
  MainWallet,
}

export interface ILeader {
  username: string | null;
  rank: number;
  staked: string | null;
  is_whale: boolean;
  me: boolean;
}
