import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;

  padding: 0.5rem;
  gap: 0.5rem;

  border-radius: 0.625rem;
  border: 1px solid ${({ theme }) => theme.colors.hapiGrey80};

  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 140%;
  }
`;

const IconContainer = styled.div<{ $fill: string }>`
  display: flex;
  padding: 0.5rem;
  border-radius: 50%;
  color: rgb(${({ $fill }) => $fill});
  background: rgb(${({ $fill }) => $fill}, 0.2);
  align-self: flex-start;
`;

export default {
  Container,
  IconContainer,
};
