import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  button {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
  }
  ${({ theme }) => theme.mediaWidth("upToMedium", "margin-top: 5rem;")};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};
  font-size: 3.375rem;
  font-weight: 400;
  line-height: 120%;
  text-transform: capitalize;

  ${({ theme }) => theme.mediaWidth("upToSmall", "font-size: 2rem;")};
`;

const Description = styled.div`
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.hapiGrey20};
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 140%;
  max-width: 37.25rem;
  ${({ theme }) => theme.mediaWidth("upToSmall", "font-size: 1rem;")};
`;

const Address = styled.div`
  color: ${({ theme }) => theme.colors.hapiGrey20};
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  ${({ theme }) => theme.mediaWidth("upToSmall", "font-size: .75rem;")};
`;

export default {
  Container,
  Title,
  Description,
  Address,
};
