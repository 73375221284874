import { useTranslation } from "react-i18next";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { useAuthWeb3 } from "shared/hooks/useAuthWeb3";
import { IModalProps } from "shared/interfaces/modal";
import theme from "shared/theme";

import styles from "./styles";

export function LogoutModal({ closeModal }: IModalProps) {
  const { t } = useTranslation();
  const { logout } = useAuthWeb3();

  const handler = () => {
    closeModal();
    logout();
  };
  return (
    <ModalWrapper closeModal={closeModal} bgColor={theme.colors.registerCardBg}>
      <styles.Container>
        <styles.Header>
          <styles.Title>{t("Dashboard.LogoutDashboard")}</styles.Title>
          <styles.Close>
            <CloseIcon onClick={closeModal} />
          </styles.Close>
        </styles.Header>
        <styles.ButtonContainer>
          <ButtonPrimary name={t("Dashboard.Actions.Cancel")} handler={closeModal} />
          <ButtonPrimary name={t("Dashboard.Actions.Logout")} handler={handler} />
        </styles.ButtonContainer>
      </styles.Container>
    </ModalWrapper>
  );
}
