import { t } from "i18next";
import { useEffect, useState } from "react";
import CountUp from "react-countup";

import arc from "assets/images/arc.webp";
import { ReactComponent as Hapi } from "assets/images/hapi.svg";
import { getRandomInt } from "shared/utils";

import { ScoreProps } from "./interface";
import styles from "./styles";

const calcDeg = (score: number) => Math.floor((score * 180) / 100 - 90);

export const Score = ({ score, levelData, loading, isShining }: ScoreProps) => {
  const [deg, setDeg] = useState<number>(-90);

  useEffect(() => {
    setDeg(calcDeg(score));
  }, [score]);

  const [loadingData, setLoadingData] = useState({
    score: 0,
    deg: calcDeg(0),
  });

  useEffect(() => {
    if (!loading) return;

    const loadDataAndUpdate = () => {
      const newScore = getRandomInt(0, 100);
      const newDeg = calcDeg(newScore);
      setLoadingData({ score: newScore, deg: newDeg });
    };
    loadDataAndUpdate();

    const interval = setInterval(loadDataAndUpdate, 1500);
    return () => clearInterval(interval);
  }, [loading]);

  return (
    <styles.Score $showLevel={Boolean(levelData)} $score={loading ? loadingData.score : score}>
      <styles.Gauge>
        <img src={arc} />
        <styles.Coin $deg={loading ? loadingData.deg : deg} $shine={Boolean(isShining)}>
          <Hapi />
        </styles.Coin>
      </styles.Gauge>
      {levelData && (
        <styles.Level>
          {t("Levels.Level", { level: levelData.level })}
          <img src={levelData.img} />
        </styles.Level>
      )}
      <styles.ScoreAmount $showLevel={Boolean(levelData)}>
        <CountUp start={score} end={loading ? loadingData.score : score} duration={2} preserveValue />
        <span>/100</span>
      </styles.ScoreAmount>
    </styles.Score>
  );
};
