import { ReactComponent as GitHub } from "assets/images/socials/github.svg";
import { ReactComponent as LinkedIn } from "assets/images/socials/linked-in.svg";
import { ReactComponent as Telegram } from "assets/images/socials/telegram.svg";
import { ReactComponent as Twitter } from "assets/images/socials/twitter.svg";
import { ESocial } from "shared/interfaces";

export const SocialsIconMap = {
  [ESocial.GitHub]: <GitHub />,
  [ESocial.LinkedIn]: <LinkedIn />,
  [ESocial.Telegram]: <Telegram />,
  [ESocial.Twitter]: <Twitter />,
};
