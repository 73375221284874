import { css, styled } from "styled-components";

const HapiIdComingSoonContainer = styled.div`
  gap: 1rem;
  display: flex;
  padding: 1rem;
  align-items: center;
  border-radius: 1.5rem;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.purple};

  button {
    height: 2.5rem;
    margin-top: 1rem;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        height: 13.75rem;
        position: relative;
        flex-direction: column;
        align-items: flex-start;

        svg {
          right: 0.5rem;
          bottom: 0.75rem;
          position: absolute;
        }
      `,
    )};
`;

const Title = styled.div`
  font-weight: 600;
  line-height: 130%;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
`;

const Subtitle = styled.div`
  font-weight: 400;
  line-height: 140%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.whiteOp5};
`;

export default {
  HapiIdComingSoonContainer,
  Title,
  Subtitle,
};
