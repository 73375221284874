import { css, styled } from "styled-components";

import { getFadeIn } from "shared/theme/animations";

const TrustedByContainer = styled.div<{ isInView: boolean }>`
  gap: 2rem;
  display: flex;
  padding: 3.75rem;
  align-items: center;
  border-radius: 1.5rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.asphalt};
  ${({ isInView }) => getFadeIn(isInView)}
`;

const Title = styled.div`
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: -0.025rem;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1.125rem;
      `,
    )};
`;

const Logos = styled.div`
  gap: 0.75rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        gap: 0.25rem;

        svg {
          height: auto;
          width: 6.875rem;
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);

        svg {
          height: auto;
          width: 8.375rem;
        }
      `,
    )};
`;

export default { TrustedByContainer, Title, Logos };
