import styled, { css } from "styled-components";

import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";

const ReferralLinksContainer = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 600;
  line-height: 116%;
  font-size: 1.25rem;
`;

const PermanentCode = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const PermanentCodeLabel = styled.div`
  font-weight: 500;
  line-height: 120%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.hapiGrey40};
`;

const PermanentCodeValue = styled.div`
  display: flex;
  max-width: 100%;
  font-size: 1rem;
  width: 26.625rem;
  font-weight: 600;
  line-height: 120%;
  align-items: center;
  border-radius: 0.75rem;
  padding: 0.9rem 0.75rem;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.hapiGrey60};
`;

const Copy = styled(CopyIcon)`
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:hover {
    opacity: 0.8;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  display: flex;
  font-weight: 400;
  line-height: 120%;
  font-size: 0.875rem;
  align-items: center;
  min-height: 3.0625rem;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.hapiGrey80};
`;

const Row = styled.div`
  gap: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        grid-template-columns: 1fr;
      `,
    )};
`;

export default {
  ReferralLinksContainer,
  Title,
  PermanentCode,
  PermanentCodeLabel,
  PermanentCodeValue,
  Copy,
  List,
  ListItem,
  Row,
};
