import { createAsyncThunk } from "@reduxjs/toolkit";
import { waitForTransactionReceipt, writeContract } from "@wagmi/core";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { Address, TransactionReceipt } from "viem";

import { walletConfig } from "services/wallet";
import ToastService from "shared/components/Toast";
import type { ThunkApiConfig } from "store";
import { getStakingData } from "store/actions/view/staking/getStakingData";
import { stakingConfig } from "store/config";

export const claim = createAsyncThunk<
  TransactionReceipt | null,
  {
    address: Address;
    setButtonLoading: Dispatch<SetStateAction<boolean>>;
    refetchBalance(): void;
  },
  ThunkApiConfig
>("staking/claim", async ({ address, setButtonLoading, refetchBalance }, { dispatch }) => {
  try {
    setButtonLoading(true);

    const claimHash = await writeContract(walletConfig, {
      ...stakingConfig,
      functionName: "claim",
      account: address,
    });

    const receipt = await waitForTransactionReceipt(walletConfig, { hash: claimHash });

    if (receipt.status === "success") {
      ToastService.success({ text: t("Dashboard.Staking.Claim.Success") });
      refetchBalance();
    }
    return receipt;
  } catch (e) {
    console.error(`Error during claiming:  \n ${e}`);
    ToastService.error({ text: t("Dashboard.Staking.Claim.Error") });
    return null;
  } finally {
    dispatch(getStakingData({ address }));
    setButtonLoading(false);
  }
});
