export const colors = {
  black: "#000000",
  blackLight: "#171723",
  blackOp07: "rgba(30, 30, 30, 0.7)",
  white: "#FFFFFF",
  grayLight: "#CCCCD3",
  violet: "#AF6CC7",
  orange: "#FF9820",
  orangeRed: "#FF6868",
  pink: "#EB4EA3",
  blueViolet: "#878CFA",
  yellow: "#FDEE2D",
  darkBlue: "#0B0B17",
  darkenBlue: "#202030",
  darkBlueRGB: "rgba(11, 11, 23, 0)",
  yellowType2: "#F9CF01",
  blue: "#54A5D3",
  green: "#54D360",
  lightGreen: "#ABFF68",
  darkYellow: "#DBC81F",
  gray: "#91919D",
  borderBlog: "#3C3C45",

  whiteOp05: "rgba(255, 255, 255, 0.05)",
  whiteOp1: "rgba(255, 255, 255, 0.1)",
  whiteOp15: "rgba(255, 255, 255, 0.15)",
  whiteOp2: "rgba(255, 255, 255, 0.2)",
  whiteOp3: "rgba(255, 255, 255, 0.3)",
  whiteOp5: "rgba(255, 255, 255, 0.5)",
  whiteOp6: "rgba(255, 255, 255, 0.6)",
  whiteOp7: "rgba(255, 255, 255, 0.7)",
  whiteOp9: "rgba(255, 255, 255, 0.9)",
  hapiGrey5: "#AAAAB6",
  hapiGrey20: "#91919D",
  hapiGrey20Alt: "#CECED1",
  hapiGrey40: "#6F6F7B",
  hapiGrey60: "#4E4E5A",
  hapiGrey80: "#2C2C38",
  hapiDark: "#0B0B17",
  hapiYellow1: "#FDEE2D",
  hapiYellow2: "#FBDE08",
  hapiYellowOp02: "rgba(251, 222, 8, 0.20)",
  whiteOpacity0: "rgba(11, 11, 23, 0.00)",
  whiteOpacity02: "rgba(11, 11, 23, 0.2)",
  whiteOpacity02Alt: "rgba(255, 255, 255, 0.2)",
  footerBg: "#12121D",
  greyBorder: "#444262",
  red: "#FF4A22",
  redError: "#EF4F45",
  lightRedError: "#F2726A",
  lightRed: "#FE8166",
  lightBlue: "#3D3ADA",
  lightYellow: "#F9CF01",

  grayRGB06: "rgba(255, 255, 255, 0.6)",
  grayRGB04: "rgba(111, 111, 123, 0.4)",
  gray2: "#BCBCC6",
  gray07: "#1E1E1ED3",
  footer: "#B8B8BF",
  hapiIdGrey: "#BCBCC6",
  hapiIdGreySocial: "#2F2842",
  hapiIdRed: "#E4656C",
  hapiIdRedAlt: "#FF5959",
  hapiIdDarkYellow: "#25231A",
  toastBg: "#21252C",
  socialCircle: "#23232E",
  overlayBg: "rgba(11, 11, 23, 0.8)",
  hapiIdGradient: "251deg, #12B9E6 12.89%, #588EFF 49.61%, #FF6960 87.11%",
  modalBG: "rgba(11, 18, 33, 0.65)",
  videoBg: "rgba(111, 111, 123, 0.5)",

  stakingCardBg: "#242544",
  daoCardBg: "#3C2719",
  burnCardBg: "#3C1E27",

  surfaceTable: "#242544",
  registerCardBg: "#202030",
  activeDashboardTab: "#35354E",
  surfaceWhale: "#35354E",

  stakingDashboard: "rgb(255, 152, 32)",
  stakingDashboardOp02: "rgba(255, 152, 32, 0.2)",
  referralsDashboard: "rgb(255, 104, 104)",
  referralsDashboardOp02: "rgba(255, 104, 104, 0.2)",
  PTError: "#FF5A64",
  hoverSurfaceWhale: "rgba(9, 14, 21, 0.20)",

  nightSky: "#242544",
  wetSand: "#3C2718",
  beetroot: "#3C1E27",
  aubergine: "#2C1F3A",
  cucumber: "#2B3C27",
  progressOrange: "255, 152, 32",
  progressBlue: "135, 140, 250",
  cinnamon: "#6F4A00",
  chipBg: "#202030",
  warningRedRGB: "239, 79, 69",
  warningRed: "#391920",
  lighterWarningRed: "#822F2F",
  warningYellowRGB: "255, 248, 39",
  asphalt: "#212226",
  snapGrey: "#cbcbcb",
  snapGreen: "#00C802",
  snapBlack: "#101012",
  snapBlackDarker: "#313131",
  snapSurfaceBG054: "rgba(33, 34, 38, 0.54)",
  pinkToBlueGradient: "linear-gradient(137deg, #B42196 3.63%, #6D85EC 53.38%, #08AABC 98.39%)",
  marqueeGradient: "linear-gradient(90deg, #212226 0%, rgba(33, 34, 38, 0) 100%)",
  x: "#1D9BF0",
  inputError: "#F0414C",
  purple: "#2c1f3a",
  greenRGB: "102, 191, 80",
};
