import styled from "styled-components";

const LogoContainer = styled.div<{ $isHome: boolean }>`
  margin: 0 auto 0 0;
  z-index: 10;
  cursor: ${({ $isHome }) => ($isHome ? "default" : "pointer")};
  svg {
    ${({ theme }) =>
      theme.mediaWidth(
        "upToMedium",
        `
          width: auto;  
          height: 2rem;
        `,
      )};
  }
`;

const styles = {
  LogoContainer,
};

export default styles;
