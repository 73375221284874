import { createAsyncThunk } from "@reduxjs/toolkit";
import { readContract } from "@wagmi/core";

import { walletConfig } from "services/wallet";
import { ITierAPY } from "shared/interfaces/staking";
import { ThunkApiConfig } from "store";
import { stakingConfig } from "store/config";

export const getTierAPY = createAsyncThunk<ITierAPY, void, ThunkApiConfig>(
  "staking/getTierAPY",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const [regular, whale] = await Promise.all([
        readContract(walletConfig, {
          ...stakingConfig,
          functionName: "tierAPY",
          args: [0],
        }),
        await readContract(walletConfig, {
          ...stakingConfig,
          functionName: "tierAPY",
          args: [1],
        }),
      ]);

      return fulfillWithValue({
        regular,
        whale,
      });
    } catch (e) {
      console.error(`Error getting tier APY: \n ${e}`);
      return rejectWithValue(null);
    }
  },
);
