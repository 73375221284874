import { t } from "i18next";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Address } from "viem";

import { APP_ROUTES } from "routes/appRoutes";
import { useGetDashboardQuery } from "services/api/hapi/ref";
import { Footer } from "shared/components/Footer";
import ToastService from "shared/components/Toast";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { HAPI_SERVICE_ERRORS } from "shared/interfaces/api";
import { getStakingData } from "store/actions/view/staking/getStakingData";

import { Header } from "./components/Header";
import { Navigation } from "./components/Navigation";
import styles from "./styles";

export const Dashboard = () => {
  const dispatch = useAppDispatch();

  const { data: response, isSuccess } = useGetDashboardQuery();
  const address = response?.data?.addresses[0].address as Address | undefined;

  useEffect(() => {
    if (isSuccess) dispatch(getStakingData({ address }));
  }, [address, dispatch, isSuccess]);

  if (isSuccess && response?.errorCode === 1 && response?.message === HAPI_SERVICE_ERRORS.WALLET_NOT_SET) {
    ToastService.error({ text: t("Dashboard.Messages.PleaseFinishRegistration") });
    return <Navigate to={APP_ROUTES.REGISTRATION} />;
  }

  return (
    <styles.Container>
      <Header />
      <styles.Body>
        <styles.NavigationWrapper>
          <Navigation />
        </styles.NavigationWrapper>
        <Outlet />
      </styles.Body>
      <Footer marginTopInRem={4.5} />
    </styles.Container>
  );
};
