import { motion } from "framer-motion";
import styled from "styled-components";

const BurgerContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
`;

const NavContainer = styled.div`
  display: flex;
  max-width: 16.5rem;
  flex-direction: column;
  margin: 5rem 0 0 4.5rem;
`;

const NavItem = styled.div<{ withSubmenu?: boolean; disabled?: boolean }>`
  gap: 0.25rem;
  padding: 0.5rem 0;
  align-items: center;
  color: ${({ theme }) => theme.colors.hapiGrey80};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  display: ${({ withSubmenu }) => (withSubmenu ? "block" : "flex")};
  font-weight: ${({ withSubmenu }) => (withSubmenu ? "400" : "700")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  line-height: ${({ withSubmenu }) => (withSubmenu ? "160%" : "140%")};
  font-size: ${({ withSubmenu }) => (withSubmenu ? "0.875rem" : "1rem")};

  > div {
    margin-top: 0.5rem;

    div {
      padding-left: 0;
    }
  }
`;

const styles = {
  BurgerContainer,
  NavContainer,
  NavItem,
};

export default styles;
