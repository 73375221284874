import styled, { css } from "styled-components";

const AdvantageCardContainer = styled.div<{ $turnRight?: boolean; $borderColor?: string; $mt: number }>`
  padding: 2rem;
  top: 16.69rem;
  max-width: 100%;
  position: sticky;
  width: 41.125rem;
  height: 22.75rem;
  margin-top: ${({ $mt }) => `${$mt}rem` || 0};
  transform: ${({ $turnRight }) => ($turnRight ? "rotate(2deg)" : "rotate(-3deg)")};

  > div {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    border-radius: 2rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.hapiDark};
    border: 2px solid ${({ $borderColor, theme }) => $borderColor || theme.colors.hapiDark};
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        width: 40rem;
        height: 23.75rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        width: 20.5rem;
        height: 22.5rem;
      `,
    )};
`;

const TitleIcon = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 700;
  line-height: 108%;
  font-size: 2.25rem;
  letter-spacing: -0.045rem;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1.5rem;
        letter-spacing: -0.03rem;
      `,
    )};
`;

const Description = styled.div`
  font-weight: 400;
  line-height: 140%;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.whiteOp7};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1rem;
      `,
    )};
`;

export default {
  AdvantageCardContainer,
  TitleIcon,
  Title,
  Description,
};
