import { createAsyncThunk } from "@reduxjs/toolkit";
import { waitForTransactionReceipt, writeContract } from "@wagmi/core";
import { TransactionReceipt, Address } from "viem";

import { walletConfig } from "services/wallet";
import { EStakeUnstakeStage } from "shared/interfaces/modal";
import type { ThunkApiConfig } from "store";
import { getStakingData } from "store/actions/view/staking/getStakingData";
import { stakingConfig } from "store/config";
import { setStakingStage } from "store/slices/staking";

export const stake = createAsyncThunk<
  TransactionReceipt | null,
  {
    address: Address;
    amount: bigint;
    isLockup: boolean;
    reset(): void;
    refetchBalance(): void;
  },
  ThunkApiConfig
>("staking/stake", async ({ address, amount, isLockup, reset, refetchBalance }, { dispatch }) => {
  try {
    dispatch(setStakingStage(EStakeUnstakeStage.STAKING_UNSTAKING_PROCESSING));

    const stakeHash = await writeContract(walletConfig, {
      ...stakingConfig,
      functionName: "stake",
      account: address,
      args: [amount, isLockup],
    });

    const receipt = await waitForTransactionReceipt(walletConfig, { hash: stakeHash });

    if (receipt.status === "success") {
      reset();
      refetchBalance();
      dispatch(setStakingStage(EStakeUnstakeStage.SUCCESS));
    }

    return receipt;
  } catch (e) {
    console.error(`Error during staking:  \n ${e}`);
    dispatch(setStakingStage(EStakeUnstakeStage.FAIL));
    return null;
  } finally {
    dispatch(getStakingData({ address }));
  }
});
