import styled from "styled-components";

const Container = styled.div<{ $bgColor: string }>`
  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  width: 100%;
  p {
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: ${({ theme }) => theme.colors.whiteOp6};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 140%;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.whiteOp2};
  }
`;

const Values = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  p {
    line-height: 140%;
    &:first-child {
      color: ${({ theme }) => theme.colors.white};
      font-size: 1.25rem;
      font-weight: 600;
    }
    &:last-child {
      color: ${({ theme }) => theme.colors.whiteOp6};
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
`;

export default {
  Container,
  Header,
  Footer,
  Values,
};
