import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as LogoYellow } from "assets/logo/hapi-logo.svg";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { MINT_HAPI_ID_HOT_WALLET } from "shared/constants";
import { ERoutes } from "shared/interfaces";
import { useRefsContext } from "shared/providers/refs";

import styles from "./styles";

export default function HeaderHapiID() {
  const navigate = useNavigate();
  const { hapiIdAdvantagesRef, hapiIdAttestationsRef, hapiIdTrustRef, hapiIdFAQRef, scrollToBlock } = useRefsContext();

  const menuItems = [
    { name: "Advantages", ref: hapiIdAdvantagesRef },
    { name: "Trust Score", ref: hapiIdTrustRef },
    { name: "Attestations", ref: hapiIdAttestationsRef },
    { name: "FAQ", ref: hapiIdFAQRef },
  ];

  return (
    <styles.Header>
      <LogoYellow onClick={() => navigate(ERoutes.HOME)} />
      <styles.Menu>
        {menuItems.map((menuItem) => (
          <div key={menuItem.name} onClick={() => scrollToBlock(menuItem.ref)}>
            {menuItem.name}
          </div>
        ))}
      </styles.Menu>
      <ButtonPrimary
        name={t("Actions.CheckMyScore")}
        handler={() => window.open(MINT_HAPI_ID_HOT_WALLET, "_blank")}
        isOutlined
      />
    </styles.Header>
  );
}
