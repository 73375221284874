import { Trans } from "react-i18next";

import i18n from "services/translation";
import { EMPTY_STRING } from "shared/constants";

import { TableProps } from "../Table/interface";

export const tableData: TableProps["data"] = {
  header: [
    [EMPTY_STRING, EMPTY_STRING],
    [
      i18n.t("TokenUtility.Table.Whales.Title"),
      <Trans i18nKey={"TokenUtility.Table.Whales.Subtitle"} components={{ span: <span /> }} />,
    ],
    [
      i18n.t("TokenUtility.Table.Regular.Title"),
      <Trans i18nKey={"TokenUtility.Table.Regular.Subtitle"} components={{ span: <span /> }} />,
    ],
  ],
  rows: [
    {
      cell: [
        i18n.t("TokenUtility.Table.Category.StakingReward"),
        <div>
          <p>{i18n.t("TokenUtility.Table.Whales.StakingReward.1")}</p>
          <p>{i18n.t("TokenUtility.Table.Whales.StakingReward.2")}</p>
          <p>{i18n.t("TokenUtility.Table.Whales.StakingReward.3")}</p>
        </div>,
        <div>
          <p>{i18n.t("TokenUtility.Table.Regular.StakingReward.1")}</p>
          <p>{i18n.t("TokenUtility.Table.Regular.StakingReward.2")}</p>
          <p>{i18n.t("TokenUtility.Table.Regular.StakingReward.3")}</p>
        </div>,
      ],
    },
    {
      cell: [
        i18n.t("TokenUtility.Table.Category.Level-1"),
        i18n.t("TokenUtility.Table.Whales.Level-1"),
        i18n.t("TokenUtility.Table.Regular.Level-1"),
      ],
    },
    {
      cell: [
        i18n.t("TokenUtility.Table.Category.Level-2"),
        i18n.t("TokenUtility.Table.Whales.Level-2"),
        i18n.t("TokenUtility.Table.Regular.Level-2"),
      ],
    },
  ],
};

const transformData = (data: TableProps["data"], index: number): TableProps["data"] => {
  const transformedHeader: TableProps["data"]["header"] = [[data.header[index][0]], [data.header[index][1]]];

  const transformedRows: TableProps["data"]["rows"] = data.rows.map((row) => {
    const transformedCell: TableProps["data"]["rows"][0]["cell"] = [row.cell[0], row.cell[index]];
    return { cell: transformedCell };
  });
  return { header: transformedHeader, rows: transformedRows };
};

export const mobileWhalesTableData: TableProps["data"] = transformData(tableData, 1);
export const mobileRegularTableData: TableProps["data"] = transformData(tableData, 2);
