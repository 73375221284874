import { t } from "i18next";
import { LoaderFunction } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";
import { checkJWTValidity } from "services/api/helpers";
import { logger } from "services/mixpanel";
import ToastService from "shared/components/Toast";
import { loggerRedirect } from "shared/hooks/useLoggerNavigate";
import store from "store";

export const loaderDashboard = (async ({ request }) => {
  const jwtToken = store.getState().staking.user.jwtToken;

  const url = new URL(request.url);

  const isRegistrationPage = url.pathname.includes(APP_ROUTES.REGISTRATION);
  const isDashboardLoginPage = url.pathname.includes(APP_ROUTES.DASHBOARD.LOGIN);
  const isDashboardPage = url.pathname.includes(APP_ROUTES.DASHBOARD.HOME);

  if (!jwtToken) {
    logger.reset();
    return isRegistrationPage || isDashboardLoginPage ? null : loggerRedirect(APP_ROUTES.DASHBOARD.LOGIN);
  }

  const response = await checkJWTValidity();
  const isValidResponse = response && response.isvalid;
  const isOnboarded = isValidResponse && response.onboarded;

  if (isRegistrationPage) {
    if (isValidResponse) {
      if (isOnboarded) {
        logger.userIdentify(response.userid.toFixed());
        return loggerRedirect(APP_ROUTES.DASHBOARD.HOME);
      } else {
        return null;
      }
    } else {
      ToastService.error({ text: t("Dashboard.Messages.TokenExpired") });
      logger.reset();
      return null;
    }
  }

  if (isDashboardLoginPage) {
    if (isValidResponse) {
      if (isOnboarded) {
        logger.userIdentify(response.userid.toFixed());
        return loggerRedirect(APP_ROUTES.DASHBOARD.HOME);
      } else {
        ToastService.error({ text: t("Dashboard.Messages.PleaseFinishRegistration") });
        logger.userIdentify(response.userid.toFixed());
        return loggerRedirect(APP_ROUTES.REGISTRATION);
      }
    } else {
      logger.reset();
      return null;
    }
  }

  if (isDashboardPage) {
    if (isValidResponse) {
      if (isOnboarded) {
        logger.userIdentify(response.userid.toFixed());
        return null;
      } else {
        ToastService.error({ text: t("Dashboard.Messages.PleaseFinishRegistration") });
        logger.userIdentify(response.userid.toFixed());
        return loggerRedirect(APP_ROUTES.REGISTRATION);
      }
    } else {
      ToastService.error({ text: t("Dashboard.Messages.TokenExpired") });
      logger.reset();
      return loggerRedirect(APP_ROUTES.DASHBOARD.LOGIN);
    }
  }

  return null;
}) satisfies LoaderFunction;
