import styled, { css } from "styled-components";

const Stats = styled.div`
  gap: 0.75rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        grid-template-columns: 1fr;
      `,
    )};
`;

const StatsLeft = styled.div<{ $isSkeleton?: boolean }>`
  padding: 1rem;
  display: flex;
  border-radius: 1rem;
  min-height: 10.625rem;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme, $isSkeleton }) => ($isSkeleton ? theme.colors.blackLight : theme.colors.beetroot)};

  button {
    height: 3rem;
    padding: 0.56256rem 0.75rem;
    color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.whiteOp2};
  }
`;

const StatsLeftTitleTimer = styled.div`
  gap: 0.53rem;
  display: flex;
  flex-direction: column;
`;

const StatsLeftTitle = styled.div`
  gap: 0.5rem;
  display: flex;
  font-weight: 600;
  line-height: 116%;
  font-size: 1.25rem;
  align-items: center;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.orangeRed};
    }
  }
`;

const StatsLeftTimer = styled.div`
  font-weight: 400;
  line-height: 140%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.whiteOp6};

  strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const AmountToClaimAction = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AmountToClaim = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-direction: column;

  div {
    &:first-child {
      font-weight: 600;
      line-height: 140%;
      font-size: 1.25rem;
    }

    &:last-child {
      font-weight: 400;
      line-height: 140%;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.whiteOp6};
    }
  }
`;

const StatsRight = styled.div`
  gap: 0.75rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const Stat = styled.div<{ $color: string; $background: string }>`
  padding: 1rem;
  display: flex;
  border-radius: 1rem;
  min-height: 8.3125rem;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ $color }) => $color};
  background-color: ${({ $background }) => $background};
`;

const ValueIcon = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Value = styled.span`
  font-weight: 600;
  line-height: 116%;
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1.5rem;
      `,
    )};
`;

const Title = styled.div`
  font-weight: 400;
  line-height: 116%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.grayRGB06};

  strong {
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 0.75rem;
      `,
    )};
`;

export default {
  Stats,
  StatsLeft,
  StatsLeftTitleTimer,
  StatsLeftTitle,
  StatsRight,
  StatsLeftTimer,
  AmountToClaimAction,
  AmountToClaim,
  Stat,
  ValueIcon,
  Title,
  Value,
};
