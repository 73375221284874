import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";

import { ReactComponent as Scamfari } from "assets/icons/ecosystem/scamfari.svg";
import { ReactComponent as Metamask } from "assets/icons/metamask.svg";
import { ReactComponent as Telegram } from "assets/icons/socials/telegram.svg";
import { ReactComponent as APIKey } from "assets/images/snap/icons/api-key.svg";
import { ReactComponent as Hapi } from "assets/logo/hapi.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { ECOSYSTEM_LINKS } from "shared/data/ecosystem";
import { SOCIALS_LINKS } from "shared/data/socials";

export const getSteps = (
  isAuth: boolean,
  isInstalled: boolean,
  isApiAdded: boolean,
  installSnap: () => void,
  connectMetamask: () => void,
  getApi: () => void,
) => [
  {
    icon: { component: <Metamask /> },
    title: t("Snap.Connect.Install.Title"),
    description: t("Snap.Connect.Install.Description"),
    button: {
      name: isAuth ? t("Snap.Connect.Install.Action") : t("Actions.ConnectMetamask"),
      handler: isAuth ? installSnap : connectMetamask,
    },
    done: {
      status: isInstalled,
      title: t("Snap.Connect.Install.Installed"),
    },
  },
  {
    icon: { component: <APIKey /> },
    title: t("Snap.Connect.AddKey.Title"),
    description: t("Snap.Connect.AddKey.Description"),
    button: {
      name: t("Snap.Connect.AddKey.Action"),
      handler: getApi,
      disabled: !isAuth || !isInstalled,
    },
    done: {
      status: isApiAdded,
      title: t("Snap.Connect.AddKey.Added"),
    },
  },
];

export const getRecommended = (navigate: NavigateFunction) => [
  {
    icon: { component: <Telegram /> },
    title: t("Snap.Connect.JoinCommunity.Title"),
    description: t("Snap.Connect.JoinCommunity.Description"),
    button: {
      name: t("Snap.Connect.JoinCommunity.Action"),
      handler: () => window.open(SOCIALS_LINKS.telegram, "_blank"),
    },
  },
  {
    icon: { component: <Scamfari /> },
    title: t("Snap.Connect.Report.Title"),
    description: t("Snap.Connect.Report.Description"),
    button: {
      name: t("Snap.Connect.Report.Action"),
      handler: () => window.open(ECOSYSTEM_LINKS.scamfari, "_blank"),
    },
  },
  {
    icon: { component: <Hapi /> },
    title: t("Snap.Connect.DigitalIdentity.Title"),
    description: t("Snap.Connect.DigitalIdentity.Description"),
    button: {
      name: t("Snap.Connect.DigitalIdentity.Action"),
      handler: () => navigate(APP_ROUTES.HAPI_ID),
    },
  },
];
