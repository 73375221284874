import { css, styled } from "styled-components";

const Container = styled.div`
  width: 100%;
  gap: 1.5rem;
  display: flex;
  max-width: 28.2rem;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        max-width: 100%;
      `,
    )};
`;

const StakingWrapper = styled.div`
  gap: 1.75rem;
  display: flex;
  flex-direction: column;
`;

const TogglerAndStakeInfo = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const CardsWrapper = styled.div`
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 0.75rem;
        grid-template-columns: 1fr;
      `,
    )};
`;

const InputWrapper = styled.div`
  input {
    font-size: 1rem;
    height: 2.75rem;
    line-height: 120%;
    border-radius: 0.75rem;
  }
`;

const CheckboxButton = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`;

export default {
  Container,
  StakingWrapper,
  TogglerAndStakeInfo,
  CardsWrapper,
  InputWrapper,
  CheckboxButton,
};
