import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding: 0.5rem 2rem;
`;

const Header = styled.div`
  width: 100%;
  position: relative;
`;

const Close = styled.div`
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  right: -20px;
  top: -20px;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.hapiGrey20};
    }
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 140%;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  p {
    padding-inline-start: 0.5rem;
    margin: 0;
    color: ${({ theme }) => theme.colors.hapiGrey20};
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 140%;
  }
  input {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.hapiGrey80};
    border-radius: 0.5rem;
    padding: 0.75rem 0.5rem;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.25rem;
  button {
    &:first-child {
      background-color: transparent;
      border: none;
      color: ${({ theme }) => theme.colors.hapiGrey20};
    }
  }
`;

export default {
  Container,
  Header,
  Title,
  Close,
  Body,
  ButtonContainer,
};
