import styled, { css, keyframes } from "styled-components";

const flicker = keyframes`
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  10% {
    opacity: 0.6;
  }
`;

const ToolsContainer = styled.div`
  position: relative;

  h2 + div {
    &:before {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      opacity: 0.1;
      display: block;
      position: absolute;
      background-color: ${({ theme }) => theme.colors.white};
    }
  }

  & > div {
    position: relative;

    &:after {
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      opacity: 0.1;
      display: block;
      position: absolute;
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const AnimationBlock = styled.div`
  -webkit-animation: ${flicker} 10s infinite;
  -moz-animation: ${flicker} 10s infinite;
  -o-animation: ${flicker} 10s infinite;
  animation: ${flicker} 10s infinite;
`;

const VioletBlock = styled(AnimationBlock)`
  position: absolute;
  top: 150px;
  right: -400px;
  border-radius: 50%;
  width: 53.75rem;
  height: 53.75rem;
  opacity: 0.6;
  background: linear-gradient(
    209deg,
    ${({ theme }) => theme.colors.lightRed} 0%,
    ${({ theme }) => theme.colors.lightBlue} 100%
  );
  filter: blur(100px);
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        top: -200px;
        right: -500px;
      `,
    )}
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        width: 39.125rem;
        height: 39.125rem;
        right: -400px;
      `,
    )}
`;

const OrangeBlock = styled(AnimationBlock)`
  position: absolute !important;
  bottom: -400px;
  left: -300px;
  border-radius: 50%;
  width: 39.125rem;
  height: 39.125rem;
  opacity: 0.6;
  z-index: -1;
  background: linear-gradient(
    209deg,
    ${({ theme }) => theme.colors.lightRed} 0%,
    ${({ theme }) => theme.colors.lightYellow} 100%
  );
  filter: blur(100px);
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        bottom: -200px;
      `,
    )}
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        width: 23.75rem;
        height: 23.75rem;
        left: -200px;
      `,
    )}
`;

const styles = {
  ToolsContainer,
  VioletBlock,
  OrangeBlock,
};

export default styles;
