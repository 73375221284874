import { t } from "i18next";

import styles from "./styles";

export function Switcher<T extends string>({
  currentType,
  handleChange,
  list,
}: {
  currentType: T;
  handleChange: (nextType: T) => void;
  list: readonly T[];
}) {
  return (
    <styles.List>
      {list.map((element) => (
        <styles.Element key={element} $isActive={currentType === element} onClick={() => handleChange(element as T)}>
          {t(`Switcher.${element}`)}
        </styles.Element>
      ))}
    </styles.List>
  );
}
