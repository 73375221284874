import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import { ReactComponent as X } from "assets/icons/socials/x.svg";
import { ReactComponent as StepSim } from "assets/icons/step-sim.svg";
import { apiURL } from "services/config";
import { logger } from "services/mixpanel";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { SEARCH_PARAMS } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { API_ROUTES, ERegistrationConnectorType } from "shared/interfaces";
import { EModals, showModal } from "store/slices/modals";
import { selectUserStaking } from "store/slices/staking";

import styles from "../../styles";

interface IRegistrationCard {
  name: string;
  step: number;
  disabled: boolean;
  account: string | null;
}

export function TwitterCard({ step, name, account, disabled }: IRegistrationCard) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { jwtToken } = useAppSelector(selectUserStaking);

  const [searchParams] = useSearchParams();
  const twitterError = searchParams.get(SEARCH_PARAMS.ERROR);

  useEffect(() => {
    if (twitterError) {
      dispatch(showModal({ modal: EModals.ACCOUNT_DUPLICATION, props: { type: ERegistrationConnectorType.X } }));
    }
  }, [twitterError, dispatch]);

  const twitterHandler = () => {
    if (!jwtToken || disabled) return;
    logger.trackEvent("REF_TWITTER");
    const authTwitterURL = `${apiURL}/${API_ROUTES.REF_AUTH_TWITTER}/${jwtToken}`;
    window.open(authTwitterURL, "_self");
  };

  return (
    <styles.Card $disabled={disabled}>
      <styles.CardHeader>
        <X />
        <p>{name}</p>
        <styles.Step>
          {step}
          <StepSim />
        </styles.Step>
      </styles.CardHeader>
      {account ? (
        <styles.ConnectedContainer>
          <DoneIcon />
          <span>{account}</span>
        </styles.ConnectedContainer>
      ) : (
        <ButtonPrimary name={t(`Registry.ConnectType.X`)} handler={twitterHandler} fullWidth disabled={disabled} />
      )}
    </styles.Card>
  );
}
