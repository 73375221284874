import { useFormContext } from "react-hook-form";

import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";
import { InputError } from "shared/components/Form/components/Error";
import formStyles from "shared/components/Form/styles";

import { IInputProps } from "./interface";
import styles from "./styles";

export function Input({
  id,
  value,
  label,
  additionalLabel,
  errorMessage,
  additionalInputHandler,
  iconLeft,
  textButton,
  ...rest
}: IInputProps) {
  const { register } = useFormContext();
  const props = rest.disabled ? { value } : { ...register(id) };

  return (
    <formStyles.InputWithError>
      <styles.LabelWrapper $isAdditionalLabel={Boolean(additionalLabel)}>
        {label && <formStyles.Label>{label}</formStyles.Label>}
        {additionalLabel && <formStyles.Label>{additionalLabel}</formStyles.Label>}
      </styles.LabelWrapper>
      <styles.AdditionalInputHandlerContainer>
        {iconLeft && <styles.IconWrapper>{iconLeft}</styles.IconWrapper>}
        <styles.InputWrapper
          {...rest}
          {...props}
          $error={Boolean(errorMessage)}
          $withIcon={Boolean(iconLeft)}
          autoComplete="off"
          autoCorrect="off"
          type={rest.type || "text"}
          inputMode={rest.type === "number" ? "decimal" : undefined}
        />
        {additionalInputHandler && (
          <ButtonIcon
            control={{
              icon: additionalInputHandler.icon,
              handler: additionalInputHandler.handler,
            }}
          />
        )}
        {textButton && (
          <styles.TextButton>
            <div>{textButton.text}</div>
            <button disabled={rest.disabled} onClick={textButton.handler}>
              {textButton.button}
            </button>
          </styles.TextButton>
        )}
      </styles.AdditionalInputHandlerContainer>
      {errorMessage && <InputError text={errorMessage} />}
    </formStyles.InputWithError>
  );
}
