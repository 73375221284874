import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

import styles from "./styles";

export default function Card() {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1 0"],
  });

  const scaleProgress = useTransform(scrollYProgress, [0, 0.5, 1], [1, 0.8, 0.6]);
  const rotateXProgress = useTransform(scrollYProgress, [0, 0.5, 1], [30, 0, -30]);

  return (
    <styles.CardWrapper>
      <motion.div
        ref={ref}
        style={{
          transformPerspective: "1200px",
          scale: scaleProgress,
          rotateX: rotateXProgress,
        }}
      >
        <styles.HapiIdCardIcon />
      </motion.div>
    </styles.CardWrapper>
  );
}
