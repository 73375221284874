import Big from "big.js";

import { FORMAT_DOT, FORMAT_COMMA, ZERO_STRING } from "shared/constants";

Big.RM = Big.roundDown;
Big.DP = 30;

const BASE = 10;
const DEFAULT_PRECISION = 3;
const MIN_AMOUNT = "0.0001";
type ValueType = string | number | Big;

export const BigNumber = (value: ValueType | undefined | null) => {
  try {
    return Big(value || ZERO_STRING);
  } catch (error) {
    console.warn(`Error: ${error} \n\n while working with this value ${value} of such a type ${typeof value} `);
    return Big(ZERO_STRING);
  }
};

export const removeTrailingZeros = (amount: string) => {
  if (amount.includes(FORMAT_DOT) || amount.includes(FORMAT_COMMA)) {
    return amount.replace(/\.?0*$/, "");
  }
  return amount;
};

export const formatUnits = (value: ValueType, decimals = 18, precision?: number): string => {
  try {
    if (!value) return ZERO_STRING;
    return removeTrailingZeros(BigNumber(value).div(BigNumber(BASE).pow(decimals)).toFixed(precision));
  } catch (error) {
    console.error(`Error Big formatUnits \n ${error}`);
    return ZERO_STRING;
  }
};

export const displayAmount = (amount?: ValueType, decimals?: number, precision = DEFAULT_PRECISION): string => {
  if (amount === undefined || amount === null) return "-";
  const formateAmount = formatUnits(amount, decimals || 0, precision);
  const amountBig = BigNumber(formateAmount);
  if (amountBig.eq(ZERO_STRING)) return ZERO_STRING;
  if (amountBig.lte(MIN_AMOUNT)) return `>${MIN_AMOUNT}`;
  return removeTrailingZeros(amountBig.toFixed(precision));
};

export const displayAmountWithSpace = (str: string | number): string =>
  Number(str)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
