import { t } from "i18next";
import { useState } from "react";

import { useGetLeadersQuery } from "services/api/hapi/ref";
import { DataTable } from "shared/components/DataTable";
import { Switcher } from "shared/components/Switcher";
import { columnsSkeleton } from "shared/constants/colunms";

import { columns, leaderboardColumnsOption, skeletonLeaderboard } from "./constants";
import styles from "./styles";
import { LeaderboardSwitcherType, populateArrayWithEmptyObj, leaderboardListSwitcher } from "./utils";
import dashboardStyles from "../../styles";

export const Leaderboard = () => {
  const [type, setType] = useState<LeaderboardSwitcherType>(leaderboardListSwitcher[0]);

  const { data: leaderboard, isLoading } = useGetLeadersQuery();

  const listByType = (type === "You" ? leaderboard?.global : leaderboard?.team) || [];

  const data = isLoading
    ? {
        columns: columnsSkeleton(columns(), leaderboardColumnsOption),
        list: skeletonLeaderboard(),
      }
    : {
        columns: columns(),
        list: listByType.slice(),
      };

  return (
    <styles.Container>
      <dashboardStyles.Title>{t("Dashboard.Leaderboard.Title")}</dashboardStyles.Title>
      <Switcher currentType={type} list={leaderboardListSwitcher} handleChange={(nextType) => setType(nextType)} />
      <styles.Body>
        <DataTable columns={data.columns} data={populateArrayWithEmptyObj(data.list)} />
      </styles.Body>
    </styles.Container>
  );
};
