import { css, styled } from "styled-components";

const SnapContainer = styled.div`
  width: 90rem;
  gap: 0.62rem;
  display: flex;
  margin: 0 auto;
  max-width: 100%;
  min-height: 100dvh;
  position: relative;
  padding: 0 3.75rem;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        padding: 0 1.5rem;

        &:after {
          background-size: auto 81.25rem;
          background-position: center 78.625rem;
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1rem;
        padding: 0 1rem;

        &:after {
          background-size: auto 93rem;
          background-position: center 100rem;
        }
      `,
    )};

  footer {
    padding: 2rem 0;
    margin-top: 4rem;
    background-color: ${({ theme }) => theme.colors.snapBlack};

    div {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const H2 = styled.h2`
  margin: 0;
  font-size: 3rem;
  font-weight: 400;
  line-height: 120%;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        font-size: 2.5rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 2rem;
      `,
    )};
`;

const Description = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.snapGrey};

  strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 0.875rem;
      `,
    )};
`;

const BG = styled.div`
  content: "";
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  background-size: auto 92rem;
  background-repeat: no-repeat;
  background-position: center 67.435rem;
  background-image: url("/static/snap/backgrounds/snap-bg.png");

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        background-size: auto 81.25rem;
        background-position: center 78.625rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        background-size: auto 93rem;
        background-position: center 100rem;
      `,
    )};
`;

export default { SnapContainer, H2, Description, BG };
