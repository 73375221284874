import styled from "styled-components";

const InputWithError = styled.div``;

const Label = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.hapiIdGrey};
  ${({ theme }) => theme.mediaWidth("upToSmall", `font-size: 0.75rem;`)};
`;

const styles = {
  InputWithError,
  Label,
};

export default styles;
