import styled, { keyframes } from "styled-components";

const loadAnimation = keyframes`
  0% { opacity: 0.3; }
  50% { opacity: 0.6; }
  100% { opacity: 0.3; }
`;

const Layout = styled.div`
  background-color: #0b0b17;
`;

const Loading = styled.div`
  min-height: 100vh;
  background-image: url("/static/hapi-head-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  animation: ${loadAnimation} ease-in 1.5s infinite;
`;

export const LayoutLoading = () => {
  return (
    <Layout>
      <Loading />
    </Layout>
  );
};
