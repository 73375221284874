import { css, styled } from "styled-components";

const MainContainer = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 1.5rem;
  padding: 7.31rem 3.75rem;

  &:before {
    content: "";
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.asphalt};
  }

  &:after {
    content: "";
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position-y: center;
    left: calc(100% - 31.84375rem);
    background-size: auto 63.75rem;
    background-image: url("/static/snap/backgrounds/snap-main-bg.png");
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        padding: 6.97rem 2rem;

        &:after {
          left: calc(100% - 20rem);
          background-size: auto 57.875rem;
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 5rem 1rem;

        &:after {
          top: -12rem;
          width: 54.8125rem;
          height: 54.875rem;
          background-size: contain;
          left: calc(100% - 15rem);
          transform: rotate(-23.355deg);
        }
      `,
    )};
`;

const Content = styled.div`
  width: 41.5rem;
  max-width: 100%;
`;

const H1 = styled.h1`
  margin: 0;
  font-weight: 400;
  line-height: 99%;
  font-size: 4.25rem;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 3.75rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 2.75rem;
      `,
    )};
`;

const H1Description = styled.div`
  font-weight: 400;
  line-height: 99%;
  font-size: 2.25rem;
  margin-top: 0.75rem;
  color: ${({ theme }) => theme.colors.whiteOp9};
  font-family: ${({ theme }) => theme.fonts.archivoBlack};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 2rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1.75rem;
      `,
    )};
`;

const Description = styled.div`
  max-width: 100%;
  font-weight: 400;
  line-height: 150%;
  width: 34.8125rem;
  font-size: 1.125rem;
  margin: 2rem 0 2.5rem 0;
  color: ${({ theme }) => theme.colors.snapGrey};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1rem;
        margin: 1.5rem 0;
      `,
    )};
`;

export default { MainContainer, Content, H1, H1Description, Description };
