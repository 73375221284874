import { zodResolver } from "@hookform/resolvers/zod";
import Big from "big.js";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useAccount } from "wagmi";

import { ReactComponent as HapiIcon } from "assets/icons/hapi-circle.svg";
import { logger } from "services/mixpanel";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { Card } from "shared/components/Dashboard/Card";
import { Datalist } from "shared/components/Dashboard/Datalist";
import { RadioButtons } from "shared/components/Dashboard/RadioButtons";
import { Toggler } from "shared/components/Dashboard/Toggler";
import Checkbox from "shared/components/Form/components/Checkbox";
import { Input } from "shared/components/Form/components/Input";
import { Information } from "shared/components/Information";
import { EMPTY_STRING, SEC } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { useBalance } from "shared/hooks/useBalance";
import { EStakingWarning, IStaker, LockupTypeEnum } from "shared/interfaces/staking";
import { isObjectEmpty } from "shared/utils";
import { bigToString } from "shared/utils/calculation";
import { displayAmount } from "shared/utils/displayAmount";
import { claim } from "store/actions/change/staking/claim";
import { stake } from "store/actions/change/staking/stake";
import { unstake } from "store/actions/change/staking/unstake";
import { EModals, showModal } from "store/slices/modals";
import { selectStakingData, selectUserStaking } from "store/slices/staking";
import { selectToken } from "store/slices/token";
import { selectUserTicketId } from "store/slices/whitelist";

import Skeleton from "./components/Skeleton";
import { StakingLabel } from "./components/StakingLabel";
import { StakingWarning } from "./components/StakingWarning";
import { lockupOptions, stakeInfo, togglerOptions, unstakeInfo } from "./data";
import styles from "./styles";
import { EFormFields, StakingViews } from "./types";
import { calculateAPY, calculateRewardPer30Days, getAmountToUnlock, parseTokenAmount, getWarningType } from "./utils";
import { stakingSchema } from "./validationScheme";
import dashboardStyles from "../../styles";

const getIsUnstakeDisabled = (isStakeView: boolean, staker?: IStaker) => {
  const currentTimestamp = Date.now() / SEC;
  if (isStakeView) return false;
  if (staker === undefined) return true;
  return staker.unlockTimestamp > currentTimestamp;
};

export const Staking: React.FC = () => {
  const { address } = useAccount();
  const dispatch = useAppDispatch();

  const { staked, staker, tierAPY, reward, stakingFieldsData, loading } = useAppSelector(selectStakingData);
  const userHapiId = useAppSelector(selectUserTicketId);
  const { data: userData } = useAppSelector(selectUserStaking);

  const token = useAppSelector((state) => selectToken(state, stakingFieldsData?.token));
  const { balance, refetchBalance } = useBalance(token?.address);

  const [view, setView] = useState<StakingViews>("stake");
  const [isLockup, setIsLockup] = useState<boolean>(true);
  const [isButtonLoading, setButtonLoading] = useState<boolean>(false);
  const symbol = token?.symbol || EMPTY_STRING;
  const decimals = token?.decimals || 0;
  const isStakeView = view === "stake";
  const minStakeFormatted = stakingFieldsData?.minStake.formatted || EMPTY_STRING;
  const apy = calculateAPY(Boolean(userData?.is_whale), !!userHapiId, isLockup, tierAPY, stakingFieldsData);
  const insufficientBalance = balance && stakingFieldsData ? balance.value < stakingFieldsData.minStake.value : true;
  const amountToUnlock = getAmountToUnlock(staker?.amount.value, reward?.value, staker?.unlockTimestamp);
  const inputDisabled = (isStakeView ? insufficientBalance : !amountToUnlock) || !address || isButtonLoading;

  const methods = useForm<{ stake: string; agreement: boolean }>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: zodResolver(
      stakingSchema({
        isStake: isStakeView,
        symbol: token?.symbol,
        minStake: stakingFieldsData?.minStake.value,
        balance: balance?.value,
        amountToUnstake: amountToUnlock,
        decimals,
      }),
    ),
    defaultValues: { stake: EMPTY_STRING, agreement: false },
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    control,
    trigger,
  } = methods;

  const input = useWatch({ name: EFormFields.STAKE, control });
  const isUnstakeDisabled = getIsUnstakeDisabled(isStakeView, staker);

  const onSubmit: SubmitHandler<{ stake: string }> = async (values) => {
    if (!address || !decimals || !balance) return;

    dispatch(
      showModal({
        modal: EModals.STAKE_MODAL,
        props: {
          input,
          decimals,
          isStakeView,
          onSubmit: () => {
            logger.trackEvent(isStakeView ? "STAKE" : "UNSTAKE", { stake, token: token?.address });
            const amount = BigInt(parseTokenAmount(values.stake, decimals).toFixed());

            const params = {
              address,
              amount,
              isLockup,
              decimals,
              reset,
              refetchBalance,
            };
            const action = isStakeView ? stake : unstake;
            dispatch(action({ ...params }));
          },
        },
      }),
    );
  };

  const claimHandler = () => {
    if (!address || !decimals || !balance) return;
    logger.trackEvent("CLAIM_STAKE_REWARD", { from: window.location.href });
    dispatch(claim({ address, setButtonLoading, refetchBalance }));
  };

  const setMaxStake = () => {
    if (!balance || !stakingFieldsData || insufficientBalance) return;
    const max = balance.formatted;
    logger.trackEvent("MAX_STAKE", { stake: max });
    setValue(EFormFields.STAKE, max);
    trigger("stake");
  };

  const setMaxUnstake = () => {
    const max = bigToString(amountToUnlock.toString(), decimals);
    logger.trackEvent("MAX_UNSTAKE", { stake: max });
    setValue(EFormFields.STAKE, max);
    trigger("stake");
  };

  useEffect(() => {
    reset();
  }, [view, setValue, reset]);

  const warningType = getWarningType({
    mainWallet: userData?.addresses[0]?.address,
    currentWallet: address,
    agreement: !errors.agreement,
  });

  const isButtonDisabled =
    inputDisabled ||
    isButtonLoading ||
    isUnstakeDisabled ||
    warningType !== EStakingWarning.Empty ||
    !isObjectEmpty(errors);

  if (loading) return <Skeleton />;
  return (
    <FormProvider {...methods}>
      <form style={{ width: "100%" }} onSubmit={(e) => e.preventDefault()}>
        <styles.Container>
          <dashboardStyles.Title>{t("Dashboard.Staking.Title")}</dashboardStyles.Title>
          <styles.StakingWrapper>
            <styles.TogglerAndStakeInfo>
              <Toggler togglerOptions={togglerOptions(view, setView)} />

              <styles.CardsWrapper>
                <Card title={t("Dashboard.Staking.Stake.Your")} value={`${staked?.formatted || 0} ${symbol}`} />
                <Card
                  title={t("Dashboard.Staking.Reward.Your")}
                  subtitle={t("Dashboard.Staking.Reward.PerMonth", {
                    amount: calculateRewardPer30Days(
                      Big(staker?.amount.formatted || 0),
                      calculateAPY(
                        Boolean(userData?.is_whale),
                        !!userHapiId,
                        Boolean(staker?.unlockTimestamp),
                        tierAPY,
                        stakingFieldsData,
                      ),
                    ),
                    symbol,
                  })}
                  value={`${reward?.formatted} ${symbol}`}
                />
              </styles.CardsWrapper>
            </styles.TogglerAndStakeInfo>
            <styles.InputWrapper>
              <Input
                step="any"
                id={EFormFields.STAKE}
                type="number"
                placeholder="0.0"
                label={
                  <StakingLabel
                    title={isStakeView ? t("Dashboard.Staking.Stake.Amount") : t("Dashboard.Staking.Unstake.Amount")}
                    value={EMPTY_STRING}
                  />
                }
                additionalLabel={
                  <StakingLabel
                    title={isStakeView ? t("Dashboard.Staking.Balance") : t("Dashboard.Staking.Available")}
                    value={
                      isStakeView
                        ? `${displayAmount(balance?.formatted || EMPTY_STRING)} ${symbol}`
                        : `${displayAmount(amountToUnlock.toString(), decimals)} ${symbol}`
                    }
                    alignRight
                  />
                }
                iconLeft={<HapiIcon />}
                textButton={{
                  text: symbol,
                  button: t("Actions.MAX"),
                  handler: isStakeView ? setMaxStake : setMaxUnstake,
                }}
                disabled={inputDisabled}
                onWheel={(event) => event.currentTarget.blur()}
                errorMessage={errors.stake?.message}
              />
            </styles.InputWrapper>
            {isStakeView && stakingFieldsData && tierAPY && (
              <RadioButtons
                label={t("Dashboard.Staking.Lockup.Title")}
                buttons={lockupOptions(
                  isLockup,
                  setIsLockup,
                  Boolean(userData?.is_whale),
                  !!userHapiId,
                  tierAPY,
                  stakingFieldsData,
                  staker?.type,
                )}
              />
            )}
            <Datalist
              rows={
                isStakeView
                  ? stakeInfo(minStakeFormatted, symbol, apy, input)
                  : unstakeInfo(
                      staker?.unlockTimestamp || 0,
                      staker?.unlockClaimTimestamp || 0,
                      staker?.amountToClaim.formatted || EMPTY_STRING,
                      symbol,
                      claimHandler,
                      isLockup ? LockupTypeEnum.Lockup : LockupTypeEnum.None,
                    )
              }
            />
            <styles.CheckboxButton>
              <Information text={t("Dashboard.Messages.AutoCompounded")} />
              <StakingWarning type={warningType} />
              {/* TODO: add link to agreement */}
              <Checkbox id={EFormFields.AGREEMENT} label={t("Dashboard.Staking.Agreement", { link: "#" })} />
              <ButtonPrimary
                name={isStakeView ? t("Actions.Stake") : t("Actions.Unstake")}
                handler={handleSubmit(onSubmit)}
                isLoading={isButtonLoading}
                disabled={isButtonDisabled}
                fullWidth
              />
            </styles.CheckboxButton>
          </styles.StakingWrapper>
        </styles.Container>
      </form>
    </FormProvider>
  );
};
