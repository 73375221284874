import { ILeader, IReferrals, IUserStakingData } from "./staking";

export type BasicResponse = { errorCode: number; message?: string };
export type TokenResponse = BasicResponse & { token?: string };
export type LoginResponse = TokenResponse & { onboarded?: boolean };
export type DashboardResponse = BasicResponse & { data?: IUserStakingData };
export type ReferralsResponse = BasicResponse & { data?: IReferrals };
export type HapiPriceResponse = BasicResponse & Partial<{ price: string; updated: number }>;
export type GetRefCodeResponse = BasicResponse & { code?: string; onboarded?: boolean };
export type CheckTokenResponse = { isvalid: boolean; userid: number; onboarded: boolean };
export type LeaderboardResponse = BasicResponse & { global?: Array<ILeader>; team?: Array<ILeader> };
export type ClaimRewardResponse = BasicResponse & { transaction_hash?: string };
export type RewardStatsResponse = BasicResponse & { accumulated_rewards?: string; treasury?: string };
export type HapiIdStatsResponse = BasicResponse & {
  attestation_issued?: string;
  total_users?: string;
  max_score?: string;
};
export type GetMessageResponse = BasicResponse & { messageForSign?: string };
export type OnboardingResponse = BasicResponse & {
  userdata?: {
    wallet?: string;
    telegram?: string;
    twitter?: string;
    // The field will be hardcoded if the user does not have the code when registering.
    // Its value will be NOCODE or user invite code.
    code: string;
  };
};

export enum HAPI_SERVICE_ERRORS {
  //ref/dashboard
  WALLET_NOT_SET = "Wallets not set",
}
