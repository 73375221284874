import { Id, toast } from "react-toastify";

import { toastOptions } from "shared/theme/options";

import { toastContent } from "./Content";

export enum EToast {
  ERROR,
  SUCCESS,
  LOADING,
}

export default class ToastService {
  static loading({ text }: { text: string }) {
    const content = toastContent(text, EToast.LOADING);
    const id = toast.loading(() => content, toastOptions);
    return id;
  }

  static update({ text, id, type = EToast.SUCCESS }: { text: string; id: Id; type: EToast }): void {
    const content = toastContent(text, type);
    toast.update(id, { render: () => content, isLoading: false, ...toastOptions });
  }

  static success({ text }: { text: string }): void {
    const content = toastContent(text);
    toast(() => content, toastOptions);
  }

  static error({ text }: { text: string }): void {
    const content = toastContent(text, EToast.ERROR);
    toast(() => content, toastOptions);
  }
}
