import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

const Section = styled.section`
  display: flex;
  margin-top: 7rem;
  position: relative;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 0 1rem;
      `,
    )};
`;

const Title = styled.div`
  font-weight: 900;
  line-height: 109%;
  align-self: center;
  font-size: 6.25rem;
  margin-bottom: 4.5rem;
  font-family: ${({ theme }) => theme.fonts.archivo};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        font-size: 3.75rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 2.75rem;
        margin-bottom: 2.75rem;
      `,
    )};
`;

const List = styled.div`
  width: 100%;
  gap: 1.5rem;
  display: flex;
  padding: 0 1rem;
  max-width: 72rem;
  align-self: center;
  justify-content: center;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        align-items: center;
        flex-direction: column;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        width: auto;
      `,
    )};
`;

const ListItem = styled.div<{ $show: boolean }>`
  cursor: pointer;
  width: 100%;
  padding: 1.5rem;
  border-radius: 1.25rem;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: ${({ theme, $show }) => ($show ? theme.colors.white : theme.colors.hapiDark)};

  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        max-width: 35rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        max-width: 20.5rem;
        padding: 0.75rem 1rem;
      `,
    )};
`;

const Question = styled.div<{ $show: boolean }>`
  gap: 1rem;
  width: 100%;
  display: flex;
  font-weight: 600;
  line-height: 140%;
  font-size: 1.25rem;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme, $show }) => ($show ? theme.colors.hapiDark : theme.colors.white)};

  svg {
    flex-shrink: 0;
    width: 2.25rem;
    align-self: flex-start;
    transition: 250ms ease-out;
    transform: rotateX(${({ $show }) => ($show ? 180 : 0)}deg);

    path {
      fill: ${({ theme, $show }) => ($show ? theme.colors.hapiDark : theme.colors.white)};
    }
  }

  -webkit-transition: color 300ms linear;
  -ms-transition: color 300ms linear;
  transition: color 300ms linear;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1rem;

        svg {
          width: 1.5rem;
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        svg {
          width: 1.5rem;
        }
      `,
    )};
`;

const Answer = styled(motion.div)`
  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
    font-style: normal;
    margin-top: 1.5rem;
    color: ${({ theme }) => theme.colors.gray07};
  }
`;

const Column = styled.div`
  flex: 1;
  gap: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

const styles = {
  Section,
  Title,
  List,
  ListItem,
  Question,
  Answer,
  ArrowIcon,
  Column,
};

export default styles;
