import styled, { css } from "styled-components";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  max-width: 70.5rem;
  padding: 0 4rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        max-width: 48.75rem;
      `,
    )}
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        padding: 0 3rem;
        max-width: 35.938rem;
      `,
    )}
    ${({ theme }) => theme.mediaWidth("upToSmall", `padding: 0 1rem;`)};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 4.063rem;

  h2 {
    margin-bottom: 1.5rem;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        margin-bottom: 1.875rem;
      `,
    )}
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 0.5rem;
      `,
    )}
`;

const Description = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.grayLight};
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  max-width: 28.5rem;
  text-align: center;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 0.875rem;
        max-width: 100%;
      `,
    )}
`;

const Team = styled.div`
  display: flex;
  gap: 4.5rem;
  height: 38.5rem;
  & > div {
    &:nth-child(1) {
      margin-top: 4.5rem;
    }
    &:nth-child(2) {
      justify-content: flex-end;
    }
    &:nth-child(3) {
      margin-top: 10.625rem;
    }
    &:nth-child(5) {
      margin-top: 9rem;
    }
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        height: 28rem;
        gap: 3.75rem;
        & > div {
          &:nth-child(1) {
            margin-top: 3.313rem;
          }
          &:nth-child(2) {
            justify-content: flex-end;
          }
          &:nth-child(3) {
            margin-top: 7.563rem;
          }
          &:nth-child(5) {
            margin-top: 6.313rem;
          }
        }
      `,
    )}
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(6, 1fr);
        gap: 5rem 3.75rem;
        grid-template-areas:
          "a ."
          "a b"
          "c b"
          "c d"
          "e d"
          "e .";
        & > div {
          &:nth-child(1) {
            margin-top: 0;
            grid-area: a;
          }
          &:nth-child(2) {
            justify-content: flex-start;
            grid-area: b;
          }
          &:nth-child(3) {
            margin-top: 0;
            grid-area: c;
          }
          &:nth-child(4) {
            grid-area: d;
          }
          &:nth-child(5) {
            margin-top: 0;
            grid-area: e;
          }
        }
      `,
    )}
  transition: all 1s ease;
  * {
    transition: all 1s ease;
  }
`;

const OtherTeamMember = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 0.5rem;
  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    & > span {
      color: ${({ theme }) => theme.colors.grayLight};
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 300;
      line-height: 160%;
    }
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        flex-direction: column-reverse;
        align-self: center;
        margin-left: 8.625rem;
      `,
    )}
`;

const Value = styled.div`
  display: flex;
  flex-flow: column nowrap;
  p {
    margin: 0;
    text-align: center;

    &:first-child {
      color: transparent;
      -webkit-text-stroke-width: 2px;
      font-family: ${({ theme }) => theme.fonts.archivo};
      -webkit-text-stroke-color: ${({ theme }) => theme.colors.white};
      font-size: 2rem;
      font-weight: 900;
    }

    &:last-child {
      color: ${({ theme }) => theme.colors.grayLight};
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 120%;
    }
  }
`;

const MemberLogos = styled.div`
  display: flex;
  position: relative;
  width: 9.188rem;
  height: 3.563rem;
`;

const MemberLogo = styled.div<{ color: string; index: number }>`
  width: 50px;
  height: 50px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.colors.black};
  overflow: hidden;
  display: flex;
  position: absolute;
  top: 0;
  left: ${({ index }) => index * 2.813}rem;
  z-index: ${({ index }) => -index};
  img {
    width: 100%;
    height: 100%;
  }
`;

const styles = {
  Section,
  Header,
  Description,
  Team,
  OtherTeamMember,
  Value,
  MemberLogos,
  MemberLogo,
};

export default styles;
