import styled, { css } from "styled-components";

import snapStyles from "../../styles";

const AdditionalInfoContainer = styled.div`
  gap: 0.62rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  > div {
    &:nth-child(1) {
      &:after {
        content: "";
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        position: absolute;
        left: calc(100% - 18rem);
        background-position: left;
        background-size: auto 40rem;
        background-repeat: no-repeat;
        background-image: url("/static/snap/backgrounds/snap-additional-bg-01.png");
      }

      &:before {
        content: "";
        top: 0;
        left: 0;
        z-index: -2;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.asphalt};
      }
    }

    &:nth-child(2) {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("/static/snap/backgrounds/snap-additional-bg-02.jpg");
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        > div {
          &:nth-child(1) {
            &:after {
              left: calc(100% - 12rem);
            }
          }
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1rem;
        grid-template-columns: 1fr;
      `,
    )};
`;

const AdditionalInfoBlock = styled.div`
  display: flex;
  padding: 1.5rem;
  overflow: hidden;
  position: relative;
  border-radius: 1.5rem;
  flex-direction: column;
  min-height: 18.1875rem;
  justify-content: space-between;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 1.25rem;
        min-height: 16.25rem;
      `,
    )};
`;

const Title = styled.div`
  width: 100%;
  font-weight: 400;
  line-height: 120%;
  font-size: 1.5rem;
  max-width: 17.125rem;
  letter-spacing: -0.03rem;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1.25rem;
        letter-spacing: -0.025rem;
      `,
    )};
`;

const Description = styled(snapStyles.Description)`
  max-width: 31rem;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        max-width: 21rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        max-width: 27rem;
        font-size: 0.875rem;
      `,
    )};
`;

export default {
  AdditionalInfoContainer,
  AdditionalInfoBlock,
  Title,
  Description,
};
