import styled, { css } from "styled-components";

const RadioButtonsWrapper = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-weight: 500;
  line-height: 140%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.hapiGrey40};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-weight: 400;
      `,
    )};
`;

const List = styled.div`
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const ListItem = styled.div<{ $isActive: boolean; $isDisabled?: boolean }>`
  gap: 0.38rem;
  display: flex;
  overflow: hidden;
  padding: 0.625rem;
  text-align: center;
  flex-direction: column;
  border-radius: 0.375rem;
  cursor: ${({ $isActive, $isDisabled }) => {
    if (typeof $isDisabled === "boolean") return $isDisabled ? "unset" : "pointer";
    return $isActive ? "unset" : "pointer";
  }};
  border: 1px solid ${({ theme, $isActive }) => ($isActive ? theme.colors.hapiYellow1 : theme.colors.hapiGrey80)};
  pointer-events: ${({ $isDisabled }) => {
    return $isDisabled ? "none" : "auto";
  }};
  div {
    &:first-child {
      font-size: 1rem;
      font-weight: 700;
      line-height: 140%;

      ${({ theme }) =>
        theme.mediaWidth(
          "upToSmall",
          css`
            font-weight: 600;
          `,
        )};
    }

    &:last-child {
      font-weight: 400;
      line-height: 140%;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.hapiGrey20};
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 0.25rem;
      `,
    )};
`;

export default {
  RadioButtonsWrapper,
  Label,
  List,
  ListItem,
};
