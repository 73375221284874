import { t } from "i18next";

import { AnimatedValue } from "shared/components/AnimatedValue";
import { dynamicValues } from "shared/constants/main";

import styles from "./styles";
import { getDetailsValuesArray } from "./utils";

export function DynamicValue() {
  const values = getDetailsValuesArray(dynamicValues);

  return (
    <styles.ValueContainer>
      {values.map(({ to, text, suffix }) => (
        <styles.Value key={text}>
          <p>
            <span>[</span>
            <AnimatedValue to={to} suffix={suffix} />
            <span>]</span>
          </p>
          <p>{t(`MainInfo.DynamicValues.${text}`)}</p>
        </styles.Value>
      ))}
    </styles.ValueContainer>
  );
}
