import styled from "styled-components";

const NavItemContainer = styled.div<{
  disabled?: boolean;
  $isFullwidthSubmenu?: boolean;
  $isHeaderAlternative?: boolean;
  $isGradient?: boolean;
}>`
  height: 100%;
  gap: 0.25rem;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  align-items: center;
  position: ${({ $isFullwidthSubmenu }) => ($isFullwidthSubmenu ? "unset" : "relative")};
  color: ${({ theme }) => theme.colors.hapiGrey5};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: ${({ theme }) => theme.transitions.hoverTransition};
  ${({ theme, $isGradient }) =>
    $isGradient &&
    `
    text-transform: uppercase;
    background: linear-gradient(${theme.colors.hapiIdGradient});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:hover {
      opacity: 0.8;
    }
  `};

  > svg {
    path {
      transition: ${({ theme }) => theme.transitions.hoverTransition};
      fill: ${({ theme }) => theme.colors.hapiGrey5};
    }
  }

  &:hover {
    color: ${({ theme, disabled, $isHeaderAlternative }) =>
      disabled || $isHeaderAlternative ? theme.colors.hapiGrey5 : theme.colors.white};

    span + span {
      svg {
        path {
          fill: ${({ theme }) => theme.colors.white};
        }
      }
    }

    > svg {
      path {
        fill: ${({ theme, disabled, $isHeaderAlternative }) =>
          disabled || $isHeaderAlternative ? theme.colors.hapiGrey5 : theme.colors.white};
      }
    }
  }

  span + span {
    line-height: 0;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.hapiGrey5};
      }
    }
  }

  ${({ theme }) => theme.mediaWidth("upToLarge", `font-size: 0.8125rem;`)};
`;

const Label = styled.span<{ $colorText?: string; $colorBg?: string }>`
  font-weight: 600;
  line-height: 150%;
  padding: 0 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.75rem;
  color: ${({ $colorText, theme }) => $colorText || theme.colors.white};
  background-color: ${({ $colorBg, theme }) => $colorBg || theme.colors.black};
`;

const BgFigure = styled.div`
  line-height: 0;
  position: relative;

  ${({ theme }) => theme.mediaWidth("upToMedium", `margin-right: 7.625rem;`)};
`;

const IconTextContainer = styled.div<{ $isHeaderAlternative: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  transform: translate(-50%, -50%);
  color: ${({ theme, $isHeaderAlternative }) => ($isHeaderAlternative ? theme.colors.hapiYellow1 : theme.colors.black)};

  svg {
    path {
      fill: ${({ theme, $isHeaderAlternative }) =>
        $isHeaderAlternative ? theme.colors.hapiYellow1 : theme.colors.black};
    }
  }
`;

const styles = {
  NavItemContainer,
  Label,
  BgFigure,
  IconTextContainer,
};

export default styles;
