import { useTranslation } from "react-i18next";

import { Header } from "pages/Dashboard/components/Header";
import { APP_ROUTES } from "routes/appRoutes";
import { useGetOnboardingQuery } from "services/api/hapi/ref";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { Footer } from "shared/components/Footer";
import { Skeleton } from "shared/components/Skeleton";
import { MISSING_INVITE_CODE } from "shared/constants";
import { useLoggerNavigate } from "shared/hooks/useLoggerNavigate";
import { IConnectedData } from "shared/interfaces";

import { InviteCard } from "./components/InviteCard";
import { TelegramCard } from "./components/TelegramCard";
import { TwitterCard } from "./components/TwitterCard";
import { WalletCard } from "./components/WalletCard";
import styles from "./styles";

export const Registration = () => {
  const { t } = useTranslation();
  const { data, isLoading, refetch } = useGetOnboardingQuery();
  const navigate = useLoggerNavigate();

  const connectedData: IConnectedData = {
    wallet: data?.userdata?.wallet || null,
    x: data?.userdata?.twitter || null,
    telegram: data?.userdata?.telegram || null,
    inviteCode: data?.userdata?.code || MISSING_INVITE_CODE,
  };

  const disabledTwitter = !connectedData.wallet;
  const disabledTelegram = !connectedData.x;

  const allDataConnected = Object.values(connectedData).every((el) => el !== null);

  const toDashboardHandler = () => {
    if (!allDataConnected) return;
    navigate(APP_ROUTES.DASHBOARD.HOME);
  };

  return (
    <styles.Container>
      <Header />
      <styles.Body>
        <styles.Description>{t("Registry.AlmostThere")}</styles.Description>
        <styles.Title>{t("Registry.FollowTheSteps")}</styles.Title>
        <styles.CardWrapper>
          {isLoading ? (
            Array.from(Array(4).keys()).map((el) => <Skeleton key={el} w="100%" h="17rem" />)
          ) : (
            <>
              <WalletCard
                step={1}
                name={t("Registry.Steps.Wallet")}
                connectedAccount={connectedData.wallet}
                inviteCode={connectedData.inviteCode}
                refetch={refetch}
              />
              <TwitterCard step={2} name={t("Registry.Steps.X")} account={connectedData.x} disabled={disabledTwitter} />
              <TelegramCard
                step={3}
                name={t("Registry.Steps.Telegram")}
                account={connectedData.telegram}
                disabled={disabledTelegram}
                refetch={refetch}
              />
              <InviteCard
                step={4}
                name={t("Registry.Steps.InviteCode")}
                inviteCode={connectedData.inviteCode}
                walletConnected={Boolean(connectedData.wallet)}
                refetch={refetch}
              />
            </>
          )}
        </styles.CardWrapper>
        <ButtonPrimary
          name={t("Actions.ProceedToDashboard")}
          handler={toDashboardHandler}
          disabled={!allDataConnected}
        />
      </styles.Body>
      <Footer />
    </styles.Container>
  );
};
