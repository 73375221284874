import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    &:first-child {
      color: ${({ theme }) => theme.colors.white};
      font-size: 1rem;
      font-weight: 600;
      line-height: 140%;
    }
    &:last-child {
      color: ${({ theme }) => theme.colors.hapiGrey20};
      font-family: Inter;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 120%;
    }
  }
`;

export default {
  Container,
  Title,
};
