import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";
import { LOCAL_STORAGE_KEY } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { useAuthWeb3 } from "shared/hooks/useAuthWeb3";
import { selectUserStaking } from "store/slices/staking";

export function ProtectedRoute() {
  const { jwtToken } = useAppSelector(selectUserStaking);
  const { logout } = useAuthWeb3();

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === LOCAL_STORAGE_KEY.REGISTRATION_TOKEN_KEY && !event.newValue) logout();
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [logout]);

  return jwtToken ? <Outlet /> : <Navigate to={APP_ROUTES.DASHBOARD.LOGIN} replace />;
}
