import Big from "big.js";

import { ReactComponent as RegularIcon } from "assets/icons/dashboard/regular.svg";
import { ReactComponent as WhaleIcon } from "assets/icons/dashboard/whale.svg";
import { ZERO_STRING } from "shared/constants";
import { ETiers } from "shared/interfaces/staking";

const WHALE_AMOUNT = 500;

export const getUserLevel = (amount: string) => {
  if (Big(amount).gte(WHALE_AMOUNT)) return ETiers.Whale;
  return ETiers.Regular;
};

export const getNumberOfScarceTokens = (balance: string | number): string => {
  return Big(WHALE_AMOUNT)
    .minus(balance || ZERO_STRING)
    .toFixed();
};

export const UserLevelIconMap = {
  [ETiers.Regular]: <RegularIcon />,
  [ETiers.Whale]: <WhaleIcon />,
};
