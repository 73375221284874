import styled, { css } from "styled-components";

const Header = styled.header`
  display: flex;
  padding: 1rem 4rem;
  justify-content: space-between;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  & > svg {
    cursor: pointer;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);

        & > :first-child {
          grid-area: 1 / 1 / 2 / 2;
        }

        & > :nth-child(2) {
          grid-area: 2 / 1 / 3 / 3;
        }

        & > :last-child {
          grid-area: 1 / 2 / 2 / 3;
          width: fit-content;
          justify-self: flex-end;
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        padding: 1rem 1.5rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 1rem 1rem;

        button {
          padding: 0 1rem;
        }
      `,
    )};
`;

const Menu = styled.div`
  gap: 2rem;
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: center;

  div {
    opacity: 0.6;
    cursor: pointer;
    font-weight: 500;
    line-height: 100%;
    position: relative;
    letter-spacing: -0.02rem;
    font-family: ${({ theme }) => theme.fonts.archivo};
    transition: ${({ theme }) => theme.transitions.hoverTransition};

    &:after {
      content: "";
      width: 0;
      left: 50%;
      height: 0.125rem;
      position: absolute;
      border-radius: 1rem;
      top: calc(100% + 0.38rem);
      transform: translateX(-50%);
      background: ${({ theme }) => theme.colors.white};
      transition: ${({ theme }) => theme.transitions.hoverTransition};
    }

    &:hover {
      opacity: 1;

      &:after {
        width: 1.75rem;
      }
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        margin-top: 1rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1rem;
      `,
    )};
`;

export default {
  Header,
  Menu,
};
