import styled from "styled-components";

import transitions from "shared/theme/transitions";

const List = styled.div`
  display: flex;
  align-items: center;
  gap: 1.875rem;
`;

const Element = styled.div<{ $isActive: boolean }>`
  position: relative;
  padding-bottom: 0.25rem;
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.white : theme.colors.hapiGrey40)};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 140%;
  cursor: pointer;
  &:hover {
    color: ${({ theme, $isActive }) => ($isActive ? theme.colors.white : theme.colors.hapiGrey20)};
  }
  transition: ${transitions.hoverTransition};

  &::after {
    content: "";
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    width: ${({ $isActive }) => ($isActive ? "80%" : "0")};
    height: 0.0625rem;
    transition: width 500ms;
  }
`;

export default {
  List,
  Element,
};
