import styled from "styled-components";

export const H2 = styled.h2`
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  font-size: 3.375rem;
  margin: 0 0 4.5rem 0;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.archivoBlack};
  ${({ theme }) => theme.mediaWidth("upToMedium", `font-size: 3rem;`)};
  ${({ theme }) => theme.mediaWidth("upToSmall", `font-size: 2rem`)};
`;
