import { LoaderFunction } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";
import { loggerRedirect } from "shared/hooks/useLoggerNavigate";
import { validateParam } from "shared/utils";

export const loaderRemovedRoutes = (async ({ request }) => {
  const { pathname } = new URL(request.url);
  const codeFromPathname = pathname.split("/").slice(-1);
  const code = validateParam(codeFromPathname[0]);
  const redirectPath = code ? `${APP_ROUTES.REGISTRATION}/${code}` : APP_ROUTES.REGISTRATION;
  return loggerRedirect(redirectPath);
}) satisfies LoaderFunction;
