import { useState } from "react";

import { NavItem } from "shared/components/Header/components/Navigation/components/NavItem";
import { navList, submenuCollectionStatusesInitial } from "shared/components/Header/components/Navigation/data";
import { INavigationProps } from "shared/components/Header/components/Navigation/interface";
import styles from "shared/components/Header/components/Navigation/styles";

export function Navigation({ isHeaderAlternative, setIsHeaderAlternative }: INavigationProps) {
  const [submenuCollectionStatuses, setSubmenuCollectionStatuses] = useState<typeof submenuCollectionStatusesInitial>(
    submenuCollectionStatusesInitial,
  );

  return (
    <styles.NavContainer>
      {navList.map((item) => (
        <NavItem
          item={item}
          key={item.key}
          isHeaderAlternative={isHeaderAlternative}
          submenuCollectionStatuses={submenuCollectionStatuses}
          setSubmenuCollectionStatuses={setSubmenuCollectionStatuses}
          setIsHeaderAlternative={setIsHeaderAlternative}
        />
      ))}
    </styles.NavContainer>
  );
}
