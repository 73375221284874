import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { ENavigationListKeys } from "shared/components/Header/components/Navigation/enum";
import { Submenu } from "shared/components/Header/components/Submenu";
import { ISubmenuOptionsProps } from "shared/components/Header/components/Submenu/interface";
import submenuStyles from "shared/components/Header/components/Submenu/styles";
import { socials } from "shared/data/socials";
import useWindowDimensions from "shared/hooks/useWindowDimensions";

export function CommunitySubmenu({ toggleSubmenu, setShowBurger }: ISubmenuOptionsProps) {
  const navigate = useNavigate();
  const { isSmall, isMedium } = useWindowDimensions();
  const isAdaptive = isSmall || isMedium;

  function openSocial(path: string) {
    try {
      const url = new URL(path);
      window.open(url, "_blank", "noreferrer");
    } catch (err) {
      navigate(path);
    } finally {
      toggleSubmenu && toggleSubmenu(ENavigationListKeys.Community, false);
      setShowBurger && setShowBurger(false);
    }
  }

  return (
    <Submenu withBackground={!isAdaptive}>
      {socials.map((social) => (
        <submenuStyles.ListItem onClick={() => openSocial(social.url)} key={social.key}>
          {social.icon} {t(`Community.${social.key}`)} <submenuStyles.Arrow />
        </submenuStyles.ListItem>
      ))}
    </Submenu>
  );
}
