import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccount, useAccountEffect } from "wagmi";

import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import { ReactComponent as StepSim } from "assets/icons/step-sim.svg";
import { logger } from "services/mixpanel";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { MISSING_INVITE_CODE } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAuthWeb3 } from "shared/hooks/useAuthWeb3";
import { getStakingToken } from "store/actions/view/staking/getStakingToken";
import { showModal, EModals } from "store/slices/modals";

import styles from "../../styles";

interface IRegistrationCard {
  name: string;
  step: number;
  connectedAccount: string | null;
  refetch: () => void;
  inviteCode: string;
}

export function WalletCard({ step, name, connectedAccount, refetch, inviteCode }: IRegistrationCard) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const account = useAccount();

  const { open } = useWeb3Modal();
  const { signUp, loginEVMWallet } = useAuthWeb3();

  const [confirmMainWallet, setConfirmMainWallet] = useState<boolean>(Boolean(connectedAccount));

  const isHardcodeInviteCode = inviteCode === MISSING_INVITE_CODE;

  const titleButton = account.isConnected
    ? confirmMainWallet
      ? t("Registry.ConnectType.SignMessage")
      : t("Registry.ConnectType.ConfirmMainWallet")
    : t("Registry.ConnectType.Wallet");

  const signMessage = async (address: string) => {
    logger.trackEvent("REF_SIGN_MESSAGE");

    const action = isHardcodeInviteCode && !connectedAccount ? loginEVMWallet : signUp;
    const isSuccess = await action(address);
    isSuccess && refetch();
  };

  const handleConfirmWalletConnect = async ({ address }: { address: string }) => {
    if (confirmMainWallet) return;
    logger.trackEvent("REF_WALLET");
    dispatch(getStakingToken());

    dispatch(
      showModal({
        modal: EModals.CONFIRM_MAIN_WALLET,
        props: {
          confirm: async () => {
            setConfirmMainWallet(true);
            signMessage(address);
          },
        },
      }),
    );
  };

  useAccountEffect({
    onConnect: ({ address }) => handleConfirmWalletConnect({ address }),
    onDisconnect: () => setConfirmMainWallet(false),
  });

  const walletHandler = async () => {
    logger.trackEvent("REF_WALLET");

    if (!account.isConnected) {
      open();
      return;
    }
    if (account.address && !confirmMainWallet) {
      handleConfirmWalletConnect({ address: account.address });
      return;
    }
    if (account.address && confirmMainWallet) {
      signMessage(account.address);
    }
  };

  return (
    <styles.Card>
      <styles.CardHeader>
        <p>{name}</p>
        <styles.Step>
          {step}
          <StepSim />
        </styles.Step>
      </styles.CardHeader>
      {connectedAccount ? (
        <styles.ConnectedContainer>
          <DoneIcon />
          <span>{connectedAccount}</span>
        </styles.ConnectedContainer>
      ) : (
        <ButtonPrimary name={titleButton} handler={walletHandler} fullWidth />
      )}
    </styles.Card>
  );
}
