import styled from "styled-components";

const ToolsContainer = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 3rem;
  padding: 1rem 4rem 2.94rem 4rem;
  grid-template-columns: repeat(3, 1fr);

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      `
        grid-row-gap: 0rem;
        padding: 1rem 2rem 2.94rem 2rem;
        grid-template-columns: repeat(2, 1fr);
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        grid-row-gap: 0rem;
        padding: 1rem 0rem 2.94rem 0rem;
        grid-template-columns: repeat(1, 1fr);
      `,
    )};
`;

const Tool = styled.div`
  gap: 1rem;
  display: flex;
  cursor: pointer;
  padding: 0.75rem;
  align-items: center;
  border-radius: 0.5rem;
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  svg {
    opacity: 0;
    height: auto;
    width: 1.5rem;
    transform: translateX(-1rem);
    transition: ${({ theme }) => theme.transitions.hoverTransition};

    path {
      fill: ${({ theme }) => theme.colors.hapiDark}!important;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.hapiYellowOp02};

    svg {
      opacity: 1;
      transform: translateX(0);
    }
  }

  ${({ theme }) => theme.mediaWidth("upToMedium", `padding: 1rem;`)};
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.02rem;
  color: ${({ theme }) => theme.colors.hapiDark};
  font-family: ${({ theme }) => theme.fonts.archivoBlack};
`;

const ShortDescription = styled.div`
  font-weight: 400;
  line-height: 160%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.hapiGrey60};
`;

const TitleShortDescription = styled.div`
  flex: 1;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const styles = {
  ToolsContainer,
  Tool,
  Title,
  ShortDescription,
  TitleShortDescription,
};

export default styles;
