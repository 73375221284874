import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAccount } from "wagmi";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { logger } from "services/mixpanel";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import ToastService from "shared/components/Toast";
import { SEARCH_PARAMS } from "shared/constants";
import { useAuthWeb3 } from "shared/hooks/useAuthWeb3";
import { ISignMessageModalProps } from "shared/interfaces/modal";
import { useWalletSelector } from "shared/providers/wallet/walletSelector";
import theme from "shared/theme";
import { cropString } from "shared/utils/stringOperations";

import styles from "./styles";

export function SignMessageModal({ closeModal, type }: ISignMessageModalProps) {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { accountId } = useWalletSelector();
  const { signUp, connectNear } = useAuthWeb3();
  const currentAddress = type === "evm" ? address : accountId;
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const closeHandler = () => {
    closeModal();
    searchParams.delete(SEARCH_PARAMS.SIGN_MESSAGE_MODAL_KEY);
    setSearchParams(searchParams);
  };

  const handler = async () => {
    setLoading(true);
    let isSuccess = false;
    logger.trackEvent("SIGN_MESSAGE", { type, address: currentAddress || undefined });
    switch (type) {
      case "near":
        isSuccess = await connectNear();
        break;
      case "evm":
        isSuccess = await signUp(address);
        break;
    }
    if (isSuccess) {
      closeHandler();
    } else {
      ToastService.error({ text: "Something went wrong" });
    }
    logger.trackEvent("SIGN_MESSAGE", { type, success: isSuccess });
    setLoading(false);
  };

  return (
    <ModalWrapper closeModal={closeHandler} bgColor={theme.colors.registerCardBg} widthInRem="23.5">
      <styles.Container>
        <styles.Header>
          <styles.Title>{t("Dashboard.SignMessage.Title")}</styles.Title>
          {currentAddress && (
            <styles.Description>
              {t("Dashboard.SignMessage.Description", { wallet: cropString(currentAddress) })}
            </styles.Description>
          )}
          <styles.Close>
            <CloseIcon onClick={closeHandler} />
          </styles.Close>
        </styles.Header>
        <ButtonPrimary name={t("Dashboard.Actions.SignMessage")} handler={handler} isLoading={loading} />
      </styles.Container>
    </ModalWrapper>
  );
}
