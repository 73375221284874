import styled, { css } from "styled-components";

const FAQItemContainer = styled.div<{ toggleActive: boolean }>`
  cursor: pointer;
  padding: 1.5rem;
  border-radius: 1.25rem;
  border: 2px solid ${({ theme, toggleActive }) => (toggleActive ? theme.colors.white : theme.colors.whiteOp2)};
  background-color: ${({ theme, toggleActive }) => (toggleActive ? theme.colors.white : theme.colors.asphalt)};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 1.25rem;
      `,
    )};
`;

const QuestionTogglerWrapper = styled.div<{ toggleActive: boolean }>`
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme, toggleActive }) => (toggleActive ? theme.colors.snapBlackDarker : theme.colors.white)};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  span {
    transform: ${({ toggleActive }) => (toggleActive ? "rotate(180deg)" : "rotate(0)")};
    transition: ${({ theme }) => theme.transitions.hoverTransition};
  }
`;

const Question = styled.div`
  font-weight: 400;
  line-height: 120%;
  font-size: 1.25rem;
  letter-spacing: -0.025rem;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1.125rem;
      `,
    )};
`;

const Answer = styled.div`
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.blackOp07};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 0.875rem;
      `,
    )};
`;

export default {
  FAQItemContainer,
  QuestionTogglerWrapper,
  Question,
  Answer,
};
