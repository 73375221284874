import styled from "styled-components";

const FolderContainer = styled.div<{ $isHeaderAlternative: boolean }>`
  svg {
    path {
      fill: ${({ $isHeaderAlternative, theme }) =>
        $isHeaderAlternative ? theme.colors.black : theme.colors.white}!important;
    }
  }
`;

const styles = {
  FolderContainer,
};

export default styles;
