import styled, { css } from "styled-components";

const Container = styled.a`
  display: flex;
  gap: 1rem;
  text-decoration: none;
  flex-shrink: 0;
  width: 22rem;
  img {
    border-radius: 0.5rem;
    object-fit: cover;
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        width: 19rem;
      `,
    )}
`;

const Picture = styled.img`
  border-radius: 0.5rem;
  object-fit: cover;
  width: 6rem;
  height: 6rem;
  flex-shrink: 0;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.gray};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

export default {
  Container,
  Title,
  Description,
  Picture,
};
