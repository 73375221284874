import { ReactComponent as DefaultIcon } from "assets/icons/info.svg";
import { colors } from "shared/theme/colors";

import { InformationProps } from "./interface";
import styles from "./styles";

export const Information = ({ text, fill = colors.greenRGB, icon = <DefaultIcon /> }: InformationProps) => {
  return (
    <styles.Container>
      <styles.IconContainer $fill={fill}>{icon}</styles.IconContainer>
      <p>{text}</p>
    </styles.Container>
  );
};
