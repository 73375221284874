import { t } from "i18next";
import { useState } from "react";

import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { BurgerMenu } from "shared/components/Header/components/BurgerMenu";
import BurgerToggle from "shared/components/Header/components/BurgerToggle";
import { Logo } from "shared/components/Header/components/Logo";
import { Navigation } from "shared/components/Header/components/Navigation";
import { useScrollTo } from "shared/hooks/useScrollTo";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { useRefsContext } from "shared/providers/refs";

import styles from "./styles";

export function Header() {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isHeaderAlternative, setIsHeaderAlternative] = useState<boolean>(false);

  const { scrollTo } = useScrollTo();
  const { plansRef } = useRefsContext();
  const { isSmall, isMedium, isExtraLarge } = useWindowDimensions();

  const isAdaptive = !isExtraLarge;

  return (
    <styles.HeaderContainer $isHeaderAlternative={isHeaderAlternative}>
      {(isSmall || isMedium) && <BurgerToggle isOpened={isOpened} setIsOpened={setIsOpened} />}
      {isOpened && <BurgerMenu setIsOpened={setIsOpened} />}
      <Logo isHeaderAlternative={isHeaderAlternative} />
      <Navigation isHeaderAlternative={isHeaderAlternative} setIsHeaderAlternative={setIsHeaderAlternative} />
      <ButtonPrimary
        name={t("Actions.GetStarted")}
        isOutlined
        clearPadding={isAdaptive}
        smallHeight={isAdaptive}
        smallFont={isAdaptive}
        handler={() => scrollTo(plansRef)}
      />
    </styles.HeaderContainer>
  );
}
