import styled from "styled-components";

import { ReactComponent as ButtonSimImage } from "assets/images/sim-button.svg";

const ButtonPrimaryWrapper = styled.button<{
  $isOutlined: boolean;
  $fullWidth: boolean;
  $isCentered: boolean;
  $clearPadding: boolean;
  $smallHeight: boolean;
  $smallFont: boolean;
  $isSim: boolean;
  $simImage: boolean;
}>`
  gap: 0.25rem;
  display: flex;
  outline: none;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  font-style: normal;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: center;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  margin: ${({ $isCentered }) => ($isCentered ? "0 auto" : "unset")};
  height: ${({ $smallHeight }) => ($smallHeight ? "2.5rem" : "3rem")};
  font-size: ${({ $smallFont }) => ($smallFont ? "0.81263rem" : "1rem")};
  padding: ${({ $clearPadding }) => ($clearPadding ? "0 0.5rem" : "0 1.5rem")};
  background-color: ${({ theme, $isOutlined, $isSim, $simImage }) =>
    $isOutlined || $isSim || $simImage ? "transparent" : theme.colors.hapiYellow1};
  color: ${({ theme, $isOutlined }) => ($isOutlined ? theme.colors.hapiYellow1 : theme.colors.hapiDark)};
  transition: ${({ theme }) => theme.transitions.hoverTransition};
  border: ${({ theme, $isOutlined, $isSim, $simImage }) => {
    if ($isSim || $simImage) return "none";
    if ($isOutlined) return `2px solid ${theme.colors.hapiYellowOp02}`;
    if (!$isOutlined) return `2px solid ${theme.colors.hapiYellow1}`;
  }};

  svg {
    height: auto;
    width: 1.5rem;
  }

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

const ButtonSim = styled(ButtonSimImage)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const SimImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const ButtonIconWrapper = styled.span`
  line-height: 0;
  cursor: pointer;
  display: inline-block;

  svg {
    path {
      transition: ${({ theme }) => theme.transitions.hoverTransition};
    }
  }

  &:hover {
    svg {
      path {
        opacity: 0.8;
      }
    }
  }
`;

export default {
  ButtonPrimaryWrapper,
  ButtonSim,
  ButtonIconWrapper,
  SimImage,
};
