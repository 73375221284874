import styled, { css } from "styled-components";

const Page = styled.div`
  gap: 3rem;
  display: flex;
  position: relative;
  flex-direction: column;
`;

const MainStatsNetworks = styled.div`
  gap: 9rem;
  display: flex;
  padding: 0 4rem;
  align-items: center;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        padding: 0 1rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 5.5rem;
      `,
    )};
`;

export default {
  Page,
  MainStatsNetworks,
};
