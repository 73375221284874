import { styled } from "styled-components";

const CodeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    &:first-child {
      border-color: transparent;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.hapiGrey20};
    }
  }
`;

export default {
  CodeContainer,
  ButtonGroup,
};
