import { Address, erc20Abi, formatUnits } from "viem";
import { useAccount, useReadContracts } from "wagmi";

import { selectToken } from "store/slices/token";

import { useAppSelector } from "./redux/useAppSelector";

export const useBalance = (tokenAddress: Address | undefined) => {
  const { address } = useAccount();
  const token = useAppSelector((state) => selectToken(state, tokenAddress));
  const result = useReadContracts({
    contracts: [
      {
        address: token?.address,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: address ? [address] : undefined,
      },
    ],
  });

  const balance = result.data?.[0].result;
  return {
    balance:
      balance !== undefined && token
        ? {
            formatted: formatUnits(balance, token.decimals),
            value: balance,
            symbol: token.symbol,
          }
        : null,
    refetchBalance: result.refetch,
    isLoading: result.isLoading,
  };
};
