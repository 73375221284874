import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { ReactComponent as HapiIdCard } from "assets/images/hapi-id-card.svg";

const CardWrapper = styled(motion.div)`
  display: flex;
  padding: 0 2.5rem;
  justify-content: center;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 0;
      `,
    )};
`;

const HapiIdCardIcon = styled(HapiIdCard)`
  width: 100%;
  max-width: 80rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
    max-width: 50rem;
  `,
    )};
`;

const styles = {
  CardWrapper,
  HapiIdCardIcon,
};

export default styles;
