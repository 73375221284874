import styled, { css } from "styled-components";

import { BaseContainer } from "shared/theme/styles";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
`;

const Body = styled(BaseContainer)`
  flex: 1;
  gap: 3.25rem;
  display: flex;
  max-width: 78rem;
  flex-direction: column;
  margin-top: 3rem;
  position: relative;

  p,
  svg {
    z-index: 2;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        gap: 7.25rem;
        padding: 0 3rem;
        margin-top: 4.75rem;
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 0;
        padding: 0 1rem;
        margin-top: 3rem;
      `,
    )};
`;

const FirstGradient = styled.img`
  position: absolute;
  z-index: 1;
  top: -350px;
  right: -55%;
  pointer-events: none;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        top: -150px;
        width: 52.55388rem;
        height: 52.55388rem;
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        right: auto;
        top: auto;
        bottom: -400px;
        left: -65%;
      `,
    )};
`;

const SecondGradient = styled.img`
  position: absolute;
  z-index: 1;
  pointer-events: none;

  bottom: -350px;
  left: -85%;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        width: 75rem;
        height: 75rem;
        bottom: -50px;
        left: -70%;
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        bottom: 600px;
        left: auto;
        right: -50%;
        width: 50rem;
        height: 50rem;
      `,
    )};
`;

export default {
  Container,
  Body,
  FirstGradient,
  SecondGradient,
};
