import { ReactComponent as Allbridge } from "assets/icons/partners/allbridge.svg";
import { ReactComponent as AscendEx } from "assets/icons/partners/ascendex.svg";
import { ReactComponent as Aurora } from "assets/icons/partners/aurora.svg";
import { ReactComponent as Avalanche } from "assets/icons/partners/avalanche.svg";
import { ReactComponent as Base } from "assets/icons/partners/base.svg";
import { ReactComponent as Bitgert } from "assets/icons/partners/bitgert.svg";
import { ReactComponent as Blockbank } from "assets/icons/partners/blockbank.svg";
import { ReactComponent as Bocachica } from "assets/icons/partners/bocachica.svg";
import { ReactComponent as BinanceSmartChain } from "assets/icons/partners/bsc.svg";
import { ReactComponent as Caduceus } from "assets/icons/partners/caduceus.svg";
import { ReactComponent as Coinstore } from "assets/icons/partners/coinstore.svg";
import { ReactComponent as Cronos } from "assets/icons/partners/cronos.svg";
import { ReactComponent as Crystal } from "assets/icons/partners/crystal.svg";
import { ReactComponent as Dafi } from "assets/icons/partners/dafi.svg";
import { ReactComponent as EchoDex } from "assets/icons/partners/echodex.svg";
import { ReactComponent as Fantom } from "assets/icons/partners/fantom.svg";
import { ReactComponent as GateIo } from "assets/icons/partners/gateio.svg";
import { ReactComponent as Passport } from "assets/icons/partners/gitcoinPassport.svg";
import { ReactComponent as Goplus } from "assets/icons/partners/goplus.svg";
import { ReactComponent as HackenAi } from "assets/icons/partners/hacken.svg";
import { ReactComponent as Hackless } from "assets/icons/partners/hackless.svg";
import { ReactComponent as Heco } from "assets/icons/partners/heco.svg";
import { ReactComponent as HorizonDex } from "assets/icons/partners/horizon.svg";
import { ReactComponent as IMe } from "assets/icons/partners/iMe.svg";
import { ReactComponent as ImToken } from "assets/icons/partners/imToken.svg";
import { ReactComponent as Izumi } from "assets/icons/partners/izumi.svg";
import { ReactComponent as Jumbo } from "assets/icons/partners/jumbo.svg";
import { ReactComponent as Juscutum } from "assets/icons/partners/juscutum.svg";
import { ReactComponent as Lockup } from "assets/icons/partners/lockup.svg";
import { ReactComponent as Bitcoin } from "assets/icons/partners/main/bitcoin.svg";
import { ReactComponent as Chainalysis } from "assets/icons/partners/main/chainalysis.svg";
import { ReactComponent as DexTools } from "assets/icons/partners/main/dextools.svg";
import { ReactComponent as Diia } from "assets/icons/partners/main/dia.svg";
import { ReactComponent as GeckoTerminal } from "assets/icons/partners/main/gecko.svg";
import { ReactComponent as HERE } from "assets/icons/partners/main/here.svg";
import { ReactComponent as HOT } from "assets/icons/partners/main/hot.svg";
import { ReactComponent as Kucoin } from "assets/icons/partners/main/kucoin.svg";
import { ReactComponent as Linea } from "assets/icons/partners/main/linea.svg";
import { ReactComponent as Metamask } from "assets/icons/partners/main/metamask.svg";
import { ReactComponent as Near } from "assets/icons/partners/main/near.svg";
import { ReactComponent as WhiteBit } from "assets/icons/partners/main/whitebit.svg";
import { ReactComponent as MexcGlobal } from "assets/icons/partners/mexc.svg";
import { ReactComponent as NautilusChain } from "assets/icons/partners/nautilus.svg";
import { ReactComponent as NewtribeCapital } from "assets/icons/partners/newtribe.svg";
import { ReactComponent as Okc } from "assets/icons/partners/okc.svg";
import { ReactComponent as Optimism } from "assets/icons/partners/optimism.svg";
import { ReactComponent as Polygon } from "assets/icons/partners/polygon.svg";
import { ReactComponent as Reef } from "assets/icons/partners/reef.svg";
import { ReactComponent as RefFinance } from "assets/icons/partners/refFinance.svg";
import { ReactComponent as Roseon } from "assets/icons/partners/roseon.svg";
import { ReactComponent as Scroll } from "assets/icons/partners/scroll.svg";
import { ReactComponent as Sender } from "assets/icons/partners/sender.svg";
import { ReactComponent as Solana } from "assets/icons/partners/solana.svg";
import { ReactComponent as Standard } from "assets/icons/partners/standard.svg";
import { ReactComponent as TeamFinance } from "assets/icons/partners/teamFinance.svg";
import { ReactComponent as TokenPocket } from "assets/icons/partners/tokenPocket.svg";
import { ReactComponent as Tron } from "assets/icons/partners/tron.svg";
import { ReactComponent as Uphold } from "assets/icons/partners/uphold.svg";
import { ReactComponent as XinFin } from "assets/icons/partners/xinFin.svg";
import { ReactComponent as Youminter } from "assets/icons/partners/youminter.svg";

export const mainPartners = [
  {
    name: "HERE",
    logo: <HERE />,
  },
  {
    name: "HOT",
    logo: <HOT />,
  },
  {
    name: "Linea",
    logo: <Linea />,
  },
  {
    name: "Kucoin",
    logo: <Kucoin />,
  },
  {
    name: "Metamask",
    logo: <Metamask />,
  },
  {
    name: "Near",
    logo: <Near />,
  },
  {
    name: "Whitebit",
    logo: <WhiteBit />,
  },
  {
    name: "Bitcoin",
    logo: <Bitcoin />,
  },
  {
    name: "Chainalysis",
    logo: <Chainalysis />,
  },
  {
    name: "GeckoTerminal",
    logo: <GeckoTerminal />,
  },
  {
    name: "DexTools",
    logo: <DexTools />,
  },
  {
    name: "Diia",
    logo: <Diia />,
  },
];

export const partners = [
  { name: "Scroll", logo: <Scroll /> },
  { name: "Tron", logo: <Tron /> },
  { name: "Base", logo: <Base /> },
  { name: "Optimism", logo: <Optimism /> },
  { name: "Cronos", logo: <Cronos /> },
  { name: "Fantom", logo: <Fantom /> },
  { name: "BinanceSmartChain", logo: <BinanceSmartChain /> },
  { name: "Heco", logo: <Heco /> },
  { name: "Aurora", logo: <Aurora /> },
  { name: "Solana", logo: <Solana /> },
  { name: "XinFin", logo: <XinFin /> },
  { name: "Polygon", logo: <Polygon /> },
  { name: "Goplus", logo: <Goplus /> },
  { name: "RefFinance", logo: <RefFinance /> },
  { name: "Reef", logo: <Reef /> },
  { name: "Okc", logo: <Okc /> },
  { name: "AscendEx", logo: <AscendEx /> },
  { name: "Crystal", logo: <Crystal /> },
  { name: "Caduceus", logo: <Caduceus /> },
  { name: "Hackless", logo: <Hackless /> },
  { name: "Blockbank", logo: <Blockbank /> },
  { name: "MexcGlobal", logo: <MexcGlobal /> },
  { name: "HackenAi", logo: <HackenAi /> },
  { name: "Allbridge", logo: <Allbridge /> },
  { name: "TeamFinance", logo: <TeamFinance /> },
  { name: "GateIo", logo: <GateIo /> },
  { name: "IMe", logo: <IMe /> },
  { name: "Coinstore", logo: <Coinstore /> },
  { name: "Lockup", logo: <Lockup /> },
  { name: "Standard", logo: <Standard /> },
  { name: "Uphold", logo: <Uphold /> },
  { name: "Jumbo", logo: <Jumbo /> },
  { name: "EchoDex", logo: <EchoDex /> },
  { name: "Avalanche", logo: <Avalanche /> },
  { name: "Bocachica", logo: <Bocachica /> },
  { name: "Dafi", logo: <Dafi /> },
  { name: "NewtribeCapital", logo: <NewtribeCapital /> },
  { name: "ImToken", logo: <ImToken /> },
  { name: "Roseon", logo: <Roseon /> },
  { name: "TokenPocket", logo: <TokenPocket /> },
  { name: "Youminter", logo: <Youminter /> },
  { name: "Sender", logo: <Sender /> },
  { name: "Izumi", logo: <Izumi /> },
  { name: "Passport", logo: <Passport /> },
  { name: "NautilusChain", logo: <NautilusChain /> },
  { name: "HorizonDex", logo: <HorizonDex /> },
  { name: "Juscutum", logo: <Juscutum /> },
  { name: "Bitgert", logo: <Bitgert /> },
];
