import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { router } from "routes";
import { LayoutLoading } from "shared/components/LayoutLoading";

import reportWebVitals from "./reportWebVitals";

import "services/translation";
import "react-toastify/dist/ReactToastify.css";
import "styles.css";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <RouterProvider router={router} fallbackElement={<LayoutLoading />} />
  </StrictMode>,
);

reportWebVitals();
