import { t } from "i18next";

import { H2 } from "shared/components/Typography";
import { team, otherTeamMember } from "shared/constants/team";
import { useRefsContext } from "shared/providers/refs";

import { TeamMember } from "./components/TeamMember";
import styles from "./styles";

export function Team() {
  const { teamRef } = useRefsContext();

  return (
    <styles.Section ref={teamRef}>
      <styles.Header>
        <H2>{t("Team.Title")}</H2>
        <styles.Description>{t("Team.Description")}</styles.Description>
      </styles.Header>
      <styles.Team>
        {team.map((member) => (
          <TeamMember key={member.name} {...member} />
        ))}
      </styles.Team>
      <styles.OtherTeamMember>
        <div>
          <span>&</span>
          <styles.Value>
            <p>{t("Team.Value")}</p>
            <p>{t("Team.Professionals")}</p>
          </styles.Value>
        </div>
        <styles.MemberLogos>
          {otherTeamMember.map((member, index) => (
            <styles.MemberLogo key={`Team.${index} - ${member.logo}`} color={member.color} index={index}>
              <img src={member.logo} alt={t("Team.Professionals")} />
            </styles.MemberLogo>
          ))}
        </styles.MemberLogos>
      </styles.OtherTeamMember>
    </styles.Section>
  );
}
