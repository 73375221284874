import { getAccount } from "@wagmi/core";
import Big from "big.js";
import moment from "moment";

import { chains } from "services/config";
import { walletConfig } from "services/wallet";
import { BASE } from "shared/constants";
import { EStakingWarning, IStakingFieldsData, ITierAPY } from "shared/interfaces/staking";
import { BigNumber, displayAmount } from "shared/utils/displayAmount";

export const calculateAPY = (
  isWhale: boolean,
  isHapiId: boolean,
  isLockup: boolean,
  tierAPY?: ITierAPY,
  stakingFieldsData?: IStakingFieldsData,
) => {
  if (!stakingFieldsData || !tierAPY) return 0;

  const regularAPY = Number(tierAPY.regular);
  const whaleAPY = Number(tierAPY.whale);

  let apy = isWhale ? whaleAPY : regularAPY;
  const { lockupBonus, hapiIDBonus } = stakingFieldsData;

  if (isHapiId) apy += hapiIDBonus;
  if (isLockup) apy += lockupBonus;

  return apy;
};

export const calculateRewardPerDay = (staked: Big, apy: number) => staked.mul(apy / 100).div(365);

export const calculateRewardPer30Days = (staked: Big, apy: number) =>
  displayAmount(calculateRewardPerDay(staked, apy).mul(30));

export const getAmountToUnlock = (staked?: bigint, reward?: bigint, unlockTimestamp?: number) => {
  if (staked === undefined || reward === undefined) return BigInt(0);

  const currentTimestamp = moment().valueOf();
  if (unlockTimestamp && currentTimestamp < unlockTimestamp) return reward;

  return staked + reward;
};

export const parseTokenAmount = (value: string, decimals: number) => BigNumber(value).mul(BASE ** decimals);

export const getWarningType = ({
  mainWallet,
  currentWallet,
  agreement,
}: {
  mainWallet: string | undefined;
  currentWallet: string | undefined;
  agreement: boolean;
}): EStakingWarning => {
  const { chainId, isConnected } = getAccount(walletConfig);

  if (!isConnected) return EStakingWarning.ConnectWallet;

  if (chainId !== chains[0].id) return EStakingWarning.SwitchNetwork;

  if (!agreement) return EStakingWarning.Agreement;

  if (mainWallet && currentWallet && mainWallet !== currentWallet) return EStakingWarning.MainWallet;
  return EStakingWarning.Empty;
};
