import { motion } from "framer-motion";
import styled from "styled-components";

import { ReactComponent as ArrowLink } from "assets/icons/arrow-link.svg";
import { ReactComponent as CornerPart } from "assets/images/sim-components/corner.svg";

const SubmenuContainer = styled(motion.div)<{ $isFullWidth: boolean }>`
  cursor: default;
  position: absolute;
  left: ${({ $isFullWidth }) => ($isFullWidth ? "0" : "50%")};
  width: ${({ $isFullWidth }) => ($isFullWidth ? "100%" : "auto")};
  min-width: ${({ $isFullWidth }) => ($isFullWidth ? "unset" : "15rem")};
  padding: ${({ $isFullWidth }) => ($isFullWidth ? "0" : "0.5rem 0.5rem 2rem")};
  top: ${({ $isFullWidth }) => ($isFullWidth ? "100%" : "calc(100% - 0.3rem)")};
  background-color: ${({ theme, $isFullWidth }) => ($isFullWidth ? theme.colors.white : "transparent")};
  transform: ${({ $isFullWidth }) => ($isFullWidth ? "none" : "translateX(-50%)")};

  &:after {
    top: 0;
    left: 0;
    z-index: -1;
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.white};
    clip-path: polygon(100% calc(100% - 2rem), calc(100% - 2rem) 100%, 0% 100%, 0% 0%, 100% 0%);
  }
`;

const Corner = styled(CornerPart)`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: rotate(270deg);
  clip-path: polygon(0% 0px, 0% 100%, 100% 100%);

  path {
    fill: ${({ theme }) => theme.colors.white}!important;
  }
`;

const ListItem = styled.div<{ $isDisabled?: boolean }>`
  gap: 0.5rem;
  display: flex;
  padding: 0.5rem;
  align-items: center;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.hapiDark};
  transition: ${({ theme }) => theme.transitions.hoverTransition};
  cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : "pointer")};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    transition: ${({ theme }) => theme.transitions.hoverTransition};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.hapiYellowOp02};

    svg {
      &:last-child {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }
`;

const Arrow = styled(ArrowLink)`
  opacity: 0;
  margin-left: auto;
  transform: translate(-0.5rem, 0.5rem);
`;

const styles = {
  SubmenuContainer,
  Corner,
  ListItem,
  Arrow,
};

export default styles;
