import styled, { css, keyframes } from "styled-components";

import { ReactComponent as Logo } from "assets/images/wheel.svg";

const rotate = keyframes`
  0% {
    
    transform: matrix(1.15, 0, 0, 0.8, 0, 0) rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: matrix(1.15, 0, 0, 0.8, 0, 0) rotate3d(0, 0, 1, -360deg);
  }
`;

const rotateMediumScreen = keyframes`
  0% {
    transform: matrix(0.9, 0, 0, 0.575, 0, 0) rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: matrix(0.9, 0, 0, 0.575, 0, 0) rotate3d(0, 0, 1, -360deg);
  }
`;

const rotateSmallScreen = keyframes`
  0% {
    transform: matrix(0.575, 0, 0, 0.345, 0, 0) rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: matrix(0.575, 0, 0, 0.345, 0, 0) rotate3d(0, 0, 1, -360deg);
  }
`;

const Section = styled.section`
  display: flex;
  gap: 18.75rem;
  width: 78.25rem;
  padding: 0 3rem;
  max-width: 100%;
  font-weight: 400;
  position: relative;
  flex-direction: column;
  margin: 4.5rem auto 0 auto;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 17.5rem;
        padding: 0 1rem;
        margin-top: 3rem;
      `,
    )}
`;

const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 72.25rem;
  width: 100%;
  align-self: center;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        max-width: 31.438rem;
        align-self: flex-start;
      `,
    )}

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        max-width: 100%;
      `,
    )}
`;

const LogoContainer = styled.div`
  position: absolute;
  right: 40px;
  top: 0;
  z-index: -1;
  transform: rotate(-15deg) translateZ(-1px);

  svg {
    width: 700px;
    height: 700px;
    animation: ${rotate} 8s linear infinite;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        right: 0;

        svg {
          animation: ${rotateMediumScreen} 8s linear infinite;
        }
      `,
    )}

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        top: 90px;
        right: -155px;

        svg {
          animation: ${rotateSmallScreen} 8s linear infinite;
        }
      `,
    )}
`;

const BoxShadow = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.colors.darkBlue} 0%, ${theme.colors.whiteOpacity0} 100%)`};
  position: absolute;
  width: 700px;
  height: 700px;
  top: 0;
  right: 0;
  border-radius: 50%;
  transform: matrix(1.15, 0, 0, 0.85, 0, 0) translateZ(1px);

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        transform: matrix(0.9, 0, 0, 0.6, 0, 0) translateZ(1px);
      `,
    )}

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        transform: matrix(0.575, 0, 0, 0.4, 0, 0) translateZ(1px);
      `,
    )}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 0.5rem;
      `,
    )}
`;

const H1 = styled.h1`
  margin: 0;
  gap: 2rem;
  display: flex;
  font-size: 6rem;
  font-weight: 800;
  line-height: 100%;
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.archivo};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 4rem;
      `,
    )}

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 3.5rem;
        flex-direction: column;
        gap: 0.375rem;
      `,
    )}
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.grayLight};
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 160%;
  margin: 0;
  max-width: 36.5rem;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 0.875rem;
      `,
    )}
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const styles = {
  Section,
  LogoContainer,
  BoxShadow,
  Logo,
  DescriptionBlock,
  Header,
  H1,
  Description,
  ButtonGroup,
};

export default styles;
