import { css, styled } from "styled-components";

import { getFadeIn } from "shared/theme/animations";

const SecureCryptosContainer = styled.div<{ isInView: boolean }>`
  gap: 0.63rem;
  display: grid;
  grid-template-columns: 2fr 3fr;
  ${({ isInView }) => getFadeIn(isInView)}

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        grid-template-columns: repeat(2, 1fr);
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1rem;
        grid-template-columns: 1fr;
      `,
    )};
`;

const Info = styled.div`
  gap: 1.5rem;
  display: flex;
  padding: 2.5rem;
  border-radius: 1.5rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.asphalt};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        padding: 2rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 2rem 1rem;
      `,
    )};

  button {
    width: fit-content;

    svg {
      height: auto;
      width: 1.5rem;
    }
  }
`;

const MarqueeContainer = styled.div`
  gap: 1.06rem;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 1.5rem;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.asphalt};

  &:before,
  &:after {
    content: "";
    top: 0;
    z-index: 9;
    height: 100%;
    width: 12.5rem;
    position: absolute;
    background: ${({ theme }) => theme.colors.marqueeGradient};
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
    transform: rotate(180deg);
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        &:before,
        &:after {
          width: 7rem;
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 3.48rem 0;
      `,
    )};
`;

const MarqueeItem = styled.div`
  gap: 1.62rem;
  display: flex;
  margin: 0 0.81rem;
`;

export default { SecureCryptosContainer, Info, MarqueeContainer, MarqueeItem };
