import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Chevron } from "assets/icons/chevron-right.svg";
import { ReactComponent as Check } from "assets/images/snap/icons/check-circle.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { useSnap } from "shared/hooks/useSnap";

import styles from "./styles";
import { getRecommended, getSteps } from "./utils";

export const SnapConnect: React.FC = () => {
  const navigate = useNavigate();
  const { isAuth, isSnapInstalled, isApiAdded, loading, installSnap, connectMetamask, getApi } = useSnap();

  const steps = getSteps(isAuth, isSnapInstalled, isApiAdded, installSnap, connectMetamask, getApi);
  const recommended = getRecommended(navigate);

  return (
    <styles.SnapConnectContainer>
      <styles.Back to={APP_ROUTES.SNAP.HOME}>
        <Chevron /> {t("Actions.Back")}
      </styles.Back>
      <styles.Steps>
        {steps.map(({ icon, title, description, button, done }) => (
          <styles.Block key={title}>
            <styles.IconContainer>{icon.component}</styles.IconContainer>
            <styles.TitleDescription>
              <styles.Title>{title}</styles.Title>
              <styles.Description>{description}</styles.Description>
            </styles.TitleDescription>
            {done.status ? (
              <styles.Done>
                {done.title}
                <Check />
              </styles.Done>
            ) : (
              <ButtonPrimary
                name={button.name}
                handler={button.handler}
                disabled={button.disabled || loading}
                isLoading={loading}
              />
            )}
          </styles.Block>
        ))}
      </styles.Steps>
      <styles.Recommended>
        {recommended.map(({ icon, title, description, button }) => (
          <styles.Block key={title}>
            <styles.IconContainer>{icon.component}</styles.IconContainer>
            <styles.TitleDescription>
              <styles.Title>{title}</styles.Title>
              <styles.Description>{description}</styles.Description>
            </styles.TitleDescription>
            <ButtonPrimary name={button.name} handler={button.handler} isOutlined />
          </styles.Block>
        ))}
      </styles.Recommended>
    </styles.SnapConnectContainer>
  );
};
