import { t } from "i18next";

import { TokenDistributionProps } from "./interface";
import styles from "./styles";
import { titleDistributionTypeMap, iconDistributionTypeMap } from "./utils";

const Card = ({ type, amount }: TokenDistributionProps) => {
  return (
    <styles.CardContainer type={type}>
      <styles.CardHeader>{titleDistributionTypeMap[type]}</styles.CardHeader>
      <styles.CardFooter>
        {iconDistributionTypeMap[type]}
        <styles.CardValue>{t("TokenUtility.Percent", { amount })}</styles.CardValue>
      </styles.CardFooter>
    </styles.CardContainer>
  );
};

export const TokenDistributionByServices = () => {
  return (
    <styles.DistributionByServiceContainer>
      <styles.Title>{t("TokenUtility.TokenDistributionByServices.Title")}</styles.Title>
      <Card type="staking" amount="70" />
      <Card type="dao" amount="20" />
      <Card type="burn" amount="10" />
      <styles.Description>
        <p>{t("TokenUtility.TokenDistributionByServices.Description-1")}</p>
        <p>{t("TokenUtility.TokenDistributionByServices.Description-2")}</p>
      </styles.Description>
    </styles.DistributionByServiceContainer>
  );
};

export const TokenDistributionBySalesMintingAttestations = () => {
  return (
    <styles.DistributionBySaleContainer>
      <styles.Title>{t("TokenUtility.TokenDistributionBySaleContainer.Title")}</styles.Title>
      <Card type="staking" amount="50" />
      <Card type="dao" amount="30" />
      <Card type="burn" amount="20" />
      <styles.Description>
        <p>{t("TokenUtility.TokenDistributionBySaleContainer.Description")}</p>
      </styles.Description>
    </styles.DistributionBySaleContainer>
  );
};
