import { AnimatePresence } from "framer-motion";
import { t } from "i18next";
import { useState } from "react";

import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { useRefsContext } from "shared/providers/refs";
import { dropdownAnimation } from "shared/theme/animations";

import styles from "./styles";

const Item = ({ index }: { index: number }) => {
  const [show, setShow] = useState<boolean>(false);
  const key = index + 1;

  return (
    <styles.ListItem $show={show} onClick={() => setShow(!show)}>
      <styles.Question $show={show}>
        {t(`HapiId.FAQ.List.${key}.Question`)}
        <styles.ArrowIcon />
      </styles.Question>
      <AnimatePresence>
        {show && (
          <styles.Answer initial="close" animate="open" exit="close" variants={dropdownAnimation}>
            <p>{t(`HapiId.FAQ.List.${key}.Answer`)}</p>
          </styles.Answer>
        )}
      </AnimatePresence>
    </styles.ListItem>
  );
};

const List = ({ items }: { items: number }) => {
  const { isExtraLarge } = useWindowDimensions();
  if (isExtraLarge) {
    const firstColumnItems = Math.ceil(items / 2);
    const secondColumnItems = items - firstColumnItems;
    return (
      <styles.List>
        <styles.Column>
          {Array.from(Array(firstColumnItems).keys()).map((index) => (
            <Item key={index} index={index} />
          ))}
        </styles.Column>
        <styles.Column>
          {Array.from(Array(secondColumnItems).keys()).map((index) => (
            <Item key={firstColumnItems + index} index={firstColumnItems + index} />
          ))}
        </styles.Column>
      </styles.List>
    );
  }
  return (
    <styles.List>
      {Array.from(Array(items).keys()).map((index) => (
        <Item key={index} index={index} />
      ))}
    </styles.List>
  );
};

export default function FAQ() {
  const { hapiIdFAQRef } = useRefsContext();

  return (
    <styles.Section ref={hapiIdFAQRef}>
      <styles.Title>{t("HapiId.FAQ.Title")}</styles.Title>
      <List items={9} />
    </styles.Section>
  );
}
