import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICurrentTicket, IStage, ITicketStats } from "shared/interfaces/whitelistOutputs";
import { RootState } from "store";

interface IWhitelistSliceState {
  user: {
    verifyStatus?: boolean;
    userTicketId?: number;
  };
  ticketStats?: ITicketStats;
  currentTicket?: ICurrentTicket;
  stage?: IStage;
  counterRef?: string;
  ticketsSoldInStage?: number;
}

const initialState: IWhitelistSliceState = { user: {} };

export const whitelistSlice = createSlice({
  name: "whitelist",
  initialState,
  reducers: {
    setTicketStats: (state, { payload }: PayloadAction<ITicketStats>) => {
      state.ticketStats = payload;
    },
    setCurrentTicket: (state, { payload }: PayloadAction<ICurrentTicket>) => {
      state.currentTicket = payload;
    },
    setStage: (state, { payload }: PayloadAction<IStage>) => {
      state.stage = payload;
    },
    setCounterRef: (state, { payload }: PayloadAction<string>) => {
      state.counterRef = payload;
    },
    setTicketsSoldInStage: (state, { payload }: PayloadAction<number>) => {
      state.ticketsSoldInStage = payload;
    },
    setUserInitialData: (state) => {
      state.user = initialState.user;
    },
    setVerifyStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.user.verifyStatus = payload;
    },
    setUserTicketId: (state, { payload }: PayloadAction<number>) => {
      state.user.userTicketId = payload;
    },
  },
});

export const selectTicketStats = (state: RootState) => state.whitelist.ticketStats;
export const selectCurrentTicket = (state: RootState) => state.whitelist.currentTicket;
export const selectStage = (state: RootState) => state.whitelist.stage;
export const selectCounterRef = (state: RootState) => state.whitelist.counterRef;
export const selectTicketsSoldInStage = (state: RootState) => state.whitelist.ticketsSoldInStage;
export const selectWhitelist = (state: RootState) => state.whitelist;
export const selectUserTicketId = (state: RootState) => state.whitelist.user.userTicketId;
export const selectVerifyStatus = (state: RootState) => state.whitelist.user.verifyStatus;

export const {
  setTicketStats,
  setCurrentTicket,
  setStage,
  setCounterRef,
  setTicketsSoldInStage,
  setUserInitialData,
  setVerifyStatus,
  setUserTicketId,
} = whitelistSlice.actions;
