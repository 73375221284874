import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";

import { ReactComponent as ArrowLink } from "assets/icons/arrow-link.svg";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { MINT_HAPI_ID_HOT_WALLET, SEC_5 } from "shared/constants";
import { useRefsContext } from "shared/providers/refs";

import { Score } from "./components/Score";
import styles from "./styles";

const marquee = {
  TransactionActivity: null,
  SocialActivity: null,
  IdentityVerification: null,
  Result: null,
};

export default function TrustScore() {
  const [value, setValue] = useState<number>(0);
  const [currentStatus, setCurrentStatus] = useState<keyof typeof marquee>("TransactionActivity");

  const { hapiIdTrustRef, hapiIdHowItWorksRef } = useRefsContext();

  const scoreLoading = useMemo(() => currentStatus !== "Result", [currentStatus]);

  const valueUpdater = () => {
    let myVar = 1;
    const totalTime = SEC_5;
    const targetValue = 100;
    const increment = 1;
    const intervalDuration = totalTime / (targetValue - myVar);

    const intervalId = setInterval(function () {
      if (myVar >= targetValue) {
        clearInterval(intervalId);
        if (currentStatus === "TransactionActivity") setCurrentStatus("SocialActivity");
        else if (currentStatus === "SocialActivity") setCurrentStatus("IdentityVerification");
        else if (currentStatus === "IdentityVerification") setCurrentStatus("Result");
        else setCurrentStatus("TransactionActivity");
      } else {
        myVar += increment;
        setValue(myVar);
      }
    }, intervalDuration);
  };

  useEffect(() => {
    valueUpdater();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus]);

  return (
    <styles.TrustScoreContainer ref={hapiIdTrustRef}>
      <styles.HowItWorks ref={hapiIdHowItWorksRef}>
        <styles.TitleDescription>
          <styles.Title>{t("HapiId.TrustScore.Title")}</styles.Title>
          <styles.Description>{t("HapiId.TrustScore.Description")}</styles.Description>
        </styles.TitleDescription>
        <styles.ScoreProgressHint>
          <Score score={68} loading={scoreLoading} isShining />
          {currentStatus !== "Result" && (
            <styles.ProgressContainer>
              <styles.ProgressTitle>{t(`HapiId.TrustScore.Statuses.${currentStatus}`)}</styles.ProgressTitle>
              <styles.Progress max={100} value={value} />
            </styles.ProgressContainer>
          )}
          {currentStatus === "Result" && (
            <styles.TrustMyScore>
              <ButtonPrimary
                name={t("HapiId.Actions.EvaluateMyScore")}
                handler={() => window.open(MINT_HAPI_ID_HOT_WALLET, "_blank")}
                iconRight={<ArrowLink />}
              />
            </styles.TrustMyScore>
          )}
          <styles.Description $isSmall>{t("HapiId.TrustScore.Hint")}</styles.Description>
        </styles.ScoreProgressHint>
      </styles.HowItWorks>
    </styles.TrustScoreContainer>
  );
}
