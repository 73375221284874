import styled, { css } from "styled-components";

const Title = styled.div`
  font-weight: 600;
  line-height: 120%;
  font-size: 1.5rem;
  font-style: normal;
  color: ${({ theme }) => theme.colors.white};
`;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Body = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1;
  gap: 2.25rem;
  padding: 0 4rem;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        display: flex;
        padding: 0 2rem;
        gap: 1.5rem;
        flex-direction: column;
        max-width: 43rem;
        width: 100%;
        align-self: center;
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1.25rem;
        padding: 0 1rem;
      `,
    )};
`;

const NavigationWrapper = styled.div`
  flex-shrink: 0;
  width: 14.625rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        max-width: 100%;
        width: 100%;
        position: sticky;
        top: 62px;
        z-index: 11;
      `,
    )};
`;

export default {
  Title,
  Container,
  Body,
  NavigationWrapper,
};
