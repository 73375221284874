enum TRANSITION_DURATIONS {
  slow = 500,
  medium = 250,
  fast = 125,
}

const scoreTransitionDurationMS = 1500;

const transitionsConfig = {
  duration: {
    slow: `${TRANSITION_DURATIONS.slow}ms`,
    medium: `${TRANSITION_DURATIONS.medium}ms`,
    fast: `${TRANSITION_DURATIONS.fast}ms`,
  },
  timing: {
    ease: "ease",
    in: "ease-in",
    out: "ease-out",
    inOut: "ease-in-out",
  },
};

const changeTransition = `${transitionsConfig.duration.fast} ${transitionsConfig.timing.in}`;
const hoverTransition = `${transitionsConfig.duration.fast} ${transitionsConfig.timing.in}`;
const hoverMediumTransition = `${transitionsConfig.duration.medium} ${transitionsConfig.timing.in}`;

const transitions = {
  scoreTransitionDurationMS,
  changeTransition,
  hoverTransition,
  hoverMediumTransition,
};

export default transitions;
