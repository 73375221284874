import { createAsyncThunk } from "@reduxjs/toolkit";
import { readContract } from "@wagmi/core";
import { Address } from "viem";

import { walletConfig } from "services/wallet";
import type { ThunkApiConfig } from "store";
import { whitelistConfig } from "store/config";
import { setUserTicketId } from "store/slices/whitelist";

export const getUserTicketId = createAsyncThunk<void, { address: Address }, ThunkApiConfig>(
  "whitelistContract/getUserTicketId",
  async ({ address }, { dispatch }) => {
    try {
      const ticketId = await readContract(walletConfig, {
        ...whitelistConfig,
        functionName: "check_ticket",
        args: [address],
      });

      dispatch(setUserTicketId(Number(ticketId)));
    } catch (e) {
      console.error(`Error getting user ticket id: \n ${e}`);
    }
  },
);
