import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { disconnect } from "@wagmi/core";

import { walletConfig } from "services/wallet";
import { CheckTokenResponse } from "shared/interfaces/api";
import store from "store";
import { logout } from "store/slices/staking";

import { refApi } from "./hapi/ref";

export const checkJWTValidity = async (): Promise<CheckTokenResponse | null> => {
  try {
    const response = await store.dispatch(refApi.endpoints.checkToken.initiate());
    if (response.data) return response.data;
    throw response;
  } catch (error) {
    store.dispatch(logout());
    disconnect(walletConfig);
    console.error("Error: while check jwt token \n\n", error);
    return null;
  }
};

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}
