import { t } from "i18next";
import { useState, useEffect, useRef } from "react";

import { PLATFORMS_WIDTH, PLATFORM_CHANGE_INTERVAL, PLATFORM_HEIGHT, SMALL_PLATFORMS_WIDTH } from "shared/constants";
import { platforms } from "shared/constants/main";
import useWindowDimensions from "shared/hooks/useWindowDimensions";

import styles from "./styles";

export function Platforms() {
  const { isSmall } = useWindowDimensions();
  const [currentPlatformIndex, setCurrentPlatformIndex] = useState(0);
  const currentPlatform = platforms[currentPlatformIndex];
  const wrapperRef = useRef<HTMLDivElement>(null);
  const adaptiveWidth = isSmall ? SMALL_PLATFORMS_WIDTH : PLATFORMS_WIDTH;
  const currentWidth = wrapperRef.current?.children[currentPlatformIndex].clientWidth || adaptiveWidth;
  const currentHeight = wrapperRef.current?.children[currentPlatformIndex].clientHeight || PLATFORM_HEIGHT;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPlatformIndex((prevIndex) => (prevIndex + 1) % platforms.length);
    }, PLATFORM_CHANGE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <styles.PlatformWrapper
      ref={wrapperRef}
      $width={currentWidth}
      $height={currentHeight}
      $bgColor={currentPlatform.color}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {platforms.map((platform, index) => (
        <styles.Platform
          key={platform.key}
          initial={{ opacity: 0 }}
          animate={{ opacity: index === currentPlatformIndex ? 1 : 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {t(`Platforms.${platform.key}`)}
        </styles.Platform>
      ))}
    </styles.PlatformWrapper>
  );
}
