import { configureStore } from "@reduxjs/toolkit";

import { hapiApi } from "services/api/hapi";
import { modalsSlice } from "store/slices/modals";
import { tokenSlice } from "store/slices/token";
import { whitelistSlice } from "store/slices/whitelist";

import { stakingSlice } from "./slices/staking";

const store = configureStore({
  reducer: {
    [modalsSlice.name]: modalsSlice.reducer,
    [whitelistSlice.name]: whitelistSlice.reducer,
    [stakingSlice.name]: stakingSlice.reducer,
    [tokenSlice.name]: tokenSlice.reducer,
    [hapiApi.reducerPath]: hapiApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(hapiApi.middleware),
});

type Store = typeof store;
export type RootState = ReturnType<Store["getState"]>;
export type AppDispatch = Store["dispatch"];

export type ThunkApiConfig = {
  state: RootState;
  dispatch: AppDispatch;
  rejectValue?: null;
  extra: unknown;
};

export default store;
