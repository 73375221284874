import { PropsWithChildren } from "react";

import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";

import { IRewardCard } from "./interface";
import styles from "./styles";

export function RewardCard({
  bgColor,
  name,
  icon,
  claimHandler,
  tooltip,
  buttonTitle,
  children,
}: IRewardCard & PropsWithChildren) {
  return (
    <styles.Container $bgColor={bgColor}>
      <styles.Header>
        <p>
          {name} {tooltip && tooltip}
        </p>
        {icon}
      </styles.Header>
      <styles.Footer>
        <styles.Values>{children}</styles.Values>
        <ButtonPrimary name={buttonTitle} clearPadding handler={claimHandler} isOutlined />
      </styles.Footer>
    </styles.Container>
  );
}
