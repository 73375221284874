import { css, styled } from "styled-components";

const CardWrapper = styled.div`
  display: flex;
  gap: 1.625rem;
  overflow: hidden;
  padding: 0.75rem;
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.blackLight};
  min-width: 202px;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 0.75rem;
      `,
    )};
`;

const Row = styled.div<{ $singleColumn?: boolean }>`
  ${({ $singleColumn }) => {
    if ($singleColumn) {
      return css`
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.5rem;
      `;
    } else {
      return css`
        gap: 0.2rem;
        display: flex;
        justify-content: space-between;
      `;
    }
  }}
`;

const Title = styled.div`
  font-weight: 500;
  overflow: hidden;
  line-height: 120%;
  font-size: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.hapiGrey40};
`;

const Subtitle = styled(Title)`
  font-weight: 300;
  text-align: right;
  letter-spacing: -0.4px;
  color: ${({ theme }) => theme.colors.hapiGrey5};
`;

const Value = styled.div`
  font-weight: 700;
  overflow: hidden;
  line-height: 120%;
  font-size: 0.875rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const Button = styled.button`
  border: 0;
  padding: 0;
  cursor: pointer;
  font-weight: 700;
  width: fit-content;
  font-size: 0.875rem;
  background-color: unset;
  color: ${({ theme }) => theme.colors.hapiYellow1};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export default {
  CardWrapper,
  Row,
  Title,
  Subtitle,
  Value,
  ButtonWrapper,
  Button,
};
