import { Skeleton } from "shared/components/Skeleton";
import { colors } from "shared/theme/colors";

import styles from "./styles";
import rewardCardStyles from "../Rewards/components/RewardCard/styles";
import rewardsStyles from "../Rewards/styles";
import userWalletStyles from "../UserWallets/styles";

export const ProfileSkeleton = () => {
  return (
    <styles.Container>
      <styles.LeftContainer>
        <Skeleton w="6.94rem" h="2rem" />
        <styles.ProfileData>
          <styles.ProfileDataCol>
            <Skeleton w="2.5rem" h="1rem" />
            <Skeleton w="3.44rem" h="1.75rem" />
          </styles.ProfileDataCol>
          <styles.ProfileDataCol>
            <Skeleton w="5.3rem" h="1rem" />
            <Skeleton w="5.63rem" h="1.75rem" />
          </styles.ProfileDataCol>
          <styles.ProfileDataCol>
            <Skeleton w="8rem" h="1rem" />
            <Skeleton w="5rem" h="1.75rem" />
          </styles.ProfileDataCol>
        </styles.ProfileData>
        <rewardsStyles.Container style={{ marginTop: "1rem" }}>
          <rewardsStyles.Title>
            <Skeleton w="5rem" h="1.4rem" />
            <Skeleton w="17rem" h="1.4rem" />
          </rewardsStyles.Title>
          <rewardCardStyles.Container $bgColor={colors.whiteOp05} style={{ marginTop: "1rem" }}>
            <rewardCardStyles.Header>
              <Skeleton w="3rem" h="1.75rem" />
            </rewardCardStyles.Header>
            <rewardCardStyles.Footer>
              <rewardCardStyles.Values>
                <Skeleton w="7rem" h="3rem" />
              </rewardCardStyles.Values>
              <Skeleton w="4.1rem" h="3rem" borderRadius="0.5rem" />
            </rewardCardStyles.Footer>
          </rewardCardStyles.Container>
          <rewardCardStyles.Container $bgColor={colors.whiteOp05}>
            <rewardCardStyles.Header>
              <Skeleton w="4rem" h="1.75rem" />
            </rewardCardStyles.Header>
            <rewardCardStyles.Footer>
              <rewardCardStyles.Values>
                <Skeleton w="5rem" h="3rem" />
              </rewardCardStyles.Values>
              <Skeleton w="4.1rem" h="3rem" borderRadius="0.5rem" />
            </rewardCardStyles.Footer>
          </rewardCardStyles.Container>
        </rewardsStyles.Container>
      </styles.LeftContainer>
      <styles.RightContainer>
        <userWalletStyles.Container>
          <Skeleton w="8rem" h="1.225rem" />
          <userWalletStyles.List>
            <userWalletStyles.Item>
              <Skeleton w="8rem" h="1.45rem" />
              <Skeleton w="6rem" h="1.45rem" />
            </userWalletStyles.Item>
            <userWalletStyles.Item>
              <Skeleton w="8rem" h="1.45rem" />
              <Skeleton w="3rem" h="1.45rem" />
            </userWalletStyles.Item>
          </userWalletStyles.List>
          <Skeleton w="10.4rem" h="3rem" borderRadius="0.5rem" />
        </userWalletStyles.Container>
      </styles.RightContainer>
    </styles.Container>
  );
};
