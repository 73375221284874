import styled from "styled-components";

export const Background = styled.div`
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.overlayBg};
`;
