import { contractStaking, contractWhitelist, stakingAbi, whitelistAbi } from "services/config";

export const whitelistConfig = {
  address: contractWhitelist,
  abi: whitelistAbi,
};

export const stakingConfig = {
  address: contractStaking,
  abi: stakingAbi,
};
