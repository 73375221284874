import styled, { css } from "styled-components";

import { TokenDistributionType } from "shared/interfaces/tokenDistribution";

import { styleDistributionType } from "./utils";

const cardSize = {
  big: "15rem",
  small: "9.75rem",
};

const CardContainer = styled.div<{ type: TokenDistributionType }>`
  height: ${cardSize.big};
  min-width: ${cardSize.big};
  width: 100%;
  background-color: ${({ type }) => styleDistributionType[type].bg};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 2rem;
  padding: 2rem 1rem 1rem;
  grid-area: ${({ type }) => type};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        min-width: fit-content;
        height: ${cardSize.small};
        border-radius: 1.25rem;
        padding: 1rem 0.5rem 0.5rem;
      `,
    )};
`;

const CardHeader = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1rem;
      `,
    )};
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  svg {
    width: 4.5rem;
    height: 4.5rem;
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        svg {
          width: 3rem;
          height: 3rem;
        }
      `,
    )};
`;

const CardValue = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  text-align: right;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 2rem;
      `,
    )};
`;

const DistributionByServiceContainer = styled.div`
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(3, ${cardSize.big});
  grid-template-rows: repeat(3, auto);
  gap: 1.5rem;

  grid-template-areas:
    "title title title"
    "staking staking dao"
    "burn description description";

  & > p {
    max-width: 46.5rem;
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        grid-template-columns: repeat(3, auto);
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        margin-top: 13.25rem;
        gap: 1rem;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
          "title title"
          "staking staking"
          "dao burn"
          "description description";
      `,
    )};
`;

const DistributionBySaleContainer = styled.div`
  margin-top: 5.75rem;
  display: grid;
  grid-template-columns: repeat(4, ${cardSize.big});
  grid-template-rows: repeat(3, auto);
  gap: 1.5rem;
  grid-template-areas:
    "empty title title title"
    "staking staking dao burn"
    "_ _ description description";
  align-self: flex-end;

  & > p {
    text-align: right;
  }
  & > div:last-child {
    & > p {
      text-align: right;
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        grid-template-columns: repeat(3, ${cardSize.big});
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
          "title title title"
          "staking staking dao"
          "description description burn";
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        margin-top: 1.75rem;
        grid-template-columns: repeat(3, auto);
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        margin-top: 6rem;
        gap: 1rem;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
          "title title"
          "staking staking"
          "dao burn"
          "description description";
        & > p {
          text-align: left;
        }
        & > div:last-child {
          & > p {
            text-align: left;
          }
        }
      `,
    )};
`;

const Title = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.archivoBlack};
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.05rem;
  grid-area: title;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1.5rem;
        letter-spacing: -0.03rem;
      `,
    )};
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: ${({ theme }) => theme.colors.hapiGrey5};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  grid-area: description;
  p {
    margin: 0;
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 0.875rem;
      `,
    )};
`;

export default {
  CardContainer,
  CardHeader,
  CardFooter,
  CardValue,
  DistributionBySaleContainer,
  DistributionByServiceContainer,
  Title,
  Description,
};
