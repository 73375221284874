export enum ERoutes {
  HOME = "/",
  HAPI_ID = "/hapi-id",
}

export enum API_METHODS {
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum API_ROUTES {
  REF_ENTER = "ref/enter",
  REF_UPDATE_CODE = "ref/update_code",
  REF_CONNECT_TELEGRAM = "ref/connect_telegram",
  REF_CONNECT_TWITTER = "ref/connect_twitter",
  REF_CONNECT_WALLET = "ref/connect_wallet",
  REF_CONNECT_NEAR = "ref/connect_near",
  REF_LOGIN_WALLET = "ref/login_wallet",
  REF_LOGIN_NEAR = "ref/login_near",
  REF_ONBOARDING = "ref/onboarding",
  REF_GET_MESSAGE = "ref/get_message",
  REF_DASHBOARD = "ref/dashboard",
  REF_SET_USERNAME = "ref/set_username",
  REF_AUTH_TWITTER = "ref/auth_twitter",
  REF_REFERRALS = "ref/referrals",
  REF_HAPI_PRICE = "ref/hapiprice",
  REF_CHECK_TOKEN = "ref/check_token",
  REF_LEADERBOARD = "ref/leaderboard",
  REF_CLAIM_REWARD = "ref/claim_reward",
  GET_REF_CODE = "ref/getrefcode",
  GET_REF_REWARD_STATS = "ref/refreward_stat",
  GET_REF_HAPI_ID_STATS = "ref/hapiid_statfront",
}

export enum ESocial {
  LinkedIn = "LinkedIn",
  GitHub = "GitHub",
  Telegram = "Telegram",
  Twitter = "Twitter",
}

export interface ISocials {
  type: ESocial;
  value?: string;
}

export interface ITeamMember {
  color: string;
  name: string;
  image: string;
  socials: ISocials[];
}

export interface IPost {
  title: string;
  author: string;
  url: string;
  publishedAt: string;
  imageUrl: string;
  postPage: string;
}

export enum STATUS {
  OK = "ok",
}

export enum ERegistrationStep {
  InviteCode = "inviteCode",
  X = "x",
  Telegram = "telegram",
  Wallet = "wallet",
}

export enum EQueryParams {
  SORT_BY_POINTS = "sort_by_points",
}

export enum ETableSorting {
  ASC = "asc",
  DESC = "desc",
}

export * from "./registration";

export type ColumnsOptions = Record<
  number,
  Partial<{ width: number; minWidth: number; maxWidth: number; isRandomWidth: boolean }>
>;
