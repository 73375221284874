import { t } from "i18next";

import theme from "shared/theme";

export const getReferralTypes = (totalStakedL1: number, totalStakedL2: number) => {
  return [
    {
      title: t("Dashboard.Referrals.Direct.Title"),
      description: t("Dashboard.Referrals.Direct.Description"),
      progress: {
        value: totalStakedL1,
        background: theme.colors.progressOrange,
      },
      table: {
        colorChecked: theme.colors.progressOrange,
        head: {
          key: t("Dashboard.Referrals.ReferralsStaked.Title"),
          value: t("Dashboard.Referrals.ReferralsStaked.Bonus"),
        },
        body: [
          {
            key: 5,
            value: t("Dashboard.Referrals.ReferralsStaked.Links", { amount: 8 }),
          },
          {
            key: 50,
            value: t("Dashboard.Referrals.ReferralsStaked.Links", { amount: 4 }),
            isChecked: totalStakedL1 >= 50,
          },
          {
            key: 100,
            value: t("Dashboard.Referrals.ReferralsStaked.Links", { amount: 2 }),
          },
          {
            key: 200,
            value: t("Dashboard.Referrals.ReferralsStaked.PermanentLink"),
          },
          {
            key: 500,
            value: t("Dashboard.Referrals.ReferralsStaked.Reward", { value: 0.25 }),
          },
          {
            key: 1000,
            value: t("Dashboard.Referrals.ReferralsStaked.Reward", { value: 0.5 }),
          },
          {
            key: 2000,
            value: t("Dashboard.Referrals.ReferralsStaked.SuperReward"),
          },
        ],
      },
    },
    {
      title: t("Dashboard.Referrals.Indirect.Title"),
      description: t("Dashboard.Referrals.Indirect.Description"),
      progress: {
        value: totalStakedL2,
        background: theme.colors.progressBlue,
      },
      table: {
        colorChecked: theme.colors.progressBlue,
        head: {
          key: t("Dashboard.Referrals.ReferralsStaked.Title"),
          value: t("Dashboard.Referrals.ReferralsStaked.Bonus"),
        },
        body: [
          {
            key: 5,
            value: t("Dashboard.Referrals.ReferralsStaked.Reward", { value: 0.25 }),
          },
          {
            key: 100,
            value: t("Dashboard.Referrals.ReferralsStaked.Reward", { value: 0.1 }),
          },
          {
            key: 500,
            value: t("Dashboard.Referrals.ReferralsStaked.Reward", { value: 0.1 }),
          },
          {
            key: 1000,
            value: t("Dashboard.Referrals.ReferralsStaked.Reward", { value: 0.1 }),
          },
          {
            key: 2000,
            value: t("Dashboard.Referrals.ReferralsStaked.Reward", { value: 0.1 }),
          },
          {
            key: 5000,
            value: t("Dashboard.Referrals.ReferralsStaked.Reward", { value: 0.2 }),
          },
          {
            key: 10000,
            value: t("Dashboard.Referrals.ReferralsStaked.SuperReward"),
          },
        ],
      },
    },
  ];
};
