import styled from "styled-components";

import transitions from "shared/theme/transitions";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding: 0.5rem 2rem;
`;

const Header = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const Close = styled.div`
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  right: -20px;
  top: -20px;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.hapiGrey20};
    }
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 140%;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.hapiGrey5};
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${({ theme }) => theme.colors.whiteOp1};
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 1rem;
  transition: ${transitions.hoverTransition};
  &:hover {
    opacity: 0.6;
    & > svg {
      transition: ${transitions.hoverTransition};
      transform: translateX(3px) rotate(270deg);
    }
  }

  span {
    text-align: start;
    flex: 1;
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.875rem;
    font-weight: 600;
  }
  & > svg {
    transform: rotate(270deg);
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Icon = styled.div`
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.chipBg};
  border-radius: 50%;
`;

export default {
  Container,
  Header,
  Title,
  Description,
  Close,
  ButtonContainer,
  Button,
  Icon,
};
