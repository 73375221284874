import { t } from "i18next";

import dashboardStyles from "pages/Dashboard/styles";
import { Skeleton } from "shared/components/Skeleton";

import styles from "./styles";

const StakingSkeleton = () => {
  return (
    <styles.Container>
      <dashboardStyles.Title>{t("Dashboard.Staking.Title")}</dashboardStyles.Title>
      <styles.StakingWrapper>
        <styles.TogglerAndStakeInfo>
          <styles.Toggler>
            <Skeleton w="3.4375rem" h="1.575rem" />
            <Skeleton w="4.5rem" h="1.575rem" />
          </styles.Toggler>
          <styles.CardsWrapper>
            <styles.Card>
              <Skeleton w="3.4375rem" h="0.75rem" />
              <Skeleton w="3.9375rem" h="1.25rem" />
            </styles.Card>
            <styles.Card>
              <Skeleton w="3.4375rem" h="0.75rem" />
              <Skeleton w="3.9375rem" h="1.25rem" />
            </styles.Card>
          </styles.CardsWrapper>
        </styles.TogglerAndStakeInfo>
        <styles.InputWrapper>
          <Skeleton w="5rem" h="0.8rem" />
          <Skeleton w="100%" h="2.625rem" />
        </styles.InputWrapper>
        <styles.RadioButtons>
          <Skeleton w="5rem" h="0.8rem" />
          <styles.CardsWrapper>
            <styles.Card>
              <Skeleton w="3.4375rem" h="1.4rem" />
              <Skeleton w="3.9375rem" h="1rem" />
            </styles.Card>
            <styles.Card>
              <Skeleton w="4.6rem" h="1.4rem" />
              <Skeleton w="3.9375rem" h="1rem" />
            </styles.Card>
          </styles.CardsWrapper>
        </styles.RadioButtons>
        <styles.Datalist>
          <div>
            <Skeleton w="6.5rem" h="0.875rem" />
            <Skeleton w="5rem" h="0.875rem" />
          </div>
          <div>
            <Skeleton w="1.5rem" h="0.875rem" />
            <Skeleton w="5rem" h="0.875rem" />
          </div>
          <div>
            <Skeleton w="6.5rem" h="0.875rem" />
            <Skeleton w="9rem" h="0.875rem" />
          </div>
        </styles.Datalist>
        <styles.Button>
          <Skeleton w="100%" h="2.625rem" />
        </styles.Button>
      </styles.StakingWrapper>
    </styles.Container>
  );
};

export default StakingSkeleton;
