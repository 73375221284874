import { t } from "i18next";

import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { H2 } from "shared/components/Typography";
import { partnerFormUrl } from "shared/constants";
import { partners, mainPartners } from "shared/constants/partners";

import styles from "./styles";

export function Partners() {
  return (
    <styles.Section>
      <H2>{t("Partner")}</H2>
      <styles.MainPartners>
        {mainPartners.map(({ name, logo }) => (
          <div key={name}>{logo}</div>
        ))}
      </styles.MainPartners>
      <styles.Partners>
        {partners.map(({ name, logo }) => (
          <div key={name}>{logo}</div>
        ))}
      </styles.Partners>
      <ButtonPrimary
        isOutlined
        name={t("Actions.BecomeAPartner")}
        handler={() => window.open(partnerFormUrl, "_blank")}
      />
    </styles.Section>
  );
}
