import { useInView } from "framer-motion";
import { t } from "i18next";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Check } from "assets/images/snap/icons/check.svg";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";

import { getData } from "./data";
import styles from "./styles";
import snapStyles from "../../styles";

export const Tariffs: React.FC = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const data = getData(navigate);

  return (
    <styles.TariffsContainer ref={ref} isInView={isInView}>
      <snapStyles.H2>{t("Snap.Tariffs.Title")}</snapStyles.H2>
      <styles.TariffBlocks>
        {data.map(({ title, description, label, tariffIncludes, button, isGradientBG }) => (
          <styles.Tariff key={title} $isGradientBG={isGradientBG}>
            <styles.TitleDescriptionLabel>
              <styles.Title>{title}</styles.Title>
              {description && <snapStyles.Description>{description}</snapStyles.Description>}
              {label && <styles.Label>{label}</styles.Label>}
            </styles.TitleDescriptionLabel>
            {Boolean(tariffIncludes?.length) && (
              <styles.TariffIncludes>
                {tariffIncludes?.map((tariffIncludesItem) => (
                  <styles.TariffIncludesItem key={tariffIncludesItem}>
                    <Check />
                    {tariffIncludesItem}
                  </styles.TariffIncludesItem>
                ))}
              </styles.TariffIncludes>
            )}
            {button && <ButtonPrimary name={button.name} handler={button.handler} />}
          </styles.Tariff>
        ))}
      </styles.TariffBlocks>
    </styles.TariffsContainer>
  );
};
