import styled, { css } from "styled-components";

import { EMPTY_STRING } from "shared/constants";

import { DotProps } from "./interfaces";

const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        margin-bottom: 1.5rem;
        justify-content: center;
      `,
    )}
`;

const Dot = styled.span<{ $isActive: boolean }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ $isActive, theme }) => ($isActive ? theme.colors.white : theme.colors.gray)};
`;

export const Dots = ({ length, activeIndex, setActiveIndex }: DotProps) => {
  return (
    <DotsWrapper>
      {new Array(length).fill(EMPTY_STRING).map((_, i) => (
        <Dot onClick={() => setActiveIndex(i)} key={i} $isActive={i === activeIndex} />
      ))}
    </DotsWrapper>
  );
};
