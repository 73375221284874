import { Route, Navigate, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { Dashboard } from "pages/Dashboard";
import { Leaderboard } from "pages/Dashboard/components/LeaderBoard";
import { Profile } from "pages/Dashboard/components/Profile";
import { ProtectedRoute } from "pages/Dashboard/components/ProtectedRoute";
import { Referrals } from "pages/Dashboard/components/Referrals";
import { Staking } from "pages/Dashboard/components/Staking";
import { loaderDashboard } from "pages/Dashboard/loader";
import { Login } from "pages/DashboardLogin";
import { HapiId } from "pages/HapiId";
import Home from "pages/Home";
import { Registration } from "pages/Registration";
import { loaderRemovedRoutes } from "pages/RemovedRoutes/loader";
import { Snap } from "pages/Snap";
import { SnapConnect } from "pages/Snap/Connect";
import { SnapOutlet } from "pages/Snap/outlet";
import { TokenUtility } from "pages/TokenUtility";
import { Providers } from "shared/providers";

import { APP_ROUTES, REMOVED_APP_ROUTES } from "./appRoutes";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Providers />}>
      <Route path={APP_ROUTES.HOME} element={<Home />} />
      <Route path={APP_ROUTES.HAPI_ID} element={<HapiId />} />
      <Route path={APP_ROUTES.TOKEN_UTILITY} element={<TokenUtility />} />

      <Route loader={loaderDashboard}>
        <Route path={APP_ROUTES.REGISTRATION + APP_ROUTES.INVITE_CODE} element={<Registration />} />
        <Route path={APP_ROUTES.DASHBOARD.LOGIN} element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path={APP_ROUTES.DASHBOARD.HOME} element={<Dashboard />}>
            <Route path={APP_ROUTES.DASHBOARD.HOME} element={<Profile />} />
            <Route path={APP_ROUTES.DASHBOARD.STAKING} element={<Staking />} />
            <Route path={APP_ROUTES.DASHBOARD.REFERRALS} element={<Referrals />} />
            <Route path={APP_ROUTES.DASHBOARD.LEADERBOARD} element={<Leaderboard />} />
          </Route>
        </Route>
      </Route>

      <Route path={APP_ROUTES.SNAP.HOME} element={<SnapOutlet />}>
        <Route path={APP_ROUTES.SNAP.HOME} element={<Snap />} />
        <Route path={APP_ROUTES.SNAP.CONNECT} element={<SnapConnect />} />
      </Route>

      {Object.values(REMOVED_APP_ROUTES).map((route) => (
        <Route key={route} path={route} loader={loaderRemovedRoutes} />
      ))}

      <Route path={APP_ROUTES.DEFAULT} element={<Navigate replace to={APP_ROUTES.HOME} />} />
    </Route>,
  ),
);
