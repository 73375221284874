import { Provider } from "react-redux";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { Layout } from "pages/Layout";
import theme from "shared/theme";
import store from "store";

import { CommonProvider } from "./common";
import { RefsProvider } from "./refs";
import { ToolsProvider } from "./tools";
import { WalletProvider } from "./wallet";

export function Providers() {
  return (
    <WalletProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <RefsProvider>
            <ToolsProvider>
              <CommonProvider>
                <Layout>
                  <Outlet />
                </Layout>
              </CommonProvider>
            </ToolsProvider>
          </RefsProvider>
        </ThemeProvider>
      </Provider>
    </WalletProvider>
  );
}
