import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { refApi } from "services/api/hapi/ref";
import { logger } from "services/mixpanel";
import { EClaimStage } from "shared/components/modals/ClaimReferralRewards/types";
import { EMPTY_STRING, LOCAL_STORAGE_KEY } from "shared/constants";
import { EStakeUnstakeStage } from "shared/interfaces/modal";
import { IStakingState } from "shared/interfaces/staking";
import { displayAmount } from "shared/utils/displayAmount";
import { RootState } from "store";
import { loadTokenData } from "store/actions/view/loadTokenData";
import { getReward } from "store/actions/view/staking/getReward";
import { getStakedAmount } from "store/actions/view/staking/getStakedAmount";
import { getStaker } from "store/actions/view/staking/getStaker";
import { getStakingFieldsData } from "store/actions/view/staking/getStakingFieldsData";
import { getStakingToken } from "store/actions/view/staking/getStakingToken";
import { getTierAPY } from "store/actions/view/staking/getTierApy";

const initialState: IStakingState = {
  loading: true,
  stakingStage: EStakeUnstakeStage.ENABLE_SPENDING,
  claimingStage: EClaimStage.CLAIM,
  reward: { value: BigInt(0), formatted: EMPTY_STRING },
  user: {
    jwtToken: localStorage.getItem(LOCAL_STORAGE_KEY.REGISTRATION_TOKEN_KEY),
    data: null,
  },
};

export const stakingSlice = createSlice({
  name: "staking",
  initialState,
  reducers: {
    setStakingStage: (state, { payload }: PayloadAction<EStakeUnstakeStage>) => {
      logger.trackEvent("STAKING_STAGE", { stage: payload });
      state.stakingStage = payload;
    },
    setClaimingStage: (state, { payload }: PayloadAction<EClaimStage>) => {
      state.claimingStage = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    logout: () => {
      localStorage.removeItem(LOCAL_STORAGE_KEY.REGISTRATION_TOKEN_KEY);
      return { ...initialState, user: { jwtToken: null, data: null } };
    },
    setStakingJWTToken: (state, { payload }: PayloadAction<string>) => {
      localStorage.setItem(LOCAL_STORAGE_KEY.REGISTRATION_TOKEN_KEY, payload);
      state.user.jwtToken = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getStakingFieldsData.fulfilled, (state, { payload }) => {
      state.stakingFieldsData = payload;
    });
    builder.addCase(getReward.fulfilled, (state, { payload }) => {
      state.reward = payload;
    });
    builder.addCase(getStakedAmount.fulfilled, (state, { payload }) => {
      state.staked = payload;
    });
    builder.addCase(getStaker.fulfilled, (state, { payload }) => {
      state.staker = payload;
    });
    builder.addCase(getTierAPY.fulfilled, (state, { payload }) => {
      state.tierAPY = payload;
    });
    builder.addCase(loadTokenData.fulfilled, (state, action) => {
      if (!action.payload) return;
      const { address, decimals } = action.payload;
      if (state.stakingFieldsData?.token === address) {
        state.stakingFieldsData.minStake.formatted = displayAmount(
          state.stakingFieldsData.minStake.value.toString(),
          decimals,
        );
        state.stakingFieldsData.totalStaked.formatted = displayAmount(
          state.stakingFieldsData.totalStaked.value.toString(),
          decimals,
        );
      }
    });
    builder.addCase(getStakingToken.fulfilled, (state, action) => {
      state.tokenAddress = action.payload;
    });
    builder.addMatcher(refApi.endpoints.getDashboard.matchFulfilled, (state, action) => {
      state.user.data = action.payload.data || null;
    });
    builder.addMatcher(refApi.endpoints.sendWalletLogin.matchFulfilled, (state, action) => {
      const token = action.payload.token;
      if (!token) return;
      localStorage.setItem(LOCAL_STORAGE_KEY.REGISTRATION_TOKEN_KEY, token);
      state.user.jwtToken = token;
    });
    builder.addMatcher(refApi.endpoints.sendRefCode.matchFulfilled, (state, action) => {
      const token = action.payload.token;
      if (!token) return;
      localStorage.setItem(LOCAL_STORAGE_KEY.REGISTRATION_TOKEN_KEY, token);
      state.user.jwtToken = token;
    });
    builder.addMatcher(refApi.endpoints.sendNearLogin.matchFulfilled, (state, action) => {
      const token = action.payload.token;
      if (!token) return;
      localStorage.setItem(LOCAL_STORAGE_KEY.REGISTRATION_TOKEN_KEY, token);
      state.user.jwtToken = token;
    });
  },
});

export const selectReward = (state: RootState) => state.staking.reward;
export const selectStakingFieldsData = (state: RootState) => state.staking.stakingFieldsData;
export const selectStaked = (state: RootState) => state.staking.staked;
export const selectStaker = (state: RootState) => state.staking.staker;
export const selectStakingData = (state: RootState) => state.staking;
export const selectStakeStage = (state: RootState) => state.staking.stakingStage;
export const selectClaimStage = (state: RootState) => state.staking.claimingStage;
export const selectUserStaking = (state: RootState) => state.staking.user;
export const selectUserStakingData = (state: RootState) => state.staking.user.data;
export const selectStakeTokenAddress = (state: RootState) => state.staking.tokenAddress;

export const { setLoading, logout, setStakingStage, setClaimingStage, setStakingJWTToken } = stakingSlice.actions;
