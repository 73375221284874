import { getAccount } from "@wagmi/core";
import Big from "big.js";
import { t } from "i18next";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";

import { chains } from "services/config";
import { logger } from "services/mixpanel";
import { walletConfig } from "services/wallet";
import { IDatalist } from "shared/components/Dashboard/Datalist/interface";
import { IRadioButtons } from "shared/components/Dashboard/RadioButtons/interface";
import { ITogglerOption } from "shared/components/Dashboard/Toggler/interface";
import { SEC } from "shared/constants";
import { IStakingFieldsData, LockupTypeEnum, ITierAPY } from "shared/interfaces/staking";

import { StakingViews } from "./types";
import { calculateAPY, calculateRewardPer30Days } from "./utils";

export const togglerOptions = (
  view: StakingViews,
  setView: Dispatch<SetStateAction<StakingViews>>,
): ITogglerOption[] => {
  return [
    {
      title: t("Dashboard.Staking.Navigation.Stake"),
      handler: () => {
        logger.trackEvent("SET_STAKING_VIEW", { view: "stake" });
        setView("stake");
      },
      isActive: view === "stake",
    },
    {
      title: t("Dashboard.Staking.Navigation.Unstake"),
      handler: () => {
        logger.trackEvent("SET_STAKING_VIEW", { view: "unstake" });
        setView("unstake");
      },
      isActive: view === "unstake",
    },
  ];
};

export const lockupOptions = (
  isLockup: boolean,
  setIsLockup: Dispatch<SetStateAction<boolean>>,
  isWhale: boolean,
  isHapiId: boolean,
  tierAPY: ITierAPY,
  stakingFieldsData: IStakingFieldsData,
  stakerType?: LockupTypeEnum,
): IRadioButtons["buttons"] => {
  return [
    {
      title: t("Dashboard.Staking.Lockup.None"),
      subtitle: t("Dashboard.Staking.Lockup.APY", {
        apy: calculateAPY(isWhale, isHapiId, false, tierAPY, stakingFieldsData),
      }),
      isActive: !isLockup,
      handler: () => {
        logger.trackEvent("SET_LOCKUP_PERIOD", { period: t("Dashboard.Staking.Lockup.None") });
        setIsLockup(false);
      },
      type: LockupTypeEnum.None,
      disabled: stakerType !== undefined ? stakerType === LockupTypeEnum.Lockup : undefined,
    },
    {
      title: t("Dashboard.Staking.Lockup.HalfYear"),
      subtitle: t("Dashboard.Staking.Lockup.APY", {
        apy: calculateAPY(isWhale, isHapiId, true, tierAPY, stakingFieldsData),
      }),
      isActive: isLockup,
      handler: () => {
        logger.trackEvent("SET_LOCKUP_PERIOD", { period: t("Dashboard.Staking.Lockup.HalfYear") });
        setIsLockup(true);
      },
      type: LockupTypeEnum.Lockup,
    },
  ];
};

export const stakeInfo = (minStake: string, symbol: string, apy: number, input: string): IDatalist["rows"] => {
  return [
    {
      title: t("Dashboard.Staking.Stake.Min"),
      value: `${minStake} ${symbol}`,
    },
    {
      title: t("Dashboard.Staking.APY.Title"),
      value: t("Dashboard.Staking.APY.Value", { apy }),
    },
    {
      title: t("Dashboard.Staking.Stake.EstimatedEarnings.Title"),
      value: t("Dashboard.Staking.Stake.EstimatedEarnings.Value", {
        amount: calculateRewardPer30Days(Big(input || 0), apy),
        symbol,
      }),
    },
  ];
};

export const unstakeInfo = (
  unlockTimestamp: number,
  unlockClaimTimestamp: number,
  amountToClaim: string,
  symbol: string,
  claimHandler: () => void,
  lockupType: LockupTypeEnum,
): IDatalist["rows"] => {
  const { isConnected, chainId } = getAccount(walletConfig);
  const isValidChain = chainId === chains[0].id;

  const now = Date.now();
  const isClaimUnlocked = now / SEC > unlockClaimTimestamp;
  const pendingRelease = !isClaimUnlocked ? amountToClaim : 0;
  const withdrawalAmount = isClaimUnlocked ? amountToClaim : 0;
  const basicUnstakeInfo = [
    {
      title: t("Dashboard.Staking.Unstake.PendingRelease"),
      value: `${pendingRelease} ${symbol}`,
      subvalue: !isClaimUnlocked
        ? t("Dashboard.Staking.Unstake.AvailableIn", { value: moment.unix(unlockClaimTimestamp).fromNow() })
        : undefined,
    },
    {
      title: t("Dashboard.Staking.Unstake.Withdrawal"),
      value: `${withdrawalAmount} ${symbol}`,
      button:
        isClaimUnlocked && Number(amountToClaim)
          ? {
              name: t("Actions.Claim"),
              handler: claimHandler,
              disabled: !isValidChain || !isConnected,
            }
          : undefined,
    },
  ];
  const lockupInfo = [
    {
      title: t("Dashboard.Staking.Unstake.UnlockOn"),
      value: moment.unix(unlockTimestamp).format("lll").toString(),
    },
  ];

  return lockupType === LockupTypeEnum.Lockup && unlockTimestamp > 0
    ? lockupInfo.concat(basicUnstakeInfo)
    : basicUnstakeInfo;
};
