import { QueryClient } from "@tanstack/react-query";
import { injected } from "@wagmi/core";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { fallback, http } from "viem";
import { mainnet, sepolia } from "viem/chains";
import { unstable_connector } from "wagmi";

import hapiLogo from "assets/logo/hapi.svg";
import { chains, walletConnectProjectId as projectId } from "services/config";

const queryClient = new QueryClient();

const metadata = {
  name: "HAPI ID",
  description: "HAPI ID",
  url: "https://hapi.one/",
  icons: [hapiLogo],
};

const walletConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  transports: {
    [mainnet.id]: fallback([
      http(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`),
      unstable_connector(injected),
    ]),
    [sepolia.id]: fallback([
      http(`https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`),
      unstable_connector(injected),
    ]),
  },
});

createWeb3Modal({
  wagmiConfig: walletConfig,
  projectId,
  enableAnalytics: true,
});

export { queryClient, walletConfig };
