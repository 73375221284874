import axios, { AxiosResponse } from "axios";

const api = axios.create({
  baseURL: "https://research.hapilabs.one/v1/",
});

api.interceptors.request.use((config) => {
  config.url = `/${config.url}`;
  return config;
});

interface ISnapApiResponse {
  error: number;
  apikey: string;
  message: string;
}

class HapilabsService {
  async getSnapApi(account: string): Promise<AxiosResponse<ISnapApiResponse>> {
    return api.get<ISnapApiResponse>(`getsnapapi/${account}`);
  }
}

const hapilabsService = new HapilabsService();
export default hapilabsService;
