import { DEFAULT_MODAL_WIDTH_IN_REM } from "shared/constants";
import { IModalProps } from "shared/interfaces/modal";
import { backgroundLayoutVariants } from "shared/theme/animations";

import styles from "./styles";

export default function ModalWrapper({
  children,
  closeModal,
  widthInRem = DEFAULT_MODAL_WIDTH_IN_REM,
  isCornerPosition = false,
  bgColor,
  clearPadding = false,
}: IModalProps) {
  return isCornerPosition ? (
    <styles.Modal
      onClick={(e) => e.stopPropagation()}
      variants={backgroundLayoutVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      $widthInRem={widthInRem}
      $isCornerPosition={isCornerPosition}
    >
      {children}
    </styles.Modal>
  ) : (
    <styles.BackgroundLayout
      onClick={closeModal}
      variants={backgroundLayoutVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <styles.Modal
        onClick={(e) => e.stopPropagation()}
        variants={backgroundLayoutVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        $widthInRem={widthInRem}
        $bgColor={bgColor}
        $clearPadding={clearPadding}
      >
        {children}
      </styles.Modal>
    </styles.BackgroundLayout>
  );
}
