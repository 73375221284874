import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { botName } from "services/config";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { Warning } from "shared/components/Warning";
import { EMPTY_STRING, SEARCH_PARAMS } from "shared/constants";
import { useLoggerNavigate } from "shared/hooks/useLoggerNavigate";
import { ERegistrationConnectorType } from "shared/interfaces";
import { IAccountDuplicationModalProps } from "shared/interfaces/modal";
import theme from "shared/theme";

import styles from "./styles";

export function AccountDuplicationModal({ closeModal, type }: IAccountDuplicationModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useLoggerNavigate();

  const close = useCallback(() => {
    searchParams.delete(SEARCH_PARAMS.ERROR);
    setSearchParams(searchParams);
    closeModal();
  }, [closeModal, searchParams, setSearchParams]);

  const restartHandler = async () => {
    if (type === ERegistrationConnectorType.Telegram) {
      const iframe = document.getElementById(`telegram-login-${botName}`) as HTMLIFrameElement | null;
      if (iframe) iframe.src += EMPTY_STRING;
    }
    close();
  };

  const loginHandler = async () => {
    close();
    navigate(APP_ROUTES.DASHBOARD.LOGIN);
  };

  useEffect(() => {
    if (location.pathname !== APP_ROUTES.REGISTRATION) close();
  }, [location, close]);

  return (
    <ModalWrapper closeModal={close} bgColor={theme.colors.registerCardBg} widthInRem="23.5">
      <styles.Container>
        <styles.Header>
          <styles.Title>{t("Registry.AccountDuplication.Title", { type })}</styles.Title>
          <styles.Description>{t("Registry.AccountDuplication.Description")}</styles.Description>
          <styles.Close>
            <CloseIcon onClick={close} />
          </styles.Close>
        </styles.Header>
        {type === ERegistrationConnectorType.Telegram && (
          <Warning
            title={t("Registry.AccountDuplication.Note.Title")}
            description={t("Registry.AccountDuplication.Note.Description")}
          />
        )}
        <styles.ButtonContainer>
          <ButtonPrimary name={t("Actions.RestartRegistration")} handler={restartHandler} isOutlined />
          <ButtonPrimary name={t("Actions.Login")} handler={loginHandler} />
        </styles.ButtonContainer>
      </styles.Container>
    </ModalWrapper>
  );
}
