import { t } from "i18next";

import { getData } from "./data";
import styles from "./styles";

export default function Networks() {
  const networks = getData();

  return (
    <styles.NetworksContainer>
      <styles.Title>{t("HapiId.Networks.Title")}</styles.Title>
      <styles.Networks>
        {networks.map(({ name, icon }) => (
          <styles.Network key={name}>
            {icon}
            <span>{name}</span>
          </styles.Network>
        ))}
      </styles.Networks>
    </styles.NetworksContainer>
  );
}
