import { css, styled } from "styled-components";

const RightSideContainer = styled.div`
  gap: 1.5rem;
  display: flex;
  width: 55.75rem;
  margin-bottom: 4.5rem;
  flex-direction: column;
  max-width: calc(100% - 16.875rem);

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        max-width: 100%;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        margin-bottom: 4rem;
      `,
    )};
`;

const ReferralsContainer = styled.div`
  gap: 3rem;
  display: flex;
  flex-direction: column;
`;

export default {
  RightSideContainer,
  ReferralsContainer,
};
