import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Background } from "shared/components/Document/styles";
import { Footer } from "shared/components/Footer";
import { Header } from "shared/components/Header";
import { Blog, MainInfo, Partners, Plans, Team, Tools } from "shared/components/Home";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useCommonContext } from "shared/providers/common";
import { useRefsContext } from "shared/providers/refs";
import { getTicketStats } from "store/actions/view/getTicketStats";

import styles from "./styles";

export default function Home() {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const { showAppOverlay } = useCommonContext();
  const { scrollToBlock } = useRefsContext();

  const ref = state?.ref;

  useEffect(() => {
    dispatch(getTicketStats());
  }, [dispatch]);

  useEffect(() => {
    if (!ref) return;

    scrollToBlock(ref);
  }, [ref, scrollToBlock]);

  return (
    <styles.Container>
      <Header />
      <styles.HomeContainer>
        <MainInfo />
        <Tools />
        <Plans />
        <Team />
        <Blog />
        <Partners />
      </styles.HomeContainer>
      {showAppOverlay && <Background />}
      <Footer />
    </styles.Container>
  );
}
