import type { TelegramUser } from "shared/components/Buttons/TelegramButton";
import { API_METHODS, API_ROUTES } from "shared/interfaces";
import type {
  TokenResponse,
  BasicResponse,
  DashboardResponse,
  ReferralsResponse,
  CheckTokenResponse,
  LeaderboardResponse,
  HapiPriceResponse,
  GetRefCodeResponse,
  ClaimRewardResponse,
  RewardStatsResponse,
  LoginResponse,
  GetMessageResponse,
  OnboardingResponse,
  HapiIdStatsResponse,
} from "shared/interfaces/api";

import { hapiApi } from "./index";

export const refApi = hapiApi.injectEndpoints({
  endpoints: (builder) => ({
    //POST
    sendRefCode: builder.mutation<TokenResponse, { code: string }>({
      query: (body) => ({
        url: API_ROUTES.REF_ENTER,
        method: API_METHODS.POST,
        body,
      }),
      invalidatesTags: ["ref"],
    }),

    sendTwitterData: builder.mutation<BasicResponse, { access_token: string }>({
      query: (body) => ({
        url: API_ROUTES.REF_CONNECT_TWITTER,
        method: API_METHODS.POST,
        body,
      }),
      invalidatesTags: ["ref"],
    }),

    sendTelegramData: builder.mutation<TokenResponse, TelegramUser>({
      query: (body) => ({
        url: API_ROUTES.REF_CONNECT_TELEGRAM,
        method: API_METHODS.POST,
        body,
      }),
      invalidatesTags: ["ref"],
    }),

    sendWalletData: builder.mutation<BasicResponse, { address: string; signature: string; message: string }>({
      query: (body) => ({
        url: API_ROUTES.REF_CONNECT_WALLET,
        method: API_METHODS.POST,
        body,
      }),
      invalidatesTags: ["ref"],
    }),

    sendWalletLogin: builder.mutation<LoginResponse, { address: string; signature: string; message: string }>({
      query: (body) => ({
        url: API_ROUTES.REF_LOGIN_WALLET,
        method: API_METHODS.POST,
        body,
      }),
      invalidatesTags: ["ref"],
    }),

    sendNearLogin: builder.mutation<LoginResponse, { userid: string; signature: string; publickey: string }>({
      query: (body) => ({
        url: API_ROUTES.REF_LOGIN_NEAR,
        method: API_METHODS.POST,
        body,
      }),
      invalidatesTags: ["ref"],
    }),

    sendNearWalletData: builder.mutation<BasicResponse, { userid: string; signature: string; publickey: string }>({
      query: (body) => ({
        url: API_ROUTES.REF_CONNECT_NEAR,
        method: API_METHODS.POST,
        body,
      }),
      invalidatesTags: ["ref"],
    }),

    sendUsername: builder.mutation<BasicResponse, { username: string }>({
      query: (body) => ({
        url: API_ROUTES.REF_SET_USERNAME,
        method: API_METHODS.POST,
        body,
      }),
      invalidatesTags: ["ref"],
    }),

    claimReward: builder.mutation<ClaimRewardResponse, { address: string; signature: string; message: string }>({
      query: (body) => ({
        url: API_ROUTES.REF_CLAIM_REWARD,
        method: API_METHODS.POST,
        body,
      }),
      invalidatesTags: ["ref"],
    }),

    sendUpdateCode: builder.mutation<BasicResponse, { code: string }>({
      query: (body) => ({
        url: API_ROUTES.REF_UPDATE_CODE,
        method: API_METHODS.POST,
        body,
      }),
      invalidatesTags: ["ref"],
    }),

    //GET
    //todo: check Error 401
    getOnboarding: builder.query<OnboardingResponse, void>({
      query: () => API_ROUTES.REF_ONBOARDING,
      providesTags: ["ref"],
    }),

    getNonceMessage: builder.query<GetMessageResponse, void>({
      query: () => API_ROUTES.REF_GET_MESSAGE,
      providesTags: ["ref"],
    }),

    //todo: check Error 401
    getDashboard: builder.query<DashboardResponse, void>({
      query: () => API_ROUTES.REF_DASHBOARD,
      providesTags: ["ref"],
    }),

    getReferrals: builder.query<ReferralsResponse, void>({
      query: () => API_ROUTES.REF_REFERRALS,
      providesTags: ["ref"],
    }),

    //todo: check Error 401
    checkToken: builder.query<CheckTokenResponse, void>({
      query: () => API_ROUTES.REF_CHECK_TOKEN,
      providesTags: ["ref"],
    }),

    getLeaders: builder.query<LeaderboardResponse, void>({
      query: () => API_ROUTES.REF_LEADERBOARD,
      providesTags: ["ref"],
    }),

    getHapiPrice: builder.query<HapiPriceResponse, void>({
      query: () => API_ROUTES.REF_HAPI_PRICE,
      providesTags: ["ref"],
    }),

    getRefCode: builder.query<GetRefCodeResponse, { wallet: string; auth: string }>({
      query: (body) => ({
        url: API_ROUTES.GET_REF_CODE,
        body,
      }),
      providesTags: ["ref"],
    }),

    getRefRewardStats: builder.query<RewardStatsResponse, void>({
      query: () => ({
        url: API_ROUTES.GET_REF_REWARD_STATS,
      }),
      providesTags: ["ref"],
    }),

    getRefHapiIdStats: builder.query<HapiIdStatsResponse, void>({
      query: () => ({
        url: API_ROUTES.GET_REF_HAPI_ID_STATS,
      }),
      providesTags: ["ref"],
    }),
  }),
});

export const {
  //APP_ROUTES.REGISTRY
  useSendTwitterDataMutation,
  useSendTelegramDataMutation,
  useSendWalletDataMutation,
  useSendRefCodeMutation,
  useSendUpdateCodeMutation,
  //APP_ROUTES.LOGIN
  useSendWalletLoginMutation,
  useSendNearLoginMutation,
  //APP_ROUTES.DASHBOARD
  useSendNearWalletDataMutation,
  useSendUsernameMutation,
  useGetOnboardingQuery,
  useLazyGetOnboardingQuery,
  useGetNonceMessageQuery,
  useLazyGetNonceMessageQuery,
  useGetDashboardQuery,
  useLazyGetDashboardQuery,
  useGetReferralsQuery,
  useLazyGetReferralsQuery,
  useGetLeadersQuery,
  useLazyGetLeadersQuery,
  useGetHapiPriceQuery,
  useLazyGetHapiPriceQuery,
  useGetRefCodeQuery,
  useLazyGetRefCodeQuery,
  useLazyGetRefRewardStatsQuery,
  useGetRefRewardStatsQuery,
  useLazyGetRefHapiIdStatsQuery,
  useGetRefHapiIdStatsQuery,
} = refApi;
