import styled from "styled-components";

const NavContainer = styled.div`
  left: 0;
  top: 50%;
  gap: 1rem;
  z-index: 9;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  transform: translateY(-50%);

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      `
        justify-content: flex-end;
      `,
    )};
`;

const styles = {
  NavContainer,
};

export default styles;
