import { createAsyncThunk } from "@reduxjs/toolkit";
import { readContract } from "@wagmi/core";
import { Address } from "viem";

import { walletConfig } from "services/wallet";
import { IStakingAmount } from "shared/interfaces/staking";
import { displayAmount } from "shared/utils/displayAmount";
import type { ThunkApiConfig } from "store";
import { stakingConfig } from "store/config";

export const getStakedAmount = createAsyncThunk<IStakingAmount, { address: Address }, ThunkApiConfig>(
  "staking/getStakedAmount",
  async ({ address }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const state = getState();
      const tokenAddress = state.staking.stakingFieldsData?.token;

      const staked = await readContract(walletConfig, {
        ...stakingConfig,
        functionName: "getStake",
        args: [address],
      });

      if (tokenAddress) {
        const tokenDecimals = state.tokens?.[tokenAddress].decimals;
        return fulfillWithValue({ value: staked, formatted: displayAmount(staked.toString(), tokenDecimals) });
      } else {
        return fulfillWithValue({ value: staked, formatted: "0" });
      }
    } catch (e) {
      console.error(`Error getting staked: \n ${e}`);
      return rejectWithValue(null);
    }
  },
);
