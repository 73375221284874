import { t } from "i18next";

import { ReactComponent as Leaderboard } from "assets/icons/dashboard/leaderboard.svg";
import { ReactComponent as Members } from "assets/icons/dashboard/members.svg";
import { ReactComponent as File } from "assets/icons/file.svg";
import theme from "shared/theme";

export const getData = (maxScore: string, totalUsers: string, issued: string) => [
  {
    value: maxScore,
    title: t("HapiId.Statistic.Data.MaxTrustScore"),
    icon: <Leaderboard />,
    color: theme.colors.orangeRed,
    background: theme.colors.beetroot,
  },
  {
    value: totalUsers,
    title: t("HapiId.Statistic.Data.TotalUsers"),
    icon: <Members />,
    color: theme.colors.blueViolet,
    background: theme.colors.nightSky,
  },
  {
    value: issued,
    title: t("HapiId.Statistic.Data.AttestationsIssued"),
    icon: <File />,
    color: theme.colors.orange,
    background: theme.colors.wetSand,
  },
];
