import styled, { css } from "styled-components";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.whiteOp1};
  }
`;

const TBody = styled.tbody`
  p {
    margin: 0;
  }
  tr {
    & > td:first-child {
      color: ${({ theme }) => theme.colors.grayLight};
      vertical-align: baseline;
    }
    &:last-child {
      border-bottom: none;
    }
  }
`;

const THead = styled.thead`
  th {
    position: relative;
    z-index: 2;
    p {
      margin-block-start: 0px;
      margin-block-end: 0px;
      margin-inline-start: 0px;
      margin-inline-end: 0px;

      &:nth-child(2) {
        color: ${({ theme }) => theme.colors.hapiGrey5};
        font-family: ${({ theme }) => theme.fonts.inter};
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        span {
          color: ${({ theme }) => theme.colors.orange};
          font-family: ${({ theme }) => theme.fonts.inter};
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: 160%;
        }
      }
    }

    &:nth-child(3) > p > span {
      color: ${({ theme }) => theme.colors.blueViolet};
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        th:nth-child(2) > p {
          color: ${({ theme }) => theme.colors.hapiGrey5};
          text-align: right;
          font-family: ${({ theme }) => theme.fonts.inter};
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 108%;
          span {
            color: ${({ theme }) => theme.colors.orange};
            font-family: ${({ theme }) => theme.fonts.inter};
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 108%;
          }
        }
      `,
    )};
`;

const th = styled.th<{ $spanColor?: string }>`
  padding: 0 1rem 1.5rem 0;
  p {
    color: ${({ theme }) => theme.colors.white};
    text-align: start;
    font-family: ${({ theme }) => theme.fonts.archivoBlack};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    ${({ theme, $spanColor }) =>
      theme.mediaWidth(
        "upToSmall",
        css`
          font-size: 1rem;
          span {
            color: ${$spanColor} !important;
          }
        `,
      )};
  }
`;

const td = styled.td`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  padding: 1.5rem 1rem 1.5rem 0;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 0.875rem;
      `,
    )};
`;

export default {
  Table,
  TBody,
  THead,
  th,
  td,
};
