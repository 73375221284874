import { ColumnDef } from "@tanstack/react-table";
import { t } from "i18next";

import { ReactComponent as Sim } from "assets/icons/dashboard/small-sim.svg";
import { EMPTY_STRING, ITEMS_PER_LEADER_PAGE_DEFAULT } from "shared/constants";
import { ColumnsOptions } from "shared/interfaces";
import { ILeader } from "shared/interfaces/staking";
import { displayAmountWithSpace } from "shared/utils/displayAmount";

import styles from "./styles";
import { dataByRank } from "./utils";

export const columns = (): ColumnDef<ILeader>[] => [
  {
    accessorKey: "rank",
    header: () => <styles.THead $textAlign="start">{t("Dashboard.Leaderboard.Rank")}</styles.THead>,
    cell: ({ row }) => {
      const { rank, me } = row.original;
      const { color, isWinner } = dataByRank(rank);
      return (
        <styles.TableRow $textAlign="start" $bold={me}>
          <styles.Winners $color={color}>
            {rank || "..."}
            {isWinner && <Sim />}
          </styles.Winners>
        </styles.TableRow>
      );
    },
  },
  {
    accessorKey: "name",
    header: () => <styles.THead $textAlign="start">{t("Dashboard.Leaderboard.Name")}</styles.THead>,
    cell: ({ row }) => {
      const { username, is_whale, me } = row.original;
      return (
        <styles.TableRow $textAlign="start" $bold={me}>
          {username}
          {is_whale && <styles.WhaleIcon />}
        </styles.TableRow>
      );
    },
  },
  {
    accessorKey: "staked",
    header: () => <styles.THead $textAlign="end">{t("Dashboard.Leaderboard.Stake")}</styles.THead>,
    cell: ({ row }) => {
      const { staked, me } = row.original;
      return (
        <styles.TableRow $textAlign="end" $bold={me}>
          {staked ? displayAmountWithSpace(staked) : EMPTY_STRING}
        </styles.TableRow>
      );
    },
  },
];

//my array index if I'm not in the TOP leaders
export const INDEX_ELEVEN = 11;

export const emptyLeader: ILeader = {
  username: EMPTY_STRING,
  rank: NaN,
  staked: EMPTY_STRING,
  is_whale: false,
  me: false,
};

export const skeletonLeaderboard = (itemPerPage = ITEMS_PER_LEADER_PAGE_DEFAULT): Array<ILeader> =>
  Array.from(Array(itemPerPage).map(() => emptyLeader));

export const leaderboardColumnsOption: ColumnsOptions = {
  0: {
    width: 1.5,
  },
  1: {
    minWidth: 3,
    maxWidth: 6,
    isRandomWidth: true,
  },
  2: {
    minWidth: 3,
    maxWidth: 5.5,
    isRandomWidth: true,
  },
};
