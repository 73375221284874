export enum ETwitterApiRoutes {
  TOKEN = "/2/oauth2/token",
}

export interface IConnectedData {
  inviteCode: string;
  x: string | null;
  telegram: string | null;
  wallet: string | null;
}

export enum ERegistrationConnectorType {
  X = "X",
  Telegram = "Telegram",
}
