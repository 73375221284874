import { EMPTY_STRING } from "shared/constants";
import { dynamicValues } from "shared/constants/main";
import { toInternationalCurrencySystem } from "shared/utils/calculation";

export const getDetailsValuesArray = (values: typeof dynamicValues) => {
  return values.map((element) => {
    const to = toInternationalCurrencySystem(element.value);
    const suffix = `${to.suffix || EMPTY_STRING}${element.suffix || EMPTY_STRING}`;
    return {
      ...element,
      to: to.value,
      suffix,
    };
  });
};
