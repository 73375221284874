import React, { createContext, PropsWithChildren, useContext, useRef } from "react";

import { toolsData } from "shared/data/tools";
import { useScrollTo } from "shared/hooks/useScrollTo";

const openedToolsInitial: { [key: string]: boolean } = Object.fromEntries(
  Array.from({ length: toolsData.length }, (_, index) => [`${index}`, false]),
);
interface IToolsContext {
  toolRefs: React.RefObject<HTMLDivElement>[];
  openTool: (index: number) => void;
  closeTool: (index: number) => void;
  openedTools: typeof openedToolsInitial;
}

export const ToolsContext = createContext<IToolsContext>({} as IToolsContext);

export const useToolsContext = () => useContext(ToolsContext);

export function ToolsProvider({ children }: PropsWithChildren) {
  const [openedTools, setOpenedTools] = React.useState<typeof openedToolsInitial>(openedToolsInitial);

  const { scrollTo } = useScrollTo();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const toolRefs = Array.from({ length: toolsData.length }, () => useRef<HTMLDivElement>(null));

  function openTool(index: number) {
    const newOpenedTools = { ...openedTools };

    for (const key in openedTools) {
      newOpenedTools[key] = key === index.toString();
    }

    setOpenedTools(newOpenedTools);

    setTimeout(() => {
      scrollTo(toolRefs[index]);
    }, 100);
  }

  function closeTool(index: number) {
    const newOpenedTools = { ...openedTools };
    newOpenedTools[index] = false;
    setOpenedTools(newOpenedTools);
  }

  return (
    <ToolsContext.Provider
      value={{
        toolRefs,
        openTool,
        closeTool,
        openedTools,
      }}
    >
      {children}
    </ToolsContext.Provider>
  );
}
