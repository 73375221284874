import styled, { css } from "styled-components";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  align-self: center;
  max-width: 68.25rem;
  width: 100%;

  h2 {
    margin: 0;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        max-width: 20.5rem;
      `,
    )}
`;

const TableTemplate = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MainPartners = styled(TableTemplate)`
  max-width: 59.625rem;
  gap: 0 4.5rem;
  div {
    width: 16.875rem;
    height: 11.25rem;
    svg {
      width: 100%;
    }
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 0 1rem;
        div {
          width: 9.75rem;
          height: 6.5rem;
        }
      `,
    )}
`;

const Partners = styled(TableTemplate)`
  gap: 0 3rem;
  div {
    width: 11.25rem;
    height: 7.5rem;
    svg {
      width: 100%;
    }
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 0 1.25rem;
        div {
          width: 6rem;
          height: 4rem;
        }
      `,
    )}
`;

export default {
  Section,
  MainPartners,
  Partners,
};
