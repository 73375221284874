import { t } from "i18next";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { ENavigationListKeys } from "shared/components/Header/components/Navigation/enum";
import { Submenu } from "shared/components/Header/components/Submenu";
import { ISubmenuOptionsProps } from "shared/components/Header/components/Submenu/interface";
import styles from "shared/components/Header/components/Submenu/options/Tools/styles";
import { toolsData } from "shared/data/tools";
import { useToolsContext } from "shared/providers/tools";

export function ToolsSubmenu({ toggleSubmenu }: ISubmenuOptionsProps) {
  const { openTool } = useToolsContext();

  function scrollToTool(index: number) {
    toggleSubmenu && toggleSubmenu(ENavigationListKeys.Tools, true);
    openTool(index);
  }

  return (
    <Submenu isFullWidth>
      <styles.ToolsContainer>
        {toolsData.map((tool, index) => (
          <styles.Tool key={tool.key} onClick={() => scrollToTool(index)}>
            <styles.TitleShortDescription>
              <styles.Title>{t(`${tool.key}.Title`)}</styles.Title>
              <styles.ShortDescription>{t(`${tool.key}.ShortDescription`)}</styles.ShortDescription>
            </styles.TitleShortDescription>
            <ArrowRight />
          </styles.Tool>
        ))}
      </styles.ToolsContainer>
    </Submenu>
  );
}
