import styled, { css } from "styled-components";

const Container = styled.div`
  background: ${({ theme }) => theme.colors.redError};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 0.75rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        justify-content: space-between;
      `,
    )};
`;

const Text = styled.div`
  display: flex;
  gap: 0.38rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 130%;
  span {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 140%;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        flex-direction: column;
        font-size: 0.6875rem;
        span {
          font-size: 0.6875rem;
        }
      `,
    )};
`;

const Button = styled.button`
  flex-shrink: 0;
  border: none;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  height: 2rem;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};

  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 140%;
  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export default {
  Container,
  Text,
  Button,
};
