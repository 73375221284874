import styled, { keyframes } from "styled-components";

import { ReactComponent as ProcessingIcon } from "assets/icons/processing.svg";

const rotate = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;

const dots = keyframes`
  0%, 25% { content: ''; }
  25%, 50% { content: '.'; }
  50%, 75% { content: '..'; }
  75%, 100% { content: '...'; }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
`;

const Close = styled.div`
  height: 1rem;
  line-height: 0;
  text-align: right;

  svg {
    width: auto;
    height: 1rem;
    cursor: pointer;
    transition: ${({ theme }) => theme.transitions.hoverTransition};

    path {
      fill: ${({ theme }) => theme.colors.hapiGrey20};
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;

const IconContainer = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

const Body = styled.div`
  gap: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 1.25rem 1.25rem 1.25rem;

  button {
    height: 2.5rem;
    width: fit-content;
  }
`;

const TitleDescriptionValue = styled.div`
  width: 100%;
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.white};
`;

const Description = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  color: ${({ theme }) => theme.colors.hapiGrey5};
`;

const Value = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 140%;
  text-align: center;
`;

const ProcessingContainer = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  div {
    display: flex;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.hapiGrey5};

    &:after {
      content: "";
      width: 0.75rem;
      display: flex;
      justify-content: flex-start;
      animation: ${dots} 1.25s infinite steps(2);
    }
  }
`;

const Processing = styled(ProcessingIcon)`
  animation: ${rotate} infinite 1.25s linear;
`;

const Table = styled.div`
  gap: 0.5rem;
  display: flex;
  margin-top: 0.75rem;
  flex-direction: column;
`;

const TableRow = styled.div`
  display: flex;
  font-weight: 400;
  line-height: 140%;
  font-size: 0.875rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.whiteOp6};

  span {
    &:last-child {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Note = styled.div`
  gap: 0.75rem;
  display: flex;
  font-weight: 400;
  line-height: 140%;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray2};

  > div {
    flex: 1;
  }
`;

export default {
  Container,
  Close,
  IconContainer,
  Body,
  TitleDescriptionValue,
  Title,
  Description,
  Value,
  ProcessingContainer,
  Processing,
  Table,
  TableRow,
  Note,
};
