import { AnimatePresence } from "framer-motion";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";

import navigationItemStyles from "shared/components/Header/components/Navigation/components/NavItem/styles";
import { navList } from "shared/components/Header/components/Navigation/data";
import { gitbook } from "shared/constants";
import { useScrollTo } from "shared/hooks/useScrollTo";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { useRefsContext } from "shared/providers/refs";
import { slideDownAnimation } from "shared/theme/animations";
import { getPaymentURL } from "shared/utils/getPaymentUrl";

import styles from "./styles";
import { ENavigationListKeys } from "../Navigation/enum";

interface IBurgerMenu {
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

export function BurgerMenu({ setIsOpened }: IBurgerMenu) {
  const { scrollTo } = useScrollTo();
  const { teamRef } = useRefsContext();
  const { isSmall, isMedium } = useWindowDimensions();

  const isAdaptive = isSmall || isMedium;

  if (!isAdaptive) {
    setIsOpened(false);
    return null;
  }

  async function clickHandler(key: ENavigationListKeys) {
    switch (key) {
      case ENavigationListKeys.About:
        scrollTo(teamRef);
        break;
      case ENavigationListKeys.Docs:
        window.open(gitbook, "_blank");
        break;
      case ENavigationListKeys.BuyCrypto: {
        const paymentURL = await getPaymentURL();
        if (!paymentURL) return null;
        window.open(paymentURL, "_blank");
        break;
      }
    }
    setIsOpened(false);
  }

  return (
    <AnimatePresence>
      <styles.BurgerContainer initial="close" animate="open" variants={slideDownAnimation} exit="close">
        <styles.NavContainer>
          {navList
            .filter((navItem) => !navItem.showOnMobile)
            .map(({ icon, label, key, submenu, disabled }) => (
              <styles.NavItem
                key={key}
                withSubmenu={Boolean(submenu)}
                disabled={disabled}
                onClick={() => clickHandler(key)}
              >
                <>
                  {t(`Navigation.${key}`)}
                  {icon && icon.component}
                  {label && (
                    <navigationItemStyles.Label $colorText={label.colorText} $colorBg={label.colorBg}>
                      {label.title}
                    </navigationItemStyles.Label>
                  )}
                  {submenu && submenu.component(undefined, setIsOpened)}
                </>
              </styles.NavItem>
            ))}
        </styles.NavContainer>
      </styles.BurgerContainer>
    </AnimatePresence>
  );
}
