import React, { createContext, PropsWithChildren, useContext, useState } from "react";

interface ICommonContext {
  showAppOverlay: boolean;
  setShowAppOverlay: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CommonContext = createContext<ICommonContext>({} as ICommonContext);

export const useCommonContext = () => useContext(CommonContext);

export function CommonProvider({ children }: PropsWithChildren) {
  const [showAppOverlay, setShowAppOverlay] = useState<boolean>(false);

  return (
    <CommonContext.Provider
      value={{
        showAppOverlay,
        setShowAppOverlay,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
}
