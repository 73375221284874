import React, { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ReactComponent as ErrorIcon } from "assets/icons/error.svg";
import { INVITE_CODE } from "routes/appRoutes";
import { EMPTY_STRING, referralCodePattern } from "shared/constants";
import { validateParam } from "shared/utils";

import { IReferralCodeProps } from "./interface";
import styles from "./styles";

const ReferralCode: React.FC<IReferralCodeProps> = ({ autoFocus = true, symbolsAmount, errorMessage, onChange }) => {
  const params = useParams<{ [INVITE_CODE]: string }>();
  const validatedParam = validateParam(params[INVITE_CODE]);

  if (isNaN(symbolsAmount) || symbolsAmount < 1) {
    throw new Error("Length should be a number and greater than 0");
  }

  const inputs: JSX.Element[] = [];
  const inputsRef = useRef<Array<HTMLInputElement>>([]);

  const sendResult = () => {
    const res = inputsRef.current.map((input) => input.value).join(EMPTY_STRING);
    onChange && onChange(res);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, nextElementSibling } = event.target;

    if (value.length > 1) {
      event.target.value = value.charAt(0);
    } else if (!value.match(referralCodePattern)) {
      event.target.value = EMPTY_STRING;
      return;
    }

    nextElementSibling && (nextElementSibling as HTMLInputElement).focus();

    sendResult();
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    const target = event.target as HTMLInputElement;

    if (key !== "Backspace" || target.value !== EMPTY_STRING) return;

    if (target.previousElementSibling !== null) {
      const prevInput = target.previousElementSibling as HTMLInputElement;

      prevInput.value = EMPTY_STRING;
      prevInput.focus();
      event.preventDefault();
      sendResult();
      return;
    }

    target.value = EMPTY_STRING;
    sendResult();
  };

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => event.target.select();

  const handleOnPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const pastedValue = event.clipboardData.getData("Text");
    let currentInput = 0;

    for (let i = 0; i < pastedValue.length && currentInput < inputsRef.current.length; i++) {
      const pastedCharacter = pastedValue.charAt(i);
      const currentElement = inputsRef.current[currentInput];

      if (!currentElement.value && pastedCharacter.match(referralCodePattern)) {
        currentElement.value = pastedCharacter;

        if (currentElement.nextElementSibling) {
          (currentElement.nextElementSibling as HTMLInputElement).focus();
          currentInput++;
        }
      }
    }

    sendResult();
  };

  for (let i = 0; i < symbolsAmount; i++) {
    inputs.push(
      <input
        key={i}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        onFocus={handleOnFocus}
        onPaste={handleOnPaste}
        pattern={referralCodePattern}
        type="text"
        ref={(el: HTMLInputElement) => (inputsRef.current[i] = el)}
        maxLength={1}
        autoComplete="off"
      />,
    );
  }

  useEffect(() => {
    autoFocus && inputsRef.current[0].focus();
    if (validatedParam) {
      let currentInput = 0;

      for (let i = 0; i < validatedParam.length && currentInput < inputsRef.current.length; i++) {
        const pastedCharacter = validatedParam.charAt(i);
        const currentElement = inputsRef.current[currentInput];

        if (!currentElement.value && pastedCharacter.match(referralCodePattern)) {
          currentElement.value = pastedCharacter;

          if (currentElement.nextElementSibling) {
            (currentElement.nextElementSibling as HTMLInputElement).focus();
            currentInput++;
          }
        }
        currentElement.focus();
      }

      sendResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <styles.ReferralCodeContainer>
      <styles.InputsContainer $isError={Boolean(errorMessage)}>{inputs}</styles.InputsContainer>
      {errorMessage && (
        <styles.ErrorMessage>
          <ErrorIcon />
          {errorMessage}
        </styles.ErrorMessage>
      )}
    </styles.ReferralCodeContainer>
  );
};

export default ReferralCode;
