import { ReactComponent as FolderFigureMini } from "assets/images/folder-mini.svg";
import { ReactComponent as FolderFigure } from "assets/images/folder.svg";
import { IFolderProps } from "shared/components/Header/components/Folder/interface";
import styles from "shared/components/Header/components/Folder/styles";
import useWindowDimensions from "shared/hooks/useWindowDimensions";

export function Folder({ isHeaderAlternative }: IFolderProps) {
  const { isSmall, isMedium } = useWindowDimensions();
  const isAdaptive = isSmall || isMedium;

  return (
    <styles.FolderContainer $isHeaderAlternative={isHeaderAlternative}>
      {isAdaptive ? <FolderFigureMini /> : <FolderFigure />}
    </styles.FolderContainer>
  );
}
