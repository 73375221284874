import { IRadioButtons } from "./interface";
import styles from "./styles";

export const RadioButtons = ({ label, buttons }: IRadioButtons) => {
  return (
    <styles.RadioButtonsWrapper>
      <styles.Label>{label}</styles.Label>
      <styles.List>
        {buttons.map(({ title, subtitle, isActive, handler, disabled }) => (
          <styles.ListItem onClick={handler} $isActive={isActive} key={title} $isDisabled={disabled}>
            <div>{title}</div>
            <div>{subtitle}</div>
          </styles.ListItem>
        ))}
      </styles.List>
    </styles.RadioButtonsWrapper>
  );
};
