import { BASE_DECIMALS } from "shared/constants";

//eslint-disable-next-line @typescript-eslint/no-var-requires
const Big = require("big.js");

export const toInternationalCurrencySystem = (labelValue: string | number, precision = 0) => {
  const num = Math.abs(Number(labelValue));
  if (num >= 1.0e9) {
    return {
      value: Number((num / 1.0e9).toFixed(precision)),
      suffix: "B",
    };
  }
  if (num >= 1.0e6) {
    return {
      value: Number((num / 1.0e6).toFixed(precision)),
      suffix: "M",
    };
  }
  return {
    value: Number(num.toFixed(precision)),
  };
};

export const bigToString = (value: string, decimals = BASE_DECIMALS) => {
  return Big(value).div(Big(10).pow(decimals)).toFixed();
};

export const randomFloat = (min: number, max: number) => Math.random() * (max - min) + min;
