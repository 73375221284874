import { motion } from "framer-motion";
import styled, { css } from "styled-components";

const PlatformWrapper = styled(motion.div)<{ $bgColor: string; $width: number; $height: number }>`
  border-radius: 3.75rem;
  color: black;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  padding: 0.625rem 1.25rem;
  background-color: ${({ $bgColor }) => $bgColor};
  width: calc(${({ $width }) => $width}px + 2.5rem);
  transition: width 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 2.875rem;
      `,
    )}
  ${({ theme, $height }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 2.5rem;
        height: calc(${$height}px + 1.25rem);
      `,
    )}
`;

const Platform = styled(motion.span)`
  position: absolute;
  user-select: none;
`;

const styles = {
  Platform,
  PlatformWrapper,
};

export default styles;
