import { ToastOptions, Slide } from "react-toastify";

import theme from "./index";

export const toastOptions: ToastOptions = {
  autoClose: 3000,
  transition: Slide,
  hideProgressBar: true,
  closeButton: false,
  position: "top-center",
  style: {
    background: theme.colors.toastBg,
    borderRadius: "1rem",
    color: theme.colors.white,
    padding: ".5rem",
    width: "100%",
  },
};
