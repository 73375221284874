import { IConfig } from "./config";
import mainnet from "./env-defaults/mainnet";
import testnet from "./env-defaults/testnet";

type Environment = "staging" | "production";

const environments: Record<Environment, IConfig> = {
  production: mainnet,
  staging: testnet,
};

export const currentEnvironment: Environment = (process.env.REACT_APP_ENV as Environment) || "production";

export const {
  contractWhitelist,
  contractToken,
  contractStaking,
  walletConnectProjectId,
  chains,
  whitelistAbi,
  stakingAbi,
  payment,
  apiURL,
  nearContractId,
  mixpanelToken,
  botName,
}: IConfig = {
  ...environments[currentEnvironment],
};
