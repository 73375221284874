export const MEDIA_WIDTHS = {
  upToSmall: 728,
  upToMedium: 900,
  upToLarge: 1280,
};

export const BASE = 10;
export const SEC = 1000;
export const MIN = SEC * 60;
export const SEC_5 = SEC * 5;
export const BASE_DECIMALS = 18;
export const ZERO_STRING = "0";
export const EMPTY_STRING = "";
export const M_DASH = "—";
export const FORMAT_DOT = ".";
export const FORMAT_COMMA = ",";
export const PLATFORM_CHANGE_INTERVAL = 2000;
export const PLATFORMS_WIDTH = 289.39;
export const SMALL_PLATFORMS_WIDTH = 158;
export const PLATFORM_HEIGHT = 40;
export const LAST_CALL_TICKETS_AMOUNT = 30;
export const SEC_2 = 2000;
export const REFERRAL_CODE_SYMBOLS_AMOUNT = 6;
export const DEFAULT_MODAL_WIDTH_IN_REM = "20.5";

export const gitbook = "https://hapi-one.gitbook.io/hapi-protocol/";
export const contactUsUrl = "https://form.typeform.com/to/kzRICYpH";
export const partnerFormUrl = "https://form.typeform.com/to/MxVOnjts";
export const linkDmHapi = "https://medium.com/@dm_hapi";
export const explorerHapi = "https://explorer.hapi.one/";
export const uniswapUrl = "https://app.uniswap.org/swap";
export const MINT_HAPI_ID_HOT_WALLET = "https://t.me/herewalletbot/app?startapp=web-score-hapi-mobi";

export const ethRegex = /^(0x)?[0-9a-fA-F]{40}$/;
export const referralCodePattern = "[a-zA-Z0-9]{1}";

export const LOCAL_STORAGE_KEY = {
  REGISTRATION_TOKEN_KEY: "REGISTRATION_TOKEN_KEY",
} as const;

export const SEARCH_PARAMS = {
  SIGN_MESSAGE_MODAL_KEY: "sign_message_modal",
  ERROR: "error",
} as const;

export const hapiIdYoutubeVideoId = "f5DL201q2Z4";

export const gtagScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-1P3ZZKRK6C');
  `;

export const elementId = {
  buyHapiId: "buyHapiId",
};

export const CODE_STATUS = {
  OK: 200,
  CREATED: 201,
  UNAUTHORIZED: 401,
};

export const ITEMS_PER_LEADER_PAGE_DEFAULT = 10;

export const TOOLTIP_ID = "TOOLTIP_ID";
export const MISSING_INVITE_CODE = "NOCODE";

export const API_ERROR = {
  USER_NOT_REGISTERED: "User not registered",
};
