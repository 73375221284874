import { css, styled } from "styled-components";

const TogglerWrapper = styled.div`
  gap: 1rem;
  display: flex;
`;

const TogglerItem = styled.div<{ $isActive?: boolean }>`
  font-weight: 600;
  position: relative;
  font-size: 1.125rem;
  cursor: ${({ $isActive }) => {
    return $isActive ? "unset" : "pointer";
  }};

  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.white : theme.colors.hapiGrey40)};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1rem;
      `,
    )};

  &:hover {
    &:after {
      width: 1.3125rem;
    }
  }

  &:after {
    content: "";
    left: 50%;
    height: 1px;
    position: absolute;
    top: calc(100% + 0.25rem);
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.white};
    width: ${({ $isActive }) => ($isActive ? "1.3125rem" : 0)};
    transition: ${({ theme }) => theme.transitions.hoverTransition};
  }
`;

export default {
  TogglerWrapper,
  TogglerItem,
};
