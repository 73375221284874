import styled from "styled-components";

const Description = styled.div`
  margin: 0 auto;
  font-weight: 400;
  line-height: 160%;
  max-width: 48.5rem;
  text-align: center;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.hapiGrey5};
`;

const TitleDescription = styled.div`
  margin-bottom: 4.5rem;
  ${({ theme }) => theme.mediaWidth("upToSmall", `margin-bottom: 3rem`)};

  h2 {
    margin-bottom: 1.5rem;
  }
`;

const PlansContainer = styled.div`
  padding: 0 4rem;
  ${({ theme }) => theme.mediaWidth("upToMedium", `padding: 0 3rem;`)};
  ${({ theme }) => theme.mediaWidth("upToSmall", `padding: 0 1rem;`)};
`;

const Plan = styled.div`
  display: flex;
  padding: 1.5rem;
  border-radius: 2rem;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors.whiteOpacity02Alt};
`;

const PlansList = styled.div`
  width: 82rem;
  display: grid;
  margin: 0 auto;
  max-width: 100%;
  gap: 3rem 1.5rem;
  grid-template-columns: repeat(4, 1fr);
  ${({ theme }) => theme.mediaWidth("upToLarge", `grid-template-columns: repeat(2, 1fr);`)};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
      gap: 1rem;
      grid-template-columns: repeat(1, 1fr);
      `,
    )};
`;

const PlanTitle = styled.div`
  font-weight: 400;
  line-height: 120%;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  letter-spacing: -0.025rem;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.archivoBlack};
`;

const PlanDescription = styled.div`
  font-weight: 400;
  line-height: 160%;
  font-size: 0.875rem;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.grayLight};
`;

const ServicesList = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`;

const ServiceItem = styled.div`
  gap: 0.5rem;
  display: flex;
  font-weight: 600;
  line-height: 120%;
  font-size: 0.75rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
`;

const IconContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const PriceButtonContainer = styled.div`
  flex: 1;
  gap: 1.5rem;
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
`;

const Price = styled.div`
  font-weight: 600;
  line-height: 160%;
  text-align: center;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.white};
`;

const styles = {
  PlansContainer,
  TitleDescription,
  Plan,
  PlansList,
  PlanTitle,
  PlanDescription,
  Description,
  ServicesList,
  ServiceItem,
  IconContainer,
  PriceButtonContainer,
  Price,
};

export default styles;
