import { useInView } from "framer-motion";
import React, { useRef } from "react";
import { Trans } from "react-i18next";

import { getData } from "./data";
import styles from "./styles";
import snapStyles from "../../styles";

export const Advantages: React.FC = () => {
  const data = getData();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <styles.AdvantagesContainer ref={ref} isInView={isInView}>
      {data.map(({ title, description, icon }) => (
        <styles.AdvantagesBlock key={title}>
          <styles.TitleIcon>
            <styles.Title>{title}</styles.Title>
            {icon}
          </styles.TitleIcon>
          <snapStyles.Description>
            <Trans i18nKey={description} components={{ strong: <strong /> }} />
          </snapStyles.Description>
        </styles.AdvantagesBlock>
      ))}
    </styles.AdvantagesContainer>
  );
};
