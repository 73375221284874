import { ITogglerProps } from "./interface";
import styles from "./styles";

export const Toggler = ({ togglerOptions }: ITogglerProps) => {
  return (
    <styles.TogglerWrapper>
      {togglerOptions.map(({ title, handler, isActive }) => (
        <styles.TogglerItem onClick={handler} key={title} $isActive={isActive}>
          {title}
        </styles.TogglerItem>
      ))}
    </styles.TogglerWrapper>
  );
};
