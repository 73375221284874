import { ReactComponent as Chainalysis } from "assets/images/snap/trusted-by/chainalysis.svg";
import { ReactComponent as Google } from "assets/images/snap/trusted-by/google.svg";
import { ReactComponent as Linea } from "assets/images/snap/trusted-by/linea.svg";
import { ReactComponent as Metamask } from "assets/images/snap/trusted-by/metamask.svg";
import { ReactComponent as Near } from "assets/images/snap/trusted-by/near.svg";
import { ReactComponent as Solana } from "assets/images/snap/trusted-by/solana.svg";

export const data = [
  { key: "metamask", logo: <Metamask /> },
  { key: "near", logo: <Near /> },
  { key: "solana", logo: <Solana /> },
  { key: "google", logo: <Google /> },
  { key: "chainalysis", logo: <Chainalysis /> },
  { key: "linea", logo: <Linea /> },
];
