import { ReactComponent as Discord } from "assets/icons/socials/discord.svg";
import { ReactComponent as GitHub } from "assets/icons/socials/github.svg";
import { ReactComponent as Medium } from "assets/icons/socials/medium.svg";
import { ReactComponent as Telegram } from "assets/icons/socials/telegram.svg";
import { ReactComponent as Twitter } from "assets/icons/socials/twitter.svg";
import { createLink } from "shared/utils";

export const SOCIALS_LINKS = {
  telegram: "https://t.me/hapiHF",
  medium: "https://medium.com/i-am-hapi",
  twitter: "https://twitter.com/i_am_hapi_one",
  discord: "https://discord.com/invite/HdGXqF32Jc",
  hapi: "https://hapi.one/",
  github: "https://github.com/HAPIprotocol",
};

export const SHARE_SOCIALS_LINKS = {
  telegram: "https://telegram.me/share/url",
  twitter: "https://twitter.com/intent/tweet",
};

export enum ESocials {
  Twitter = "Twitter",
  Telegram = "Telegram",
  Medium = "Medium",
  Discord = "Discord",
  GitHub = "GitHub",
}

//TODO: change the shareLink title when someone gets older
export const socials = [
  {
    key: ESocials.Twitter,
    url: SOCIALS_LINKS.twitter,
    icon: <Twitter />,
    shareLink: (url: string) => createLink(SHARE_SOCIALS_LINKS.twitter, { url, title: "WARNING!!!!!!!!" }),
  },
  {
    key: ESocials.Telegram,
    url: SOCIALS_LINKS.telegram,
    icon: <Telegram />,
    shareLink: (url: string) => createLink(SHARE_SOCIALS_LINKS.telegram, { url, title: "WARNING!!!!!!!!" }),
  },
  {
    key: ESocials.Discord,
    url: SOCIALS_LINKS.discord,
    icon: <Discord />,
  },
  {
    key: ESocials.Medium,
    url: SOCIALS_LINKS.medium,
    icon: <Medium />,
  },
  {
    key: ESocials.GitHub,
    url: SOCIALS_LINKS.github,
    icon: <GitHub />,
  },
];
