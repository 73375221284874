import Big from "big.js";

import { ZERO_STRING } from "shared/constants";

import { BigNumber, removeTrailingZeros } from "./displayAmount";

export const getUserFriendlyAmount = (amount: number | Big | string, precision = 2) => {
  try {
    const convertedAmount = typeof amount === "string" || typeof amount === "number" ? BigNumber(amount) : amount;

    if (convertedAmount.eq(0)) return ZERO_STRING;

    if (convertedAmount.lte(0.01)) return "< 0.01";

    return removeTrailingZeros(convertedAmount.toFixed(precision));
  } catch (error) {
    return ZERO_STRING;
  }
};
