import { ReactNode } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { TOOLTIP_ID } from "shared/constants";
import { colors } from "shared/theme/colors";

import { StyledIcon } from "./styles";

export const TooltipContent = () => {
  return (
    <ReactTooltip
      id={TOOLTIP_ID}
      place="top"
      opacity={1}
      style={{
        maxWidth: "16rem",
        borderRadius: "0.5rem",
        backgroundColor: colors.surfaceWhale,
        padding: "0.5rem",
        fontSize: "0.75rem",
        fontWeight: 300,
      }}
    />
  );
};

export const Tooltip = ({ tooltip, icon = <InfoIcon /> }: { tooltip: ReactNode; icon?: JSX.Element }) => {
  const props =
    typeof tooltip === "string"
      ? {
          "data-tooltip-content": tooltip,
        }
      : {
          "data-tooltip-html": renderToStaticMarkup(tooltip),
        };
  return (
    <StyledIcon data-tooltip-id={TOOLTIP_ID} {...props}>
      {icon}
    </StyledIcon>
  );
};
