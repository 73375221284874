import { AnimatePresence } from "framer-motion";
import { PropsWithChildren, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import { contractToken } from "services/config";
import { TooltipContent } from "shared/components/Tooltip";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import theme, { GlobalStyle } from "shared/theme";
import { loadTokenData } from "store/actions/view/loadTokenData";
import { selectModalState, MODALS, closeModal } from "store/slices/modals";

export const Layout = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(selectModalState);
  const Modal = MODALS[modalState.modal];

  useEffect(() => {
    dispatch(loadTokenData({ tokenAddress: contractToken }));
  }, [dispatch]);

  return (
    <>
      <AnimatePresence>
        {Modal && <Modal closeModal={() => dispatch(closeModal())} {...modalState.props} />}
      </AnimatePresence>
      <div>
        <GlobalStyle theme={theme} />
        {children}
        <ToastContainer />
        <TooltipContent />
      </div>
    </>
  );
};
