import styled, { css } from "styled-components";

import { getFadeIn } from "shared/theme/animations";

const IntegratedWalletSecurityContainer = styled.div<{ isInView: boolean }>`
  gap: 3.75rem;
  display: flex;
  align-items: center;
  padding: 2rem 3.75rem;
  border-radius: 1.5rem;
  backdrop-filter: blur(60px);
  background: ${({ theme }) => theme.colors.snapSurfaceBG054};
  ${({ isInView }) => getFadeIn(isInView)};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        svg {
          height: auto;
          width: 18.26835rem;
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 2rem 1rem;
        flex-direction: column;

        svg {
          order: 2;
          width: 100%;
          height: auto;
        }
      `,
    )};
`;

const Info = styled.div`
  flex: 1;
  gap: 2rem;
  display: flex;
  flex-direction: column;

  button {
    width: fit-content;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        svg {
          gap: 1.5rem;
        }
      `,
    )};
`;

const DescriptionContainer = styled.div`
  gap: 1.75rem;
  display: flex;
  flex-direction: column;
`;

export default {
  IntegratedWalletSecurityContainer,
  Info,
  DescriptionContainer,
};
