import Card from "pages/HapiId/components/Card";
import Header from "pages/HapiId/components/Header";
import { Footer } from "shared/components/Footer";

import Advantages from "./components/Advantages";
import Attestations from "./components/Attestations";
import FAQ from "./components/FAQ";
import Main from "./components/Main";
import Networks from "./components/Networks";
import Statistic from "./components/Statistic";
import TrustScore from "./components/TrustScore";
import styles from "./styles";

export function HapiId() {
  return (
    <styles.Page>
      <Header />
      <styles.MainStatsNetworks>
        <Main />
        <Statistic />
        <Networks />
        <Card />
      </styles.MainStatsNetworks>
      <Advantages />
      <TrustScore />
      <Attestations />
      <FAQ />
      <Footer />
    </styles.Page>
  );
}
