import styled from "styled-components";

const Container = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  svg {
    align-self: flex-start;
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const Description = styled.div<{ $error?: boolean }>`
  font-weight: 600;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.white};
`;

const styles = {
  Container,
  Description,
};

export default styles;
