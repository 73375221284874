import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 1.5rem;

  padding: 1rem;

  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.surfaceWhale};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        flex-direction: column;
      `,
    )};
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  & > div {
    flex: 1;
    &:first-child {
      color: ${({ theme }) => theme.colors.white};
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 120%;
    }
    &:last-child {
      color: ${({ theme }) => theme.colors.hapiGrey20};
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 120%;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

export default {
  Container,
  Element,
};
