import { IFeedItem } from "services/api/interfaces";
import { EMPTY_STRING } from "shared/constants";
import { WindowDimensions } from "shared/hooks/useWindowDimensions";
import { IPost } from "shared/interfaces";

const hapiLabsLinkPath = "hapi-labs";
const iAmHapiLinkPath = "i-am-hapi";
const hapiLabs = "HAPI.Labs";
const iAmHapi = "I'm #HAPI";
const ImgRegexp = new RegExp(/<img[^>]+src="(https:\/\/[^">]+)"/g);
const SourceRegexp = new RegExp(/(https:\/\/[^">]+)/g);

const getPostPage = (link: string) => {
  const url = new URL(link);
  const postPage = url.pathname.split("/")[1];
  switch (postPage) {
    case hapiLabsLinkPath:
      return hapiLabs;
    case iAmHapiLinkPath:
      return iAmHapi;
    default:
      return EMPTY_STRING;
  }
};

const parsedDate = (date: string) => {
  const nowDate = new Date();
  const dateObj = new Date(date);
  if (nowDate.getFullYear() === dateObj.getFullYear()) {
    const longMonth = dateObj.toLocaleString("en-US", { month: "long" });
    return `${longMonth} ${dateObj.getFullYear()}`;
  }
  const shortMonth = dateObj.toLocaleString("en-US", { month: "short" });
  return `${dateObj.getDate()} ${shortMonth}`;
};

export const setupPosts = (feedItems: IFeedItem[]): IPost[] => {
  return feedItems.reverse().map((items) => ({
    title: items.title,
    author: items.author,
    url: items.link,
    publishedAt: parsedDate(items.pubDate),
    imageUrl: getImageUrl(items.description),
    postPage: getPostPage(items.link),
  }));
};

export const getImageUrl = (content: string): string => {
  const imageArrays = content.match(ImgRegexp);
  const firstImage = imageArrays?.[0];
  if (!firstImage) return "";
  return firstImage.match(SourceRegexp)?.[0] ?? "";
};

export const getPostsPerSlide = (dimension: WindowDimensions): number => {
  if (dimension.isSmall) return 1;
  if (dimension.isLarge || dimension.isMedium) return 2;
  return 3;
};
