import { createAsyncThunk } from "@reduxjs/toolkit";
import { readContract } from "@wagmi/core";
import { Address } from "viem";

import { walletConfig } from "services/wallet";
import { ThunkApiConfig } from "store";
import { stakingConfig } from "store/config";

import { loadTokenData } from "../loadTokenData";

//todo: redone after refactoring/redux
export const getStakingToken = createAsyncThunk<Address, void, ThunkApiConfig>(
  "staking/getStakingToken",
  async (_, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const tokenAddress = await readContract(walletConfig, {
        ...stakingConfig,
        functionName: "token",
      });
      dispatch(loadTokenData({ tokenAddress }));
      return fulfillWithValue(tokenAddress);
    } catch (e) {
      console.error(`Error getting staking token address: \n`, e);
      return rejectWithValue(null);
    }
  },
);
