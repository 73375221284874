import { t } from "i18next";
import { generatePath } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";
import { useGetReferralsQuery } from "services/api/hapi/ref";
import ToastService from "shared/components/Toast";
import { EMPTY_STRING } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { copy } from "shared/utils/copy";
import { selectStakingData } from "store/slices/staking";
import { selectToken } from "store/slices/token";

import styles from "./styles";
import { ReferralLinksSkeleton } from "../Skeletons";

export const ReferralLinks: React.FC = () => {
  const { stakingFieldsData } = useAppSelector(selectStakingData);
  const token = useAppSelector((state) => selectToken(state, stakingFieldsData?.token));
  const { data, isLoading } = useGetReferralsQuery();

  if (isLoading) return <ReferralLinksSkeleton />;

  const url = new URL(window.location.origin);
  const symbol = token?.symbol || EMPTY_STRING;
  const referrals = data?.data;

  const permanentCode = referrals?.permanent_code;
  const codes = referrals?.onetime_codes || [];
  const referralsL1 = referrals?.level1.referrals || [];
  const referralsL2 = referrals?.level2.referrals || [];

  const copyHandler = async (copyQuery: string) => {
    const link = generatePath(`/${APP_ROUTES.REGISTRATION}/${copyQuery}`);
    const copyState = await copy(`${url.origin}${link}`);
    copyState
      ? ToastService.success({ text: t("HapiId.UserProfile.Copied") })
      : ToastService.error({ text: t("HapiId.Toasts.CopyError") });
  };

  return (
    <styles.ReferralLinksContainer>
      <styles.Title>{t("Dashboard.Referrals.Links")}</styles.Title>
      {permanentCode && (
        <styles.PermanentCode>
          <styles.PermanentCodeLabel>{t("Dashboard.Referrals.PermanentLink")}</styles.PermanentCodeLabel>
          <styles.PermanentCodeValue>
            <span>{permanentCode}</span>
            <styles.Copy onClick={() => copyHandler(permanentCode)} />
          </styles.PermanentCodeValue>
        </styles.PermanentCode>
      )}
      {Boolean(codes.length) && !permanentCode && (
        <styles.Row>
          <styles.List>
            {codes.map((code) => (
              <styles.ListItem key={code}>
                <span>{code}</span>
                <styles.Copy onClick={() => copyHandler(code)} />
              </styles.ListItem>
            ))}
          </styles.List>
        </styles.Row>
      )}
      <styles.Row>
        <styles.List>
          {referralsL1.map(({ username, stake }) => (
            <styles.ListItem key={username}>
              <span>{username}</span>
              <span>
                {stake} {symbol}
              </span>
            </styles.ListItem>
          ))}
        </styles.List>
        <styles.List>
          {referralsL2.map(({ username, stake }) => (
            <styles.ListItem key={username}>
              <span>{username}</span>
              <span>
                {stake} {symbol}
              </span>
            </styles.ListItem>
          ))}
        </styles.List>
      </styles.Row>
    </styles.ReferralLinksContainer>
  );
};
