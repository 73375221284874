import { EMPTY_STRING } from "shared/constants";

export default function formatNumber(value: number, precision = 0) {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "k", threshold: 1e3 },
    { suffix: EMPTY_STRING, threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(value) >= x.threshold);

  if (found) return (value / found.threshold).toFixed(precision) + found.suffix;

  return value;
}
