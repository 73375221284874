import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";

import { ISubmenuProps } from "shared/components/Header/components/Submenu/interface";
import styles from "shared/components/Header/components/Submenu/styles";
import { useCommonContext } from "shared/providers/common";
import { slideDownAnimation, submenuAnimationCentered } from "shared/theme/animations";

export function Submenu({ children, isFullWidth = false, withBackground = true }: ISubmenuProps) {
  const { setShowAppOverlay } = useCommonContext();

  useEffect(() => {
    setShowAppOverlay(isFullWidth);

    return () => setShowAppOverlay(false);
  }, [isFullWidth, setShowAppOverlay]);

  if (!withBackground) return <div>{children}</div>;

  return (
    <AnimatePresence>
      <styles.SubmenuContainer
        initial="close"
        animate="open"
        variants={isFullWidth ? slideDownAnimation : submenuAnimationCentered}
        exit="close"
        $isFullWidth={isFullWidth}
        onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
      >
        {children}
        {!isFullWidth && <styles.Corner />}
      </styles.SubmenuContainer>
    </AnimatePresence>
  );
}
