import { FetchArgs, createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

import { apiURL } from "services/config";
import { CODE_STATUS } from "shared/constants";
import { RootState } from "store";

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#bailing-out-of-error-re-tries
const staggeredBaseQueryWithBailOut = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: apiURL,
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).staking.user.jwtToken;
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
      },
    })(args, api, extraOptions);

    if (result.error?.status === CODE_STATUS.UNAUTHORIZED) {
      retry.fail(result.error);
    }

    return result;
  },
  {
    maxRetries: 1,
  },
);

export const hapiApi = createApi({
  reducerPath: "hapiApi",
  baseQuery: staggeredBaseQueryWithBailOut,
  tagTypes: ["ref"],
  endpoints: () => ({}),
});
