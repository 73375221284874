import styled, { css } from "styled-components";

import { ReactComponent as ArrowLink } from "assets/icons/arrow-link.svg";

const Section = styled.section`
  padding: 0 4rem;
  ${({ theme }) => theme.mediaWidth("upToMedium", `padding: 0 1rem;`)};
`;

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-self: center;
  padding: 1rem 3rem 3rem 3rem;
  border: 2px solid ${({ theme }) => theme.colors.borderBlog};
  border-radius: 0.313rem 2rem 2rem 2rem;
  position: relative;
  width: 74.4375rem;
  & > svg {
    position: absolute;
    top: -42px;
    left: -2px;
    z-index: 1;
    path {
      stroke: ${({ theme }) => theme.colors.borderBlog};
    }
  }
  &::after {
    content: "";
    position: absolute;
    border: none;
    background: ${({ theme }) => theme.colors.hapiDark};
    top: -3px;
    left: 0px;
    width: 35.313rem;
    height: 0.313rem;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        width: 42.25rem;
        padding: 1rem;
        &::after {
          width: 27.75rem;
        }
      `,
    )}
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        &::after {
          width: 15.25rem;
        }
      `,
    )}
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        width: 21.25rem;
      `,
    )}
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  margin-bottom: 4.5rem;
`;

const Posts = styled.div`
  display: flex;
  max-width: 100%;
  margin-bottom: 2.25rem;
  overflow: hidden;
  width: 68.25rem;
  & > div {
    margin-right: 1.125rem;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        width: 38rem;
        & > div {
          margin-right: 0;
        }
      `,
    )}
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        width: 19rem;
        & > div {
          margin-right: 0;
        }
      `,
    )}
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        gap: 1.5rem;
      `,
    )}
`;

const Link = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  text-decoration: none;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.white};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export default {
  Section,
  Container,
  Title,
  Posts,
  ArrowContainer,
  Footer,
  Link,
  ArrowLink,
};
