import { EMPTY_STRING } from "shared/constants";

const STRING_TRIM_LENGTH = 45;

export const trimString = (string: string, length = STRING_TRIM_LENGTH) => {
  if (string.length > length) {
    return `${string.slice(0, length)}...`;
  }
  return string;
};

export const cropString = (query: string, step = 4, headFromIndex = 0) => {
  if (!query) return EMPTY_STRING;

  if (query.length > 20) {
    const head = query.substring(headFromIndex, step + 2);
    const tail = query.substring(query.length - step, query.length);

    return `${head}…${tail}`;
  }
  return query;
};
