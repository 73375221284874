import { QueryClientProvider } from "@tanstack/react-query";
import { PropsWithChildren } from "react";
import { WagmiProvider } from "wagmi";

import { queryClient, walletConfig } from "services/wallet";

import { WalletSelectorProvider } from "./walletSelector";
import "@near-wallet-selector/modal-ui/styles.css";

export function WalletProvider({ children }: PropsWithChildren) {
  return (
    <WagmiProvider config={walletConfig}>
      <QueryClientProvider client={queryClient}>
        <WalletSelectorProvider>{children}</WalletSelectorProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
