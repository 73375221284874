import styled, { css } from "styled-components";

import { ReactComponent as PlayVideoIcon } from "assets/icons/play-video.svg";
import { ReactComponent as HapiId } from "assets/logo/hapi-id.svg";

const MainContainer = styled.div`
  gap: 3rem;
  display: flex;
  position: relative;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        gap: 2.5rem;
      `,
    )};
`;

const Text = styled.div`
  width: 100%;
  align-self: center;
  max-width: 71.875rem;
`;

const Title = styled.div`
  font-weight: 900;
  line-height: 96%;
  font-size: 6.25rem;
  text-align: center;
  letter-spacing: -0.15rem;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        font-size: 3.75rem;
        letter-spacing: -0.075rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        line-height: 109%;
        font-size: 2.75rem;
      `,
    )};
`;

const HAPI = styled(Title)`
  gap: 0.93rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HapiIdIcon = styled(HapiId)`
  height: auto;
  width: 9.35114rem;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        width: 4.875rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        width: 3.88501rem;
      `,
    )};
`;

const Description = styled.div`
  margin: 0 auto;
  font-weight: 400;
  line-height: 140%;
  font-size: 1.25rem;
  text-align: center;
  margin-top: 1.25rem;
  max-width: 45.6875rem;
  color: ${({ theme }) => theme.colors.grayLight};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        margin-top: 0.7rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1rem;
        margin-top: 1.51rem;
      `,
    )};
`;

const WrapperButtons = styled.div`
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1rem;
      `,
    )};
`;

const styles = {
  MainContainer,
  Text,
  Title,
  HAPI,
  Description,
  WrapperButtons,
  HapiIdIcon,
  PlayVideoIcon,
};

export default styles;
