import { useInView } from "framer-motion";
import { t } from "i18next";
import React, { useRef } from "react";

import { data } from "./data";
import styles from "./styles";

export const TrustedBy: React.FC = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <styles.TrustedByContainer ref={ref} isInView={isInView}>
      <styles.Title>{t("Snap.TrustedBy")}</styles.Title>
      <styles.Logos>
        {data.map(({ key, logo }) => (
          <React.Fragment key={key}>{logo}</React.Fragment>
        ))}
      </styles.Logos>
    </styles.TrustedByContainer>
  );
};
