import { createAsyncThunk } from "@reduxjs/toolkit";
import { readContract } from "@wagmi/core";
import { Address } from "viem";

import { walletConfig } from "services/wallet";
import { ZERO_STRING } from "shared/constants";
import { IStaker, LockupTypeEnum } from "shared/interfaces/staking";
import { displayAmount } from "shared/utils/displayAmount";
import type { ThunkApiConfig } from "store";
import { stakingConfig } from "store/config";

export const getStaker = createAsyncThunk<IStaker, { address: Address }, ThunkApiConfig>(
  "staking/getStaker",
  async ({ address }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const state = getState();
      const tokenAddress = state.staking.stakingFieldsData?.token;
      const tokenDecimals = state.tokens?.[tokenAddress || ZERO_STRING]?.decimals;

      const [tier, amount, stakedTimestamp, unlockTimestamp, unlockClaimTimestamp, amountToClaim] = await readContract(
        walletConfig,
        {
          ...stakingConfig,
          functionName: "stakers",
          args: [address],
        },
      );

      const formattedAmount =
        tokenAddress && tokenDecimals ? displayAmount(amount.toString(), tokenDecimals) : ZERO_STRING;
      const formattedAmountToClaim =
        tokenAddress && tokenDecimals ? displayAmount(amountToClaim.toString(), tokenDecimals) : ZERO_STRING;

      const unlockTime = Number(unlockTimestamp);
      const currentTime = Math.ceil(Date.now() / 1000);

      const type =
        amount > 0
          ? unlockTime > 0 && unlockTime > currentTime
            ? LockupTypeEnum.Lockup
            : LockupTypeEnum.None
          : undefined;

      return fulfillWithValue({
        tier,
        amount: { value: amount, formatted: formattedAmount },
        stakedTimestamp: Number(stakedTimestamp),
        unlockTimestamp: Number(unlockTimestamp),
        unlockClaimTimestamp: Number(unlockClaimTimestamp),
        amountToClaim: { value: amountToClaim, formatted: formattedAmountToClaim },
        type,
      });
    } catch (e) {
      console.error(`Error getting staker: \n ${e}`);
      return rejectWithValue(null);
    }
  },
);
