import type { AccountState, WalletSelector } from "@near-wallet-selector/core";
import { setupWalletSelector } from "@near-wallet-selector/core";
import { setupHereWallet } from "@near-wallet-selector/here-wallet";
import type { WalletSelectorModal } from "@near-wallet-selector/modal-ui";
import { setupModal } from "@near-wallet-selector/modal-ui";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import React, { useCallback, useContext, useEffect, useState, useMemo, createContext } from "react";
import type { ReactNode } from "react";
import { distinctUntilChanged, map } from "rxjs";

import { currentEnvironment, nearContractId } from "services/config";

interface WalletSelectorContextValue {
  selector: WalletSelector;
  modal: WalletSelectorModal;
  accounts: Array<AccountState>;
  accountId: string | null;
}

const WalletSelectorContext = createContext<WalletSelectorContextValue>({} as WalletSelectorContextValue);

export const WalletSelectorProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [selector, setSelector] = useState<WalletSelector | null>(null);
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const [accounts, setAccounts] = useState<Array<AccountState>>([]);

  const init = useCallback(async () => {
    const selectorInstance = await setupWalletSelector({
      network: currentEnvironment === "production" ? "mainnet" : "testnet",
      debug: true,
      modules: [setupMyNearWallet(), setupHereWallet()],
    });
    const modalInstance = setupModal(selectorInstance, {
      contractId: nearContractId,
    });
    const state = selectorInstance.store.getState();
    setAccounts(state.accounts);

    window.selector = selectorInstance;
    window.modal = modalInstance;

    setSelector(selectorInstance);
    setModal(modalInstance);
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
    });
  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    const subscription = selector.store.observable
      .pipe(
        map((state) => {
          const result = state.accounts;
          return result;
        }),
        distinctUntilChanged(),
      )
      .subscribe((nextAccounts) => {
        setAccounts(nextAccounts);
      });

    return () => subscription.unsubscribe();
  }, [selector]);

  const walletSelectorContextValue = useMemo<WalletSelectorContextValue>(
    () => ({
      selector: selector!,
      modal: modal!,
      accounts,
      accountId: accounts.find((account) => account.active)?.accountId || null,
    }),
    [selector, modal, accounts],
  );

  return <WalletSelectorContext.Provider value={walletSelectorContextValue}>{children}</WalletSelectorContext.Provider>;
};

export const useWalletSelector = () => useContext(WalletSelectorContext);
