import styled from "styled-components";

const InputErrorWrapper = styled.div`
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  color: ${({ theme }) => theme.colors.inputError};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const styles = {
  InputErrorWrapper,
};

export default styles;
