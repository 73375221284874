import styled from "styled-components";

const Toggle = styled.button<{ $isOpen?: boolean }>`
  border: none;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  transition: none;
  overflow: hidden;
  padding: 0.25rem;
  border-radius: 50%;
  margin-right: 1rem;
  display: inline-flex;
  z-index: 13 !important;
  flex-direction: column;
  background: transparent;
  justify-content: space-around;
  background-color: ${({ $isOpen, theme }) => ($isOpen ? theme.colors.hapiDark : "transparent")};
  position: ${({ $isOpen }) => ($isOpen ? "fixed" : "relative")};

  &:focus {
    outline: none;
  }

  div {
    width: 100%;
    min-height: 7px;
    position: relative;
    border-radius: 0.5rem;
    transform-origin: 1px;
    transition:
      width 200ms ease-out,
      height 0.3s linear,
      transform 0.3s linear,
      opacity 300ms linear;

    span {
      display: block;
      height: 0.125rem;
      margin: 0.125rem 0;
      border-radius: 0.25rem;
      background-color: ${({ $isOpen, theme }) => ($isOpen ? theme.colors.hapiYellow1 : theme.colors.hapiGrey5)};
    }
  }
`;

const styles = {
  Toggle,
};

export default styles;
