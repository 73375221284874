import { t } from "i18next";

import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { contactUsUrl } from "shared/constants";
import { useRefsContext } from "shared/providers/refs";

import { DynamicValue } from "./components/DynamicValue";
import { Platforms } from "./components/Platforms";
import styles from "./styles";

export function MainInfo() {
  const { toolsRef, scrollToBlock } = useRefsContext();

  return (
    <styles.Section>
      <styles.DescriptionBlock>
        <styles.Header>
          <styles.H1>
            {t("MainInfo.Secure")}
            <Platforms />
          </styles.H1>
          <styles.H1>{t("MainInfo.WithHAPITools")}</styles.H1>
        </styles.Header>
        <styles.Description>{t("MainInfo.Description")}</styles.Description>
        <styles.LogoContainer>
          <styles.Logo />
          <styles.BoxShadow />
        </styles.LogoContainer>
        <styles.ButtonGroup>
          <ButtonPrimary name={t("Actions.TryOurTools")} handler={() => scrollToBlock(toolsRef)} />
          <ButtonPrimary isOutlined name={t("Actions.ContactUs")} handler={() => window.open(contactUsUrl, "_blank")} />
        </styles.ButtonGroup>
      </styles.DescriptionBlock>
      <DynamicValue />
    </styles.Section>
  );
}
