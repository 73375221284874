import { t } from "i18next";
import { Address } from "viem";

import { ReactComponent as Check } from "assets/icons/dashboard/check.svg";
import { ReactComponent as Fail } from "assets/icons/dashboard/error.svg";
import { ReactComponent as Success } from "assets/icons/dashboard/success.svg";
import { parseTokenAmount } from "pages/Dashboard/components/Staking/utils";
import { EStakeUnstakeStage } from "shared/interfaces/modal";
import { displayAmount } from "shared/utils/displayAmount";
import { AppDispatch } from "store";
import { approve } from "store/actions/change/staking/approve";

export const getStageData = (
  input: string,
  decimals: number,
  closeAndSetInitialModal: () => void,
  onSubmit: () => void,
  isStakeView: boolean,
  dispatch: AppDispatch,
  stage?: EStakeUnstakeStage,
  address?: Address,
) => {
  if (!address) return {};
  const amount = BigInt(parseTokenAmount(input, decimals).toFixed());
  const formattedInput = displayAmount(input);

  switch (stage) {
    case EStakeUnstakeStage.ENABLE_SPENDING:
      return {
        title: t("Dashboard.Staking.Modal.Enable", { amount: formattedInput }),
        button: {
          name: t("Actions.Confirm"),
          iconRight: <Check />,
          handler: () => dispatch(approve({ address, amount })),
        },
      };
    case EStakeUnstakeStage.ENABLE_SPENDING_PROCESSING:
      return {
        title: t("Dashboard.Staking.Modal.TransactionPending"),
        description: t("Dashboard.Staking.Modal.EnableProcessing", { amount: formattedInput }),
        isLoading: true,
      };
    case EStakeUnstakeStage.STAKING_UNSTAKING:
      return {
        description: isStakeView ? t("Dashboard.Staking.Modal.Staking") : t("Dashboard.Staking.Modal.Unstaking"),
        value: t("Dashboard.Staking.Modal.TokenAmount", { amount: formattedInput }),
        button: {
          name: isStakeView ? t("Actions.Stake") : t("Actions.Unstake"),
          handler: onSubmit,
        },
      };
    case EStakeUnstakeStage.STAKING_UNSTAKING_PROCESSING:
      return {
        title: t("Dashboard.Staking.Modal.TransactionPending"),
        description: isStakeView
          ? t("Dashboard.Staking.Modal.EnabledStaking", { amount: formattedInput })
          : t("Dashboard.Staking.Modal.EnabledUnstaking", { amount: formattedInput }),
        isLoading: true,
      };
    case EStakeUnstakeStage.SUCCESS:
      return {
        icon: <Success />,
        title: isStakeView ? t("Dashboard.Staking.Modal.StakeSuccess") : t("Dashboard.Staking.Modal.UnstakeSuccess"),
        button: {
          name: t("Actions.GoToDashboard"),
          handler: closeAndSetInitialModal,
        },
        hideClose: true,
      };
    case EStakeUnstakeStage.FAIL:
      return {
        icon: <Fail />,
        title: isStakeView ? t("Dashboard.Staking.Modal.StakeError") : t("Dashboard.Staking.Modal.UnstakeError"),
        button: {
          name: t("Actions.GoToDashboard"),
          handler: closeAndSetInitialModal,
        },
        hideClose: true,
      };
    default:
      return {};
  }
};
