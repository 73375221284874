import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 28.2rem;
  width: 100%;
  button {
    width: fit-content;
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.hapiGrey20};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    line-height: 140%;
    color: ${({ theme }) => theme.colors.white};
    &:first-child {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 700;
      gap: 0.5rem;
    }
    &:last-child {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
`;

const Chip = styled.span`
  display: flex;
  padding: 0.25rem 0.375rem;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.colors.chipBg};
  color: ${({ theme }) => theme.colors.hapiGrey20};
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 140%;
`;

export default {
  Container,
  Title,
  List,
  Item,
  Chip,
};
