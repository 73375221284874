import Big from "big.js";

import { IModalProps } from "shared/interfaces/modal";

export enum EClaimStage {
  CLAIM,
  CLAIM_PROCESSING,
  SUCCESS,
  FAIL,
}

export interface IClaimModalProps extends IModalProps {
  claimAmount: Big;
  symbol: string;
}
