import { ReactComponent as Error } from "assets/icons/fail.svg";
import { ReactComponent as Success } from "assets/icons/success.svg";
import "react-toastify/dist/ReactToastify.css";

import { EToast } from "./index";
import styles from "./styles";

export const toastContent = (text: string, type = EToast.SUCCESS) => {
  const Icon = () => {
    switch (type) {
      case EToast.ERROR:
        return <Error />;
      case EToast.SUCCESS:
        return <Success />;
      default:
        return null;
    }
  };
  return (
    <styles.Container>
      <Icon />
      <styles.Description>{text}</styles.Description>
    </styles.Container>
  );
};
