import { css, styled } from "styled-components";

import { getFadeIn } from "shared/theme/animations";

const FAQContainer = styled.div<{ isInView: boolean }>`
  gap: 3rem;
  display: grid;
  padding: 3.75rem;
  overflow: hidden;
  position: relative;
  border-radius: 1.5rem;
  background-position: left bottom;
  grid-template-columns: repeat(2, 1fr);
  ${({ isInView }) => getFadeIn(isInView)};

  &:after {
    content: "";
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: -19rem;
    position: absolute;
    background-size: auto 48rem;
    background-repeat: no-repeat;
    background-image: url("/static/snap/backgrounds/snap-faq-bg.png");
  }

  &:before {
    content: "";
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.asphalt};
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        grid-template-columns: 1fr;

        &:after {
          top: 8.5rem;
          left: -21rem;
        }

        h2 {
          text-align: center;
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1.5rem;
        padding: 2rem 1rem;

        &:after {
          content: unset;
        }

        h2 {
          text-align: left;
        }
      `,
    )};
`;

const List = styled.div`
  width: 100%;
  gap: 1.5rem;
  display: flex;
  margin: 0 auto;
  max-width: 37.3125rem;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1rem;
      `,
    )};
`;

export default {
  FAQContainer,
  List,
};
