import { createSlice } from "@reduxjs/toolkit";
import { Address } from "viem";

import { contractToken } from "services/config";
import { RootState } from "store";
import { loadTokenData } from "store/actions/view/loadTokenData";

export interface Token {
  address: Address;
  decimals: number;
  name: string;
  symbol: string;
  totalSupply: {
    formatted: string;
    value: bigint;
  };
}

export const tokenSlice = createSlice({
  name: "tokens",
  initialState: {} as { [key: string]: Token },
  reducers: {},
  extraReducers: (build) => {
    build.addCase(loadTokenData.fulfilled, (state, { payload }) => {
      if (!payload) return;
      return {
        ...state,
        [payload.address]: payload,
      };
    });
  },
});

//todo: redone hardcode contractToken
export const selectHAPIToken = (state: RootState): Token | null => state.tokens[contractToken];
export const selectToken = (state: RootState, id?: Address | string): Token | null => state.tokens?.[id || ""] || null;
