import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as ArrowLink } from "assets/icons/arrow-link.svg";
import { ReactComponent as Metamask } from "assets/icons/metamask.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import BurgerToggle from "shared/components/Header/components/BurgerToggle";
import { gitbook } from "shared/constants";
import { useSnap } from "shared/hooks/useSnap";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { cropString } from "shared/utils/stringOperations";

import styles from "./styles";

export const Header: React.FC = () => {
  const { address, isAuth, connectMetamask, disconnect } = useSnap();
  const { isSmall } = useWindowDimensions();

  const [isMenuShowed, setIsMenuShowed] = useState<boolean>(false);

  const buttonText =
    isAuth && address ? cropString(address, 4) : isSmall ? t("Actions.Connect") : t("Actions.ConnectMetamask");

  const navigation = [
    {
      title: t("Navigation.Docs"),
      link: gitbook,
    },
    {
      title: t("Navigation.HapiId"),
      to: "/hapi-id",
      isUppercase: true,
    },
  ];

  useEffect(() => {
    isSmall ? setIsMenuShowed(false) : setIsMenuShowed(true);
  }, [isSmall]);

  return (
    <styles.HeaderContainer>
      {isSmall && <BurgerToggle isOpened={isMenuShowed} setIsOpened={setIsMenuShowed} />}
      <Link to={APP_ROUTES.HOME}>
        <styles.Logo />
      </Link>
      {isMenuShowed && (
        <styles.Menu>
          {navigation.map(({ title, link, to, isUppercase }) => (
            <styles.NavItem key={title} $isUppercase={isUppercase}>
              {link && (
                <a href={link} target="_blank" rel="noreferrer" onClick={() => setIsMenuShowed(false)}>
                  {title}
                  <ArrowLink />
                </a>
              )}
              {to && (
                <Link to={to} onClick={() => setIsMenuShowed(false)}>
                  {title}
                </Link>
              )}
            </styles.NavItem>
          ))}
        </styles.Menu>
      )}
      <ButtonPrimary
        name={buttonText}
        handler={isAuth ? disconnect : connectMetamask}
        iconLeft={isAuth ? undefined : <Metamask />}
        isOutlined
      />
    </styles.HeaderContainer>
  );
};
