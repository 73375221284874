import { REFERRAL_CODE_SYMBOLS_AMOUNT } from "shared/constants";

export const createLink = (href: string, EQueryParams: { [key: string | number]: string | number }) => {
  const url = new URL(href);
  const combinedParams = { ...Object.fromEntries(url.searchParams), ...EQueryParams };
  Object.entries(combinedParams).forEach(([key, value]) => url.searchParams.set(key, value.toString()));
  return url;
};

export function validateParam(str: string | undefined): string | undefined {
  if (!str || str.length !== REFERRAL_CODE_SYMBOLS_AMOUNT) return;
  if (!/^[A-Za-z0-9]*$/.test(str)) return;

  return str;
}

export const isObjectEmpty = (object: object) => {
  return object && Object.keys(object).length === 0 && object.constructor === Object;
};

export const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const delay = async (time = 1000): Promise<void> => {
  return new Promise((res) => setTimeout(res, time));
};

export const getColorByScore = (value: number): string => {
  const colorPoints: {
    value: number;
    color: string;
  }[] = [
    { value: 0, color: "rgb(255,77,77)" },
    { value: 50, color: "rgb(253,191,30)" },
    { value: 100, color: "rgb(100,209,62)" },
  ];

  if (value <= colorPoints[0].value) {
    return colorPoints[0].color;
  }

  if (value >= colorPoints[colorPoints.length - 1].value) {
    return colorPoints[colorPoints.length - 1].color;
  }

  for (let i = 0; i < colorPoints.length - 1; i++) {
    if (value >= colorPoints[i].value && value <= colorPoints[i + 1].value) {
      const percent = (value - colorPoints[i].value) / (colorPoints[i + 1].value - colorPoints[i].value);
      const startColor = colorPoints[i].color.match(/\d+/g)!.map(Number);
      const endColor = colorPoints[i + 1].color.match(/\d+/g)!.map(Number);
      const interpolatedColor = startColor.map((channel, index) =>
        Math.round(channel + percent * (endColor[index] - channel)),
      );
      return `rgb(${interpolatedColor.join(",")})`;
    }
  }

  return "";
};
