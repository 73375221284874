import { useInView } from "framer-motion";
import { t } from "i18next";
import { useRef } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as IntegrationWalletSecurityImage } from "assets/images/snap/integration-wallet-security.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";

import styles from "./styles";
import snapStyles from "../../styles";

export const IntegratedWalletSecurity: React.FC = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const isInView = useInView(ref, { once: true });

  return (
    <styles.IntegratedWalletSecurityContainer ref={ref} isInView={isInView}>
      <IntegrationWalletSecurityImage />
      <styles.Info>
        <snapStyles.H2>{t("Snap.IntegratedWalletSecurity.Title")}</snapStyles.H2>
        <snapStyles.Description>
          <styles.DescriptionContainer>
            <div>{t("Snap.IntegratedWalletSecurity.Description.P1")}</div>
            <div>
              <Trans i18nKey="Snap.IntegratedWalletSecurity.Description.P2" components={{ strong: <strong /> }} />
            </div>
          </styles.DescriptionContainer>
        </snapStyles.Description>
        <ButtonPrimary name={t("Actions.StartForFree")} handler={() => navigate(APP_ROUTES.SNAP.CONNECT)} />
      </styles.Info>
    </styles.IntegratedWalletSecurityContainer>
  );
};
