import styled, { css } from "styled-components";

const AttestationContainer = styled.div`
  gap: 4rem;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  margin-top: -3rem;
  align-items: center;
  padding: 6.25rem 4rem;
  flex-direction: column;
  background-color: #1cd4f0;

  img {
    height: auto;
    max-width: 100%;
    border-radius: 24px;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        padding: 14rem 4rem 9rem 4rem;

        img {
          margin-bottom: 0;
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        padding: 11.75rem 1rem 10rem 1rem;

        img {
          border-radius: 1rem;
        }
      `,
    )};
`;

const TitleDescription = styled.div`
  gap: 1.5rem;
  display: flex;
  max-width: 100%;
  width: 64.8125rem;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        width: 43.875rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        width: 20.5rem;
      `,
    )};
`;

const Title = styled.div`
  font-weight: 900;
  line-height: 109%;
  text-align: center;
  font-size: 6.25rem;
  color: ${({ theme }) => theme.colors.hapiDark};
  font-family: ${({ theme }) => theme.fonts.archivo};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        font-size: 3.75rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 2.75rem;
      `,
    )};
`;

const Description = styled.div`
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  font-size: 1.25rem;
  // TODO: add color
  color: rgba(0, 0, 0, 0.8);

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 1rem;
      `,
    )};
`;

export default {
  AttestationContainer,
  TitleDescription,
  Title,
  Description,
};
