import { IPost } from "shared/interfaces";
import { trimString } from "shared/utils/stringOperations";

import styles from "./styles";

export function Post({ title, author, url, publishedAt, imageUrl, postPage }: IPost) {
  return (
    <styles.Container href={url} target="_blank" rel="noreferrer">
      <styles.Picture src={imageUrl} alt={author} />
      <styles.Description>
        <p>{postPage}</p>
        <styles.Title>{trimString(title)}</styles.Title>
        <p>
          {publishedAt} • {author}
        </p>
      </styles.Description>
    </styles.Container>
  );
}
