import { css, styled } from "styled-components";

const NetworksContainer = styled.div`
  gap: 2rem;
  display: flex;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        gap: 1.5rem;
      `,
    )};
`;

const Title = styled.div`
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  font-size: 1.25rem;
`;

const Networks = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 43rem;
  justify-content: center;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        gap: 0.75rem;
      `,
    )};
`;

const Network = styled.div`
  gap: 0.75rem;
  display: flex;
  font-size: 2rem;
  padding: 0.75rem;
  font-weight: 600;
  line-height: 120%;
  align-items: center;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.colors.whiteOp1};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 1.5rem;
      `,
    )};
`;

export default {
  NetworksContainer,
  Title,
  Networks,
  Network,
};
