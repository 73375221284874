import { t } from "i18next";

import { ReactComponent as ArrowLink } from "assets/icons/arrow-link.svg";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { MINT_HAPI_ID_HOT_WALLET } from "shared/constants";

import styles from "./styles";

export default function Main() {
  return (
    <styles.MainContainer>
      <styles.Text>
        <styles.Title>{t("HapiId.Main.Title")}</styles.Title>
        <styles.HAPI>
          {t("HapiId.MainInfo.HAPI")}
          <styles.HapiIdIcon />
        </styles.HAPI>
        <styles.Description>{t("HapiId.Main.Description")}</styles.Description>
      </styles.Text>
      <styles.WrapperButtons>
        <ButtonPrimary
          name={t("Actions.GetStarted")}
          handler={() => window.open(MINT_HAPI_ID_HOT_WALLET, "_blank")}
          iconRight={<ArrowLink />}
        />
      </styles.WrapperButtons>
    </styles.MainContainer>
  );
}
