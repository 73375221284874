import styled, { css } from "styled-components";

import { ReactComponent as CoinsIcons } from "assets/images/coins.svg";

const Container = styled.section`
  position: relative;
  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.white};
    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-family: ${({ theme }) => theme.fonts.archivoBlack};
    max-width: 33.75rem;
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        p {
          line-height: 100%;
          font-size: 4rem;
          max-width: 15rem;
          font-family: ${({ theme }) => theme.fonts.archivo};
          font-weight: 800;
        }
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        p {
          font-size: 3.5rem;
          font-weight: 900;
        }
      `,
    )};
`;

const Coins = styled(CoinsIcons)`
  position: absolute;
  right: -128px;
  top: -99px;
  pointer-events: none;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        width: 31.125rem;
        height: 25.54238rem;
        right: -97px;
        top: -87px;
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        width: 22.42531rem;
        height: 18.40313rem;
        right: -90px;
        top: 29px;
      `,
    )};
`;

export default {
  Container,
  Coins,
};
