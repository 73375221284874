import { useState } from "react";

import { ReactComponent as DropdownArrow } from "assets/icons/arrow.svg";
import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";
import { SlideDown } from "shared/theme/animations/SlideDown";

import { IFAQItem } from "./interface";
import styles from "./styles";

export const FAQItem = ({ question, answer }: IFAQItem) => {
  const [showAnswer, setShowAnswer] = useState<boolean>(false);

  return (
    <styles.FAQItemContainer onClick={() => setShowAnswer(!showAnswer)} toggleActive={showAnswer}>
      <styles.QuestionTogglerWrapper toggleActive={showAnswer}>
        <styles.Question>{question}</styles.Question>
        <ButtonIcon control={{ icon: <DropdownArrow />, handler: () => setShowAnswer(!showAnswer) }} />
      </styles.QuestionTogglerWrapper>
      <SlideDown show={showAnswer}>
        <styles.Answer>{answer}</styles.Answer>
      </SlideDown>
    </styles.FAQItemContainer>
  );
};
