import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const SnapConnectContainer = styled.div`
  gap: 1.25rem;
  display: flex;
  max-width: 100%;
  width: 54.625rem;
  flex-direction: column;
  margin: 0 auto auto auto;
`;

const Back = styled(Link)`
  gap: 0.25rem;
  display: flex;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  align-items: center;
  text-decoration: unset;
  color: ${({ theme }) => theme.colors.whiteOp6};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:hover {
    opacity: 0.8;
  }

  svg {
    transform: rotate(180deg);
  }
`;

const Steps = styled.div`
  gap: 1.25rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  > div {
    min-height: 17.625rem;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        grid-template-columns: 1fr;

        > div {
          min-height: unset;
        }
      `,
    )};
`;

const Recommended = styled.div`
  gap: 1.25rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  > div {
    min-height: 20.25rem;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        grid-template-columns: 1fr;

        > div {
          min-height: unset;
        }
      `,
    )};
`;

const Block = styled.div`
  display: flex;
  padding: 1.25rem;
  border-radius: 1.5rem;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.asphalt};

  button {
    height: 2.5rem;
    margin-top: 1.5rem;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        button {
          padding: 0 0.5rem;
          font-size: 0.875rem;
        }
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        button {
          font-size: 1rem;
          margin-top: 1.5rem;
        }
      `,
    )};
`;

const IconContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.hapiDark};
  background-color: ${({ theme }) => theme.colors.white};

  svg {
    width: auto;
    height: 1.5rem;
  }
`;

const TitleDescription = styled.div`
  flex: 1;
  gap: 1rem;
  display: flex;
  margin-top: 1.25rem;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 400;
  line-height: 120%;
  font-size: 1.25rem;
  letter-spacing: -0.025rem;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};
`;

const Description = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.snapGrey};
`;

const Done = styled.div`
  gap: 0.5rem;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  align-items: center;
  color: ${({ theme }) => theme.colors.snapGreen};
`;

export default {
  SnapConnectContainer,
  Back,
  Steps,
  Recommended,
  Block,
  IconContainer,
  TitleDescription,
  Title,
  Description,
  Done,
};
