import { PropsWithChildren } from "react";
import { NavigateFunction } from "react-router-dom";

import { ERegistrationConnectorType } from "./registration";

export interface IModalProps extends PropsWithChildren {
  closeModal: () => void;
  navigate?: NavigateFunction;
  widthInRem?: string;
  isCornerPosition?: boolean;
  bgColor?: string;
  clearPadding?: boolean;
}

export interface ISignMessageModalProps extends IModalProps {
  type: "near" | "evm";
}

export interface IAddWalletModalProps extends IModalProps {
  nearHandler: () => void;
  evmHandler: () => void;
}

export interface IStakeModalProps extends IModalProps {
  input: string;
  decimals: number;
  isStakeView: boolean;
  onSubmit(): void;
}

export enum EStakeUnstakeStage {
  ENABLE_SPENDING = "ENABLE_SPENDING",
  ENABLE_SPENDING_PROCESSING = "ENABLE_SPENDING_PROCESSING",
  STAKING_UNSTAKING = "STAKING_UNSTAKING",
  STAKING_UNSTAKING_PROCESSING = "STAKING_UNSTAKING_PROCESSING",
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
}

export interface IConfirmMainWalletModalProps extends IModalProps {
  confirm: () => void;
}

export interface IAccountDuplicationModalProps extends IModalProps {
  type: ERegistrationConnectorType;
}
