import { waitForTransactionReceipt } from "@wagmi/core";
import { t } from "i18next";
import { useCallback } from "react";
import { Address } from "viem";
import { useAccount, useSignMessage } from "wagmi";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { refApi, useGetReferralsQuery } from "services/api/hapi/ref";
import { walletConfig } from "services/wallet";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import theme from "shared/theme";
import { selectClaimStage, setClaimingStage } from "store/slices/staking";

import { EClaimStage, IClaimModalProps } from "./types";
import { getStageData } from "./utils";
import styles from "../Stake/styles";

export function ClaimReferralRewardsModal({ claimAmount, symbol, closeModal }: IClaimModalProps) {
  const { address } = useAccount();
  const dispatch = useAppDispatch();
  const { refetch } = useGetReferralsQuery();
  const { signMessageAsync } = useSignMessage();
  const stage = useAppSelector(selectClaimStage);

  const claim = useCallback(async (): Promise<boolean> => {
    try {
      if (!address) throw new Error("Wallet not connected");
      dispatch(setClaimingStage(EClaimStage.CLAIM_PROCESSING));

      const nonce = await dispatch(refApi.endpoints.getNonceMessage.initiate()).unwrap();
      const message = nonce.messageForSign;

      if (!message) throw new Error(`code: ${nonce.errorCode}, message: ${nonce.message}`);

      const signature = await signMessageAsync({ message });
      const response = await dispatch(refApi.endpoints.claimReward.initiate({ address, signature, message }));

      if ("data" in response && response.data.transaction_hash) {
        await waitForTransactionReceipt(walletConfig, { hash: response.data.transaction_hash as Address });
        refetch();
        dispatch(setClaimingStage(EClaimStage.SUCCESS));
        return true;
      }

      dispatch(setClaimingStage(EClaimStage.FAIL));
      return false;
    } catch (error) {
      console.error("Error: while claiming reward.", error);
      dispatch(setClaimingStage(EClaimStage.FAIL));
      return false;
    }
  }, [address, dispatch, signMessageAsync, refetch]);

  const closeAndSetInitialModal = () => {
    closeModal();
    dispatch(setClaimingStage(EClaimStage.CLAIM));
  };

  const { icon, title, table, description, note, isLoading, button, hideClose } = getStageData(
    claimAmount,
    symbol,
    claim,
    closeAndSetInitialModal,
    stage,
  );

  return (
    <ModalWrapper closeModal={() => {}} bgColor={theme.colors.darkenBlue} widthInRem="23.5" clearPadding>
      <styles.Container>
        <styles.Close>{!hideClose && <CloseIcon onClick={closeModal} />}</styles.Close>
        <styles.IconContainer>{icon && icon}</styles.IconContainer>
        <styles.Body>
          <styles.TitleDescriptionValue>
            {title && <styles.Title>{title}</styles.Title>}
            {table && (
              <styles.Table>
                {Object.entries(table).map((row) => {
                  const rowKey = row[0];
                  const rowValue = row[1];

                  return (
                    <styles.TableRow key={rowKey}>
                      <span>{rowKey}</span>
                      <span>{rowValue}</span>
                    </styles.TableRow>
                  );
                })}
              </styles.Table>
            )}
            {description && <styles.Description>{description}</styles.Description>}
            {note && (
              <styles.Note>
                {note.icon}
                <div>{note.text}</div>
              </styles.Note>
            )}
          </styles.TitleDescriptionValue>
          {isLoading && (
            <styles.ProcessingContainer>
              <styles.Processing />
              <div>{t("Dashboard.Referrals.ClaimModal.Processing")}</div>
            </styles.ProcessingContainer>
          )}
          {button && <ButtonPrimary name={button.name} handler={button.handler} disabled={button.disabled} />}
        </styles.Body>
      </styles.Container>
    </ModalWrapper>
  );
}
