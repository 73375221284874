import { DefaultTheme, css } from "styled-components";

import { MEDIA_WIDTHS } from "shared/constants";

import { colors } from "./colors";
import { fonts } from "./fonts";
import transitions from "./transitions";

const mediaWidth = (key: keyof typeof MEDIA_WIDTHS, styles: TemplateStringsArray | string) => {
  return css`
    @media (max-width: ${MEDIA_WIDTHS[key] - 1}px) {
      ${styles}
    }
  `;
};

const theme: DefaultTheme = {
  name: "default",
  fonts,
  colors,
  mediaWidth,
  transitions,
  breakpoint: MEDIA_WIDTHS,
};

export default theme;
export type Theme = typeof theme;
export { default as GlobalStyle } from "./globalStyles";
