import { createAsyncThunk } from "@reduxjs/toolkit";
import { Address } from "viem";

import type { ThunkApiConfig } from "store";
import { setLoading } from "store/slices/staking";

import { getReward } from "./getReward";
import { getStakedAmount } from "./getStakedAmount";
import { getStaker } from "./getStaker";
import { getStakingFieldsData } from "./getStakingFieldsData";
import { getTierAPY } from "./getTierApy";
import { getUserTicketId } from "../getUserTicketId";
import { loadTokenData } from "../loadTokenData";

export const getStakingData = createAsyncThunk<void, { address?: Address }, ThunkApiConfig>(
  "staking/getStakingData",
  async ({ address }, { dispatch, getState }) => {
    try {
      const stakingFieldsData = await dispatch(getStakingFieldsData()).unwrap();
      if (!stakingFieldsData) return;
      await dispatch(loadTokenData({ tokenAddress: stakingFieldsData.token }));

      if (!address) return;
      await dispatch(getReward({ address }));
      await dispatch(getStakedAmount({ address }));
      await dispatch(getStaker({ address }));

      const { userTicketId } = getState().whitelist.user;
      !userTicketId && (await dispatch(getUserTicketId({ address })).unwrap());
      await dispatch(getTierAPY());
    } catch (e) {
      console.error(`Error getting all staking data: \n`, e);
    } finally {
      dispatch(setLoading(false));
    }
  },
);
