import { sepolia } from "viem/chains";

import { stakingAbi } from "shared/contract/abi/staking";
import { whitelistAbi } from "shared/contract/abi/whitelist";

import { IConfig } from "../config";

export default {
  apiURL: "https://hapi-one.stage.hapi.farm",
  contractWhitelist: "0x2f1e2762E42588ecE3048BA6cAae6996DE984006",
  contractToken: "0x3D827294d51100E2a829Cc11B439866cE592758e",
  contractStaking: "0x07231CbF0d2d143ae020bf1DD7c5e025Af129ec6",
  walletConnectProjectId: "4fca9eef1648bf1cf0739eb8aa9271f6",
  chains: [sepolia],
  whitelistAbi,
  stakingAbi,
  payment: {
    tokenUrl: "https://hapi-one.stage.hapi.farm/payment",
    iframeUrl: "https://app.sandbox.topperpay.com",
  },
  nearContractId: "contract.hapi-test.testnet",
  mixpanelToken: "",
  botName: "HAPI_Research_bot",
} as IConfig;
