import styled from "styled-components";

const Tariffs = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`;

const Tariff = styled.div<{ $isCurrent?: boolean }>`
  cursor: pointer;
  padding: 0.75rem;
  border-radius: 1rem;
  border: 1px solid ${({ theme, $isCurrent }) => ($isCurrent ? theme.colors.hapiYellow1 : theme.colors.hapiGrey40)};
`;

const TitleIcon = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 600;
  line-height: 160%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.grayLight};
`;

const Details = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

const Detail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    &:first-child {
      font-weight: 400;
      line-height: 120%;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.hapiGrey20};
    }

    &:last-child {
      font-weight: 600;
      line-height: 160%;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const styles = {
  Tariffs,
  Tariff,
  TitleIcon,
  Title,
  Details,
  Detail,
};

export default styles;
