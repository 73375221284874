import { t } from "i18next";

import { ReactComponent as Access } from "assets/images/snap/icons/access.svg";
import { ReactComponent as Search } from "assets/images/snap/icons/search.svg";
import { ReactComponent as Security } from "assets/images/snap/icons/security.svg";

export const getData = () => [
  {
    title: t("Snap.Advantages.DueDiligence.Title"),
    description: "Snap.Advantages.DueDiligence.Description",
    icon: <Search />,
  },
  {
    title: t("Snap.Advantages.Security.Title"),
    description: "Snap.Advantages.Security.Description",
    icon: <Security />,
  },
  {
    title: t("Snap.Advantages.ReliabilityAndAccess.Title"),
    description: "Snap.Advantages.ReliabilityAndAccess.Description",
    icon: <Access />,
  },
];
