import styled, { css } from "styled-components";

const TrustScoreContainer = styled.div`
  margin-top: -3rem;
  background-color: #4a8cff;
`;

const TitleDescription = styled.div`
  gap: 1.5rem;
  display: flex;
  max-width: 100%;
  align-items: center;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        width: 43.875rem;
      `,
    )};
`;

const Title = styled.div`
  font-weight: 900;
  line-height: 109%;
  font-size: 6.25rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.hapiDark};
  font-family: ${({ theme }) => theme.fonts.archivo};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        font-size: 3.75rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 2.75rem;
      `,
    )};
`;

const Description = styled.div<{ $isSmall?: boolean }>`
  gap: 1rem;
  display: flex;
  max-width: 100%;
  line-height: 140%;
  text-align: center;
  flex-direction: column;
  font-weight: ${({ $isSmall }) => ($isSmall ? 500 : 400)};
  margin: ${({ $isSmall }) => ($isSmall ? "0.75rem 0 2.25rem 0" : 0)};
  font-size: ${({ $isSmall }) => ($isSmall ? "0.875rem" : "1.25rem")};
  width: ${({ $isSmall }) => ($isSmall ? "25.4375rem" : "40.375rem")};
  color: ${({ $isSmall }) => ($isSmall ? "rgba(11, 11, 23, 0.70)" : "rgba(0, 0, 0, 0.8)")};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        font-size: 1rem;
      `,
    )};
`;

const HowItWorks = styled.div`
  display: flex;
  padding: 0 1rem;
  margin: 11.25rem 0;
  align-items: center;
  flex-direction: column;
  min-height: calc(100dvh - 22.5rem);
`;

const ProgressContainer = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

const ProgressTitle = styled.div`
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.hapiDark};
`;

const Progress = styled.progress`
  border: 0;
  height: 0.5rem;
  max-width: 100%;
  width: 14.1875rem;
  border-radius: 1rem;
  background-color: #3c3c45;

  &::-webkit-progress-value {
    border: 0;
    height: 0.25rem;
    border-radius: 1rem;
    background-color: #fff;
  }

  &::-moz-progress-bar {
    border: 0;
    height: 0.5rem;
    border-radius: 1rem;
    background-color: #3c3c45;
  }

  &::-webkit-progress-bar {
    border: 0;
    height: 0.5rem;
    border-radius: 1rem;
    background-color: #3c3c45;
  }
`;

const TrustMyScore = styled.div`
  gap: 1rem;
  width: 100%;
  margin-top: 2rem;
  align-items: center;
  display: inline-flex;
  flex-direction: column;

  & > p {
    margin: 0;
    font-weight: 700;
    line-height: 120%;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.black};
  }

  & > button {
    z-index: 1;
  }
`;

const ScoreProgressHint = styled.div`
  gap: 3rem;
  display: flex;
  max-width: 100%;
  margin-top: 3rem;
  align-items: center;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        gap: 2.5rem;
        margin-top: 2.5rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 2.25rem;
        margin-top: 2.25rem;
      `,
    )};
`;

export default {
  TrustScoreContainer,
  TitleDescription,
  Title,
  Description,
  HowItWorks,
  Progress,
  ProgressContainer,
  ProgressTitle,
  TrustMyScore,
  ScoreProgressHint,
};
