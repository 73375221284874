import { createAsyncThunk } from "@reduxjs/toolkit";
import { readContract } from "@wagmi/core";

import { walletConfig } from "services/wallet";
import { MIN } from "shared/constants";
import { IStakingFieldsData } from "shared/interfaces/staking";
import type { ThunkApiConfig } from "store";
import { stakingConfig } from "store/config";

export const getStakingFieldsData = createAsyncThunk<IStakingFieldsData, void, ThunkApiConfig>(
  "staking/getStakingFieldsData",
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const previousStakingFieldsData = getState().staking.stakingFieldsData;
      if (
        previousStakingFieldsData &&
        previousStakingFieldsData.lastUpdateTimestamp &&
        Date.now() - previousStakingFieldsData.lastUpdateTimestamp < MIN
      ) {
        return fulfillWithValue(previousStakingFieldsData);
      }

      const [token, totalStaked, lockupBonus, hapiIDBonus, compoundNumber, lockupPeriod, claimLockDuration, minStake] =
        await Promise.all([
          readContract(walletConfig, { ...stakingConfig, functionName: "token" }),
          readContract(walletConfig, { ...stakingConfig, functionName: "totalStaked" }),
          readContract(walletConfig, { ...stakingConfig, functionName: "lockupBonus" }),
          readContract(walletConfig, { ...stakingConfig, functionName: "hapiIDBonus" }),
          readContract(walletConfig, { ...stakingConfig, functionName: "compoundNumber" }),
          readContract(walletConfig, { ...stakingConfig, functionName: "lockupPeriod" }),
          readContract(walletConfig, { ...stakingConfig, functionName: "claimLockDuration" }),
          readContract(walletConfig, { ...stakingConfig, functionName: "minStake" }),
        ]);

      const stakingFieldsData = {
        token,
        totalStaked: {
          value: totalStaked,
        },
        lockupPeriod: {
          value: lockupPeriod,
          formatted: Number(lockupPeriod),
        },
        claimLockDuration: {
          value: claimLockDuration,
          formatted: Number(claimLockDuration),
        },
        minStake: {
          value: minStake,
        },
        lockupBonus,
        hapiIDBonus,
        compoundNumber,
        lastUpdateTimestamp: Date.now(),
      };
      return fulfillWithValue(stakingFieldsData);
    } catch (e) {
      console.error(`Error getting staking data: \n ${e}`);
      return rejectWithValue(null);
    }
  },
);
