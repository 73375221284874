import { useEffect, useState } from "react";
import { useAccount, useConnect, useDisconnect } from "wagmi";

import hapilabsService from "services/api/hapilabs";

const snapId = "npm:hapilabs-snap";
const metamaskId = "io.metamask";
const metamaskInstall = "https://metamask.io/download/";

export const useSnap = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSnapInstalled, setIsSnapInstalled] = useState<boolean>(false);
  const [isApiAdded, seIsApiAdded] = useState<boolean>(false);

  const { disconnect } = useDisconnect();
  const { address, connector, isConnected } = useAccount();
  const { connectors, connect } = useConnect();

  // eslint-disable-next-line
  const request = (window as any).ethereum?.request;
  const isAuth = Boolean(address && connector?.id === metamaskId);

  const connectMetamask = () => {
    if (isAuth) return;
    if (isConnected) disconnect();

    const metamask = connectors.find(({ id }) => id === metamaskId);
    if (!metamask) return window.open(metamaskInstall, "_blank");

    connect({ connector: metamask });
  };

  const getInstalledSnaps = async () => {
    try {
      if (!request) return;

      const snaps = await request({ method: "wallet_getSnaps" });
      const isSnapInstalled = snaps[snapId];
      setIsSnapInstalled(Boolean(isSnapInstalled));
    } catch (error) {
      console.error("Error while getting snaps", error);
    }
  };

  const installSnap = async () => {
    if (!isAuth || !request) return;

    try {
      setLoading(true);
      await request({ method: "wallet_requestSnaps", params: { [snapId]: {} } });
      getInstalledSnaps();
    } catch (error) {
      console.error("Error while installing snap", error);
    } finally {
      setLoading(false);
    }
  };

  const getApi = async () => {
    if (!isAuth || !request || !address) return;

    try {
      const {
        data: { error, apikey: api },
      } = await hapilabsService.getSnapApi(address);

      if (error === 1) throw new Error("Error while getting api");

      await request({
        method: "wallet_invokeSnap",
        params: {
          snapId,
          request: { method: "gethapiapi", params: { api } },
        },
      });

      seIsApiAdded(true);
    } catch (error) {
      console.error("Error while getting api", error);
    }
  };

  useEffect(() => {
    getInstalledSnaps();
  }, [isAuth]);

  return { address, isAuth, isSnapInstalled, isApiAdded, loading, connectMetamask, installSnap, getApi, disconnect };
};
