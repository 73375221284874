import dima from "assets/images/team/dima.webp";
import dred from "assets/images/team/dred.webp";
import eugen from "assets/images/team/eugen.webp";
import irakliy from "assets/images/team/irakliy.webp";
import jumbo from "assets/images/team/jumbo.webp";
import mark from "assets/images/team/mark.webp";
import mister from "assets/images/team/mister.webp";
import vadym from "assets/images/team/vadym.webp";
import { ITeamMember, ESocial } from "shared/interfaces";
import { colors } from "shared/theme/colors";

export const team: ITeamMember[] = [
  {
    color: colors.yellowType2,
    name: "Vadym",
    image: vadym,
    socials: [
      {
        type: ESocial.LinkedIn,
        value: "https://www.linkedin.com/in/vadym-prykhodko-66480289",
      },
      { type: ESocial.Twitter, value: "https://twitter.com/vadikprykhodko" },
    ],
  },
  {
    color: colors.orange,
    name: "Dread",
    image: dred,
    socials: [
      { type: ESocial.Twitter, value: "https://twitter.com/dread_deimos" },
      { type: ESocial.GitHub, value: "https://github.com/ars9" },
      {
        type: ESocial.LinkedIn,
        value: "https://www.linkedin.com/in/andrei-arsenin-930b9939",
      },
    ],
  },
  {
    color: colors.orangeRed,
    name: "Mister",
    image: mister,
    socials: [
      {
        type: ESocial.LinkedIn,
        value: "https://www.linkedin.com/in/yurii-olentir-a3878414b",
      },
    ],
  },
  {
    color: colors.pink,
    name: "Yevgen",
    image: eugen,
    socials: [
      { type: ESocial.GitHub, value: "https://github.com/IevgenPshenychkin" },
      {
        type: ESocial.LinkedIn,
        value: "https://www.linkedin.com/in/eugene-pshenychkin-b3513522",
      },
    ],
  },
  {
    color: colors.violet,
    name: "Mark",
    image: mark,
    socials: [
      { type: ESocial.LinkedIn, value: "https://www.linkedin.com/in/uaelifan" },
      { type: ESocial.Twitter, value: "https://twitter.com/UAEliFan" },
    ],
  },
];

export const otherTeamMember = [
  {
    logo: irakliy,
    color: colors.blue,
  },
  {
    logo: dima,
    color: colors.green,
  },
  {
    logo: jumbo,
    color: colors.darkYellow,
  },
];
