import { css, styled } from "styled-components";

const Score = styled.div<{ $showLevel: boolean; $score: number }>`
  display: flex;
  height: 8.0625rem;
  position: relative;
  justify-content: center;

  span {
    font-weight: 900;
    line-height: 116%;

    &:first-child {
      color: #1f1f1f;
      font-size: 2.75rem;
      letter-spacing: -0.0825rem;

      transition-property: all;
      transition-duration: 1500ms;
      transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }

    &:last-child {
      color: ${({ theme }) => theme.colors.black};
      opacity: 0.5;
      font-size: 1.75rem;
      font-weight: 900;
      margin-bottom: 0.25rem;
    }
  }
  ${({ $showLevel }) =>
    $showLevel &&
    css`
      margin-bottom: 1rem;
    `}
`;

const Level = styled.div`
  top: 3.5rem;
  position: absolute;

  display: flex;
  padding: 0.1875rem 0.375rem;
  align-items: center;
  gap: 0.375rem;

  border-radius: 6.25rem;
  background: #f3ebe9;

  color: #736963;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.375rem;

  & > img {
    width: 2rem;
    height: 2rem;
  }
`;

const ScoreAmount = styled.div<{ $showLevel: boolean }>`
  bottom: ${({ $showLevel }) => ($showLevel ? "-20px" : " 0")};
  gap: 0.25rem;
  display: flex;
  position: absolute;
  align-items: flex-end;
`;

const Gauge = styled.div`
  position: relative;
  width: 16.25rem;
  height: 8rem;

  & > img {
    width: 16.25rem;
    height: 8rem;
  }
`;

const Coin = styled.div<{ $deg: number; $shine: boolean }>`
  width: 2.5rem;
  height: 2.5rem;

  position: absolute;

  transition-property: transform;
  transition-duration: 1500ms;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  z-index: 10;
  transform: rotate(${({ $deg }) => $deg}deg);

  transform-origin: center 140px;
  top: -10px;
  right: 110px;
  border-radius: 50%;

  overflow: hidden;

  & > svg {
    width: 100%;
    height: auto;
  }

  &::before {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(45deg);
    ${({ $shine }) =>
      $shine
        ? css`
            animation: shine 2s infinite;
          `
        : css`
            display: none;
          `}
  }

  @keyframes shine {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export default {
  Score,
  Level,
  ScoreAmount,
  Gauge,
  Coin,
};
