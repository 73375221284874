import { AnimatePresence } from "framer-motion";
import { t } from "i18next";
import Marquee from "react-fast-marquee";

import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { useRefsContext } from "shared/providers/refs";
import { useToolsContext } from "shared/providers/tools";
import { slideDownAnimation, slideDownAnimationGrid } from "shared/theme/animations";

import { IToolProps } from "./interface";
import styles from "./styles";

export function Tool({ icon, toolKey, clientLogos, reference, isOpened, index, additionalButton }: IToolProps) {
  const { scrollToBlock, plansRef } = useRefsContext();
  const { openTool, closeTool } = useToolsContext();
  const { isSmall, isMedium } = useWindowDimensions();
  const isAdaptive = isSmall || isMedium;

  return (
    <styles.ToolContainer
      $isOpened={isOpened}
      ref={reference}
      onClick={() => (isOpened ? closeTool(index) : openTool(index))}
    >
      <AnimatePresence>
        <styles.Tool $isOpened={isOpened}>
          <styles.MainContainer $isOpened={isOpened}>
            <styles.Title $isOpened={isOpened}>
              {!isAdaptive && icon}
              <span>{t(`${toolKey}.Title`)}</span>
            </styles.Title>
            <styles.Description $isOpened={isOpened}>{t(`${toolKey}.Description`)}</styles.Description>
          </styles.MainContainer>
          {isOpened && (
            <styles.AdditionalContainer initial="close" animate="open" variants={slideDownAnimationGrid} exit="close">
              {Array.from({ length: 4 }, (_, i) => i + 1).map((item) => (
                <styles.Metric key={`${toolKey}.Metrics.${item}.Title`}>
                  <styles.TitleSmall>{t(`${toolKey}.Metrics.${item}.Title`)}</styles.TitleSmall>
                  <styles.MetricValue>{t(`${toolKey}.Metrics.${item}.Value`)}</styles.MetricValue>
                </styles.Metric>
              ))}
            </styles.AdditionalContainer>
          )}
          {isOpened && (
            <styles.ClientsContainer initial="close" animate="open" variants={slideDownAnimation} exit="close">
              <styles.ClientsTitle>Clients</styles.ClientsTitle>
              <styles.MarqueeContainer>
                <styles.GradientLeft />
                <Marquee autoFill>{clientLogos.map((logo) => logo)}</Marquee>
                <styles.GradientRight />
              </styles.MarqueeContainer>
            </styles.ClientsContainer>
          )}
          {isOpened && (
            <styles.ButtonContainer>
              <ButtonPrimary
                fullWidth={isSmall}
                name={t("Actions.GoToTariffs")}
                handler={() => scrollToBlock(plansRef)}
              />
              {additionalButton && (
                <ButtonPrimary
                  isOutlined
                  fullWidth={isSmall}
                  name={t(additionalButton.name)}
                  iconRight={additionalButton.iconRight}
                  handler={() => window.open(additionalButton.link, "_blank")}
                />
              )}
            </styles.ButtonContainer>
          )}
          {!isAdaptive && isOpened ? null : <styles.Toggle $isOpened={isOpened} />}
        </styles.Tool>
      </AnimatePresence>
    </styles.ToolContainer>
  );
}
