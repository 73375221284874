import { t } from "i18next";

import { ReactComponent as Check } from "assets/icons/check.svg";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { H2 } from "shared/components/Typography";
import { useRefsContext } from "shared/providers/refs";

import { HapiApiPlan } from "./components/HapiAPIPlan";
import { plans } from "./data";
import styles from "./styles";

export function Plans() {
  const { plansRef } = useRefsContext();

  return (
    <styles.PlansContainer ref={plansRef}>
      <styles.TitleDescription>
        <H2>{t("Plans.Title")}</H2>
        <styles.Description>{t("Plans.Description")}</styles.Description>
      </styles.TitleDescription>
      <styles.PlansList>
        <HapiApiPlan />
        {plans.map((plan) => (
          <styles.Plan key={plan.title}>
            {plan.icon && <styles.IconContainer>{plan.icon}</styles.IconContainer>}
            <styles.PlanTitle>{t(plan.title)}</styles.PlanTitle>
            <styles.PlanDescription>{t(plan.description)}</styles.PlanDescription>
            {Boolean(plan.services) && (
              <styles.ServicesList>
                {plan.services?.map((service) => (
                  <styles.ServiceItem key={service}>
                    <Check />
                    {t(`Plans.${service}`)}
                  </styles.ServiceItem>
                ))}
              </styles.ServicesList>
            )}
            <styles.PriceButtonContainer>
              {plan.price && <styles.Price>{t(plan.price)}</styles.Price>}
              <ButtonPrimary
                fullWidth
                name={t(plan.button?.title)}
                isOutlined={plan.button?.isOutlined}
                handler={plan.button?.clickHandler}
              />
            </styles.PriceButtonContainer>
          </styles.Plan>
        ))}
      </styles.PlansList>
    </styles.PlansContainer>
  );
}
