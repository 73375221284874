import { t } from "i18next";

import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { colors } from "shared/theme/colors";

import { tableData, mobileWhalesTableData, mobileRegularTableData } from "./constants";
import styles from "./styles";
import { Table } from "../Table";

const MobileTable = () => {
  return (
    <>
      <styles.TableContainer>
        <Table data={mobileWhalesTableData} />
        <styles.TableDescription>{t("TokenUtility.StakingProgramDescription")}</styles.TableDescription>
      </styles.TableContainer>
      <styles.TableContainer>
        <Table data={mobileRegularTableData} additionHeadColor={colors.blueViolet} />
      </styles.TableContainer>
    </>
  );
};

const DesktopTable = () => {
  return (
    <styles.TableContainer>
      <Table data={tableData} />
      <styles.TableDescription>{t("TokenUtility.StakingProgramDescription")}</styles.TableDescription>
    </styles.TableContainer>
  );
};

export const StakingProgram = () => {
  const { isSmall } = useWindowDimensions();
  return (
    <styles.Container>
      <styles.Title>{t("TokenUtility.StakingProgram")}</styles.Title>
      {isSmall ? <MobileTable /> : <DesktopTable />}
    </styles.Container>
  );
};
