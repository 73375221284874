import { motion } from "framer-motion";

import { SliderProps } from "./interfaces";

export const Slider = ({ x, i, onDragEnd, children, totalSliders }: SliderProps) => (
  <motion.div
    style={{
      x,
      left: `${i * 100}%`,
      right: `${i * 100}%`,
    }}
    drag={totalSliders > 1 && "x"}
    dragElastic={0.3}
    onDragEnd={onDragEnd}
  >
    {children}
  </motion.div>
);
