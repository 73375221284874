import { animate } from "framer-motion";
import { useRef, useEffect } from "react";

import { EMPTY_STRING } from "shared/constants";

import { IAnimatedValue } from "./interface";

export function AnimatedValue({ to, prefix, suffix, precision = 0, from = 0 }: IAnimatedValue) {
  const nodeRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    const node = nodeRef.current;
    if (!node) return;
    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        node.textContent = `${prefix || EMPTY_STRING}${value.toFixed(precision)}${suffix || EMPTY_STRING}`;
      },
    });
    return () => controls.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  return <span ref={nodeRef} />;
}
