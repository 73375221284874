import { t } from "i18next";

import { ReactComponent as Airdrop } from "assets/icons/hapi-id/advantages/airdrop.svg";
import { ReactComponent as APY } from "assets/icons/hapi-id/advantages/apy.svg";
import { ReactComponent as Dapp } from "assets/icons/hapi-id/advantages/dapp.svg";
import { ReactComponent as Verification } from "assets/icons/hapi-id/advantages/human-verification.svg";
import { ReactComponent as Protection } from "assets/icons/hapi-id/advantages/protection.svg";
import { ReactComponent as Ref } from "assets/icons/hapi-id/advantages/ref.svg";
import { ReactComponent as Secure } from "assets/icons/hapi-id/advantages/secure.svg";
import { useRefsContext } from "shared/providers/refs";

import AdvantageCard from "./components/Card";
import styles from "./styles";

export default function Advantages() {
  const { hapiIdAdvantagesRef } = useRefsContext();

  return (
    <styles.AdvantagesContainer ref={hapiIdAdvantagesRef}>
      <styles.Advantages>
        <styles.Title>{t("HapiId.Advantages.Title")}</styles.Title>
        <AdvantageCard
          borderColor="#FF6960"
          title={t("HapiId.Advantages.Cards.HumanVerification.Title")}
          icon={<Verification />}
          description={t("HapiId.Advantages.Cards.HumanVerification.Description")}
          mt={34}
        />
        <AdvantageCard
          borderColor="#5289FF"
          title={t("HapiId.Advantages.Cards.Protection.Title")}
          icon={<Protection />}
          description={t("HapiId.Advantages.Cards.Protection.Description")}
          mt={45}
          turnRight
        />
        <AdvantageCard
          borderColor="#FF9820"
          title={t("HapiId.Advantages.Cards.Airdrop.Title")}
          icon={<Airdrop />}
          description={t("HapiId.Advantages.Cards.Airdrop.Description")}
          mt={55}
        />
        <AdvantageCard
          borderColor="#66BF50"
          title={t("HapiId.Advantages.Cards.APY.Title")}
          icon={<APY />}
          description={t("HapiId.Advantages.Cards.APY.Description")}
          mt={65}
          turnRight
        />
        <AdvantageCard
          borderColor="#AF6CC7"
          title={t("HapiId.Advantages.Cards.Ref.Title")}
          icon={<Ref />}
          description={t("HapiId.Advantages.Cards.Ref.Description")}
          mt={75}
        />
        <AdvantageCard
          borderColor="#12AED9"
          title={t("HapiId.Advantages.Cards.Secure.Title")}
          icon={<Secure />}
          description={t("HapiId.Advantages.Cards.Secure.Description")}
          mt={85}
          turnRight
        />
        <AdvantageCard
          borderColor="#FF6960"
          title={t("HapiId.Advantages.Cards.Dapp.Title")}
          icon={<Dapp />}
          description={t("HapiId.Advantages.Cards.Dapp.Description")}
          mt={95}
        />
      </styles.Advantages>
    </styles.AdvantagesContainer>
  );
}
