import { contactUsUrl } from "shared/constants";

export const tariffs = [
  {
    key: "free",
    title: "Free",
    details: ["ComputeResources", "RequestThroughput"],
    price: "Free.Price",
    buttonName: "Actions.StartForFree",
    clickHandler: () => window.open(contactUsUrl, "_blank"),
  },
  {
    key: "business",
    title: "Business",
    details: ["ComputeResources", "RequestThroughput"],
    price: "Business.Price",
    buttonName: "Actions.BuyNow",
    clickHandler: () => window.open(contactUsUrl, "_blank"),
  },
];
