import { AnimatePresence, motion } from "framer-motion";
import { PropsWithChildren } from "react";

import { dropdownAnimation } from ".";

interface ISlideDown extends PropsWithChildren {
  show: boolean;
}

export const SlideDown = ({ show, children }: ISlideDown) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div initial="close" animate="open" exit="close" variants={dropdownAnimation}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
