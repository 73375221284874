import { colors } from "shared/theme/colors";

export const platforms = [
  {
    key: "Cryptos",
    color: colors.blueViolet,
  },
  {
    key: "DeFi",
    color: colors.orange,
  },
  {
    key: "Wallets",
    color: colors.orangeRed,
  },
  {
    key: "DEX",
    color: colors.pink,
  },
  {
    key: "CEX",
    color: colors.violet,
  },
];

export const dynamicValues = [
  {
    value: 300,
    text: "First",
    suffix: "K+",
  },
  {
    value: 215,
    text: "Second",
    suffix: "K+/day",
  },
  {
    value: 200,
    text: "Third",
    suffix: "+",
  },
];
