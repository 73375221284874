import moment from "moment";
import { useEffect, useState } from "react";

import { ZERO_STRING } from "shared/constants";

const CountdownTimer = () => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  function calculateTimeRemaining() {
    const now = moment.utc();
    const targetTime = moment.utc().set({ hour: 0, minute: 0, second: 0 });

    if (now.isAfter(targetTime)) targetTime.add(1, "day");

    return moment.duration(targetTime.diff(now));
  }

  useEffect(() => {
    const now = moment.utc();
    const targetTime = moment.utc().set({ hour: 0, minute: 0, second: 0 });

    if (now.isAfter(targetTime)) {
      const nextDayTargetTime = targetTime.add(1, "day");
      const delayUntilNextDay = nextDayTargetTime.diff(now);

      setTimeout(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, delayUntilNextDay);
    }
  }, []);

  return (
    <strong>
      {timeRemaining.hours().toString().padStart(2, ZERO_STRING)}:
      {timeRemaining.minutes().toString().padStart(2, ZERO_STRING)}:
      {timeRemaining.seconds().toString().padStart(2, ZERO_STRING)}
    </strong>
  );
};

export default CountdownTimer;
