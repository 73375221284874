import Big from "big.js";
import { t } from "i18next";

import { ReactComponent as Fail } from "assets/icons/dashboard/error.svg";
import { ReactComponent as Info } from "assets/icons/dashboard/info.svg";
import { ReactComponent as Success } from "assets/icons/dashboard/success.svg";

import { EClaimStage } from "./types";

export const CLAIM_FEE = 3;
export const MIN_CLAIM_AMOUNT = 5;

export const getStageData = (
  claimAmount: Big,
  symbol: string,
  claim: () => void,
  closeAndSetInitialModal: () => void,
  stage?: EClaimStage,
) => {
  const insufficientFunds = claimAmount.lt(MIN_CLAIM_AMOUNT);

  switch (stage) {
    case EClaimStage.CLAIM: {
      const tableData = {
        [t("Dashboard.Referrals.ClaimModal.Balance")]: `${claimAmount} ${symbol}`,
        [t("Dashboard.Referrals.ClaimModal.Min")]: `${MIN_CLAIM_AMOUNT} ${symbol}`,
        [t("Dashboard.Referrals.ClaimModal.Fee")]: `${CLAIM_FEE} ${symbol}`,
      };

      return {
        title: t("Dashboard.Referrals.ClaimModal.Title"),
        table: insufficientFunds
          ? tableData
          : {
              ...tableData,
              [t("Dashboard.Referrals.ClaimModal.Receive")]: `${claimAmount.minus(CLAIM_FEE)} ${symbol}`,
            },
        button: {
          name: t("Actions.Claim"),
          handler: claim,
          disabled: insufficientFunds,
        },
        note: {
          icon: insufficientFunds ? <Fail /> : <Info />,
          text: insufficientFunds
            ? t("Dashboard.Referrals.ClaimModal.Note.InsufficientFunds")
            : t("Dashboard.Referrals.ClaimModal.Note.Default"),
        },
      };
    }
    case EClaimStage.CLAIM_PROCESSING:
      return {
        title: t("Dashboard.Referrals.ClaimModal.Title"),
        description: t("Dashboard.Referrals.ClaimModal.Claimed", { amount: claimAmount, symbol }),
        isLoading: true,
      };
    case EClaimStage.SUCCESS:
      return {
        icon: <Success />,
        title: t("Dashboard.Referrals.ClaimModal.Success"),
        button: {
          name: t("Actions.GoToDashboard"),
          handler: closeAndSetInitialModal,
        },
        hideClose: true,
      };
    case EClaimStage.FAIL:
      return {
        icon: <Fail />,
        title: t("Dashboard.Referrals.ClaimModal.Error"),
        button: {
          name: t("Actions.GoToDashboard"),
          handler: closeAndSetInitialModal,
        },
        hideClose: true,
      };
    default:
      return {};
  }
};
