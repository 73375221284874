import { t } from "i18next";

import { ReactComponent as CheckIcon } from "assets/icons/dashboard/check.svg";
import { useGetReferralsQuery } from "services/api/hapi/ref";
import { EMPTY_STRING } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { selectStakingData } from "store/slices/staking";
import { selectToken } from "store/slices/token";

import styles from "./styles";
import { getReferralTypes } from "./utils";
import { ReferralTypesSkeleton } from "../Skeletons";

export const ReferralTypes: React.FC = () => {
  const { stakingFieldsData } = useAppSelector(selectStakingData);
  const token = useAppSelector((state) => selectToken(state, stakingFieldsData?.token));
  const { isSmall } = useWindowDimensions();
  const { data, isLoading } = useGetReferralsQuery();

  if (isLoading) return <ReferralTypesSkeleton />;

  const symbol = token?.symbol || EMPTY_STRING;
  const referrals = data?.data;

  const totalStakedL1 = referrals?.level1.total_stake || 0;
  const totalStakedL2 = referrals?.level2.total_stake || 0;

  const referralTypes = getReferralTypes(totalStakedL1, totalStakedL2);

  return (
    <styles.ReferralTypesContainer>
      {!isSmall && (
        <styles.TitleDescriptionContainer>
          <styles.TitleDescription>
            <styles.Title>{t("Dashboard.Referrals.Direct.Title")}</styles.Title>
            <styles.Description>{t("Dashboard.Referrals.Direct.Description")}</styles.Description>
          </styles.TitleDescription>
          <styles.TitleDescription>
            <styles.Title>{t("Dashboard.Referrals.Indirect.Title")}</styles.Title>
            <styles.Description>{t("Dashboard.Referrals.Indirect.Description")}</styles.Description>
          </styles.TitleDescription>
        </styles.TitleDescriptionContainer>
      )}
      <styles.ReferralColumns>
        {referralTypes.map(({ title, description, progress, table }) => {
          const currentStepIndex = table.body.findIndex(({ key }) => progress.value < key);
          const currentStep = currentStepIndex > -1 ? table.body[currentStepIndex] : table.body[table.body.length - 1];
          const progressMax = currentStep.key;

          return (
            <styles.Referral key={description}>
              <styles.TitleProgress>
                {isSmall && (
                  <styles.TitleDescription>
                    <styles.Title>{title}</styles.Title>
                    <styles.Description>{description}</styles.Description>
                  </styles.TitleDescription>
                )}
                <styles.ProgressContainer>
                  <styles.ProgressLabel>
                    {progress.value} {symbol} / {progressMax} {symbol}
                  </styles.ProgressLabel>
                  <styles.Progress max={progressMax} value={progress.value} $background={progress.background} />
                  <styles.AdditionalInfo>
                    <styles.AdditionalInfoKeyValue>
                      <styles.AdditionalInfoKey>
                        {t("Dashboard.Referrals.ReferralsStaked.Current")}
                      </styles.AdditionalInfoKey>
                      <styles.AdditionalInfoValue>
                        {Math.round((progress.value / progressMax) * 100)}%
                      </styles.AdditionalInfoValue>
                    </styles.AdditionalInfoKeyValue>
                    {currentStepIndex > -1 && (
                      <styles.AdditionalInfoKeyValue>
                        <styles.AdditionalInfoKey>
                          {t("Dashboard.Referrals.ReferralsStaked.Next")}
                        </styles.AdditionalInfoKey>
                        <styles.AdditionalInfoValue>{currentStep.value}</styles.AdditionalInfoValue>
                      </styles.AdditionalInfoKeyValue>
                    )}
                  </styles.AdditionalInfo>
                </styles.ProgressContainer>
              </styles.TitleProgress>
              <div>
                <styles.Tr>
                  <span>{table.head.key}</span>
                  <span>{table.head.value}</span>
                </styles.Tr>
                <styles.TableBody>
                  {table.body.map(({ key, value }) => {
                    const isChecked = progress.value >= key;

                    return (
                      <styles.TrBody key={key} checked={isChecked} $colorChecked={table.colorChecked}>
                        <span>
                          {key} {isChecked && <CheckIcon />}
                        </span>
                        <span>{value}</span>
                      </styles.TrBody>
                    );
                  })}
                </styles.TableBody>
              </div>
            </styles.Referral>
          );
        })}
      </styles.ReferralColumns>
      <styles.HowItWorks>
        <div>
          <styles.HowItWorksTitle>{t("Dashboard.Referrals.HowItWorks.Title")}</styles.HowItWorksTitle>
          <styles.HowItWorksDescription>
            <div>{t("Dashboard.Referrals.HowItWorks.Description.P1")}</div>
            <div>{t("Dashboard.Referrals.HowItWorks.Description.P2")}</div>
          </styles.HowItWorksDescription>
        </div>
        <styles.ReferralsImage />
      </styles.HowItWorks>
    </styles.ReferralTypesContainer>
  );
};
