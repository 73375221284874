import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        max-width: 6.75rem;
      `,
    )}
`;

const ImageContainer = styled.div<{ color: string }>`
  width: 10.5rem;
  height: 17.5rem;
  background-color: ${({ color }) => color};
  border-radius: 8.375rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  img {
    height: 100%;
    width: 100%;
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        width: 6.75rem;
        height: 11.25rem;
      `,
    )}
`;

const Name = styled.p`
  margin: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};
`;

const Position = styled.p`
  text-align: center;
  margin: 0.5rem 0;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.grayLight};
`;

const SocialsWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  a {
    display: flex;
    align-self: center;
  }
`;

const styles = {
  Container,
  ImageContainer,
  Name,
  Position,
  SocialsWrapper,
};

export default styles;
