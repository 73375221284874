import { NavigateOptions, redirect, To, useLocation, useNavigate } from "react-router-dom";

import { logger } from "services/mixpanel";

export const loggerRedirect = (to: string) => {
  logger.trackEvent("NAVIGATE", { from: window.location.pathname, to });
  return redirect(to);
};

export const useLoggerNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (to: To, options?: NavigateOptions | undefined, additionalData?: Record<string, string>) => {
    const from = location.pathname;
    if (from === to) return;

    const loggerEventData = additionalData
      ? { from, to: typeof to === "string" ? to : { ...to }, additionalData }
      : { from, to: typeof to === "string" ? to : { ...to } };

    logger.trackEvent("NAVIGATE", loggerEventData);

    navigate(to, { state: { from }, ...options });
  };
};
