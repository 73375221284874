import { ReactComponent as Info } from "assets/icons/info-outlined.svg";

import { IDatalist } from "./interface";
import styles from "./styles";

export const Datalist = ({ rows }: IDatalist) => {
  return (
    <styles.List>
      {rows.map(({ title, value, subvalue, tooltip, button }) => (
        <styles.ListItem key={title}>
          <styles.TitleTooltip>
            <styles.Title>{title}</styles.Title>
            {tooltip && (
              <styles.Tooltip>
                <Info />
              </styles.Tooltip>
            )}
          </styles.TitleTooltip>
          <styles.Value>
            {subvalue && <styles.Subvalue>{subvalue}</styles.Subvalue>}
            <span>{value}</span>
            {button && (
              <styles.Button onClick={button.handler} disabled={button.disabled}>
                {button.name}
              </styles.Button>
            )}
          </styles.Value>
        </styles.ListItem>
      ))}
    </styles.List>
  );
};
