import styled from "styled-components";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const HomeContainer = styled.div`
  flex: 1;
  gap: 12.5rem;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth("upToMedium", `gap: 9rem;`)};
`;

const styles = {
  Container,
  HomeContainer,
};

export default styles;
