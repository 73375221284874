import { AdditionalInfo } from "./components/AdditionalInfo";
import { Advantages } from "./components/Advantages";
import { FAQ } from "./components/FAQ";
import { IntegratedWalletSecurity } from "./components/IntegratedWalletSecurity";
import { Main } from "./components/Main";
import { SecureCryptos } from "./components/SecureCryptos";
import { Tariffs } from "./components/Tariffs";
import { TrustedBy } from "./components/TrustedBy";
import styles from "./styles";

export const Snap: React.FC = () => {
  return (
    <>
      <Main />
      <AdditionalInfo />
      <TrustedBy />
      <IntegratedWalletSecurity />
      <Advantages />
      <Tariffs />
      <SecureCryptos />
      <FAQ />
      <styles.BG />
    </>
  );
};
