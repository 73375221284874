import styled, { css } from "styled-components";

import { ReactComponent as Whale } from "assets/icons/dashboard/whale.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 53rem;
  width: 100%;
  flex: 1;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.37rem;
  table {
    tr {
      height: 3.0625rem;
      th {
        &:first-child {
          width: 5.5rem;
        }
        &:last-child {
          width: 7rem;
        }
      }
    }
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        table {
          tr {
            th {
              &:first-child {
                width: 3rem;
              }
            }
          }
        }
      `,
    )};
`;

const THead = styled.div<{ $textAlign: "start" | "end" }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  justify-content: flex-${({ $textAlign }) => $textAlign};
  color: ${({ theme }) => theme.colors.hapiGrey20};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 120%;
`;

const TableRow = styled.div<{ $textAlign: "start" | "end"; $bold: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-${({ $textAlign }) => $textAlign};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  font-weight: ${({ $bold }) => ($bold ? 700 : 400)};
  line-height: 120%;
  gap: 0.5rem;
`;

const WhaleIcon = styled(Whale)`
  width: 1rem;
  height: 1rem;
`;

const Winners = styled.div<{ $color: string }>`
  width: 1.5rem;
  height: 1.4375rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${({ $color }) => $color};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
`;

export default {
  Container,
  Body,
  THead,
  TableRow,
  WhaleIcon,
  Winners,
};
