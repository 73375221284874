import { createAsyncThunk } from "@reduxjs/toolkit";
import { readContract } from "@wagmi/core";

import { walletConfig } from "services/wallet";
import type { ThunkApiConfig } from "store";
import { whitelistConfig } from "store/config";
import { setTicketStats } from "store/slices/whitelist";

export const getTicketStats = createAsyncThunk<void, void, ThunkApiConfig>(
  "whitelistContract/getTicketStats",
  async (_, { dispatch }) => {
    try {
      const [total, left] = await readContract(walletConfig, {
        ...whitelistConfig,
        functionName: "ticket_stats",
      });

      dispatch(
        setTicketStats({
          formatted: { total: Number(total), left: Number(left) },
        }),
      );
    } catch (e) {
      console.error(`Error getting ticket stats: \n ${e}`);
    }
  },
);
