import React, { createContext, PropsWithChildren, useContext, useRef } from "react";

interface IRefsContext {
  plansRef: React.RefObject<HTMLDivElement>;
  toolsRef: React.RefObject<HTMLDivElement>;
  teamRef: React.RefObject<HTMLDivElement>;
  buyHapiRef: React.RefObject<HTMLDivElement>;
  videoRef: React.RefObject<HTMLDivElement>;
  hapiIdAdvantagesRef: React.RefObject<HTMLDivElement>;
  hapiIdTrustRef: React.RefObject<HTMLDivElement>;
  hapiIdHowItWorksRef: React.RefObject<HTMLDivElement>;
  hapiIdAttestationsRef: React.RefObject<HTMLDivElement>;
  hapiIdFAQRef: React.RefObject<HTMLDivElement>;
  scrollToBlock(blockRef: React.RefObject<HTMLDivElement>): void;
}

export const RefsContext = createContext<IRefsContext>({} as IRefsContext);

export const useRefsContext = () => useContext(RefsContext);

export function RefsProvider({ children }: PropsWithChildren) {
  const plansRef = useRef(null);
  const toolsRef = useRef(null);
  const teamRef = useRef(null);
  const buyHapiRef = useRef(null);
  const videoRef = useRef(null);
  const hapiIdAdvantagesRef = useRef(null);
  const hapiIdTrustRef = useRef(null);
  const hapiIdHowItWorksRef = useRef(null);
  const hapiIdAttestationsRef = useRef(null);
  const hapiIdFAQRef = useRef(null);

  function scrollToBlock(blockRef: React.RefObject<HTMLDivElement>) {
    const ref = blockRef.current;
    ref &&
      ref.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }

  return (
    <RefsContext.Provider
      value={{
        plansRef,
        toolsRef,
        teamRef,
        buyHapiRef,
        videoRef,
        hapiIdAdvantagesRef,
        hapiIdTrustRef,
        hapiIdHowItWorksRef,
        hapiIdAttestationsRef,
        hapiIdFAQRef,
        scrollToBlock,
      }}
    >
      {children}
    </RefsContext.Provider>
  );
}
