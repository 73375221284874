export const INVITE_CODE = "code";

export const APP_ROUTES = {
  HOME: "/",
  HAPI_ID: "/hapi-id",
  TOKEN_UTILITY: "/token-utility",
  REGISTRATION: "/registration",
  INVITE_CODE: `/:${INVITE_CODE}?`,
  SNAP: {
    HOME: "/snap",
    CONNECT: "/snap/connect",
  },
  DASHBOARD: {
    HOME: "/dashboard",
    get LOGIN() {
      return this.HOME + "-login";
    },
    get STAKING() {
      return this.HOME + "/staking";
    },
    get REFERRALS() {
      return this.HOME + "/referrals";
    },
    get LEADERBOARD() {
      return this.HOME + "/leaderboard";
    },
  },
  DEFAULT: "*",
} as const;

export const REMOVED_APP_ROUTES = {
  WHALES_EARLY_ACCESS: "whales-early-access/*",
  EARLY_ACCESS: "early-access/*",
} as const;
