import styled, { css } from "styled-components";

const Container = styled.div`
  gap: 0.75rem;
  display: flex;
  position: sticky;
  top: 96px;
  width: 14.625rem;
  max-width: 14.625rem;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        position: static;
        top: auto;
        max-width: 100%;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        background-color: ${({ theme }) => theme.colors.hapiDark};
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        overflow-y: auto;
      `,
    )};
`;

const NavItem = styled.button<{ $isActive: boolean }>`
  background-color: ${({ theme, $isActive }) => ($isActive ? theme.colors.activeDashboardTab : "transparent")};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 0.75rem;
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.white : theme.colors.hapiGrey5)};
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  line-height: 140%;
  transition: ${({ theme }) => theme.transitions.hoverTransition};
  &:hover {
    background-color: ${({ theme }) => theme.colors.activeDashboardTab};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.hapiGrey60};
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
    }
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        padding: 0;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        flex-direction: column;
        gap: 0.25rem;
        font-size: 0.75rem;
        width: 5.125rem;
        flex-shrink: 0;
        svg {
          width: 1rem;
          height: 1rem;
        }
      `,
    )};
`;

export default {
  Container,
  NavItem,
};
