import { AnimatePresence, motion } from "framer-motion";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ENavigationListKeys } from "shared/components/Header/components/Navigation/enum";
import { INavigationItem } from "shared/components/Header/components/Navigation/interface";
import { gitbook } from "shared/constants";
import { ECOSYSTEM_LINKS } from "shared/data/ecosystem";
import useDeviceDetection from "shared/hooks/useDeviceDetection";
import { useScrollTo } from "shared/hooks/useScrollTo";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { useRefsContext } from "shared/providers/refs";
import { getPaymentURL } from "shared/utils/getPaymentUrl";

import { INavItem } from "./interface";
import styles from "./styles";

export function NavItem({
  item,
  isHeaderAlternative,
  submenuCollectionStatuses,
  setSubmenuCollectionStatuses,
  setIsHeaderAlternative,
}: INavItem) {
  const { scrollTo } = useScrollTo();
  const navigate = useNavigate();
  const isTouchedDisplay = useDeviceDetection();
  const { isSmall, isMedium } = useWindowDimensions();
  const isAdaptive = isSmall || isMedium;
  const { teamRef } = useRefsContext();
  const [itemHovered, setItemHovered] = useState<boolean>(false);

  function toggleSubmenu(key: ENavigationListKeys, isFullWidth: boolean) {
    setSubmenuCollectionStatuses((prev) => {
      const updatedStatuses = { ...prev };

      for (const submenuKey in updatedStatuses) {
        if (submenuKey !== key) {
          updatedStatuses[submenuKey as ENavigationListKeys] = {
            display: false,
          };
        }
      }

      updatedStatuses[key] = {
        display: !updatedStatuses[key].display,
      };

      setIsHeaderAlternative(updatedStatuses[key].display && isFullWidth);

      return updatedStatuses;
    });
  }

  async function clickHandler(item: INavigationItem) {
    switch (item.key) {
      case ENavigationListKeys.Ecosystem:
      case ENavigationListKeys.Community:
      case ENavigationListKeys.Tools:
        isTouchedDisplay && toggleSubmenu(item.key, item.submenu?.fullWidth || false);
        break;
      case ENavigationListKeys.HapiId:
        navigate(ECOSYSTEM_LINKS.hapiId);
        break;
      case ENavigationListKeys.About:
        scrollTo(teamRef);
        break;
      case ENavigationListKeys.Docs:
        window.open(gitbook, "_blank");
        break;
      case ENavigationListKeys.BuyCrypto: {
        const paymentURL = await getPaymentURL();
        if (!paymentURL) return null;
        window.open(paymentURL, "_blank");
        break;
      }
    }
  }

  function mouseEnterHandler(item: INavigationItem) {
    setItemHovered(true);
    if (isTouchedDisplay || !item.submenu || submenuCollectionStatuses[item.key].display) return;

    setSubmenuCollectionStatuses((prev) => {
      setIsHeaderAlternative(item.submenu?.fullWidth || false);

      return {
        ...prev,
        [item.key]: {
          display: true,
        },
      };
    });
  }

  function mouseLeaveHandler(item: INavigationItem) {
    setItemHovered(false);
    if (isTouchedDisplay || !item.submenu || !submenuCollectionStatuses[item.key].display) return;

    setSubmenuCollectionStatuses((prev) => {
      setIsHeaderAlternative(false);

      return {
        ...prev,
        [item.key]: {
          display: false,
        },
      };
    });
  }

  if (isAdaptive && !item.showOnMobile) return null;

  return (
    <styles.NavItemContainer
      disabled={item.disabled}
      $isHeaderAlternative={isHeaderAlternative}
      $isGradient={item.key === ENavigationListKeys.HapiId}
      $isFullwidthSubmenu={item.submenu?.fullWidth || false}
      onClick={() => clickHandler(item)}
      onMouseEnter={() => mouseEnterHandler(item)}
      onMouseLeave={() => mouseLeaveHandler(item)}
    >
      {item.backgroundFigure ? (
        <styles.BgFigure>
          <styles.IconTextContainer $isHeaderAlternative={isHeaderAlternative}>
            <span>{t(`Navigation.${item.key}`)}</span>
            {item.icon && (isAdaptive ? item.icon.componentAdaptive : item.icon.component)}
          </styles.IconTextContainer>
          {item.backgroundFigure.component(isHeaderAlternative)}
        </styles.BgFigure>
      ) : (
        <>
          <span>{t(`Navigation.${item.key}`)}</span>
          <AnimatePresence>
            {item.icon && itemHovered && (
              <motion.span
                initial={{ width: 0, opacity: 0 }}
                animate={{ width: 24, opacity: 1 }}
                exit={{ width: 0, opacity: 0 }}
              >
                {item.icon.component}
              </motion.span>
            )}
          </AnimatePresence>
        </>
      )}
      {item.label && (
        <styles.Label $colorText={item.label.colorText} $colorBg={item.label.colorBg}>
          {item.label.title}
        </styles.Label>
      )}
      {item.submenu && submenuCollectionStatuses[item.key].display && item.submenu.component(toggleSubmenu)}
    </styles.NavItemContainer>
  );
}
