import styled, { css } from "styled-components";

export const ReferralCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1rem;
      `,
    )};
`;

export const InputsContainer = styled.div<{ $isError: boolean }>`
  display: flex;
  justify-content: space-between;

  input {
    width: 2rem;
    height: 3rem;
    font-size: 1.25rem;
    padding: 0.625rem 0;
    text-align: center;
    border-radius: 0.5rem;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.archivo};
    border: 1px solid ${({ theme, $isError }) => ($isError ? theme.colors.redError : theme.colors.hapiGrey40)};

    &:focus {
      outline: none;
      border: 1px solid ${({ theme }) => theme.colors.hapiYellow1};
    }

    ${({ theme }) =>
      theme.mediaWidth(
        "upToSmall",
        css`
          width: 2.125rem;
          height: 2.875rem;
          font-size: 1.25rem;
          border-radius: 0.5rem;
        `,
      )};
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 0.5rem;
      `,
    )};
`;

export const ErrorMessage = styled.div`
  gap: 0.5rem;
  display: flex;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  align-items: center;
  color: ${({ theme }) => theme.colors.redError};

  svg {
    height: auto;
    width: 1rem;

    path {
      fill: ${({ theme }) => theme.colors.redError};
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 0.875rem;

        svg {
          width: 1rem;
        }
      `,
    )};
`;

export default {
  ReferralCodeContainer,
  InputsContainer,
  ErrorMessage,
};
