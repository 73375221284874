import { LoadingSpinner } from "shared/components/LoadingSpinner";

import { IButtonProps } from "./interfaces";
import styles from "./styles";

export function ButtonPrimary({
  name,
  handler,
  isOutlined = false,
  fullWidth = false,
  isCentered = false,
  disabled,
  clearPadding = false,
  smallHeight = false,
  smallFont = false,
  isSim = false,
  isLoading = false,
  iconLeft,
  iconRight,
  simImage,
}: IButtonProps) {
  function onClickHandler(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    handler();
  }

  return (
    <styles.ButtonPrimaryWrapper
      type="button"
      onClick={onClickHandler}
      $isOutlined={isOutlined}
      $fullWidth={fullWidth}
      $isCentered={isCentered}
      disabled={disabled}
      $clearPadding={clearPadding}
      $smallHeight={smallHeight}
      $smallFont={smallFont}
      $isSim={isSim}
      $simImage={Boolean(simImage)}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {iconLeft || null}
          {name}
          {iconRight || null}
          {isSim && <styles.ButtonSim />}
          {simImage && <styles.SimImage>{simImage}</styles.SimImage>}
        </>
      )}
    </styles.ButtonPrimaryWrapper>
  );
}
