import { createAsyncThunk } from "@reduxjs/toolkit";
import { readContract, waitForTransactionReceipt, writeContract } from "@wagmi/core";
import { TransactionReceipt, Address } from "viem";

import { contractStaking } from "services/config";
import { walletConfig } from "services/wallet";
import { tokenAbi } from "shared/contract/abi/token";
import { EStakeUnstakeStage } from "shared/interfaces/modal";
import { ThunkApiConfig } from "store";
import { setStakingStage } from "store/slices/staking";

export const approve = createAsyncThunk<
  TransactionReceipt | null,
  {
    address: Address;
    amount: bigint;
  },
  ThunkApiConfig
>("staking/approve", async ({ address, amount }, { getState, dispatch }) => {
  try {
    dispatch(setStakingStage(EStakeUnstakeStage.ENABLE_SPENDING_PROCESSING));

    const token = getState().staking.stakingFieldsData?.token;
    if (!token) throw new Error("Token is undefined");

    const allowance = await readContract(walletConfig, {
      address: token,
      abi: tokenAbi,
      functionName: "allowance",
      args: [address, contractStaking],
    });

    if (allowance >= amount) {
      dispatch(setStakingStage(EStakeUnstakeStage.STAKING_UNSTAKING));
      return null;
    }

    const approveHash = await writeContract(walletConfig, {
      address: token,
      abi: tokenAbi,
      functionName: "approve",
      account: address,
      args: [contractStaking, amount],
    });

    const receipt = await waitForTransactionReceipt(walletConfig, { hash: approveHash });

    dispatch(setStakingStage(EStakeUnstakeStage.STAKING_UNSTAKING));

    return receipt;
  } catch (e) {
    dispatch(setStakingStage(EStakeUnstakeStage.FAIL));
    console.error(`Error during aprrove:  \n ${e}`);
    return null;
  }
});
