import styled, { css } from "styled-components";

import { getFadeIn } from "shared/theme/animations";

const TariffsContainer = styled.div<{ isInView: boolean }>`
  gap: 2.5rem;
  display: flex;
  align-items: center;
  border-radius: 1.5rem;
  padding: 5rem 3.75rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.asphalt};
  ${({ isInView }) => getFadeIn(isInView)}

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        padding: 3.75rem 2rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1.5rem;
        padding: 2rem 1rem;
      `,
    )};
`;

const TariffBlocks = styled.div`
  gap: 1.5rem;
  display: grid;
  width: 53.25rem;
  max-width: 100%;
  grid-template-columns: repeat(2, 1fr);

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        grid-template-columns: 1fr;
      `,
    )};
`;

const Tariff = styled.div<{ $isGradientBG?: boolean }>`
  gap: 2rem;
  display: flex;
  padding: 1.5rem;
  overflow: hidden;
  position: relative;
  border-radius: 1.5rem;
  min-height: 21.6875rem;
  flex-direction: column;
  background: ${({ $isGradientBG, theme }) => ($isGradientBG ? theme.colors.pinkToBlueGradient : "transparent")};
  border: ${({ $isGradientBG, theme }) => ($isGradientBG ? "transparent" : `2px solid ${theme.colors.whiteOp2}`)};

  button {
    z-index: 2;
    height: 2.5rem;
  }

  ${({ $isGradientBG }) =>
    !$isGradientBG &&
    css`
      &:after {
        content: "";
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: calc(100% - 11rem);
        left: calc(100% - 12.5rem);
        background-repeat: no-repeat;
        background-size: auto 18.9375rem;
        background-image: url("/static/snap/backgrounds/snap-tariff-bg.png");
      }

      ${({ theme }) =>
        theme.mediaWidth(
          "upToMedium",
          css`
            &:after {
              top: calc(100% - 12.5rem);
              left: calc(100% - 11.25rem);
            }
          `,
        )};

      ${({ theme }) =>
        theme.mediaWidth(
          "upToSmall",
          css`
            &:after {
              top: calc(100% - 10.5rem);
              left: calc(100% - 12.25rem);
            }
          `,
        )};
    `}
`;

const TitleDescriptionLabel = styled.div`
  gap: 1rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  font-weight: 400;
  line-height: 120%;
  font-size: 1.25rem;
  letter-spacing: -0.025rem;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};
`;

const Label = styled.div`
  font-weight: 600;
  line-height: 160%;
  font-size: 0.625rem;
  border-radius: 6.25rem;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  background: rgba(253, 238, 45, 0.2);
`;

const TariffIncludes = styled.div`
  z-index: 2;
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.63rem;
`;

const TariffIncludesItem = styled.div`
  gap: 0.5rem;
  display: flex;
  font-weight: 600;
  line-height: 120%;
  font-size: 0.875rem;
  align-items: center;
`;

export default {
  TariffsContainer,
  TariffBlocks,
  Tariff,
  TitleDescriptionLabel,
  Title,
  Label,
  TariffIncludes,
  TariffIncludesItem,
};
