import { t } from "i18next";

import { SocialsIconMap } from "shared/constants/socials";
import { ITeamMember } from "shared/interfaces";

import styles from "./styles";

export function TeamMember({ name, image, socials, color }: ITeamMember) {
  return (
    <styles.Container>
      <styles.ImageContainer color={color}>
        <img src={image} alt={name} />
      </styles.ImageContainer>
      <styles.Name>{t(`Team.${name}.Name`)}</styles.Name>
      <styles.Position>{t(`Team.${name}.Position`)}</styles.Position>
      {Boolean(socials.length) && (
        <styles.SocialsWrapper>
          {socials.map((social) => (
            <a key={social.type} href={social.value} target="_blank" rel="noreferrer">
              {SocialsIconMap[social.type]}
            </a>
          ))}
        </styles.SocialsWrapper>
      )}
    </styles.Container>
  );
}
