import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { useSendUsernameMutation } from "services/api/hapi/ref";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import ModalWrapper from "shared/components/modals/ModalWrapper";
import ToastService from "shared/components/Toast";
import { EMPTY_STRING } from "shared/constants";
import { IModalProps } from "shared/interfaces/modal";
import theme from "shared/theme";

import styles from "./styles";

export function EditNameModal({ closeModal }: IModalProps) {
  const { t } = useTranslation();
  const [name, setName] = useState(EMPTY_STRING);
  const [setUsername] = useSendUsernameMutation();

  const handler = async () => {
    try {
      const response = await setUsername({ username: name });
      if ("error" in response) throw response.error;
      if (response.data.errorCode !== 0) {
        throw Error(`code: ${response.data.errorCode}, message: ${response.data.message}`);
      }
      closeModal();
    } catch (error) {
      ToastService.error({ text: "Error" });
      console.error("Error: while change username \n\n", error);
    }
  };
  return (
    <ModalWrapper closeModal={closeModal} bgColor={theme.colors.registerCardBg} widthInRem="23.5">
      <styles.Container>
        <styles.Header>
          <styles.Title>{t("Dashboard.EditName.Title")}</styles.Title>
          <styles.Close>
            <CloseIcon onClick={closeModal} />
          </styles.Close>
        </styles.Header>
        <styles.Body>
          <p>{t("Dashboard.EditName.Name")}</p>
          <input value={name} onChange={(e) => setName(e.target.value)} />
        </styles.Body>
        <styles.ButtonContainer>
          <ButtonPrimary name={t("Dashboard.Actions.Cancel")} handler={closeModal} />
          <ButtonPrimary name={t("Dashboard.Actions.Save")} handler={handler} />
        </styles.ButtonContainer>
      </styles.Container>
    </ModalWrapper>
  );
}
