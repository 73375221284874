import { t } from "i18next";

import { ReactComponent as BurnIcon } from "assets/icons/tokenUtility/burn.svg";
import { ReactComponent as DaoIcon } from "assets/icons/tokenUtility/dao.svg";
import { ReactComponent as StakingIcon } from "assets/icons/tokenUtility/staking.svg";
import { TokenDistributionType } from "shared/interfaces/tokenDistribution";
import theme from "shared/theme";

export const styleDistributionType: Record<TokenDistributionType, { bg: string }> = {
  staking: { bg: theme.colors.stakingCardBg },
  dao: { bg: theme.colors.daoCardBg },
  burn: { bg: theme.colors.burnCardBg },
};

export const titleDistributionTypeMap: Record<TokenDistributionType, string> = {
  staking: t("TokenUtility.StakingProgram"),
  dao: t("TokenUtility.DAOInitiatives"),
  burn: t("TokenUtility.Burn"),
};

export const iconDistributionTypeMap: Record<TokenDistributionType, JSX.Element> = {
  staking: <StakingIcon />,
  dao: <DaoIcon />,
  burn: <BurnIcon />,
};
