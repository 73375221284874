import styled from "styled-components";

const HeaderContainer = styled.header<{ $isHeaderAlternative: boolean }>`
  z-index: 10;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.94rem 4rem;
  justify-content: space-between;
  background-color: ${({ theme, $isHeaderAlternative }) =>
    $isHeaderAlternative ? theme.colors.white : theme.colors.hapiDark};
  transition: ${({ theme }) => theme.transitions.changeTransition};

  button {
    z-index: 10;

    &:last-child {
      color: ${({ theme, $isHeaderAlternative }) =>
        $isHeaderAlternative ? theme.colors.hapiGrey80 : theme.colors.hapiYellow1};
      border-color: ${({ theme, $isHeaderAlternative }) =>
        $isHeaderAlternative ? theme.colors.whiteOpacity02 : theme.colors.hapiYellowOp02};
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      `
        padding: 0.25rem 1rem;
      `,
    )};
`;

const styles = {
  HeaderContainer,
};

export default styles;
