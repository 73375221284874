/* eslint-disable max-len */
"use client";
import useWindowDimensions from "shared/hooks/useWindowDimensions";

export const BorderForBlog = () => {
  const { isSmall, isMedium, isLarge } = useWindowDimensions();
  if (isSmall || isMedium) {
    return (
      <svg width="247" height="66" viewBox="0 0 247 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.00008 65.1147C1.00008 42.6725 1.00013 31.4513 5.36519 22.8795C9.20481 15.3395 15.3314 9.20931 22.8671 5.3675C31.434 0.999947 42.6488 0.999952 65.0782 0.999954L153.187 1.00008C162.983 1.00008 167.881 1.00008 172.49 2.10726C176.576 3.08889 180.483 4.70797 184.066 6.90505C188.108 9.38316 191.571 12.8484 198.497 19.7789L200.798 22.0803C207.724 29.0108 211.187 32.4761 215.229 34.9542C218.812 37.1513 222.719 38.7703 226.805 39.752C231.414 40.8591 236.312 40.8591 246.108 40.8591"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    );
  }
  if (isLarge) {
    return (
      <svg width="447" height="66" viewBox="0 0 447 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.00201 65.1147C1.00202 42.6725 1.00207 31.4513 5.36713 22.8794C9.20675 15.3395 15.3334 9.2093 22.8691 5.36749C31.436 0.99993 42.6508 0.999934 65.0803 0.999936L353.191 0.999961C362.987 0.999962 367.884 0.999954 372.493 2.10714C376.58 3.08877 380.486 4.70785 384.07 6.90493C388.111 9.38304 391.575 12.8483 398.501 19.7788L400.801 22.0802C407.728 29.0107 411.191 32.476 415.233 34.9541C418.816 37.1512 422.722 38.7702 426.809 39.7518C431.418 40.859 436.316 40.859 446.111 40.859"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    );
  }
  return (
    <svg width="569" height="66" viewBox="0 0 569 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.00201 65.1147C1.00202 42.6724 1.00207 31.4512 5.36713 22.8794C9.20675 15.3395 15.3334 9.20929 22.8691 5.36748C31.436 0.999919 42.6508 0.999924 65.0803 0.999926L475.191 0.999961C484.987 0.999962 489.884 0.999954 494.493 2.10714C498.58 3.08877 502.486 4.70785 506.07 6.90493C510.111 9.38304 513.575 12.8483 520.501 19.7788L522.801 22.0802C529.728 29.0107 533.191 32.476 537.233 34.9541C540.816 37.1512 544.722 38.7702 548.809 39.7518C553.418 40.859 558.316 40.859 568.111 40.859"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
