import { useState, useEffect } from "react";

import { MEDIA_WIDTHS } from "shared/constants";

export interface WindowDimensions {
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isExtraLarge: boolean;
}

const initialDimensions: WindowDimensions = {
  isSmall: false,
  isMedium: false,
  isLarge: false,
  isExtraLarge: false,
};

function getWindowDimensions() {
  const { innerWidth: width } = window;

  if (width >= MEDIA_WIDTHS.upToLarge) return { ...initialDimensions, isExtraLarge: true };
  if (width >= MEDIA_WIDTHS.upToMedium && width < MEDIA_WIDTHS.upToLarge)
    return { ...initialDimensions, isLarge: true };
  if (width >= MEDIA_WIDTHS.upToSmall && width < MEDIA_WIDTHS.upToMedium)
    return { ...initialDimensions, isMedium: true };
  if (width < MEDIA_WIDTHS.upToSmall) return { ...initialDimensions, isSmall: true };
  return initialDimensions;
}

const useWindowDimensions = () => {
  const [windowDimension, setWindowDimension] = useState<typeof initialDimensions>(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      const dimension = getWindowDimensions();
      if (JSON.stringify(dimension) !== JSON.stringify(windowDimension)) setWindowDimension(dimension);
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return windowDimension;
};

export default useWindowDimensions;
