import styled, { css } from "styled-components";

const FooterContainer = styled.footer<{ $marginTopInRem?: number }>`
  margin-top: ${({ $marginTopInRem }) => $marginTopInRem || 12}rem;
  padding: 3rem 2rem;
  background-color: ${({ theme }) => theme.colors.footerBg};

  ${({ theme, $marginTopInRem }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        margin-top: ${$marginTopInRem || 12}rem;
      `,
    )};
`;

const Title = styled.div`
  font-weight: 700;
  line-height: 120%;
  font-size: 0.75rem;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
`;

const List = styled.div<{ $isSingleColumn?: boolean }>`
  display: grid;
  grid-row-gap: 1.5rem;
  grid-column-gap: 3rem;
  grid-template-columns: ${({ $isSingleColumn }) => ($isSingleColumn ? "repeat(1, 1fr)" : "repeat(2, 1fr)")};

  ${({ theme }) => theme.mediaWidth("upToSmall", `grid-template-columns: repeat(1, 1fr);`)};
`;

const ListItem = styled.div`
  cursor: pointer;
  font-weight: 500;
  min-width: 17rem;
  line-height: 140%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.hapiGrey5};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  ${({ theme }) => theme.mediaWidth("upToSmall", `min-width: unset;`)};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const FirstRow = styled.div`
  gap: 3rem;
  display: flex;
  ${({ theme }) => theme.mediaWidth("upToSmall", `flex-direction: column;`)};
`;

// TODO: add margin-top, when first row will be available
const SecondRow = styled.div`
  display: flex;
  /* margin-top: 6.38rem; */
  align-items: center;
  justify-content: space-between;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        gap: 3rem;
        flex-direction: column;
        `,
    )};
`;

const Copyright = styled.div`
  font-weight: 500;
  line-height: 160%;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.hapiGrey40};
  ${({ theme }) => theme.mediaWidth("upToSmall", `order: 1;`)};
`;

const Socials = styled.div`
  gap: 1rem;
  display: flex;
`;

const Social = styled.div`
  display: flex;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:hover {
    opacity: 0.8;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const styles = {
  FooterContainer,
  Title,
  List,
  ListItem,
  FirstRow,
  SecondRow,
  Copyright,
  Socials,
  Social,
};

export default styles;
