import { css, styled } from "styled-components";

import { getFadeIn } from "shared/theme/animations";

const AdvantagesContainer = styled.div<{ isInView: boolean }>`
  gap: 0.63rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  ${({ isInView }) => getFadeIn(isInView)};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1rem;
        grid-template-columns: 1fr;
      `,
    )};
`;

const AdvantagesBlock = styled.div`
  display: flex;
  padding: 1.5rem;
  min-height: 21.25rem;
  border-radius: 1.5rem;
  flex-direction: column;
  justify-content: space-between;
  backdrop-filter: blur(60px);
  background: ${({ theme }) => theme.colors.snapSurfaceBG054};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        gap: 1rem;
        min-height: unset;
        justify-content: flex-start;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        min-height: 13.75rem;
        justify-content: space-between;
      `,
    )};
`;

const Title = styled.div`
  flex: 1;
  font-size: 2rem;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.04rem;
  font-family: ${({ theme }) => theme.fonts.archivoBlack};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        order: 2;
        font-size: 1.625rem;
        letter-spacing: -0.0325rem;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        order: 1;
        font-size: 1.5rem;
        letter-spacing: -0.03rem;
      `,
    )};
`;

const TitleIcon = styled.div`
  gap: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToLarge",
      css`
        flex-direction: column;
        align-items: flex-start;
      `,
    )};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        svg {
          order: 2;
          width: 2rem;
          height: auto;
        }
      `,
    )};
`;

export default {
  AdvantagesContainer,
  AdvantagesBlock,
  Title,
  TitleIcon,
};
