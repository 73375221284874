import { ReactElement } from "react";

import { TableProps } from "./interface";
import styles from "./styles";

export const Table = ({ data, additionHeadColor }: TableProps) => {
  return (
    <styles.Table>
      <styles.THead>
        <tr>
          {data.header.map((headerItem, index) => (
            <styles.th key={`${index}-${headerItem}`} $spanColor={additionHeadColor}>
              {headerItem.map((el: string | ReactElement, index) => (
                <p key={`${index}-${el}-${index}`}>{el}</p>
              ))}
            </styles.th>
          ))}
        </tr>
      </styles.THead>
      <styles.TBody>
        {data.rows.map((row, rowIndex) => (
          <tr key={`${rowIndex}-${row}`}>
            {row.cell.map((cell, cellIndex) => (
              <styles.td key={`${cellIndex}-${cell}`}>{cell}</styles.td>
            ))}
          </tr>
        ))}
      </styles.TBody>
    </styles.Table>
  );
};
