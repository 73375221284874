import { motion } from "framer-motion";
import styled from "styled-components";

const BackgroundLayout = styled(motion.div)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 199;
  display: flex;
  overflow: auto;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.modalBG};
`;

const Modal = styled(motion.div)<{
  $widthInRem: string;
  $isCornerPosition?: boolean;
  $bgColor?: string;
  $clearPadding?: boolean;
}>`
  z-index: 200;
  gap: 1.5rem;
  display: flex;
  max-width: 90%;
  overflow: auto;
  max-height: 90vh;
  overflow: hidden;
  align-items: center;
  border-radius: 1.5rem;
  flex-direction: column;
  width: ${({ $widthInRem }) => `${$widthInRem}rem`};
  padding: ${({ $clearPadding }) => ($clearPadding ? 0 : "1.5rem 0")};
  background-color: ${({ theme, $bgColor }) => $bgColor || theme.colors.hapiGrey80};
  ${({ $isCornerPosition }) =>
    $isCornerPosition &&
    `
    position: fixed;
    right: 1.75rem;
    bottom: 1.75rem;
  `}
`;

const styles = {
  BackgroundLayout,
  Modal,
};

export default styles;
