import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import { ReactComponent as Telegram } from "assets/icons/socials/telegram-2.svg";
import { ReactComponent as StepSim } from "assets/icons/step-sim.svg";
import { useSendTelegramDataMutation } from "services/api/hapi/ref";
import { botName } from "services/config";
import { logger } from "services/mixpanel";
import TelegramLoginButton, { TelegramUser } from "shared/components/Buttons/TelegramButton";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { ERegistrationConnectorType } from "shared/interfaces";
import { showModal, EModals } from "store/slices/modals";

import styles from "../../styles";

interface IRegistrationCard {
  name: string;
  step: number;
  disabled: boolean;
  account: string | null;
  refetch: () => void;
}

export function TelegramCard({ step, name, disabled, account, refetch }: IRegistrationCard) {
  const dispatch = useAppDispatch();
  const [sendTelegram] = useSendTelegramDataMutation();

  const telegramHandler = async (user: TelegramUser) => {
    if (disabled) return;
    logger.trackEvent("REF_TELEGRAM");
    const response = await sendTelegram(user);
    if ("data" in response) refetch();
    if ("data" in response && response.data.errorCode === 1) {
      dispatch(showModal({ modal: EModals.ACCOUNT_DUPLICATION, props: { type: ERegistrationConnectorType.Telegram } }));
    }
  };

  return (
    <styles.Card $disabled={disabled}>
      <styles.CardHeader>
        <Telegram />
        <p>{name}</p>
        <styles.Step>
          {step}
          <StepSim />
        </styles.Step>
      </styles.CardHeader>
      {account ? (
        <styles.ConnectedContainer>
          <DoneIcon />
          <span>{account}</span>
        </styles.ConnectedContainer>
      ) : (
        <TelegramLoginButton botName={botName} cornerRadius={8} dataOnauth={telegramHandler} />
      )}
    </styles.Card>
  );
}
