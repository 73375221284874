import { ReactElement } from "react";

import useWindowDimensions from "shared/hooks/useWindowDimensions";

import styles from "./styles";

interface IHeaderBannerProps {
  text: string;
  description?: string | ReactElement;
  button?: {
    name: string;
    handler: () => void;
    icon?: JSX.Element;
  };
  icon?: JSX.Element;
}

export function HeaderBanner({ text, description, button, icon }: IHeaderBannerProps) {
  const { isSmall } = useWindowDimensions();
  return (
    <styles.Container>
      {icon && !isSmall ? icon : null}
      <styles.Text>
        {text}
        {description && <span>{description}</span>}
      </styles.Text>
      {button && (
        <styles.Button onClick={button.handler}>
          {button.name}
          {button.icon && button.icon}
        </styles.Button>
      )}
    </styles.Container>
  );
}
