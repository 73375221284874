import { getData } from "./data";
import styles from "./styles";

export const AdditionalInfo: React.FC = () => {
  const data = getData();

  return (
    <styles.AdditionalInfoContainer>
      {data.map(({ title, description }) => (
        <styles.AdditionalInfoBlock key={title}>
          <styles.Title>{title}</styles.Title>
          <styles.Description>{description}</styles.Description>
        </styles.AdditionalInfoBlock>
      ))}
    </styles.AdditionalInfoContainer>
  );
};
