import styled from "styled-components";

const InputWrapper = styled.input<{ $error?: boolean; $withIcon?: boolean }>`
  width: 100%;
  outline: none;
  height: 3.375rem;
  font-weight: 600;
  background: none;
  line-height: 120%;
  font-style: normal;
  font-size: 1.25rem;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ $withIcon }) => ($withIcon ? "0 7.5rem 0 2.5rem" : "0 7.5rem 0 0.5rem")};
  border: 1px solid ${({ theme, $error }) => ($error ? theme.colors.inputError : theme.colors.hapiGrey60)};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      `
        font-size: 1rem;
        height: 2.75rem;
      `,
    )};

  &:focus,
  &:active {
    border: 1px solid ${({ theme, $error }) => ($error ? theme.colors.inputError : theme.colors.white)};
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const AdditionalInputHandlerContainer = styled.div`
  position: relative;

  span {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
`;

const Handler = styled.div`
  cursor: pointer;
  font-weight: 700;
  text-align: right;
  line-height: 140%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    opacity: 0.9;
  }
`;

const LabelWrapper = styled.div<{ $isAdditionalLabel?: boolean }>`
  display: grid;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  grid-template-columns: ${({ $isAdditionalLabel }) => ($isAdditionalLabel ? "1fr 1fr" : "1fr")};
`;

const IconWrapper = styled.div`
  top: 50%;
  left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  transform: translateY(-50%);
`;

const TextButton = styled.div`
  position: absolute;
  top: 50%;
  gap: 0.75rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  transform: translateY(-50%);

  div {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &:after {
    order: 2;
    content: "";
    width: 1px;
    height: 1rem;
    border-radius: 0.0625rem;
    background-color: ${({ theme }) => theme.colors.hapiGrey60};
  }

  button {
    order: 3;
    border: 0;
    cursor: pointer;
    height: 1.75rem;
    font-weight: 700;
    font-size: 0.66675rem;
    border-radius: 0.25rem;
    padding: 0.37506rem 0.50006rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.socialCircle};
    transition: ${({ theme }) => theme.transitions.hoverTransition};

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.4;
    }
  }
`;

const styles = {
  InputWrapper,
  AdditionalInputHandlerContainer,
  Handler,
  LabelWrapper,
  IconWrapper,
  TextButton,
};

export default styles;
