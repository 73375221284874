import Big from "big.js";
import { useTranslation } from "react-i18next";

import { ReactComponent as ReferralIcon } from "assets/icons/dashboard/referrals.svg";
import { ReactComponent as StakingIcon } from "assets/icons/dashboard/staking.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { useGetHapiPriceQuery, useGetReferralsQuery } from "services/api/hapi/ref";
import { logger } from "services/mixpanel";
import { EMPTY_STRING, ZERO_STRING } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { useLoggerNavigate } from "shared/hooks/useLoggerNavigate";
import { colors } from "shared/theme/colors";
import { displayAmount } from "shared/utils/displayAmount";
import { getUserFriendlyAmount } from "shared/utils/getUserFriendlyAmount";
import { EModals, showModal } from "store/slices/modals";
import { selectStakingData, selectUserStaking } from "store/slices/staking";
import { selectToken } from "store/slices/token";
import { selectUserTicketId } from "store/slices/whitelist";

import { RewardCard } from "./components/RewardCard";
import styles from "./styles";
import { calculateAPY, calculateRewardPer30Days } from "../Staking/utils";

export function Rewards() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data } = useGetReferralsQuery();
  const { data: hapiPriceData } = useGetHapiPriceQuery();

  const { staked, staker, tierAPY, stakingFieldsData } = useAppSelector(selectStakingData);
  const userHapiId = useAppSelector(selectUserTicketId);
  const { data: userData } = useAppSelector(selectUserStaking);
  const token = useAppSelector((state) => selectToken(state, stakingFieldsData?.token));
  const symbol = token?.symbol || EMPTY_STRING;
  const navigate = useLoggerNavigate();

  const hapiPrice = hapiPriceData?.price || ZERO_STRING;
  const rewardDirect = data?.data?.level1.reward || ZERO_STRING;
  const rewardIndirect = data?.data?.level2.reward || ZERO_STRING;
  const reward = Big(rewardDirect).add(rewardIndirect);
  const rewardUSD = reward.mul(hapiPrice).toFixed(2);

  const claimStakeReward = () => {
    logger.trackEvent("STAKE_MORE", { from: window.location.href });
    navigate(APP_ROUTES.DASHBOARD.STAKING);
  };

  const claimReferralReward = () => {
    logger.trackEvent("CLAIM_REFERRAL_REWARD");
    dispatch(showModal({ modal: EModals.CLAIM_MODAL, props: { claimAmount: reward, symbol } }));
  };

  const incomeAmount = calculateRewardPer30Days(
    Big(staker?.amount.formatted || 0),
    calculateAPY(
      Boolean(userData?.is_whale),
      !!userHapiId,
      Boolean(staker?.unlockTimestamp),
      tierAPY,
      stakingFieldsData,
    ),
  );

  return (
    <styles.Container>
      <styles.Title>
        <p>{t("Dashboard.Rewards.Title")}</p>
        {/* <p>{t("Dashboard.Rewards.Fee")}</p> */}
      </styles.Title>
      <RewardCard
        bgColor={colors.stakingDashboardOp02}
        name={t("Dashboard.Staking.YourStake")}
        icon={<StakingIcon color={colors.stakingDashboard} />}
        claimHandler={claimStakeReward}
        buttonTitle={t("Dashboard.Staking.StakeMore")}
      >
        <p>
          {displayAmount(staked?.formatted)} {symbol}
        </p>
        <p>
          {t("Dashboard.Staking.Reward.PerMonth", {
            amount: incomeAmount,
            symbol,
          })}
        </p>
      </RewardCard>
      <RewardCard
        bgColor={colors.referralsDashboardOp02}
        name={t("Dashboard.Referrals.Title")}
        icon={<ReferralIcon color={colors.referralsDashboard} />}
        claimHandler={claimReferralReward}
        buttonTitle={t("Dashboard.Actions.Claim")}
      >
        <p>
          {getUserFriendlyAmount(reward)} {symbol}
        </p>
        <p>${rewardUSD}</p>
      </RewardCard>
    </styles.Container>
  );
}
