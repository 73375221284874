import theme from "shared/theme";

import { IAdvantageCardProps } from "./interface";
import styles from "./styles";

export default function AdvantageCard({
  title,
  icon,
  description,
  borderColor = theme.colors.hapiDark,
  turnRight = false,
  mt = 0,
}: IAdvantageCardProps) {
  return (
    <styles.AdvantageCardContainer $turnRight={turnRight} $borderColor={borderColor} $mt={mt}>
      <div>
        <styles.TitleIcon>
          <styles.Title>{title}</styles.Title>
          <div>{icon}</div>
        </styles.TitleIcon>
        <styles.Description>{description}</styles.Description>
      </div>
    </styles.AdvantageCardContainer>
  );
}
