import styled from "styled-components";

import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow-icon.svg";

import { ArrowProps } from "./interfaces";

const StylesArrow = styled.div<{ $left: boolean }>`
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.whiteOp1};
  border-radius: 50%;
  cursor: pointer;
  svg {
    transform: ${({ $left }) => ($left ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;

export const Arrow = ({ onClick, left = false }: ArrowProps) => (
  <StylesArrow onClick={onClick} $left={left}>
    <ArrowIcon />
  </StylesArrow>
);
