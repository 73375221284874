import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ReactComponent as HomeIcon } from "assets/icons/dashboard/home.svg";
import { ReactComponent as LeaderboardIcon } from "assets/icons/dashboard/leaderboard.svg";
import { ReactComponent as ReferralsIcon } from "assets/icons/dashboard/referrals.svg";
import { ReactComponent as StakingIcon } from "assets/icons/dashboard/staking.svg";
import { APP_ROUTES } from "routes/appRoutes";
import { useLoggerNavigate } from "shared/hooks/useLoggerNavigate";

import styles from "./styles";

const DASHBOARD_ROUTES: Array<{ name: string; route: string; icon: ReactElement; disabled: boolean }> = [
  {
    name: "Home",
    route: APP_ROUTES.DASHBOARD.HOME,
    icon: <HomeIcon />,
    disabled: false,
  },
  {
    name: "Staking",
    route: APP_ROUTES.DASHBOARD.STAKING,
    icon: <StakingIcon />,
    disabled: false,
  },
  {
    name: "Referrals",
    route: APP_ROUTES.DASHBOARD.REFERRALS,
    icon: <ReferralsIcon />,
    disabled: false,
  },
  {
    name: "Leaderboard",
    route: APP_ROUTES.DASHBOARD.LEADERBOARD,
    icon: <LeaderboardIcon />,
    disabled: false,
  },
];

export const Navigation = () => {
  const { t } = useTranslation();
  const navigate = useLoggerNavigate();
  const location = useLocation();
  return (
    <styles.Container className="no-scrollbar">
      {DASHBOARD_ROUTES.map(({ name, route, icon, disabled }) => (
        <styles.NavItem
          key={route}
          onClick={() => !disabled && navigate(route)}
          disabled={disabled}
          $isActive={route === location.pathname}
        >
          {icon}
          {t(`Dashboard.Routes.${name}`)}
        </styles.NavItem>
      ))}
    </styles.Container>
  );
};
