import styled, { css } from "styled-components";

import { ReactComponent as HapiSnap } from "assets/images/snap/logo/hapisnap-logo.svg";

const HeaderContainer = styled.header`
  display: flex;
  padding: 0.26rem 0;
  position: relative;
  align-items: center;
  margin-top: 0.68rem;
  justify-content: space-between;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        position: unset;
        margin-bottom: 1rem;
        justify-content: unset;

        a + button {
          margin: 0 0 0 auto;
        }
      `,
    )};
`;

const Menu = styled.div`
  top: 50%;
  left: 50%;
  gap: 2rem;
  display: flex;
  position: absolute;
  align-items: center;
  transform: translate(-50%, -50%);

  a {
    gap: 0.5rem;
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    line-height: 150%;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.whiteOp6};
    transition: ${({ theme }) => theme.transitions.hoverTransition};

    svg {
      height: auto;
      width: 1.5rem;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        top: 0;
        left: 0;
        gap: 1.5rem;
        z-index: 10;
        width: 100%;
        display: flex;
        height: 100dvh;
        position: fixed;
        transform: unset;
        padding-left: 3.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background-color: ${({ theme }) => theme.colors.snapBlack};

        a {
          font-weight: 700;
          font-size: 1.5rem;
        }
      `,
    )};
`;

const NavItem = styled.div<{ $isUppercase?: boolean }>`
  text-transform: ${({ $isUppercase }) => ($isUppercase ? "uppercase" : "unset")};
`;

const Logo = styled(HapiSnap)`
  width: auto;
  height: 3.125rem;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        height: 2.25rem;
      `,
    )};
`;

export default {
  HeaderContainer,
  Menu,
  NavItem,
  Logo,
};
