import styled, { css } from "styled-components";

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 1rem 4rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 11;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  & > svg {
    cursor: pointer;
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        background-color: ${({ theme }) => theme.colors.hapiDark};
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        padding: 1rem 1.5rem;
        & > svg {
          width: 4.706rem;
          height: 2rem;
        }
        button {
          padding: 0.68763rem 0.50006rem;
          font-size: 0.81263rem;
          font-weight: 700;
          line-height: 140%;
        }
      `,
    )};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        top: -1;
        padding: 1rem 1rem;
      `,
    )};
`;

const Whale = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: 600;
  line-height: 140%;
  user-select: none;
`;

const IconContainer = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6.25rem;
  background: ${({ theme }) => theme.colors.surfaceWhale};
  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const HeaderBannerIcon = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.625rem;
  background: ${({ theme }) => theme.colors.whiteOp2};
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  & > svg {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.hapiGrey20};
    &:hover {
      color: ${({ theme }) => theme.colors.PTError};
    }
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        gap: 1rem;
      `,
    )};
`;

export default {
  Header,
  Whale,
  IconContainer,
  HeaderBannerIcon,
  RightWrapper,
};
