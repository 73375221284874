import { t } from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { ReactComponent as DoneIcon } from "assets/icons/done.svg";
import { ReactComponent as StepSim } from "assets/icons/step-sim.svg";
import { INVITE_CODE } from "routes/appRoutes";
import { useSendRefCodeMutation, useSendUpdateCodeMutation } from "services/api/hapi/ref";
import { logger } from "services/mixpanel";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import ReferralCode from "shared/components/ReferralCode";
import { EMPTY_STRING, MISSING_INVITE_CODE, REFERRAL_CODE_SYMBOLS_AMOUNT } from "shared/constants";

import inviteCodeStyles from "./styles";
import styles from "../../styles";

interface IInviteCardProps {
  name: string;
  step: number;
  inviteCode: string;
  walletConnected: boolean;
  refetch: () => void;
}

export function InviteCard({ step, name, inviteCode, walletConnected, refetch }: IInviteCardProps) {
  const params = useParams<{ [INVITE_CODE]: string }>();
  const isHardcodeInviteCode = inviteCode === MISSING_INVITE_CODE;

  const [sendRefCode] = useSendRefCodeMutation();
  const [updateCode] = useSendUpdateCodeMutation();

  const [isEnteringCode, setIsEnteringCode] = useState<boolean>(Boolean(!isHardcodeInviteCode || params[INVITE_CODE]));
  const [code, setCode] = useState<string>(EMPTY_STRING);
  const [error, setError] = useState<boolean>(false);

  const handleOnChange = (res: string) => {
    setCode(res);
    error && setError(false);
  };

  const confirm = async () => {
    const eventAction = isHardcodeInviteCode && walletConnected ? "UPDATE_REF_CODE" : "SEND_REF_CODE";
    const serviceFunction = isHardcodeInviteCode && walletConnected ? updateCode : sendRefCode;

    logger.trackEvent(eventAction, { code });

    try {
      const response = await serviceFunction({ code }).unwrap();

      if ("token" in response || response.errorCode === 0) {
        refetch();
      } else {
        console.warn(response.message);
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };

  const cancel = () => {
    logger.trackEvent("CANCEL_REF_CODE");
    handleOnChange(EMPTY_STRING);
    setIsEnteringCode(false);
  };

  const enterRefCode = () => {
    logger.trackEvent("ENTER_REF_CODE");
    setIsEnteringCode((prev) => !prev);
  };

  return (
    <styles.Card>
      <styles.CardHeader>
        <p>
          {name}
          {walletConnected && <span>({t("Messages.Optional")})</span>}
        </p>
        <styles.Step>
          {step}
          <StepSim />
        </styles.Step>
      </styles.CardHeader>
      {!isHardcodeInviteCode ? (
        <styles.ConnectedContainer>
          <DoneIcon />
          <span>{inviteCode}</span>
        </styles.ConnectedContainer>
      ) : isEnteringCode ? (
        <inviteCodeStyles.CodeContainer>
          <ReferralCode
            onChange={handleOnChange}
            symbolsAmount={REFERRAL_CODE_SYMBOLS_AMOUNT}
            errorMessage={error ? t("EarlyAccess.Code.Invalid") : undefined}
          />
          <inviteCodeStyles.ButtonGroup>
            <ButtonPrimary name={t("Actions.Cancel")} handler={cancel} />
            <ButtonPrimary name={t("Actions.Confirm")} handler={confirm} isOutlined />
          </inviteCodeStyles.ButtonGroup>
        </inviteCodeStyles.CodeContainer>
      ) : (
        <ButtonPrimary name={t("Actions.EnterCode")} handler={enterRefCode} isOutlined fullWidth />
      )}
    </styles.Card>
  );
}
