import { t } from "i18next";

import { ReactComponent as Aurora } from "assets/icons/networks/aurora.svg";
import { ReactComponent as Base } from "assets/icons/networks/base.svg";
import { ReactComponent as BNB } from "assets/icons/networks/bnb.svg";
import { ReactComponent as Ethereum } from "assets/icons/networks/ethereum.svg";
import { ReactComponent as Linea } from "assets/icons/networks/linea.svg";
import { ReactComponent as Near } from "assets/icons/networks/near.svg";

export const getData = () => [
  {
    name: t("HapiId.Networks.Near"),
    icon: <Near />,
  },
  {
    name: t("HapiId.Networks.Ethereum"),
    icon: <Ethereum />,
  },
  {
    name: t("HapiId.Networks.Aurora"),
    icon: <Aurora />,
  },
  {
    name: t("HapiId.Networks.Linea"),
    icon: <Linea />,
  },
  {
    name: t("HapiId.Networks.BNB"),
    icon: <BNB />,
  },
  {
    name: t("HapiId.Networks.Base"),
    icon: <Base />,
  },
];
